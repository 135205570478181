import { Stack } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { messages } from '../../../data/messages'
import { useIntl } from 'react-intl'
import { IconButton, LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export type FeedbackActionsProps = {
    onFeedback: (feedback: boolean) => void
}

function FeedbackActions(props: FeedbackActionsProps): JSX.Element {
    const { onFeedback } = props
    const intl = useIntl()
    const [feedback, setFeedback] = useState(null)

    const handlePositiveFeedback = useCallback(() => {
        setFeedback(true)
        onFeedback(true)
    }, [onFeedback])

    const handleNegativeFeedback = useCallback(() => {
        setFeedback(false)
        onFeedback(false)
    }, [onFeedback])

    const positiveFeedbackColor = useMemo(() => {
        if (feedback) {
            return 'accent'
        } else if (!feedback) {
            return undefined
        }
    }, [feedback])

    const negativeFeedbackColor = useMemo(() => {
        if (feedback || feedback === null) {
            return undefined
        } else if (!feedback) {
            return 'accent'
        }
    }, [feedback])

    return (
        <Stack direction={'row'} sx={{ whiteSpace: 'nowrap' }}>
            <IconButton
                aria-label={intl.formatMessage(
                    messages.positiveFeedbackAriaLabel
                )}
                color={positiveFeedbackColor}
                onClick={handlePositiveFeedback}
            >
                {
                    <LeafIcon
                        icon={'thumbs-up'}
                        iconStyle={feedback ? 'solid' : 'regular'}
                    />
                }
            </IconButton>
            <IconButton
                aria-label={intl.formatMessage(
                    messages.negativeFeedbackAriaLabel
                )}
                color={negativeFeedbackColor}
                onClick={handleNegativeFeedback}
            >
                {
                    <LeafIcon
                        icon={'thumbs-down'}
                        iconStyle={
                            feedback || feedback === null ? 'regular' : 'solid'
                        }
                    />
                }
            </IconButton>
        </Stack>
    )
}

export default React.memo(FeedbackActions)
