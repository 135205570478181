import {
    Avatar,
    Card,
    CardContent,
    ListItem,
    Skeleton,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import AvatarIcon from '../../../icons/avatar'
import { useIntl } from 'react-intl'
import { messages } from '../../../data/messages'

function GuideResponseLoading(): JSX.Element {
    const theme = useTheme()
    const intl = useIntl()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <ListItem
            sx={{
                justifyContent: 'flex-start',
                maxWidth: '100%',
                alignItems: 'flex-end',
                width: '100%',
                mt: theme.spacing(1)
            }}
        >
            <Avatar
                component={AvatarIcon}
                alt={intl.formatMessage(messages.avatarAriaLabel)}
                scale={20}
                sx={{
                    mr: theme.spacing(1),
                    backgroundColor: 'secondary.main',
                    boxShadow: 2,
                    width: theme.spacing(isMobileView ? 5 : 6),
                    height: theme.spacing(isMobileView ? 5 : 6)
                }}
            ></Avatar>
            <Card sx={{ width: '70%' }}>
                <CardContent>
                    <Skeleton width={'100%'} variant="text" height={20} />
                    <Skeleton width={'80%'} variant="text" height={20} />
                    <Skeleton width={'90%'} variant="text" height={20} />
                </CardContent>
            </Card>
        </ListItem>
    )
}

export default React.memo(GuideResponseLoading)
