export function toTitleCase(str) {
    try {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    } catch (e) {
        console.error(e)
        return str
    }
}
