import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const Home = lazy(() => import('../pages/GuidePage'))

enum ROUTES {
    HOME = '/'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <Switch>
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
