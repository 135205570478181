import { Card, CardContent, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useContext, useState } from 'react'
import { ChatContent, ContentType } from '../../../data/models'
import { ChatContext } from '../../../context/chat'
import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'

export type RecommendedContentProps = {
    item: ChatContent
    transactionId: string
}

function RecommendedContent(props: RecommendedContentProps): JSX.Element {
    const { item, transactionId } = props
    const theme = useTheme()

    const { sessionId } = useContext(ChatContext)

    const [committed, setCommitted] = useState(item?.committed ?? false)

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const { handleNavigateToContent, handleMicrostep } = useContext(ChatContext)

    const handleCommitClick = useCallback(() => {
        if (committed) {
            setCommitted(false)
        } else {
            Avo.microstepCheckinCompleted({
                activityType: 'thrive_guide_recommendation',
                challengeId: null,
                challengeTheme: null,
                challengeType: null,
                dayNumber: null,
                featureType: 'thrive_guide',
                isAutomaticCheckin: false,
                journey: null,
                journeyDay: null,
                journeyId: null,
                journeyLevel: null,
                microstepBody: item.title,
                microstepId: item.id,
                microstepName: item.title,
                teamId: null,
                teamType: null,
                checkInDate: new Date().toISOString(),
                firstJourney: null,
                isOnboarding: false,
                tabName: null
            })

            setCommitted(true)
        }
        handleMicrostep(item)
    }, [committed, handleMicrostep, item])

    const handleActionClick = useCallback(() => {
        if (item.type === ContentType.MICROSTEP) {
            handleCommitClick()
        } else {
            Avo.contentStarted({
                activityType: 'thrive_guide_recommendation',
                contentFormatType: 'text',
                challengeId: null,
                challengeTheme: null,
                challengeType: null,
                contentId: item.id,
                contentSubtype: null,
                contentTitle: item.title,
                contentType: item.type as AvoTypes.ContentTypeValueType,
                contentUrl: null,
                conversationId: sessionId,
                dayNumber: null,
                featureType: 'thrive_guide',
                messageType: null,
                resetType: null,
                teamId: null,
                teamType: null,
                transactionId: null,
                contentSource: null,
                tabName: null,
                tstPlatform: null
            })

            handleNavigateToContent(item)
        }
    }, [handleCommitClick, handleNavigateToContent, item, sessionId])

    const ActionIcon = useCallback(() => {
        if (item.type === ContentType.MICROSTEP) {
            return committed ? (
                <LeafIcon icon={'check'} />
            ) : (
                <LeafIcon icon={'plus'} />
            )
        } else if (item.type == ContentType.RESET) {
            return <LeafIcon icon={'play'} sx={{ ml: 0.5 }} />
        } else {
            return <LeafIcon icon={'arrow-right'} />
        }
    }, [item.type, committed])

    const ContentIcon = useCallback(() => {
        const size = isMobileView ? 'small' : 'medium'
        if (item.type === ContentType.MICROSTEP) {
            return <LeafIcon icon={'circle-check'} fontSize={size} />
        } else if (item.type == ContentType.RESET) {
            return <LeafIcon icon={'video'} fontSize={size} />
        } else if (item.type == ContentType.CHALLENGE) {
            return <LeafIcon icon={'flag'} fontSize={size} />
        } else {
            return <LeafIcon icon={'align-left'} fontSize={size} />
        }
    }, [item.type, isMobileView])

    return (
        <Card>
            <CardContent sx={{ width: '100%' }}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    gap={0.5}
                >
                    <Stack direction={'row'} gap={1}>
                        <ContentIcon />
                        <Stack>
                            <CoreTypography
                                variant="caption"
                                color={theme.palette.text.secondary}
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {item?.type?.toLowerCase().replace('_', ' ') ??
                                    'Content'}
                            </CoreTypography>
                            <CoreTypography
                                variant="h5"
                                fontSize={theme.spacing(
                                    isMobileView ? 1.4 : 1.8
                                )}
                                color={theme.palette.text.primary}
                            >
                                {item?.title}
                            </CoreTypography>
                        </Stack>
                    </Stack>
                    <IconButton
                        color={committed ? 'success' : 'secondary'}
                        variant="contained"
                        onClick={handleActionClick}
                    >
                        <ActionIcon />
                    </IconButton>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default React.memo(RecommendedContent)
