import { createContext } from 'react'
import { ChatHistory } from '../../data/models'

const defaultContextValue: ChatContextType = {
    submitChatMessage: () => Promise.resolve(),
    chatHistory: [],
    loading: false,
    handleNavigateToContent: () => null,
    handleMicrostep: () => Promise.resolve(),
    sessionId: '',
    error: null,
    handleClearChatError: () => null,
    updateUIElements: []
}

export type ChatContextType = {
    submitChatMessage: any
    chatHistory: ChatHistory[]
    loading: boolean
    handleNavigateToContent: any
    handleMicrostep: any
    sessionId: string
    error: any
    handleClearChatError: any
    updateUIElements: any[]
}

export default createContext(defaultContextValue)
