import { generatePath } from 'react-router-dom'

import { LearnContentStatusType } from '../graphql/generated/autogenerated'
import { ROUTES } from '../routes/routes'

enum ChallengeStatusType {
    Challenge = 'Challenge'
}

type ContentType = ChallengeStatusType | LearnContentStatusType

export function getPathToFeaturedContent(
    featuredContentType: ContentType,
    contentId: string,
    parentId?: string,
    subParentId?: string
): string {
    switch (featuredContentType) {
        case 'Article':
            return generatePath(ROUTES.ARTICLE_PAGE, {
                articleId: contentId
            })
        case 'Recipe':
            return generatePath(ROUTES.ARTICLE_PAGE, {
                articleId: contentId
            })
        case 'Microstep':
            return generatePath(ROUTES.MICROSTEPS_DIALOG, {
                microstepId: contentId
            })
        case 'Challenge':
            return generatePath(ROUTES.CHALLENGE_PAGE, {
                challengeId: contentId
            })
        case 'RoleModel':
            return generatePath(ROUTES.ARTICLE_PAGE, {
                articleId: contentId
            })
        case 'WatchMeThrive':
            return generatePath(ROUTES.ARTICLE_PAGE, {
                articleId: contentId
            })
        case 'Course':
            return generatePath(ROUTES.COURSE_PAGE, {
                courseId: contentId
            })
        case 'Webinar':
            return generatePath(ROUTES.WEBINAR_PAGE, {
                webinarId: contentId
            })
        case 'PodcastEpisode':
            return `${ROUTES.PODCAST_EPISODE}/${parentId}?season=${subParentId}&episode=${contentId}`
        case 'Module':
            return `${ROUTES.COURSE_MODULE}/${parentId}?module=${contentId}`
        case 'Reset':
            return `${ROUTES.RESETS}/thrive/${contentId}`
        default:
            return ''
    }
}
