import { gql, Apollo } from '@thriveglobal/thrive-web-core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    AboutPageAboutPageComponentsDynamicZoneInput: any
    AllowedLocale: any
    CareersPageCareersPageComponentsDynamicZoneInput: any
    Color: any
    Date: any
    DateTime: any
    EthicsPageEthicsPageComponentsDynamicZoneInput: any
    GuidePagesDynamicZoneInput: any
    HomepageHomepageComponentsDynamicZoneInput: any
    I18NLocaleCode: any
    ISODate: any
    Instant: any
    JSON: any
    Locale: any
    Long: any
    PartnerUserId: any
    PrivacyPagePrivacyPageComponentsDynamicZoneInput: any
    ProductPageProductPageComponentsDynamicZoneInput: any
    SecurityPageSecurityPageComponentsDynamicZoneInput: any
    StoriesPageStoriesPageDynamicZoneInput: any
    TermsPageTermsPageComponentsDynamicZoneInput: any
    TimeZone: any
    UUID: any
    Upload: any
    Url: any
}

export type AboutPage = {
    __typename?: 'AboutPage'
    aboutPageComponents?: Maybe<
        Array<Maybe<AboutPageAboutPageComponentsDynamicZone>>
    >
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<AboutPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type AboutPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type AboutPageAboutPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type AboutPageEntity = {
    __typename?: 'AboutPageEntity'
    attributes?: Maybe<AboutPage>
    id?: Maybe<Scalars['ID']>
}

export type AboutPageEntityResponse = {
    __typename?: 'AboutPageEntityResponse'
    data?: Maybe<AboutPageEntity>
}

export type AboutPageInput = {
    aboutPageComponents?: InputMaybe<
        Array<Scalars['AboutPageAboutPageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type AboutPageRelationResponseCollection = {
    __typename?: 'AboutPageRelationResponseCollection'
    data: Array<AboutPageEntity>
}

export type AcceptToSInput = {
    userTime: Scalars['Instant']
    userZoneId?: InputMaybe<Scalars['TimeZone']>
}

export type AccessTypeInput = {
    name: Scalars['String']
}

export type AccessTypePage = {
    __typename?: 'AccessTypePage'
    items: Array<Scalars['String']>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type AchievementDiagnosticInfo = {
    __typename?: 'AchievementDiagnosticInfo'
    events: Array<UserAchievementEventInfo>
    info: AchievementV2
    levelFromEvents?: Maybe<Scalars['Int']>
    levels: Array<UserLevelHistoryInfo>
    progress?: Maybe<Scalars['Int']>
    progressFromEvents?: Maybe<Scalars['Int']>
}

export type AchievementInfo = {
    __typename?: 'AchievementInfo'
    /** The action type of the achievement */
    actionType?: Maybe<Scalars['String']>
    /** The description of the achievement */
    description?: Maybe<Scalars['String']>
    /** The entity uuid of the related entity */
    entityId?: Maybe<Scalars['UUID']>
    /** The uuid of the achievement */
    id?: Maybe<Scalars['UUID']>
    /** The url of the image */
    image?: Maybe<Scalars['String']>
    /** The quantity of the achievement */
    quantity?: Maybe<Scalars['Int']>
    /** The title of the achievement */
    title?: Maybe<Scalars['String']>
    /** Tell us if the achievement is a one off */
    unique?: Maybe<Scalars['Boolean']>
    /** The achievement upc */
    upc?: Maybe<Scalars['String']>
}

export type AchievementLevel = {
    __typename?: 'AchievementLevel'
    /** the claim date */
    claimedAt?: Maybe<Scalars['Instant']>
    /** the completion date */
    completedAt?: Maybe<Scalars['Instant']>
    /** The level of the achievement */
    level?: Maybe<Scalars['Int']>
    /** The amount needed to get the achievement */
    total?: Maybe<Scalars['Int']>
}

export type AchievementProgress = {
    __typename?: 'AchievementProgress'
    /** The id of the achievement */
    achievementId?: Maybe<Scalars['UUID']>
    /** The levels of the achievement */
    levels: Array<AchievementLevel>
    /** The progress of the achievement */
    progress?: Maybe<Scalars['Int']>
    /** The user id of the achievementProgress */
    userId?: Maybe<Scalars['UUID']>
}

export type AchievementV2 = {
    __typename?: 'AchievementV2'
    /** The achievement action type */
    actionType?: Maybe<Scalars['String']>
    /** The description of the achievement */
    description?: Maybe<Scalars['String']>
    /** The uuid of the achievement */
    id?: Maybe<Scalars['UUID']>
    /** The url of the image */
    image?: Maybe<Scalars['String']>
    /** The maximum level achievable */
    maximumLevel: Scalars['Int']
    /** The reward amount the user will receive when claiming the achievement */
    rewardAmount?: Maybe<Scalars['Int']>
    /** The reward currency code the user will receive when claiming the achievement */
    rewardCurrencyCode?: Maybe<Scalars['String']>
    /** The title of the achievement */
    title?: Maybe<Scalars['String']>
    /** The achievement upc */
    upc?: Maybe<Scalars['String']>
}

export type AchievementsMutation = {
    __typename?: 'AchievementsMutation'
    /** Claim achievement */
    claimAchievement: Array<AchievementProgress>
    createAchievement?: Maybe<AchievementInfo>
    deleteAchievement: Scalars['String']
    deleteAchievementsForAllUsers: Scalars['String']
    deleteAchievementsForUser: Scalars['String']
    deleteAllAchievementsForUsers: Scalars['String']
    updateAchievement?: Maybe<AchievementInfo>
}

export type AchievementsMutationClaimAchievementArgs = {
    achievementId: Scalars['UUID']
    levels: Array<Scalars['Int']>
}

export type AchievementsMutationCreateAchievementArgs = {
    actionType: Scalars['String']
    description: Scalars['String']
    entityId?: InputMaybe<Scalars['UUID']>
    image: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type AchievementsMutationDeleteAchievementArgs = {
    achievementId: Scalars['UUID']
}

export type AchievementsMutationDeleteAchievementsForAllUsersArgs = {
    achievementIds: Array<Scalars['UUID']>
}

export type AchievementsMutationDeleteAchievementsForUserArgs = {
    achievementIds: Array<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type AchievementsMutationDeleteAllAchievementsForUsersArgs = {
    excluding: Scalars['Boolean']
    userIds: Array<Scalars['UUID']>
}

export type AchievementsMutationUpdateAchievementArgs = {
    achievementId: Scalars['UUID']
    actionType: Scalars['String']
    description: Scalars['String']
    entityId?: InputMaybe<Scalars['UUID']>
    image: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type AchievementsQuery = {
    __typename?: 'AchievementsQuery'
    /** Get achievement diagnostic info related to the user */
    getAchievementsDiagnosticInfo: Array<AchievementDiagnosticInfo>
    /** Get achievement progress related to the user */
    getAchievementsUserProgress: Array<AchievementProgress>
    /** Get v2 achievements related to the user */
    getAchievementsV2: Array<AchievementV2>
}

export type AchievementsQueryGetAchievementsDiagnosticInfoArgs = {
    userId: Scalars['UUID']
}

export enum ActionType {
    CheckIn = 'CHECK_IN',
    JourneyLevelCompleted = 'JOURNEY_LEVEL_COMPLETED',
    PulseSurveyCompleted = 'PULSE_SURVEY_COMPLETED',
    ReferralCompleted = 'REFERRAL_COMPLETED',
    ResetWatched = 'RESET_WATCHED'
}

export type ActivityEntry = {
    __typename?: 'ActivityEntry'
    date?: Maybe<Scalars['ISODate']>
    dayOfYear?: Maybe<Scalars['Int']>
    durationMins?: Maybe<Scalars['Int']>
    label?: Maybe<Scalars['String']>
    steps: Scalars['Int']
    year?: Maybe<Scalars['Int']>
}

export type ActivityEntryDto = {
    __typename?: 'ActivityEntryDTO'
    date: Scalars['ISODate']
    durationMins: Scalars['Int']
    id: Scalars['UUID']
    label: Scalars['String']
    steps: Scalars['Int']
}

export type ActivityEntryInput = {
    date?: InputMaybe<Scalars['ISODate']>
    dayOfYear?: InputMaybe<Scalars['Int']>
    durationMins?: InputMaybe<Scalars['Int']>
    label?: InputMaybe<Scalars['String']>
    steps: Scalars['Int']
    year?: InputMaybe<Scalars['Int']>
}

export type ActivityMutation = {
    __typename?: 'ActivityMutation'
    /** Delete activity entries by Id for a user */
    deleteManualActivityEntries: Scalars['Boolean']
    /** Save an activity entry for a user */
    saveActivityEntry: ActivityEntry
    /** Save an updated Step Count from Apple Health */
    saveAppleHealthStepsActivity: Array<AppleHealthSteps>
}

export type ActivityMutationDeleteManualActivityEntriesArgs = {
    date: Scalars['ISODate']
    entryIds: Array<Scalars['UUID']>
}

export type ActivityMutationSaveActivityEntryArgs = {
    activityEntry: ActivityEntryInput
}

export type ActivityMutationSaveAppleHealthStepsActivityArgs = {
    stepsRecords: Array<AppleHealthStepsInput>
}

export type ActivityQuery = {
    __typename?: 'ActivityQuery'
    /** Get a list of activity entries for date */
    getActivityEntriesForDate: Array<ActivityEntryDto>
    /** Get a user's total step count for the given date */
    getStepCount: Scalars['Int']
}

export type ActivityQueryGetActivityEntriesForDateArgs = {
    date: Scalars['ISODate']
}

export type ActivityQueryGetStepCountArgs = {
    date: Scalars['ISODate']
}

export type ActivitySummaryStats = {
    averageSteps: Scalars['Float']
}

export type ActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'ActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
}

export type AcuteCareBanner = {
    __typename?: 'AcuteCareBanner'
    description: Scalars['String']
    title: Scalars['String']
}

export type AcuteCareResource = {
    __typename?: 'AcuteCareResource'
    alreadySeenToday: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    pulseDimensionTypes: Array<Scalars['String']>
    pulseSurveyIds: Array<Scalars['String']>
    resources: Array<CompanyResource>
    subtitle: Scalars['String']
    title: Scalars['String']
    uuid: Scalars['String']
}

export type AdminChallengeSubscription = {
    __typename?: 'AdminChallengeSubscription'
    challengesId: Array<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
}

export type AdminMutation = {
    __typename?: 'AdminMutation'
    /** Pull and save the latest Benchmark Ranges from BigQuery */
    refreshBenchmarkRanges: Array<OptimalRange>
}

export type AgentAnalyticsResponse = {
    __typename?: 'AgentAnalyticsResponse'
    agentId: Scalars['String']
    companyId: Scalars['String']
    data: Array<AgentMetricData>
    endDate: Scalars['Instant']
    startDate: Scalars['Instant']
}

export type AgentEngagement = {
    __typename?: 'AgentEngagement'
    agentId: Scalars['String']
    interactions: Scalars['Int']
    lastResetTimestamp?: Maybe<Scalars['Instant']>
    name: Scalars['String']
    numResetsReceived: Scalars['Int']
    resetCompletionRate: Scalars['Float']
}

export type AgentEngagementReport = {
    __typename?: 'AgentEngagementReport'
    agentCount: Scalars['Int']
    agentEngagementNumbers: Array<AgentEngagement>
}

export type AgentGroup = {
    __typename?: 'AgentGroup'
    id: Scalars['String']
    name?: Maybe<Scalars['String']>
}

export type AgentLastReset = {
    __typename?: 'AgentLastReset'
    agentId: Scalars['UUID']
    clientRowId: Scalars['UUID']
    id: Scalars['UUID']
    lastResetTime: Scalars['Instant']
    queryQueueId?: Maybe<Scalars['UUID']>
    trigger?: Maybe<Trigger>
}

export type AgentMetricCoordinate = {
    __typename?: 'AgentMetricCoordinate'
    /** Trigger reason: duration in seconds or number of interactions */
    trigger: Scalars['Float']
    /** Time range */
    x: Scalars['String']
    /** Duration in seconds */
    y: Scalars['Float']
}

export type AgentMetricData = {
    __typename?: 'AgentMetricData'
    coordinates: Array<AgentMetricCoordinate>
    metric: AgentMetricType
}

export enum AgentMetricType {
    CallDurationReset = 'CALL_DURATION_RESET',
    ConsecutiveCallReset = 'CONSECUTIVE_CALL_RESET',
    CustomerInteraction = 'CUSTOMER_INTERACTION',
    ManualReset = 'MANUAL_RESET'
}

export type AgentQuery = {
    __typename?: 'AgentQuery'
    /** Terminates an agent's last reset interaction. */
    endReset: Scalars['Boolean']
    /** Get agent details */
    getAgent?: Maybe<PlatformAgent>
    /** Get agents that belong to the requested group */
    getGroupAgents: Array<PlatformAgent>
    /** Get agent groups for a company by group type */
    getGroups: PlatformGroup
    /** Checks if the current user is a platform admin. */
    hasAdminAccess: Scalars['Boolean']
    /** Checks if the current user has view permission to Thrive admin page. */
    hasViewAccess: Scalars['Boolean']
    /** Send a Thrive Reset to the requested agent. Returns conversation id of the Reset interaction created. */
    sendReset?: Maybe<Scalars['String']>
    /** Request a reset to be sent to the authenticated user. */
    sendResetToSelf?: Maybe<Scalars['String']>
}

export type AgentQueryEndResetArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQueryGetAgentArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQueryGetGroupAgentsArgs = {
    companyId: Scalars['UUID']
    groupIdentifier: Scalars['String']
    groupType: GroupType
    platform: PlatformType
}

export type AgentQueryGetGroupsArgs = {
    companyId: Scalars['UUID']
    groupType: GroupType
    platform: PlatformType
}

export type AgentQueryHasAdminAccessArgs = {
    platform: PlatformType
}

export type AgentQueryHasViewAccessArgs = {
    platform: PlatformType
}

export type AgentQuerySendResetArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQuerySendResetToSelfArgs = {
    platform: PlatformType
}

export type AggregatePerformanceMetrics = {
    __typename?: 'AggregatePerformanceMetrics'
    callSkillName?: Maybe<Scalars['String']>
    interactionType: InteractionType
    metrics: Array<MetricData>
    queueId: Scalars['UUID']
    queueName?: Maybe<Scalars['String']>
}

export type AppUsageTrend = {
    __typename?: 'AppUsageTrend'
    endValue: Scalars['Long']
    percentDifference: Scalars['Float']
    startValue: Scalars['Long']
}

export type AppUsageTrends = {
    __typename?: 'AppUsageTrends'
    activeUsers: AppUsageTrend
    challengesStarted: AppUsageTrend
    checkins: AppUsageTrend
    cumlativeActiveUsers: AppUsageTrend
    learnModulesStarted: AppUsageTrend
    microstepsCheckedIn: AppUsageTrend
    resetsPlayed: AppUsageTrend
    uniqueChallengeUsers: AppUsageTrend
    uniqueCheckinUsers: AppUsageTrend
    uniqueLearnUsers: AppUsageTrend
    uniqueMicrostepUsers: AppUsageTrend
    uniqueResetUsers: AppUsageTrend
}

export type AppleHealthSleepBlocksInput = {
    appleHealthId: Scalars['UUID']
    endDate: Scalars['Instant']
    startDate: Scalars['Instant']
    thriveUserId: Scalars['UUID']
    value: Scalars['String']
}

export type AppleHealthSteps = {
    __typename?: 'AppleHealthSteps'
    dayOfYear: Scalars['Int']
    steps: Scalars['Int']
    thriveUserId: Scalars['UUID']
    year: Scalars['Int']
}

export type AppleHealthStepsInput = {
    dayOfYear: Scalars['Int']
    steps: Scalars['Int']
    thriveUserId: Scalars['UUID']
    year: Scalars['Int']
}

export type Article = {
    authors: Array<ArticleAuthor>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    createdAt?: Maybe<Scalars['Instant']>
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    summary: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
    userJourneys: UserJourneys
}

export type ArticleAuthor = {
    __typename?: 'ArticleAuthor'
    bio: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    photoUrl: Scalars['String']
    title: Scalars['String']
}

export type ArticleContentStatusInput = {
    /** article id */
    contentId: Scalars['String']
    /** article progress in seconds (for video and audio articles) */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    subtype?: InputMaybe<LearnContentStatusSubtype>
}

export enum ArticleContentType {
    Article = 'Article',
    Audio = 'Audio',
    Text = 'Text',
    Video = 'Video'
}

export type ArticleContributorDto = {
    __typename?: 'ArticleContributorDTO'
    name: Scalars['String']
}

export type ArticleDto = {
    __typename?: 'ArticleDTO'
    authors?: Maybe<Array<ArticleContributorDto>>
    body?: Maybe<Scalars['String']>
    durationInSeconds: Scalars['Int']
    featuredImage?: Maybe<ArticleUploadFileDto>
    isRoleModel: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    subtitle?: Maybe<Scalars['String']>
    title: Scalars['String']
    uuid?: Maybe<Scalars['String']>
}

export type ArticleDetail = {
    __typename?: 'ArticleDetail'
    article: ArticleV2
    articleInfo: ArticleV2Detail
    goalType: GoalType
}

export type ArticlePage = {
    __typename?: 'ArticlePage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Article>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ArticlePageV2 = {
    __typename?: 'ArticlePageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ArticleV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export enum ArticleType {
    Article = 'Article',
    Recipe = 'Recipe',
    RoleModel = 'RoleModel',
    WatchMeThrive = 'WatchMeThrive'
}

export type ArticleUploadFileDto = {
    __typename?: 'ArticleUploadFileDTO'
    caption?: Maybe<Scalars['String']>
    height?: Maybe<Scalars['Int']>
    mime: Scalars['String']
    name: Scalars['String']
    previewUrl?: Maybe<Scalars['String']>
    size: Scalars['Float']
    url: Scalars['String']
    width?: Maybe<Scalars['Int']>
}

export type ArticleV2 = {
    __typename?: 'ArticleV2'
    articleContentType: ArticleContentType
    authors: Array<ArticleAuthor>
    body?: Maybe<Scalars['String']>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    relatedArticles: Array<ArticleV2>
    source?: Maybe<Scalars['String']>
    summary: Scalars['String']
    title: Scalars['String']
    userJourneys: UserJourneys
}

export type ArticleV2Detail = {
    __typename?: 'ArticleV2Detail'
    articleContentType?: Maybe<Scalars['String']>
    durationSeconds?: Maybe<Scalars['Int']>
    id: Scalars['String']
    imageUrl?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type AspectRatioUrls = {
    __typename?: 'AspectRatioUrls'
    landscape?: Maybe<Scalars['Url']>
    portrait?: Maybe<Scalars['Url']>
}

export type AssessmentStats = {
    average: Scalars['Float']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
}

export type Audio = {
    __typename?: 'Audio'
    author: Scalars['String']
    createdAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with durationInSeconds */
    duration: Scalars['Int']
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
    url: Scalars['String']
}

export type AudioArticle = Article & {
    __typename?: 'AudioArticle'
    authors: Array<ArticleAuthor>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    createdAt?: Maybe<Scalars['Instant']>
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    mimeType?: Maybe<Scalars['String']>
    source: Scalars['String']
    summary: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
    userJourneys: UserJourneys
}

export type AudioBookChapter = {
    __typename?: 'AudioBookChapter'
    number: Scalars['Int']
    /** Collection of audiobook chapter parts (audio file urls) */
    parts: Array<AudioBookChapterPart>
    /** Audio book chapter progress for user, based on userId from context. Checks progress on audio book chapter parts. */
    progress?: Maybe<LearnContentProgress>
    title?: Maybe<Scalars['String']>
}

export type AudioBookChapterPart = {
    __typename?: 'AudioBookChapterPart'
    /** Url to the audio file */
    audioUrl: Scalars['String']
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    /** Duration in seconds of the audio file */
    durationSeconds: Scalars['Int']
    id: Scalars['UUID']
    /** Audiobook chapter part number */
    number: Scalars['Int']
}

/** Progress input for Podcast Episode */
export type AudioBookChapterPartContentStatusInput = {
    /** audiobook chapter part id */
    contentId: Scalars['String']
    /** progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of in seconds */
    totalInSeconds: Scalars['Int']
}

export type AudioBookMutation = {
    __typename?: 'AudioBookMutation'
    /** Mutation creating or updating audio book chapter part status, based on userId from context */
    updateAudioBookChapterPartStatus: LearnContentStatus
}

export type AudioBookMutationUpdateAudioBookChapterPartStatusArgs = {
    input: AudioBookChapterPartContentStatusInput
}

export type AudioQuery = {
    __typename?: 'AudioQuery'
    thrive: ThriveAudioQuery
}

export type Badge = {
    __typename?: 'Badge'
    description?: Maybe<Scalars['String']>
    id: Scalars['ID']
    image?: Maybe<Scalars['String']>
    level?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type BadgeInput = {
    description: Scalars['String']
    image?: InputMaybe<Scalars['String']>
    level?: InputMaybe<Scalars['String']>
    title: Scalars['String']
}

export type BalanceInfo = {
    __typename?: 'BalanceInfo'
    /** The balance amount */
    amount: Scalars['Int']
    /** The currency code */
    currencyCode: Scalars['String']
}

export type BannerQuery = {
    __typename?: 'BannerQuery'
    thrive: ThriveBannerQuery
}

export type Biotype = {
    __typename?: 'Biotype'
    id?: Maybe<Scalars['ID']>
    name?: Maybe<Scalars['String']>
}

export type Blurb = {
    __typename?: 'Blurb'
    altText: Scalars['String']
    description: Scalars['String']
    image: Scalars['String']
    title: Scalars['String']
}

export type BlurbV2 = {
    __typename?: 'BlurbV2'
    description: Scalars['String']
}

export type Book = {
    __typename?: 'Book'
    /** Collection of audiobook chapters */
    audioChapters: Array<AudioBookChapter>
    /** Collection of authors */
    authors: Array<ArticleAuthor>
    id: Scalars['ID']
    imageUrl: Scalars['String']
    /** Url to the pdf resource file of the book */
    resourceUrl?: Maybe<Scalars['String']>
    subtitle: Scalars['String']
    title: Scalars['String']
    uuid: Scalars['UUID']
}

export type BookMutation = {
    __typename?: 'BookMutation'
    audio: AudioBookMutation
}

export type BookPage = {
    __typename?: 'BookPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Book>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type BookQuery = {
    __typename?: 'BookQuery'
    /** Get book */
    getBook?: Maybe<Book>
    /** Get books */
    getBooks: BookPage
}

export type BookQueryGetBookArgs = {
    bookId: Scalars['UUID']
}

export type BookQueryGetBooksArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type BookmarkMutation = {
    __typename?: 'BookmarkMutation'
    create: Scalars['Boolean']
    delete: Scalars['Boolean']
}

export type BookmarkMutationCreateArgs = {
    id: Scalars['UUID']
}

export type BookmarkMutationDeleteArgs = {
    id: Scalars['UUID']
}

export type BookmarkQuery = {
    __typename?: 'BookmarkQuery'
    bookmarked: BookmarkedContentPage
    isBookmarked: Scalars['Boolean']
}

export type BookmarkQueryBookmarkedArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type BookmarkQueryIsBookmarkedArgs = {
    id: Scalars['UUID']
}

export type BookmarkedContent = ThriveReset | ThriveResetAudio

export type BookmarkedContentPage = {
    __typename?: 'BookmarkedContentPage'
    items: Array<BookmarkedContent>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    total: Scalars['Int']
}

export type BooleanFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
    contains?: InputMaybe<Scalars['Boolean']>
    containsi?: InputMaybe<Scalars['Boolean']>
    endsWith?: InputMaybe<Scalars['Boolean']>
    eq?: InputMaybe<Scalars['Boolean']>
    eqi?: InputMaybe<Scalars['Boolean']>
    gt?: InputMaybe<Scalars['Boolean']>
    gte?: InputMaybe<Scalars['Boolean']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
    lt?: InputMaybe<Scalars['Boolean']>
    lte?: InputMaybe<Scalars['Boolean']>
    ne?: InputMaybe<Scalars['Boolean']>
    not?: InputMaybe<BooleanFilterInput>
    notContains?: InputMaybe<Scalars['Boolean']>
    notContainsi?: InputMaybe<Scalars['Boolean']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
    startsWith?: InputMaybe<Scalars['Boolean']>
}

export type Brand = {
    __typename?: 'Brand'
    companyId: Scalars['UUID']
    createdAt: Scalars['Instant']
    imageUrl?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type BrandInput = {
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic?: InputMaybe<Scalars['Boolean']>
    name: Scalars['String']
}

export type BrandInputGraphQlInput = {
    companyId: Scalars['UUID']
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
}

export type BrandPage = {
    __typename?: 'BrandPage'
    items: Array<Brand>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type BulkDailyCheckInRequestInput = {
    /** The Daily Check-In request date */
    requestDate: Scalars['ISODate']
    /** The channel requesting the Daily Check-In */
    requestingChannel: Channel
    /** The user options for the requested Daily Check-Ins */
    userConfigs: Array<UserConfigInput>
}

export enum CacheControlScope {
    Private = 'PRIVATE',
    Public = 'PUBLIC'
}

export enum CacheTypes {
    Challenges = 'CHALLENGES',
    Identity = 'IDENTITY',
    Socialgroups = 'SOCIALGROUPS'
}

export type CalendarQuery = {
    __typename?: 'CalendarQuery'
    getResetEventUrl: Scalars['Url']
    services: Array<CalendarServiceFields>
}

export type CalendarQueryGetResetEventUrlArgs = {
    service: CalendarService
}

export enum CalendarService {
    Google = 'Google',
    Office365 = 'Office365',
    Outlook = 'Outlook'
}

export type CalendarServiceFields = {
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type CallQueue = {
    __typename?: 'CallQueue'
    division?: Maybe<Division>
    id: Scalars['UUID']
    memberCount?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
}

export type CallToAction = {
    __typename?: 'CallToAction'
    args: Array<Scalars['String']>
    title: Scalars['String']
    type: CallToActionType
}

export enum CallToActionType {
    CreatePersonalizedReset = 'CreatePersonalizedReset',
    PlayThriveReset = 'PlayThriveReset',
    Redirect = 'Redirect',
    ScheduleResetPlayback = 'ScheduleResetPlayback'
}

export type CallsMutation = {
    __typename?: 'CallsMutation'
    company: CompanyMutation
    trigger: TriggerMutation
}

export type CallsQuery = {
    __typename?: 'CallsQuery'
    agent: AgentQuery
    company: CompanyQuery
    reporting: ReportingQuery
    trigger: TriggerQuery
}

export type CareersPage = {
    __typename?: 'CareersPage'
    careersPageComponents?: Maybe<
        Array<Maybe<CareersPageCareersPageComponentsDynamicZone>>
    >
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CareersPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CareersPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type CareersPageCareersPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFeatureGridWithPhoto
    | ComponentMsComponentsFeatureWithPhoto
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type CareersPageEntity = {
    __typename?: 'CareersPageEntity'
    attributes?: Maybe<CareersPage>
    id?: Maybe<Scalars['ID']>
}

export type CareersPageEntityResponse = {
    __typename?: 'CareersPageEntityResponse'
    data?: Maybe<CareersPageEntity>
}

export type CareersPageInput = {
    careersPageComponents?: InputMaybe<
        Array<Scalars['CareersPageCareersPageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type CareersPageRelationResponseCollection = {
    __typename?: 'CareersPageRelationResponseCollection'
    data: Array<CareersPageEntity>
}

export type Category = {
    __typename?: 'Category'
    id?: Maybe<Scalars['ID']>
    journeyVersions?: Maybe<Array<Maybe<Scalars['Int']>>>
    name?: Maybe<Scalars['String']>
    preselect?: Maybe<Scalars['Boolean']>
    primaryImageUrl?: Maybe<Scalars['String']>
    priority?: Maybe<Scalars['Int']>
    secondaryImageUrl?: Maybe<Scalars['String']>
    shortName?: Maybe<Scalars['String']>
    version?: Maybe<Scalars['Int']>
    wpCategoryId?: Maybe<Scalars['Int']>
}

export type Challenge = {
    __typename?: 'Challenge'
    badges?: Maybe<Array<ChallengeBadge>>
    biotype?: Maybe<Biotype>
    category?: Maybe<Category>
    company?: Maybe<Company>
    description?: Maybe<Scalars['String']>
    /**
     * Duration in days, will be used to calculate expired challenges
     * after join date in ChallengeActivity object.
     */
    duration?: Maybe<Scalars['Int']>
    goal?: Maybe<Scalars['String']>
    header?: Maybe<Scalars['String']>
    id: Scalars['ID']
    isFeatured?: Maybe<Scalars['Boolean']>
    microsteps?: Maybe<Array<Microstep>>
    name?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['String']>
    totalParticipants?: Maybe<Scalars['Int']>
    type?: Maybe<Scalars['String']>
    welcomeText?: Maybe<Scalars['String']>
    welcomeVideo?: Maybe<Scalars['String']>
}

export type ChallengeActivity = {
    __typename?: 'ChallengeActivity'
    completedOn?: Maybe<Scalars['Date']>
    expiredOn?: Maybe<Scalars['Date']>
    id: Scalars['ID']
    join: Scalars['Date']
    leave?: Maybe<Scalars['Date']>
    microstepActivities?: Maybe<Array<Maybe<MicrostepActivities>>>
    streak?: Maybe<Streak>
}

export type ChallengeBadge = {
    __typename?: 'ChallengeBadge'
    badge?: Maybe<Badge>
    minUserActivityCount: Scalars['Int']
}

export type ChallengeBadgeInput = {
    badgeId: Scalars['ID']
    minUserActivityCount: Scalars['Int']
}

export type ChallengeCustomization = {
    __typename?: 'ChallengeCustomization'
    challengeId: Scalars['UUID']
    userId: Scalars['UUID']
    waterAmount?: Maybe<Scalars['Int']>
}

export type ChallengeCustomizationOperationResult =
    | ChallengeCustomizationResult
    | ErrorMessageCustomization

export type ChallengeCustomizationResult = {
    __typename?: 'ChallengeCustomizationResult'
    challengeCustomization: ChallengeCustomization
}

export type ChallengeGoal = {
    __typename?: 'ChallengeGoal'
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the alternative goal entity */
    altGoalEntityTitle?: Maybe<Scalars['String']>
    /** Alternative goal type */
    altGoalType?: Maybe<Scalars['String']>
    /** The id of the challenge */
    challengeId: Scalars['UUID']
    createdAt?: Maybe<Scalars['Instant']>
    /** The day of the goal */
    day: Scalars['Int']
    /** The id of the challenge goal entity */
    goalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the goal entity */
    goalEntityTitle?: Maybe<Scalars['String']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: Maybe<Scalars['Int']>
    /** The goal type */
    goalType?: Maybe<Scalars['String']>
    /** Number of water intake for GoalType.WATER */
    goalWater?: Maybe<Scalars['Int']>
    /** The id of this challenge goal */
    id: Scalars['UUID']
    /** Ordering of the goal */
    position?: Maybe<Scalars['Int']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: Maybe<Scalars['String']>
}

export type ChallengeGoalCreateInput = {
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: InputMaybe<Scalars['UUID']>
    /** Alternative goal type */
    altGoalType?: InputMaybe<Scalars['String']>
    /** The id of the challenge */
    challengeId: Scalars['UUID']
    /** Day this goal belongs during the challenge */
    day: Scalars['Int']
    /** The id of the entity related with the goal */
    goalEntityId?: InputMaybe<Scalars['UUID']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: InputMaybe<Scalars['Int']>
    /** Goal type */
    goalType: Scalars['String']
    /** Number of water intake for GoalType.WATER */
    goalWater?: InputMaybe<Scalars['Int']>
    /** Ordering during the day */
    position?: InputMaybe<Scalars['Int']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: InputMaybe<Scalars['String']>
}

export type ChallengeInput = {
    badges?: InputMaybe<Array<InputMaybe<ChallengeBadgeInput>>>
    categoryId?: InputMaybe<Scalars['ID']>
    companyId?: InputMaybe<Scalars['ID']>
    description?: InputMaybe<Scalars['String']>
    duration?: InputMaybe<Scalars['Int']>
    goal?: InputMaybe<Scalars['String']>
    header?: InputMaybe<Scalars['String']>
    isFeatured?: InputMaybe<Scalars['Boolean']>
    microstepIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    thumbnail?: InputMaybe<Scalars['String']>
    welcomeText?: InputMaybe<Scalars['String']>
    welcomeVideo?: InputMaybe<Scalars['String']>
}

export type ChallengeParticipation = {
    __typename?: 'ChallengeParticipation'
    challenge?: Maybe<Challenge>
    participation?: Maybe<Array<Maybe<ChallengeActivity>>>
}

export type ChallengeRecommendationAck = {
    __typename?: 'ChallengeRecommendationAck'
    /** The challenge id acknowledge */
    challengeId: Scalars['UUID']
    /** The createdAt of the acknowledge */
    createdAt: Scalars['Instant']
    /** The microstepId id acknowledge */
    microstepId: Scalars['UUID']
}

export type ChallengeSubscriptionsInfo = {
    __typename?: 'ChallengeSubscriptionsInfo'
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    companyName: Scalars['String']
    enabled: Scalars['Boolean']
    id: Scalars['UUID']
    subscriptionId?: Maybe<Scalars['UUID']>
}

export type ChallengeTeamDailyGoal = {
    __typename?: 'ChallengeTeamDailyGoal'
    /** The external entity detail */
    userDailyGoals: Array<ChallengeUserDailyGoal>
    /** The user's display name */
    userDisplayName: Scalars['String']
}

export type ChallengeTemplate = {
    __typename?: 'ChallengeTemplate'
    /** Category */
    category?: Maybe<UnifiedJourney>
    /** Description of the template */
    description?: Maybe<Scalars['String']>
    /** Identifies if challenge supports wearables functionality  */
    deviceEnabled: Scalars['Boolean']
    /** Duration of the challenge */
    duration: Scalars['Int']
    /** Goal of the challenge */
    goal?: Maybe<Scalars['String']>
    /** Header image */
    header?: Maybe<Scalars['String']>
    /** The id of this template */
    id: Scalars['UUID']
    /** Indicates is this a featured template */
    isFeatured: Scalars['Boolean']
    /** List of Journeys associated to this template */
    journeys?: Maybe<Array<TemplateJourney>>
    /** Name of the template */
    name: Scalars['String']
    /** Custom  order position set for the challenge */
    position: Scalars['Int']
    /** The description for promo 1 field */
    promoDescription1?: Maybe<Scalars['String']>
    /** The description for promo 2 field */
    promoDescription2?: Maybe<Scalars['String']>
    /** The description for promo 3 field */
    promoDescription3?: Maybe<Scalars['String']>
    /** The header for promo 1 field */
    promoHeader1?: Maybe<Scalars['String']>
    /** The header for promo 2 field */
    promoHeader2?: Maybe<Scalars['String']>
    /** The header for promo 3 field */
    promoHeader3?: Maybe<Scalars['String']>
    /** The image for promo 1 field */
    promoImage1?: Maybe<Scalars['String']>
    /** The image for promo 2 field */
    promoImage2?: Maybe<Scalars['String']>
    /** The image for promo 3 field */
    promoImage3?: Maybe<Scalars['String']>
    /** Indicates the scope of this template: COMPANY, GROUP... */
    templateScope: Scalars['String']
    /** The company challenge theme */
    theme?: Maybe<Scalars['String']>
    /** Thumbnail */
    thumbnail?: Maybe<Scalars['String']>
    /** The image for the community challenge trophy plant */
    trophyPlantImage?: Maybe<Scalars['String']>
}

export type ChallengeTemplateCreateInput = {
    /** The id of the challenge template */
    challengeTemplateId?: InputMaybe<Scalars['UUID']>
    /** The company id to which the challenge is assigned */
    companyId?: InputMaybe<Scalars['UUID']>
    /** Enabled flag for the challenge */
    enabled?: InputMaybe<Scalars['Boolean']>
    /** Indicates if the challenge is featured */
    isFeatured?: InputMaybe<Scalars['Boolean']>
    /** Optional prefix to be displayed before the challenge's name */
    namePrefix?: InputMaybe<Scalars['String']>
    /** The signup date of the challenge */
    signupDate?: InputMaybe<Scalars['ISODate']>
    /** The start date of the challenge */
    startDate?: InputMaybe<Scalars['ISODate']>
    /** The subscription id to which the challenge is assigned */
    subscriptionId?: InputMaybe<Scalars['UUID']>
    /** Trophy plant image url */
    trophyPlantImgUrl?: InputMaybe<Scalars['String']>
}

export type ChallengeTemplateGoal = {
    __typename?: 'ChallengeTemplateGoal'
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the alternative goal entity */
    altGoalEntityTitle?: Maybe<Scalars['String']>
    /** Alternative goal type */
    altGoalType?: Maybe<Scalars['String']>
    /** The id of the challenge template */
    challengeTemplateId: Scalars['UUID']
    /** The day of the goal */
    day: Scalars['Int']
    /** The id of the challenge goal entity */
    goalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the challenge goal entity */
    goalEntityTitle?: Maybe<Scalars['String']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: Maybe<Scalars['Int']>
    /** The goal type */
    goalType?: Maybe<Scalars['String']>
    /** Number of water intake for GoalType.WATER */
    goalWater?: Maybe<Scalars['Int']>
    /** Ordering of the goal */
    position?: Maybe<Scalars['Int']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: Maybe<Scalars['String']>
}

export type ChallengeTemplateInstance = {
    __typename?: 'ChallengeTemplateInstance'
    /** The challenge company relation */
    challengeCompanyRelation: UnifiedChallengeCompany
    /** The challenge goals */
    challengeGoals: Array<ChallengeGoal>
    /** The challenge */
    challengeInstance: UnifiedChallenge
    /** The challenge template */
    challengeTemplate?: Maybe<ChallengeTemplate>
}

export type ChallengeUserDailyGoal = {
    __typename?: 'ChallengeUserDailyGoal'
    /** The challenge goal info */
    challengeGoal: ChallengeGoal
    /** True if the user has completed this daily goal */
    completed: Scalars['Boolean']
    /** The external entity detail */
    goalEntityDetail: GoalEntityDetail
}

export enum Channel {
    MobileAndroid = 'MOBILE_ANDROID',
    MobileIos = 'MOBILE_IOS',
    MobileNotification = 'MOBILE_NOTIFICATION',
    MsTeams = 'MS_TEAMS',
    Slack = 'SLACK',
    Web = 'WEB'
}

export type CheckInResponseDetailsDto = {
    __typename?: 'CheckInResponseDetailsDTO'
    pulseUserId: Scalars['UUID']
    /** @deprecated Use selectedOption and responseChannel in root of object */
    response: DailyCheckInResponseDto
    responseChannel: Scalars['String']
    responseDate: Scalars['ISODate']
    selectedOption: OptionsDto
    survey: PulseSurveyQuestionDto
    thriveId?: Maybe<Scalars['UUID']>
}

export type CheckInResponseDetailsPage = {
    __typename?: 'CheckInResponseDetailsPage'
    content: Array<CheckInResponseDetailsDto>
    page: Scalars['Long']
    totalElements: Scalars['Long']
    totalPages: Scalars['Long']
}

export type CheckinCount = {
    __typename?: 'CheckinCount'
    count: Scalars['Int']
    date: Scalars['Instant']
}

export type CmsAchievement = {
    __typename?: 'CmsAchievement'
    actionType?: Maybe<Enum_Cmsachievement_Actiontype>
    applyToRelationsOnly: Scalars['Boolean']
    challenges?: Maybe<CmsChallengeRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsAchievementRelationResponseCollection>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    quantity: Scalars['Int']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsAchievementChallengesArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsAchievementLocalizationsArgs = {
    filters?: InputMaybe<CmsAchievementFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsAchievementMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsAchievementEntity = {
    __typename?: 'CmsAchievementEntity'
    attributes?: Maybe<CmsAchievement>
    id?: Maybe<Scalars['ID']>
}

export type CmsAchievementEntityResponse = {
    __typename?: 'CmsAchievementEntityResponse'
    data?: Maybe<CmsAchievementEntity>
}

export type CmsAchievementEntityResponseCollection = {
    __typename?: 'CmsAchievementEntityResponseCollection'
    data: Array<CmsAchievementEntity>
    meta: ResponseCollectionMeta
}

export type CmsAchievementFiltersInput = {
    actionType?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<CmsAchievementFiltersInput>>>
    applyToRelationsOnly?: InputMaybe<BooleanFilterInput>
    challenges?: InputMaybe<CmsChallengeFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsAchievementFiltersInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<CmsAchievementFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsAchievementFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    quantity?: InputMaybe<IntFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsAchievementInput = {
    actionType?: InputMaybe<Enum_Cmsachievement_Actiontype>
    applyToRelationsOnly?: InputMaybe<Scalars['Boolean']>
    challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    quantity?: InputMaybe<Scalars['Int']>
    title?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsAchievementRelationResponseCollection = {
    __typename?: 'CmsAchievementRelationResponseCollection'
    data: Array<CmsAchievementEntity>
}

export type CmsArticle = {
    __typename?: 'CmsArticle'
    audio?: Maybe<UploadFileEntityResponse>
    authors?: Maybe<CmsContributorRelationResponseCollection>
    body?: Maybe<Scalars['String']>
    challenges?: Maybe<CmsChallengeRelationResponseCollection>
    contentType: Enum_Cmsarticle_Contenttype
    createdAt?: Maybe<Scalars['DateTime']>
    durationInSeconds: Scalars['Int']
    featuredImage?: Maybe<UploadFileEntityResponse>
    isRoleModel: Scalars['Boolean']
    journeys?: Maybe<CmsJourneyRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsArticleRelationResponseCollection>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    personae?: Maybe<PersonaRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    subtitle?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
    videoUrl?: Maybe<Scalars['String']>
}

export type CmsArticleAuthorsArgs = {
    filters?: InputMaybe<CmsContributorFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleChallengesArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleJourneysArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleLocalizationsArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticlePersonaeArgs = {
    filters?: InputMaybe<PersonaFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticlePulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchive = {
    __typename?: 'CmsArticleArchive'
    Subtitle?: Maybe<Scalars['String']>
    Summary: Scalars['String']
    Title: Scalars['String']
    UUID?: Maybe<Scalars['String']>
    authors?: Maybe<CmsContributorRelationResponseCollection>
    body?: Maybe<Scalars['String']>
    challenges?: Maybe<CmsChallengeRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    duration_seconds?: Maybe<Scalars['Int']>
    image?: Maybe<UploadFileEntityResponse>
    journeys?: Maybe<CmsJourneyRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsArticleArchiveRelationResponseCollection>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    personae?: Maybe<PersonaRelationResponseCollection>
    publish_to_app: Scalars['Boolean']
    publish_to_website: Scalars['Boolean']
    publishedAt?: Maybe<Scalars['DateTime']>
    role_model?: Maybe<Scalars['Boolean']>
    source?: Maybe<UploadFileEntityResponse>
    type: Enum_Cmsarticlearchive_Type
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsArticleArchiveAuthorsArgs = {
    filters?: InputMaybe<CmsContributorFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchiveChallengesArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchiveJourneysArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchiveLocalizationsArgs = {
    filters?: InputMaybe<CmsArticleArchiveFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchiveMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchivePersonaeArgs = {
    filters?: InputMaybe<PersonaFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsArticleArchiveEntity = {
    __typename?: 'CmsArticleArchiveEntity'
    attributes?: Maybe<CmsArticleArchive>
    id?: Maybe<Scalars['ID']>
}

export type CmsArticleArchiveEntityResponse = {
    __typename?: 'CmsArticleArchiveEntityResponse'
    data?: Maybe<CmsArticleArchiveEntity>
}

export type CmsArticleArchiveEntityResponseCollection = {
    __typename?: 'CmsArticleArchiveEntityResponseCollection'
    data: Array<CmsArticleArchiveEntity>
    meta: ResponseCollectionMeta
}

export type CmsArticleArchiveFiltersInput = {
    Subtitle?: InputMaybe<StringFilterInput>
    Summary?: InputMaybe<StringFilterInput>
    Title?: InputMaybe<StringFilterInput>
    UUID?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<CmsArticleArchiveFiltersInput>>>
    authors?: InputMaybe<CmsContributorFiltersInput>
    body?: InputMaybe<StringFilterInput>
    challenges?: InputMaybe<CmsChallengeFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    duration_seconds?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    journeys?: InputMaybe<CmsJourneyFiltersInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsArticleArchiveFiltersInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<CmsArticleArchiveFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsArticleArchiveFiltersInput>>>
    personae?: InputMaybe<PersonaFiltersInput>
    publish_to_app?: InputMaybe<BooleanFilterInput>
    publish_to_website?: InputMaybe<BooleanFilterInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    role_model?: InputMaybe<BooleanFilterInput>
    type?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsArticleArchiveInput = {
    Subtitle?: InputMaybe<Scalars['String']>
    Summary?: InputMaybe<Scalars['String']>
    Title?: InputMaybe<Scalars['String']>
    UUID?: InputMaybe<Scalars['String']>
    authors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    body?: InputMaybe<Scalars['String']>
    challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    duration_seconds?: InputMaybe<Scalars['Int']>
    image?: InputMaybe<Scalars['ID']>
    journeys?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    personae?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publish_to_app?: InputMaybe<Scalars['Boolean']>
    publish_to_website?: InputMaybe<Scalars['Boolean']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    role_model?: InputMaybe<Scalars['Boolean']>
    source?: InputMaybe<Scalars['ID']>
    type?: InputMaybe<Enum_Cmsarticlearchive_Type>
}

export type CmsArticleArchiveRelationResponseCollection = {
    __typename?: 'CmsArticleArchiveRelationResponseCollection'
    data: Array<CmsArticleArchiveEntity>
}

export type CmsArticleEntity = {
    __typename?: 'CmsArticleEntity'
    attributes?: Maybe<CmsArticle>
    id?: Maybe<Scalars['ID']>
}

export type CmsArticleEntityResponse = {
    __typename?: 'CmsArticleEntityResponse'
    data?: Maybe<CmsArticleEntity>
}

export type CmsArticleEntityResponseCollection = {
    __typename?: 'CmsArticleEntityResponseCollection'
    data: Array<CmsArticleEntity>
    meta: ResponseCollectionMeta
}

export type CmsArticleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsArticleFiltersInput>>>
    authors?: InputMaybe<CmsContributorFiltersInput>
    body?: InputMaybe<StringFilterInput>
    challenges?: InputMaybe<CmsChallengeFiltersInput>
    contentType?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    durationInSeconds?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    isRoleModel?: InputMaybe<BooleanFilterInput>
    journeys?: InputMaybe<CmsJourneyFiltersInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsArticleFiltersInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<CmsArticleFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsArticleFiltersInput>>>
    personae?: InputMaybe<PersonaFiltersInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    subtitle?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
    videoUrl?: InputMaybe<StringFilterInput>
}

export type CmsArticleInput = {
    audio?: InputMaybe<Scalars['ID']>
    authors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    body?: InputMaybe<Scalars['String']>
    challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    contentType?: InputMaybe<Enum_Cmsarticle_Contenttype>
    durationInSeconds?: InputMaybe<Scalars['Int']>
    featuredImage?: InputMaybe<Scalars['ID']>
    isRoleModel?: InputMaybe<Scalars['Boolean']>
    journeys?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    personae?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subtitle?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
    videoUrl?: InputMaybe<Scalars['String']>
}

export type CmsArticleRelationResponseCollection = {
    __typename?: 'CmsArticleRelationResponseCollection'
    data: Array<CmsArticleEntity>
}

export type CmsBiotype = {
    __typename?: 'CmsBiotype'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsBiotypeRelationResponseCollection>
    name: Scalars['String']
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsBiotypeLocalizationsArgs = {
    filters?: InputMaybe<CmsBiotypeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsBiotypeEntity = {
    __typename?: 'CmsBiotypeEntity'
    attributes?: Maybe<CmsBiotype>
    id?: Maybe<Scalars['ID']>
}

export type CmsBiotypeEntityResponse = {
    __typename?: 'CmsBiotypeEntityResponse'
    data?: Maybe<CmsBiotypeEntity>
}

export type CmsBiotypeEntityResponseCollection = {
    __typename?: 'CmsBiotypeEntityResponseCollection'
    data: Array<CmsBiotypeEntity>
    meta: ResponseCollectionMeta
}

export type CmsBiotypeFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsBiotypeFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsBiotypeFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsBiotypeFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsBiotypeFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsBiotypeInput = {
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsBiotypeRelationResponseCollection = {
    __typename?: 'CmsBiotypeRelationResponseCollection'
    data: Array<CmsBiotypeEntity>
}

export type CmsChallenge = {
    __typename?: 'CmsChallenge'
    Articles?: Maybe<CmsArticleRelationResponseCollection>
    Description: Scalars['String']
    Name: Scalars['String']
    Thumbnail: UploadFileEntityResponse
    achievements?: Maybe<CmsAchievementRelationResponseCollection>
    biotype?: Maybe<CmsBiotypeEntityResponse>
    challenge_type?: Maybe<Enum_Cmschallenge_Challenge_Type>
    createdAt?: Maybe<Scalars['DateTime']>
    device_enabled?: Maybe<Scalars['Boolean']>
    durationInDays: Scalars['Int']
    goal: Scalars['String']
    header: UploadFileEntityResponse
    isFeatured: Scalars['Boolean']
    journeys?: Maybe<CmsJourneyRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsChallengeRelationResponseCollection>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    position?: Maybe<Scalars['Int']>
    publishedAt?: Maybe<Scalars['DateTime']>
    type: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsChallengeArticlesArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsChallengeAchievementsArgs = {
    filters?: InputMaybe<CmsAchievementFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsChallengeJourneysArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsChallengeLocalizationsArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsChallengeMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsChallengeEntity = {
    __typename?: 'CmsChallengeEntity'
    attributes?: Maybe<CmsChallenge>
    id?: Maybe<Scalars['ID']>
}

export type CmsChallengeEntityResponse = {
    __typename?: 'CmsChallengeEntityResponse'
    data?: Maybe<CmsChallengeEntity>
}

export type CmsChallengeEntityResponseCollection = {
    __typename?: 'CmsChallengeEntityResponseCollection'
    data: Array<CmsChallengeEntity>
    meta: ResponseCollectionMeta
}

export type CmsChallengeFiltersInput = {
    Articles?: InputMaybe<CmsArticleFiltersInput>
    Description?: InputMaybe<StringFilterInput>
    Name?: InputMaybe<StringFilterInput>
    achievements?: InputMaybe<CmsAchievementFiltersInput>
    and?: InputMaybe<Array<InputMaybe<CmsChallengeFiltersInput>>>
    biotype?: InputMaybe<CmsBiotypeFiltersInput>
    challenge_type?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    device_enabled?: InputMaybe<BooleanFilterInput>
    durationInDays?: InputMaybe<IntFilterInput>
    goal?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    isFeatured?: InputMaybe<BooleanFilterInput>
    journeys?: InputMaybe<CmsJourneyFiltersInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsChallengeFiltersInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<CmsChallengeFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsChallengeFiltersInput>>>
    position?: InputMaybe<IntFilterInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    type?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsChallengeInput = {
    Articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    Description?: InputMaybe<Scalars['String']>
    Name?: InputMaybe<Scalars['String']>
    Thumbnail?: InputMaybe<Scalars['ID']>
    achievements?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    biotype?: InputMaybe<Scalars['ID']>
    challenge_type?: InputMaybe<Enum_Cmschallenge_Challenge_Type>
    device_enabled?: InputMaybe<Scalars['Boolean']>
    durationInDays?: InputMaybe<Scalars['Int']>
    goal?: InputMaybe<Scalars['String']>
    header?: InputMaybe<Scalars['ID']>
    isFeatured?: InputMaybe<Scalars['Boolean']>
    journeys?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    position?: InputMaybe<Scalars['Int']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    type?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsChallengeRelationResponseCollection = {
    __typename?: 'CmsChallengeRelationResponseCollection'
    data: Array<CmsChallengeEntity>
}

export type CmsCompanyResourceSection = {
    __typename?: 'CmsCompanyResourceSection'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsCompanyResourceSectionRelationResponseCollection>
    name?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['DateTime']>
    resources?: Maybe<Array<Maybe<ComponentCompanyCompanyResource>>>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsCompanyResourceSectionLocalizationsArgs = {
    filters?: InputMaybe<CmsCompanyResourceSectionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanyResourceSectionResourcesArgs = {
    filters?: InputMaybe<ComponentCompanyCompanyResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanyResourceSectionSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanyResourceSectionEntity = {
    __typename?: 'CmsCompanyResourceSectionEntity'
    attributes?: Maybe<CmsCompanyResourceSection>
    id?: Maybe<Scalars['ID']>
}

export type CmsCompanyResourceSectionEntityResponse = {
    __typename?: 'CmsCompanyResourceSectionEntityResponse'
    data?: Maybe<CmsCompanyResourceSectionEntity>
}

export type CmsCompanyResourceSectionEntityResponseCollection = {
    __typename?: 'CmsCompanyResourceSectionEntityResponseCollection'
    data: Array<CmsCompanyResourceSectionEntity>
    meta: ResponseCollectionMeta
}

export type CmsCompanyResourceSectionFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsCompanyResourceSectionFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsCompanyResourceSectionFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsCompanyResourceSectionFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsCompanyResourceSectionFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    resources?: InputMaybe<ComponentCompanyCompanyResourceFiltersInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    subtitle?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsCompanyResourceSectionInput = {
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    resources?: InputMaybe<
        Array<InputMaybe<ComponentCompanyCompanyResourceInput>>
    >
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subtitle?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
}

export type CmsCompanyResourceSectionRelationResponseCollection = {
    __typename?: 'CmsCompanyResourceSectionRelationResponseCollection'
    data: Array<CmsCompanyResourceSectionEntity>
}

export type CmsCompanySubscription = {
    __typename?: 'CmsCompanySubscription'
    articles?: Maybe<CmsArticleRelationResponseCollection>
    companyId?: Maybe<Scalars['String']>
    companyResourceSections?: Maybe<CmsCompanyResourceSectionRelationResponseCollection>
    courses?: Maybe<CmsCourseRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    featuredItems?: Maybe<TodayFeaturedItemRelationResponseCollection>
    journeys?: Maybe<CmsJourneyRelationResponseCollection>
    name?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['DateTime']>
    resets?: Maybe<CmsResetRelationResponseCollection>
    subscriptionId: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    webinars?: Maybe<CmsWebinarRelationResponseCollection>
}

export type CmsCompanySubscriptionArticlesArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionCompanyResourceSectionsArgs = {
    filters?: InputMaybe<CmsCompanyResourceSectionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionCoursesArgs = {
    filters?: InputMaybe<CmsCourseFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionFeaturedItemsArgs = {
    filters?: InputMaybe<TodayFeaturedItemFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionJourneysArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionResetsArgs = {
    filters?: InputMaybe<CmsResetFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionWebinarsArgs = {
    filters?: InputMaybe<CmsWebinarFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCompanySubscriptionEntity = {
    __typename?: 'CmsCompanySubscriptionEntity'
    attributes?: Maybe<CmsCompanySubscription>
    id?: Maybe<Scalars['ID']>
}

export type CmsCompanySubscriptionEntityResponse = {
    __typename?: 'CmsCompanySubscriptionEntityResponse'
    data?: Maybe<CmsCompanySubscriptionEntity>
}

export type CmsCompanySubscriptionEntityResponseCollection = {
    __typename?: 'CmsCompanySubscriptionEntityResponseCollection'
    data: Array<CmsCompanySubscriptionEntity>
    meta: ResponseCollectionMeta
}

export type CmsCompanySubscriptionFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsCompanySubscriptionFiltersInput>>>
    articles?: InputMaybe<CmsArticleFiltersInput>
    companyId?: InputMaybe<StringFilterInput>
    companyResourceSections?: InputMaybe<CmsCompanyResourceSectionFiltersInput>
    courses?: InputMaybe<CmsCourseFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    featuredItems?: InputMaybe<TodayFeaturedItemFiltersInput>
    id?: InputMaybe<IdFilterInput>
    journeys?: InputMaybe<CmsJourneyFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsCompanySubscriptionFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    resets?: InputMaybe<CmsResetFiltersInput>
    subscriptionId?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    webinars?: InputMaybe<CmsWebinarFiltersInput>
}

export type CmsCompanySubscriptionInput = {
    articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    companyId?: InputMaybe<Scalars['String']>
    companyResourceSections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    featuredItems?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    journeys?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    resets?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subscriptionId?: InputMaybe<Scalars['String']>
    webinars?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type CmsCompanySubscriptionRelationResponseCollection = {
    __typename?: 'CmsCompanySubscriptionRelationResponseCollection'
    data: Array<CmsCompanySubscriptionEntity>
}

export enum CmsContentType {
    CmsArticle = 'CMS_ARTICLE',
    CmsBook = 'CMS_BOOK',
    CmsChallenge = 'CMS_CHALLENGE',
    CmsCompanySubscription = 'CMS_COMPANY_SUBSCRIPTION',
    CmsCourse = 'CMS_COURSE',
    CmsCourseLesson = 'CMS_COURSE_LESSON',
    CmsCourseModule = 'CMS_COURSE_MODULE',
    CmsJourney = 'CMS_JOURNEY',
    CmsMicrostep = 'CMS_MICROSTEP',
    CmsPodcast = 'CMS_PODCAST',
    CmsPodcastEpisode = 'CMS_PODCAST_EPISODE',
    CmsPodcastSeason = 'CMS_PODCAST_SEASON',
    CmsReset = 'CMS_RESET',
    CmsResetAudio = 'CMS_RESET_AUDIO'
}

export type CmsContributor = {
    __typename?: 'CmsContributor'
    articles?: Maybe<CmsArticleRelationResponseCollection>
    bio?: Maybe<Scalars['String']>
    courses?: Maybe<CmsCourseRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsContributorRelationResponseCollection>
    name: Scalars['String']
    photo?: Maybe<UploadFileEntityResponse>
    podcastEpisodes?: Maybe<CmsPodcastEpisodeRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    title?: Maybe<Scalars['String']>
    type?: Maybe<Array<Maybe<ComponentContributorType>>>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsContributorArticlesArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsContributorCoursesArgs = {
    filters?: InputMaybe<CmsCourseFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsContributorLocalizationsArgs = {
    filters?: InputMaybe<CmsContributorFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsContributorPodcastEpisodesArgs = {
    filters?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsContributorTypeArgs = {
    filters?: InputMaybe<ComponentContributorTypeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsContributorEntity = {
    __typename?: 'CmsContributorEntity'
    attributes?: Maybe<CmsContributor>
    id?: Maybe<Scalars['ID']>
}

export type CmsContributorEntityResponse = {
    __typename?: 'CmsContributorEntityResponse'
    data?: Maybe<CmsContributorEntity>
}

export type CmsContributorEntityResponseCollection = {
    __typename?: 'CmsContributorEntityResponseCollection'
    data: Array<CmsContributorEntity>
    meta: ResponseCollectionMeta
}

export type CmsContributorFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsContributorFiltersInput>>>
    articles?: InputMaybe<CmsArticleFiltersInput>
    bio?: InputMaybe<StringFilterInput>
    courses?: InputMaybe<CmsCourseFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsContributorFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsContributorFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsContributorFiltersInput>>>
    podcastEpisodes?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    title?: InputMaybe<StringFilterInput>
    type?: InputMaybe<ComponentContributorTypeFiltersInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsContributorInput = {
    articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    bio?: InputMaybe<Scalars['String']>
    courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    photo?: InputMaybe<Scalars['ID']>
    podcastEpisodes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    title?: InputMaybe<Scalars['String']>
    type?: InputMaybe<Array<InputMaybe<ComponentContributorTypeInput>>>
}

export type CmsContributorRelationResponseCollection = {
    __typename?: 'CmsContributorRelationResponseCollection'
    data: Array<CmsContributorEntity>
}

export type CmsCourse = {
    __typename?: 'CmsCourse'
    about: Scalars['String']
    blurbs?: Maybe<Array<Maybe<ComponentCourseBlurb>>>
    contentId?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['DateTime']>
    experts?: Maybe<CmsContributorRelationResponseCollection>
    lessons: Array<Maybe<ComponentCourseLesson>>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsCourseRelationResponseCollection>
    name?: Maybe<Scalars['String']>
    order?: Maybe<Scalars['Int']>
    publishedAt?: Maybe<Scalars['DateTime']>
    pulse_dimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    resources?: Maybe<CmsCourseResourceRelationResponseCollection>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    subtitle: Scalars['String']
    thumbnail: UploadFileEntityResponse
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsCourseBlurbsArgs = {
    filters?: InputMaybe<ComponentCourseBlurbFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseExpertsArgs = {
    filters?: InputMaybe<CmsContributorFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseLessonsArgs = {
    filters?: InputMaybe<ComponentCourseLessonFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseLocalizationsArgs = {
    filters?: InputMaybe<CmsCourseFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCoursePulse_DimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseResourcesArgs = {
    filters?: InputMaybe<CmsCourseResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseEntity = {
    __typename?: 'CmsCourseEntity'
    attributes?: Maybe<CmsCourse>
    id?: Maybe<Scalars['ID']>
}

export type CmsCourseEntityResponse = {
    __typename?: 'CmsCourseEntityResponse'
    data?: Maybe<CmsCourseEntity>
}

export type CmsCourseEntityResponseCollection = {
    __typename?: 'CmsCourseEntityResponseCollection'
    data: Array<CmsCourseEntity>
    meta: ResponseCollectionMeta
}

export type CmsCourseFiltersInput = {
    about?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<CmsCourseFiltersInput>>>
    blurbs?: InputMaybe<ComponentCourseBlurbFiltersInput>
    contentId?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    experts?: InputMaybe<CmsContributorFiltersInput>
    id?: InputMaybe<IdFilterInput>
    lessons?: InputMaybe<ComponentCourseLessonFiltersInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsCourseFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsCourseFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsCourseFiltersInput>>>
    order?: InputMaybe<IntFilterInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulse_dimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    resources?: InputMaybe<CmsCourseResourceFiltersInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    subtitle?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsCourseId = {
    __typename?: 'CmsCourseId'
    courseId: Scalars['String']
    courseResources?: Maybe<CmsCourseResourceRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsCourseIdCourseResourcesArgs = {
    filters?: InputMaybe<CmsCourseResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseIdEntity = {
    __typename?: 'CmsCourseIdEntity'
    attributes?: Maybe<CmsCourseId>
    id?: Maybe<Scalars['ID']>
}

export type CmsCourseIdEntityResponse = {
    __typename?: 'CmsCourseIdEntityResponse'
    data?: Maybe<CmsCourseIdEntity>
}

export type CmsCourseIdEntityResponseCollection = {
    __typename?: 'CmsCourseIdEntityResponseCollection'
    data: Array<CmsCourseIdEntity>
    meta: ResponseCollectionMeta
}

export type CmsCourseIdFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsCourseIdFiltersInput>>>
    courseId?: InputMaybe<StringFilterInput>
    courseResources?: InputMaybe<CmsCourseResourceFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    not?: InputMaybe<CmsCourseIdFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsCourseIdFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsCourseIdInput = {
    courseId?: InputMaybe<Scalars['String']>
    courseResources?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type CmsCourseIdRelationResponseCollection = {
    __typename?: 'CmsCourseIdRelationResponseCollection'
    data: Array<CmsCourseIdEntity>
}

export type CmsCourseInput = {
    about?: InputMaybe<Scalars['String']>
    blurbs?: InputMaybe<Array<InputMaybe<ComponentCourseBlurbInput>>>
    contentId?: InputMaybe<Scalars['String']>
    experts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    lessons?: InputMaybe<Array<InputMaybe<ComponentCourseLessonInput>>>
    name?: InputMaybe<Scalars['String']>
    order?: InputMaybe<Scalars['Int']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulse_dimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    resources?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subtitle?: InputMaybe<Scalars['String']>
    thumbnail?: InputMaybe<Scalars['ID']>
    title?: InputMaybe<Scalars['String']>
}

export type CmsCourseRelationResponseCollection = {
    __typename?: 'CmsCourseRelationResponseCollection'
    data: Array<CmsCourseEntity>
}

export type CmsCourseResource = {
    __typename?: 'CmsCourseResource'
    courseIds?: Maybe<CmsCourseIdRelationResponseCollection>
    courses?: Maybe<CmsCourseRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    link?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsCourseResourceRelationResponseCollection>
    name?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['DateTime']>
    resource?: Maybe<UploadFileEntityResponse>
    resourceType?: Maybe<Enum_Cmscourseresource_Resourcetype>
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsCourseResourceCourseIdsArgs = {
    filters?: InputMaybe<CmsCourseIdFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseResourceCoursesArgs = {
    filters?: InputMaybe<CmsCourseFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseResourceLocalizationsArgs = {
    filters?: InputMaybe<CmsCourseResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsCourseResourceEntity = {
    __typename?: 'CmsCourseResourceEntity'
    attributes?: Maybe<CmsCourseResource>
    id?: Maybe<Scalars['ID']>
}

export type CmsCourseResourceEntityResponse = {
    __typename?: 'CmsCourseResourceEntityResponse'
    data?: Maybe<CmsCourseResourceEntity>
}

export type CmsCourseResourceEntityResponseCollection = {
    __typename?: 'CmsCourseResourceEntityResponseCollection'
    data: Array<CmsCourseResourceEntity>
    meta: ResponseCollectionMeta
}

export type CmsCourseResourceFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsCourseResourceFiltersInput>>>
    courseIds?: InputMaybe<CmsCourseIdFiltersInput>
    courses?: InputMaybe<CmsCourseFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    link?: InputMaybe<StringFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsCourseResourceFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsCourseResourceFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsCourseResourceFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    resourceType?: InputMaybe<StringFilterInput>
    summary?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsCourseResourceInput = {
    courseIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    link?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    resource?: InputMaybe<Scalars['ID']>
    resourceType?: InputMaybe<Enum_Cmscourseresource_Resourcetype>
    summary?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsCourseResourceRelationResponseCollection = {
    __typename?: 'CmsCourseResourceRelationResponseCollection'
    data: Array<CmsCourseResourceEntity>
}

export type CmsEvent = {
    __typename?: 'CmsEvent'
    cmsId: Scalars['Int']
    contentType: Scalars['String']
    locale?: Maybe<Scalars['String']>
    ts: Scalars['Instant']
    type: CmsMutationType
    uuid?: Maybe<Scalars['String']>
}

export type CmsJourney = {
    __typename?: 'CmsJourney'
    UUID?: Maybe<Scalars['String']>
    article?: Maybe<CmsArticleRelationResponseCollection>
    cms_challenges?: Maybe<CmsChallengeRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    enabled: Scalars['Boolean']
    image?: Maybe<UploadFileRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsJourneyRelationResponseCollection>
    microstep?: Maybe<CmsMicrostepRelationResponseCollection>
    name: Scalars['String']
    order_position?: Maybe<Scalars['Int']>
    publishedAt?: Maybe<Scalars['DateTime']>
    short_name: Scalars['String']
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsJourneyArticleArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneyCms_ChallengesArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneyImageArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneyLocalizationsArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneyMicrostepArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneySubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsJourneyEntity = {
    __typename?: 'CmsJourneyEntity'
    attributes?: Maybe<CmsJourney>
    id?: Maybe<Scalars['ID']>
}

export type CmsJourneyEntityResponse = {
    __typename?: 'CmsJourneyEntityResponse'
    data?: Maybe<CmsJourneyEntity>
}

export type CmsJourneyEntityResponseCollection = {
    __typename?: 'CmsJourneyEntityResponseCollection'
    data: Array<CmsJourneyEntity>
    meta: ResponseCollectionMeta
}

export type CmsJourneyFiltersInput = {
    UUID?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<CmsJourneyFiltersInput>>>
    article?: InputMaybe<CmsArticleFiltersInput>
    cms_challenges?: InputMaybe<CmsChallengeFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    enabled?: InputMaybe<BooleanFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsJourneyFiltersInput>
    microstep?: InputMaybe<CmsMicrostepFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsJourneyFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsJourneyFiltersInput>>>
    order_position?: InputMaybe<IntFilterInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    short_name?: InputMaybe<StringFilterInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsJourneyInput = {
    UUID?: InputMaybe<Scalars['String']>
    article?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    cms_challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    description?: InputMaybe<Scalars['String']>
    enabled?: InputMaybe<Scalars['Boolean']>
    image?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    microstep?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    order_position?: InputMaybe<Scalars['Int']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    short_name?: InputMaybe<Scalars['String']>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type CmsJourneyRelationResponseCollection = {
    __typename?: 'CmsJourneyRelationResponseCollection'
    data: Array<CmsJourneyEntity>
}

export type CmsMicrostep = {
    __typename?: 'CmsMicrostep'
    achievements?: Maybe<CmsAchievementRelationResponseCollection>
    action?: Maybe<Scalars['String']>
    article?: Maybe<CmsArticleRelationResponseCollection>
    body: Scalars['String']
    cms_challenges?: Maybe<CmsChallengeRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    deprecate_at?: Maybe<Scalars['Date']>
    duration?: Maybe<Scalars['Int']>
    effort?: Maybe<Enum_Cmsmicrostep_Effort>
    frequency?: Maybe<Enum_Cmsmicrostep_Frequency>
    journey?: Maybe<CmsJourneyRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsMicrostepRelationResponseCollection>
    personaes?: Maybe<PersonaRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    timeOfDay?: Maybe<Enum_Cmsmicrostep_Timeofday>
    time_unit?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsMicrostepAchievementsArgs = {
    filters?: InputMaybe<CmsAchievementFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepArticleArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepCms_ChallengesArgs = {
    filters?: InputMaybe<CmsChallengeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepJourneyArgs = {
    filters?: InputMaybe<CmsJourneyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepLocalizationsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepPersonaesArgs = {
    filters?: InputMaybe<PersonaFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepPulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsMicrostepEntity = {
    __typename?: 'CmsMicrostepEntity'
    attributes?: Maybe<CmsMicrostep>
    id?: Maybe<Scalars['ID']>
}

export type CmsMicrostepEntityResponse = {
    __typename?: 'CmsMicrostepEntityResponse'
    data?: Maybe<CmsMicrostepEntity>
}

export type CmsMicrostepEntityResponseCollection = {
    __typename?: 'CmsMicrostepEntityResponseCollection'
    data: Array<CmsMicrostepEntity>
    meta: ResponseCollectionMeta
}

export type CmsMicrostepFiltersInput = {
    achievements?: InputMaybe<CmsAchievementFiltersInput>
    action?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<CmsMicrostepFiltersInput>>>
    article?: InputMaybe<CmsArticleFiltersInput>
    body?: InputMaybe<StringFilterInput>
    cms_challenges?: InputMaybe<CmsChallengeFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    deprecate_at?: InputMaybe<DateFilterInput>
    duration?: InputMaybe<IntFilterInput>
    effort?: InputMaybe<StringFilterInput>
    frequency?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    journey?: InputMaybe<CmsJourneyFiltersInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<CmsMicrostepFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsMicrostepFiltersInput>>>
    personaes?: InputMaybe<PersonaFiltersInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    timeOfDay?: InputMaybe<StringFilterInput>
    time_unit?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsMicrostepInput = {
    achievements?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    action?: InputMaybe<Scalars['String']>
    article?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    body?: InputMaybe<Scalars['String']>
    cms_challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    deprecate_at?: InputMaybe<Scalars['Date']>
    duration?: InputMaybe<Scalars['Int']>
    effort?: InputMaybe<Enum_Cmsmicrostep_Effort>
    frequency?: InputMaybe<Enum_Cmsmicrostep_Frequency>
    journey?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    personaes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    timeOfDay?: InputMaybe<Enum_Cmsmicrostep_Timeofday>
    time_unit?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsMicrostepRelationResponseCollection = {
    __typename?: 'CmsMicrostepRelationResponseCollection'
    data: Array<CmsMicrostepEntity>
}

export enum CmsMutationType {
    Create = 'CREATE',
    Delete = 'DELETE',
    Update = 'UPDATE'
}

export type CmsPodcast = {
    __typename?: 'CmsPodcast'
    createdAt?: Maybe<Scalars['DateTime']>
    image?: Maybe<UploadFileEntityResponse>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsPodcastRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    seasons?: Maybe<CmsPodcastSeasonRelationResponseCollection>
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsPodcastLocalizationsArgs = {
    filters?: InputMaybe<CmsPodcastFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastPulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastSeasonsArgs = {
    filters?: InputMaybe<CmsPodcastSeasonFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastEntity = {
    __typename?: 'CmsPodcastEntity'
    attributes?: Maybe<CmsPodcast>
    id?: Maybe<Scalars['ID']>
}

export type CmsPodcastEntityResponse = {
    __typename?: 'CmsPodcastEntityResponse'
    data?: Maybe<CmsPodcastEntity>
}

export type CmsPodcastEntityResponseCollection = {
    __typename?: 'CmsPodcastEntityResponseCollection'
    data: Array<CmsPodcastEntity>
    meta: ResponseCollectionMeta
}

export type CmsPodcastEpisode = {
    __typename?: 'CmsPodcastEpisode'
    audio?: Maybe<UploadFileEntityResponse>
    createdAt?: Maybe<Scalars['DateTime']>
    guests?: Maybe<CmsContributorRelationResponseCollection>
    image?: Maybe<UploadFileEntityResponse>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsPodcastEpisodeRelationResponseCollection>
    number: Scalars['Int']
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    season?: Maybe<CmsPodcastSeasonEntityResponse>
    summary: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsPodcastEpisodeGuestsArgs = {
    filters?: InputMaybe<CmsContributorFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastEpisodeLocalizationsArgs = {
    filters?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastEpisodePulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastEpisodeEntity = {
    __typename?: 'CmsPodcastEpisodeEntity'
    attributes?: Maybe<CmsPodcastEpisode>
    id?: Maybe<Scalars['ID']>
}

export type CmsPodcastEpisodeEntityResponse = {
    __typename?: 'CmsPodcastEpisodeEntityResponse'
    data?: Maybe<CmsPodcastEpisodeEntity>
}

export type CmsPodcastEpisodeEntityResponseCollection = {
    __typename?: 'CmsPodcastEpisodeEntityResponseCollection'
    data: Array<CmsPodcastEpisodeEntity>
    meta: ResponseCollectionMeta
}

export type CmsPodcastEpisodeFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsPodcastEpisodeFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    guests?: InputMaybe<CmsContributorFiltersInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    not?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    number?: InputMaybe<IntFilterInput>
    or?: InputMaybe<Array<InputMaybe<CmsPodcastEpisodeFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    season?: InputMaybe<CmsPodcastSeasonFiltersInput>
    summary?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsPodcastEpisodeInput = {
    audio?: InputMaybe<Scalars['ID']>
    guests?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    image?: InputMaybe<Scalars['ID']>
    number?: InputMaybe<Scalars['Int']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    season?: InputMaybe<Scalars['ID']>
    summary?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
}

export type CmsPodcastEpisodeRelationResponseCollection = {
    __typename?: 'CmsPodcastEpisodeRelationResponseCollection'
    data: Array<CmsPodcastEpisodeEntity>
}

export type CmsPodcastFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsPodcastFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsPodcastFiltersInput>
    not?: InputMaybe<CmsPodcastFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsPodcastFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    seasons?: InputMaybe<CmsPodcastSeasonFiltersInput>
    summary?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsPodcastInput = {
    image?: InputMaybe<Scalars['ID']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    seasons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    summary?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
}

export type CmsPodcastRelationResponseCollection = {
    __typename?: 'CmsPodcastRelationResponseCollection'
    data: Array<CmsPodcastEntity>
}

export type CmsPodcastSeason = {
    __typename?: 'CmsPodcastSeason'
    createdAt?: Maybe<Scalars['DateTime']>
    episodes?: Maybe<CmsPodcastEpisodeRelationResponseCollection>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsPodcastSeasonRelationResponseCollection>
    number: Scalars['Int']
    podcast?: Maybe<CmsPodcastEntityResponse>
    publishedAt?: Maybe<Scalars['DateTime']>
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsPodcastSeasonEpisodesArgs = {
    filters?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastSeasonLocalizationsArgs = {
    filters?: InputMaybe<CmsPodcastSeasonFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPodcastSeasonEntity = {
    __typename?: 'CmsPodcastSeasonEntity'
    attributes?: Maybe<CmsPodcastSeason>
    id?: Maybe<Scalars['ID']>
}

export type CmsPodcastSeasonEntityResponse = {
    __typename?: 'CmsPodcastSeasonEntityResponse'
    data?: Maybe<CmsPodcastSeasonEntity>
}

export type CmsPodcastSeasonEntityResponseCollection = {
    __typename?: 'CmsPodcastSeasonEntityResponseCollection'
    data: Array<CmsPodcastSeasonEntity>
    meta: ResponseCollectionMeta
}

export type CmsPodcastSeasonFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsPodcastSeasonFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    episodes?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsPodcastSeasonFiltersInput>
    not?: InputMaybe<CmsPodcastSeasonFiltersInput>
    number?: InputMaybe<IntFilterInput>
    or?: InputMaybe<Array<InputMaybe<CmsPodcastSeasonFiltersInput>>>
    podcast?: InputMaybe<CmsPodcastFiltersInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    summary?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsPodcastSeasonInput = {
    episodes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    number?: InputMaybe<Scalars['Int']>
    podcast?: InputMaybe<Scalars['ID']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    summary?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
}

export type CmsPodcastSeasonRelationResponseCollection = {
    __typename?: 'CmsPodcastSeasonRelationResponseCollection'
    data: Array<CmsPodcastSeasonEntity>
}

export type CmsPulseDimension = {
    __typename?: 'CmsPulseDimension'
    articles?: Maybe<CmsArticleRelationResponseCollection>
    courses?: Maybe<CmsCourseRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsPulseDimensionRelationResponseCollection>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    name: Scalars['String']
    podcastEpisodes?: Maybe<CmsPodcastEpisodeRelationResponseCollection>
    podcasts?: Maybe<CmsPodcastRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    quotes?: Maybe<QuoteRelationResponseCollection>
    resets?: Maybe<CmsResetRelationResponseCollection>
    type?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid?: Maybe<Scalars['String']>
}

export type CmsPulseDimensionArticlesArgs = {
    filters?: InputMaybe<CmsArticleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionCoursesArgs = {
    filters?: InputMaybe<CmsCourseFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionLocalizationsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionPodcastEpisodesArgs = {
    filters?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionPodcastsArgs = {
    filters?: InputMaybe<CmsPodcastFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionQuotesArgs = {
    filters?: InputMaybe<QuoteFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionResetsArgs = {
    filters?: InputMaybe<CmsResetFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsPulseDimensionEntity = {
    __typename?: 'CmsPulseDimensionEntity'
    attributes?: Maybe<CmsPulseDimension>
    id?: Maybe<Scalars['ID']>
}

export type CmsPulseDimensionEntityResponse = {
    __typename?: 'CmsPulseDimensionEntityResponse'
    data?: Maybe<CmsPulseDimensionEntity>
}

export type CmsPulseDimensionEntityResponseCollection = {
    __typename?: 'CmsPulseDimensionEntityResponseCollection'
    data: Array<CmsPulseDimensionEntity>
    meta: ResponseCollectionMeta
}

export type CmsPulseDimensionFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsPulseDimensionFiltersInput>>>
    articles?: InputMaybe<CmsArticleFiltersInput>
    courses?: InputMaybe<CmsCourseFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsPulseDimensionFiltersInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsPulseDimensionFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsPulseDimensionFiltersInput>>>
    podcastEpisodes?: InputMaybe<CmsPodcastEpisodeFiltersInput>
    podcasts?: InputMaybe<CmsPodcastFiltersInput>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    quotes?: InputMaybe<QuoteFiltersInput>
    resets?: InputMaybe<CmsResetFiltersInput>
    type?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsPulseDimensionInput = {
    articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    description?: InputMaybe<Scalars['String']>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    podcastEpisodes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    podcasts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    quotes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    resets?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    type?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsPulseDimensionRelationResponseCollection = {
    __typename?: 'CmsPulseDimensionRelationResponseCollection'
    data: Array<CmsPulseDimensionEntity>
}

export type CmsReset = {
    __typename?: 'CmsReset'
    closed_caption?: Maybe<Scalars['String']>
    cloudflare_public_variant_url_landscape?: Maybe<Scalars['String']>
    cloudflare_public_variant_url_portrait?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['DateTime']>
    description: Scalars['String']
    duration_in_seconds: Scalars['Int']
    iframe_url_landscape?: Maybe<Scalars['String']>
    iframe_url_portrait?: Maybe<Scalars['String']>
    is_archived: Scalars['Boolean']
    is_public: Enum_Cmsreset_Is_Public
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsResetRelationResponseCollection>
    manifest_url_landscape?: Maybe<Scalars['String']>
    manifest_url_portrait?: Maybe<Scalars['String']>
    name: Scalars['String']
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    theme: Enum_Cmsreset_Theme
    thumbnail_url_landscape?: Maybe<Scalars['String']>
    thumbnail_url_portrait?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid: Scalars['String']
}

export type CmsResetLocalizationsArgs = {
    filters?: InputMaybe<CmsResetFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetPulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetAudio = {
    __typename?: 'CmsResetAudio'
    closed_caption?: Maybe<Scalars['String']>
    cloudflare_public_variant_url: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    description: Scalars['String']
    duration_in_seconds: Scalars['Int']
    is_archived: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsResetAudioRelationResponseCollection>
    mp3_url: Scalars['String']
    name: Scalars['String']
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid: Scalars['String']
}

export type CmsResetAudioLocalizationsArgs = {
    filters?: InputMaybe<CmsResetAudioFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetAudioEntity = {
    __typename?: 'CmsResetAudioEntity'
    attributes?: Maybe<CmsResetAudio>
    id?: Maybe<Scalars['ID']>
}

export type CmsResetAudioEntityResponse = {
    __typename?: 'CmsResetAudioEntityResponse'
    data?: Maybe<CmsResetAudioEntity>
}

export type CmsResetAudioEntityResponseCollection = {
    __typename?: 'CmsResetAudioEntityResponseCollection'
    data: Array<CmsResetAudioEntity>
    meta: ResponseCollectionMeta
}

export type CmsResetAudioFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsResetAudioFiltersInput>>>
    closed_caption?: InputMaybe<StringFilterInput>
    cloudflare_public_variant_url?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    duration_in_seconds?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    is_archived?: InputMaybe<BooleanFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsResetAudioFiltersInput>
    mp3_url?: InputMaybe<StringFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsResetAudioFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsResetAudioFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsResetAudioInput = {
    closed_caption?: InputMaybe<Scalars['String']>
    cloudflare_public_variant_url?: InputMaybe<Scalars['String']>
    description?: InputMaybe<Scalars['String']>
    duration_in_seconds?: InputMaybe<Scalars['Int']>
    is_archived?: InputMaybe<Scalars['Boolean']>
    mp3_url?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsResetAudioRelationResponseCollection = {
    __typename?: 'CmsResetAudioRelationResponseCollection'
    data: Array<CmsResetAudioEntity>
}

export type CmsResetEntity = {
    __typename?: 'CmsResetEntity'
    attributes?: Maybe<CmsReset>
    id?: Maybe<Scalars['ID']>
}

export type CmsResetEntityResponse = {
    __typename?: 'CmsResetEntityResponse'
    data?: Maybe<CmsResetEntity>
}

export type CmsResetEntityResponseCollection = {
    __typename?: 'CmsResetEntityResponseCollection'
    data: Array<CmsResetEntity>
    meta: ResponseCollectionMeta
}

export type CmsResetFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsResetFiltersInput>>>
    closed_caption?: InputMaybe<StringFilterInput>
    cloudflare_public_variant_url_landscape?: InputMaybe<StringFilterInput>
    cloudflare_public_variant_url_portrait?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    duration_in_seconds?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    iframe_url_landscape?: InputMaybe<StringFilterInput>
    iframe_url_portrait?: InputMaybe<StringFilterInput>
    is_archived?: InputMaybe<BooleanFilterInput>
    is_public?: InputMaybe<StringFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsResetFiltersInput>
    manifest_url_landscape?: InputMaybe<StringFilterInput>
    manifest_url_portrait?: InputMaybe<StringFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsResetFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsResetFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    theme?: InputMaybe<StringFilterInput>
    thumbnail_url_landscape?: InputMaybe<StringFilterInput>
    thumbnail_url_portrait?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsResetInput = {
    closed_caption?: InputMaybe<Scalars['String']>
    cloudflare_public_variant_url_landscape?: InputMaybe<Scalars['String']>
    cloudflare_public_variant_url_portrait?: InputMaybe<Scalars['String']>
    description?: InputMaybe<Scalars['String']>
    duration_in_seconds?: InputMaybe<Scalars['Int']>
    iframe_url_landscape?: InputMaybe<Scalars['String']>
    iframe_url_portrait?: InputMaybe<Scalars['String']>
    is_archived?: InputMaybe<Scalars['Boolean']>
    is_public?: InputMaybe<Enum_Cmsreset_Is_Public>
    manifest_url_landscape?: InputMaybe<Scalars['String']>
    manifest_url_portrait?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    theme?: InputMaybe<Enum_Cmsreset_Theme>
    thumbnail_url_landscape?: InputMaybe<Scalars['String']>
    thumbnail_url_portrait?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsResetRelationResponseCollection = {
    __typename?: 'CmsResetRelationResponseCollection'
    data: Array<CmsResetEntity>
}

export type CmsResetStockAudio = {
    __typename?: 'CmsResetStockAudio'
    createdAt?: Maybe<Scalars['DateTime']>
    duration_in_seconds: Scalars['Int']
    is_archived: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsResetStockAudioRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    subtitle?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    url: Scalars['String']
    uuid: Scalars['String']
}

export type CmsResetStockAudioLocalizationsArgs = {
    filters?: InputMaybe<CmsResetStockAudioFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetStockAudioEntity = {
    __typename?: 'CmsResetStockAudioEntity'
    attributes?: Maybe<CmsResetStockAudio>
    id?: Maybe<Scalars['ID']>
}

export type CmsResetStockAudioEntityResponse = {
    __typename?: 'CmsResetStockAudioEntityResponse'
    data?: Maybe<CmsResetStockAudioEntity>
}

export type CmsResetStockAudioEntityResponseCollection = {
    __typename?: 'CmsResetStockAudioEntityResponseCollection'
    data: Array<CmsResetStockAudioEntity>
    meta: ResponseCollectionMeta
}

export type CmsResetStockAudioFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsResetStockAudioFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    duration_in_seconds?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    is_archived?: InputMaybe<BooleanFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsResetStockAudioFiltersInput>
    not?: InputMaybe<CmsResetStockAudioFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsResetStockAudioFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    subtitle?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    url?: InputMaybe<StringFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsResetStockAudioInput = {
    duration_in_seconds?: InputMaybe<Scalars['Int']>
    is_archived?: InputMaybe<Scalars['Boolean']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    subtitle?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
    url?: InputMaybe<Scalars['String']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsResetStockAudioRelationResponseCollection = {
    __typename?: 'CmsResetStockAudioRelationResponseCollection'
    data: Array<CmsResetStockAudioEntity>
}

export type CmsResetStockImage = {
    __typename?: 'CmsResetStockImage'
    cloudflare_public_variant_url: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    description: Scalars['String']
    is_archived: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsResetStockImageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid: Scalars['String']
}

export type CmsResetStockImageLocalizationsArgs = {
    filters?: InputMaybe<CmsResetStockImageFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetStockImageEntity = {
    __typename?: 'CmsResetStockImageEntity'
    attributes?: Maybe<CmsResetStockImage>
    id?: Maybe<Scalars['ID']>
}

export type CmsResetStockImageEntityResponse = {
    __typename?: 'CmsResetStockImageEntityResponse'
    data?: Maybe<CmsResetStockImageEntity>
}

export type CmsResetStockImageEntityResponseCollection = {
    __typename?: 'CmsResetStockImageEntityResponseCollection'
    data: Array<CmsResetStockImageEntity>
    meta: ResponseCollectionMeta
}

export type CmsResetStockImageFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsResetStockImageFiltersInput>>>
    cloudflare_public_variant_url?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    is_archived?: InputMaybe<BooleanFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsResetStockImageFiltersInput>
    not?: InputMaybe<CmsResetStockImageFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsResetStockImageFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsResetStockImageInput = {
    cloudflare_public_variant_url?: InputMaybe<Scalars['String']>
    description?: InputMaybe<Scalars['String']>
    is_archived?: InputMaybe<Scalars['Boolean']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsResetStockImageRelationResponseCollection = {
    __typename?: 'CmsResetStockImageRelationResponseCollection'
    data: Array<CmsResetStockImageEntity>
}

export type CmsResetStockQuote = {
    __typename?: 'CmsResetStockQuote'
    author: Scalars['String']
    body: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    is_archived: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsResetStockQuoteRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
    uuid: Scalars['String']
}

export type CmsResetStockQuoteLocalizationsArgs = {
    filters?: InputMaybe<CmsResetStockQuoteFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsResetStockQuoteEntity = {
    __typename?: 'CmsResetStockQuoteEntity'
    attributes?: Maybe<CmsResetStockQuote>
    id?: Maybe<Scalars['ID']>
}

export type CmsResetStockQuoteEntityResponse = {
    __typename?: 'CmsResetStockQuoteEntityResponse'
    data?: Maybe<CmsResetStockQuoteEntity>
}

export type CmsResetStockQuoteEntityResponseCollection = {
    __typename?: 'CmsResetStockQuoteEntityResponseCollection'
    data: Array<CmsResetStockQuoteEntity>
    meta: ResponseCollectionMeta
}

export type CmsResetStockQuoteFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsResetStockQuoteFiltersInput>>>
    author?: InputMaybe<StringFilterInput>
    body?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    is_archived?: InputMaybe<BooleanFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsResetStockQuoteFiltersInput>
    not?: InputMaybe<CmsResetStockQuoteFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsResetStockQuoteFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    uuid?: InputMaybe<StringFilterInput>
}

export type CmsResetStockQuoteInput = {
    author?: InputMaybe<Scalars['String']>
    body?: InputMaybe<Scalars['String']>
    is_archived?: InputMaybe<Scalars['Boolean']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    uuid?: InputMaybe<Scalars['String']>
}

export type CmsResetStockQuoteRelationResponseCollection = {
    __typename?: 'CmsResetStockQuoteRelationResponseCollection'
    data: Array<CmsResetStockQuoteEntity>
}

export type CmsThriveContent = {
    __typename?: 'CmsThriveContent'
    /** Description of the content */
    description: Scalars['String']
    /** JSON string with extra info for the conten */
    extra: Scalars['String']
    /** ID for the content */
    id: Scalars['String']
    /** The image for the content */
    image?: Maybe<Scalars['String']>
    /** Title of the content */
    name: Scalars['String']
    type: CmsThriveContentType
    /** UUID for the content */
    uuid: Scalars['String']
}

export enum CmsThriveContentType {
    Article = 'article',
    Course = 'course',
    Microstep = 'microstep',
    Podcast = 'podcast',
    PodcastEpisode = 'podcastEpisode',
    Quote = 'quote',
    Reset = 'reset',
    Rolemodel = 'rolemodel'
}

export enum CmsThrivePulseDimensionType {
    Efficacy = 'efficacy',
    Energy = 'energy',
    Enthusiasm = 'enthusiasm'
}

export type CmsWebinar = {
    __typename?: 'CmsWebinar'
    createdAt?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    events: Array<Maybe<ComponentWebinarWebinarEvent>>
    image?: Maybe<UploadFileEntityResponse>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<CmsWebinarRelationResponseCollection>
    name?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['DateTime']>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type CmsWebinarEventsArgs = {
    filters?: InputMaybe<ComponentWebinarWebinarEventFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsWebinarLocalizationsArgs = {
    filters?: InputMaybe<CmsWebinarFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsWebinarSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CmsWebinarEntity = {
    __typename?: 'CmsWebinarEntity'
    attributes?: Maybe<CmsWebinar>
    id?: Maybe<Scalars['ID']>
}

export type CmsWebinarEntityResponse = {
    __typename?: 'CmsWebinarEntityResponse'
    data?: Maybe<CmsWebinarEntity>
}

export type CmsWebinarEntityResponseCollection = {
    __typename?: 'CmsWebinarEntityResponseCollection'
    data: Array<CmsWebinarEntity>
    meta: ResponseCollectionMeta
}

export type CmsWebinarFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<CmsWebinarFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    events?: InputMaybe<ComponentWebinarWebinarEventFiltersInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<CmsWebinarFiltersInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<CmsWebinarFiltersInput>
    or?: InputMaybe<Array<InputMaybe<CmsWebinarFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type CmsWebinarInput = {
    description?: InputMaybe<Scalars['String']>
    events?: InputMaybe<Array<InputMaybe<ComponentWebinarWebinarEventInput>>>
    image?: InputMaybe<Scalars['ID']>
    name?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    title?: InputMaybe<Scalars['String']>
}

export type CmsWebinarRelationResponseCollection = {
    __typename?: 'CmsWebinarRelationResponseCollection'
    data: Array<CmsWebinarEntity>
}

export type CognitoIntegrationMutation = {
    __typename?: 'CognitoIntegrationMutation'
    /** Disables AWS Cognito integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new AWS Cognito integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type CognitoIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type CognitoIntegrationMutationEnableArgs = {
    awsRegion?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    userPoolId?: InputMaybe<Scalars['String']>
}

export type CommunityChallengeCompletedDay = {
    __typename?: 'CommunityChallengeCompletedDay'
    /** The challenge id */
    challengeId: Scalars['UUID']
    createdAt?: Maybe<Scalars['Instant']>
    /** The completed day */
    day: Scalars['Int']
    updatedAt?: Maybe<Scalars['Instant']>
    /** The user's id */
    userId: Scalars['UUID']
}

export type CommunityChallengeThreshold = {
    __typename?: 'CommunityChallengeThreshold'
    achieved: Scalars['Int']
    threshold: Scalars['Int']
    total: Scalars['Int']
}

export type CommunityGardenInfo = {
    __typename?: 'CommunityGardenInfo'
    /** Challenge day date */
    date: Scalars['Instant']
    /** Total number of plants for that day */
    plantsNumber: Scalars['Int']
    /** The 30*15 plants template to represent the garden */
    plantsTemplate: Array<PlantInfo>
}

export type CompaniesQuery = {
    __typename?: 'CompaniesQuery'
    /** Get company details from Pulse */
    info?: Maybe<CompanyInfoDto>
}

export type Company = {
    __typename?: 'Company'
    brands: Array<Brand>
    configuration: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    imageUrl?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
    seatsAllowed: Scalars['Int']
    /** Social Groups for this company */
    socialGroups: Array<SocialGroup>
    status: CompanyStatus
    updatedAt: Scalars['Instant']
}

export type CompanyConfiguration = {
    __typename?: 'CompanyConfiguration'
    agentTriggerLimit?: Maybe<Scalars['Int']>
    companyId?: Maybe<Scalars['UUID']>
    companyName?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    digitalResetRoutingId?: Maybe<Scalars['String']>
    isActive: Scalars['Boolean']
    oauthClientId: Scalars['String']
    /** @deprecated This field has been renamed to platformOrgId, replace with platformOrgId */
    organizationId?: Maybe<Scalars['String']>
    platform: PlatformType
    platformOrgId?: Maybe<Scalars['String']>
    queryAllQueues: Scalars['Boolean']
    region?: Maybe<Scalars['String']>
    resetBufferMinutes?: Maybe<Scalars['Int']>
    resetRoutingId?: Maybe<Scalars['String']>
    resetScriptId?: Maybe<Scalars['String']>
    rollout: Scalars['Boolean']
    /** @deprecated This field has been renamed to companyId, replace with companyId */
    thriveCompanyId?: Maybe<Scalars['UUID']>
    triggerConfiguration: Array<TriggerConfiguration>
    updatedAt: Scalars['Instant']
    usesPilotPage: Scalars['Boolean']
    usesSSO: Scalars['Boolean']
    wrapUpCodeId?: Maybe<Scalars['String']>
}

export type CompanyConfigurationCreateInput = {
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    companyId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    oauthClientId: Scalars['String']
    oauthClientSecret: Scalars['String']
    platform: PlatformType
    platformOrgId?: InputMaybe<Scalars['String']>
    region?: InputMaybe<Scalars['String']>
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetRoutingId: Scalars['String']
    resetScriptId: Scalars['String']
    usesPilotPage?: InputMaybe<Scalars['Boolean']>
    usesSSO: Scalars['Boolean']
    wrapUpCodeId?: InputMaybe<Scalars['String']>
}

export type CompanyId = {
    __typename?: 'CompanyId'
    id: Scalars['UUID']
}

export type CompanyInfoDto = {
    __typename?: 'CompanyInfoDTO'
    age?: Maybe<Scalars['Int']>
    createdAt?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    matured?: Maybe<Scalars['Boolean']>
    totalEmployees: Scalars['Int']
}

export type CompanyInputGraphQlInput = {
    brands?: InputMaybe<Array<BrandInput>>
    configuration?: InputMaybe<Scalars['String']>
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic?: InputMaybe<Scalars['Boolean']>
    name: Scalars['String']
    seatsAllowed?: InputMaybe<Scalars['Int']>
    status?: InputMaybe<CompanyStatus>
}

export type CompanyMutation = {
    __typename?: 'CompanyMutation'
    /** Adds company configuration details for an organization */
    create: CompanyConfiguration
    /** Deletes the configuration for a company */
    delete: Scalars['String']
    /** Modifies the configuration for an existing company */
    update: CompanyConfiguration
}

export type CompanyMutationCreateArgs = {
    companyConfiguration: CompanyConfigurationCreateInput
}

export type CompanyMutationDeleteArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type CompanyMutationUpdateArgs = {
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    companyId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    isActive?: InputMaybe<Scalars['Boolean']>
    platform: PlatformType
    platformOrgId?: InputMaybe<Scalars['String']>
    region?: InputMaybe<Scalars['String']>
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetRoutingId?: InputMaybe<Scalars['String']>
    resetScriptId?: InputMaybe<Scalars['String']>
    usesPilotPage?: InputMaybe<Scalars['Boolean']>
    usesSSO?: InputMaybe<Scalars['Boolean']>
    wrapUpCodeId?: InputMaybe<Scalars['String']>
}

export type CompanyPage = {
    __typename?: 'CompanyPage'
    items: Array<Company>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type CompanyQuery = {
    __typename?: 'CompanyQuery'
    /** Retrieves calls company configuration with filters. */
    getAllCompanyConfiguration: Array<CompanyConfiguration>
    /** Retrieves calls company configuration by Thrive company id. */
    getCompanyConfigurationByThriveCompanyId?: Maybe<CompanyConfiguration>
    /** Returns company information for an agent if they are queued for a reset */
    getCompanyConfigurationForAgent?: Maybe<CompanyConfiguration>
    /** Retrieves the company configuration for the current user. */
    getCompanyConfigurationForUser: Array<CompanyConfiguration>
}

export type CompanyQueryGetAllCompanyConfigurationArgs = {
    companyId?: InputMaybe<Scalars['UUID']>
    isActive?: InputMaybe<Scalars['Boolean']>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    platformType?: InputMaybe<PlatformType>
}

export type CompanyQueryGetCompanyConfigurationByThriveCompanyIdArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type CompanyQueryGetCompanyConfigurationForAgentArgs = {
    agentId: Scalars['String']
    routingId: Scalars['String']
    scriptId: Scalars['String']
}

export type CompanyQueryGetCompanyConfigurationForUserArgs = {
    isActive?: InputMaybe<Scalars['Boolean']>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    platformType?: InputMaybe<PlatformType>
}

export type CompanyResource = {
    __typename?: 'CompanyResource'
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    link?: Maybe<Scalars['String']>
    subtitle: Scalars['String']
    title: Scalars['String']
    type: CompanyResourceType
}

export type CompanyResourceSection = {
    __typename?: 'CompanyResourceSection'
    createdAt: Scalars['Instant']
    isAcuteCare?: Maybe<Scalars['Boolean']>
    resources: Array<CompanyResource>
    subtitle: Scalars['String']
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type CompanyResourceSectionPage = {
    __typename?: 'CompanyResourceSectionPage'
    items: Array<CompanyResourceSection>
    page: Scalars['Int']
    pageSize: Scalars['Int']
}

export enum CompanyResourceType {
    Call = 'Call',
    Email = 'Email',
    Link = 'Link',
    Pdf = 'Pdf'
}

export enum CompanyStatus {
    Active = 'active',
    Demo = 'demo',
    Inactive = 'inactive',
    Prospect = 'prospect',
    Test = 'test'
}

export type ComponentArticleAudioArticle = {
    __typename?: 'ComponentArticleAudioArticle'
    audio: UploadFileEntityResponse
    id: Scalars['ID']
}

export type ComponentArticleAuthors = {
    __typename?: 'ComponentArticleAuthors'
    articleAuthor?: Maybe<Scalars['String']>
    id: Scalars['ID']
}

export type ComponentArticleTextArticle = {
    __typename?: 'ComponentArticleTextArticle'
    body?: Maybe<Scalars['String']>
    htmlBody?: Maybe<Scalars['String']>
    id: Scalars['ID']
}

export type ComponentArticleVideoArticle = {
    __typename?: 'ComponentArticleVideoArticle'
    id: Scalars['ID']
    video?: Maybe<UploadFileEntityResponse>
    videoUrl: Scalars['String']
}

export type ComponentCompanyCompanyResource = {
    __typename?: 'ComponentCompanyCompanyResource'
    id: Scalars['ID']
    image?: Maybe<UploadFileEntityResponse>
    subtitle: Scalars['String']
    title: Scalars['String']
    url: Scalars['String']
}

export type ComponentCompanyCompanyResourceFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentCompanyCompanyResourceFiltersInput>>
    >
    not?: InputMaybe<ComponentCompanyCompanyResourceFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentCompanyCompanyResourceFiltersInput>>
    >
    subtitle?: InputMaybe<StringFilterInput>
    title?: InputMaybe<StringFilterInput>
    url?: InputMaybe<StringFilterInput>
}

export type ComponentCompanyCompanyResourceInput = {
    id?: InputMaybe<Scalars['ID']>
    image?: InputMaybe<Scalars['ID']>
    subtitle?: InputMaybe<Scalars['String']>
    title?: InputMaybe<Scalars['String']>
    url?: InputMaybe<Scalars['String']>
}

export type ComponentContributorType = {
    __typename?: 'ComponentContributorType'
    id: Scalars['ID']
    type: Enum_Componentcontributortype_Type
}

export type ComponentContributorTypeFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentContributorTypeFiltersInput>>>
    not?: InputMaybe<ComponentContributorTypeFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentContributorTypeFiltersInput>>>
    type?: InputMaybe<StringFilterInput>
}

export type ComponentContributorTypeInput = {
    id?: InputMaybe<Scalars['ID']>
    type?: InputMaybe<Enum_Componentcontributortype_Type>
}

export type ComponentCourseBlurb = {
    __typename?: 'ComponentCourseBlurb'
    description: Scalars['String']
    id: Scalars['ID']
}

export type ComponentCourseBlurbFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentCourseBlurbFiltersInput>>>
    description?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentCourseBlurbFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentCourseBlurbFiltersInput>>>
}

export type ComponentCourseBlurbInput = {
    description?: InputMaybe<Scalars['String']>
    id?: InputMaybe<Scalars['ID']>
}

export type ComponentCourseLesson = {
    __typename?: 'ComponentCourseLesson'
    contentId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    modules: Array<Maybe<ComponentCourseModule>>
    resources?: Maybe<CmsCourseResourceRelationResponseCollection>
    thumbnail?: Maybe<UploadFileEntityResponse>
    title: Scalars['String']
}

export type ComponentCourseLessonModulesArgs = {
    filters?: InputMaybe<ComponentCourseModuleFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCourseLessonResourcesArgs = {
    filters?: InputMaybe<CmsCourseResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCourseLessonFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentCourseLessonFiltersInput>>>
    contentId?: InputMaybe<StringFilterInput>
    modules?: InputMaybe<ComponentCourseModuleFiltersInput>
    not?: InputMaybe<ComponentCourseLessonFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentCourseLessonFiltersInput>>>
    resources?: InputMaybe<CmsCourseResourceFiltersInput>
    title?: InputMaybe<StringFilterInput>
}

export type ComponentCourseLessonInput = {
    contentId?: InputMaybe<Scalars['String']>
    id?: InputMaybe<Scalars['ID']>
    modules?: InputMaybe<Array<InputMaybe<ComponentCourseModuleInput>>>
    resources?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    thumbnail?: InputMaybe<Scalars['ID']>
    title?: InputMaybe<Scalars['String']>
}

export type ComponentCourseModule = {
    __typename?: 'ComponentCourseModule'
    byline: Scalars['String']
    contentId?: Maybe<Scalars['String']>
    displayName: Scalars['String']
    id: Scalars['ID']
    thumbnail: UploadFileEntityResponse
    title: Scalars['String']
    type: Enum_Componentcoursemodule_Type
    video?: Maybe<UploadFileEntityResponse>
    videoUrl: Scalars['String']
}

export type ComponentCourseModuleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentCourseModuleFiltersInput>>>
    byline?: InputMaybe<StringFilterInput>
    contentId?: InputMaybe<StringFilterInput>
    displayName?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentCourseModuleFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentCourseModuleFiltersInput>>>
    title?: InputMaybe<StringFilterInput>
    type?: InputMaybe<StringFilterInput>
    videoUrl?: InputMaybe<StringFilterInput>
}

export type ComponentCourseModuleInput = {
    byline?: InputMaybe<Scalars['String']>
    contentId?: InputMaybe<Scalars['String']>
    displayName?: InputMaybe<Scalars['String']>
    id?: InputMaybe<Scalars['ID']>
    thumbnail?: InputMaybe<Scalars['ID']>
    title?: InputMaybe<Scalars['String']>
    type?: InputMaybe<Enum_Componentcoursemodule_Type>
    video?: InputMaybe<Scalars['ID']>
    videoUrl?: InputMaybe<Scalars['String']>
}

export type ComponentGuidePageGuidePageInfo = {
    __typename?: 'ComponentGuidePageGuidePageInfo'
    htmlText?: Maybe<Scalars['String']>
    id: Scalars['ID']
}

export type ComponentGuidePageGuidePageQuiz = {
    __typename?: 'ComponentGuidePageGuidePageQuiz'
    answerBottomText?: Maybe<Scalars['String']>
    id: Scalars['ID']
    questionBottomText?: Maybe<Scalars['String']>
    quizChoice?: Maybe<Array<Maybe<ComponentGuidePageGuidePageQuizChoice>>>
    quizQuestion?: Maybe<Scalars['String']>
}

export type ComponentGuidePageGuidePageQuizQuizChoiceArgs = {
    filters?: InputMaybe<ComponentGuidePageGuidePageQuizChoiceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentGuidePageGuidePageQuizChoice = {
    __typename?: 'ComponentGuidePageGuidePageQuizChoice'
    choiceText?: Maybe<Scalars['String']>
    correctAnswer?: Maybe<Scalars['Boolean']>
    id: Scalars['ID']
}

export type ComponentGuidePageGuidePageQuizChoiceFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentGuidePageGuidePageQuizChoiceFiltersInput>>
    >
    choiceText?: InputMaybe<StringFilterInput>
    correctAnswer?: InputMaybe<BooleanFilterInput>
    not?: InputMaybe<ComponentGuidePageGuidePageQuizChoiceFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentGuidePageGuidePageQuizChoiceFiltersInput>>
    >
}

export type ComponentGuidePageGuidePageQuote = {
    __typename?: 'ComponentGuidePageGuidePageQuote'
    author: Scalars['String']
    id: Scalars['ID']
    image: UploadFileEntityResponse
    quote: Scalars['String']
}

export type ComponentGuidePageGuidePageTakeaway = {
    __typename?: 'ComponentGuidePageGuidePageTakeaway'
    id: Scalars['ID']
    takeaway: Scalars['String']
}

export type ComponentGuidePageGuidePageTakeawayFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentGuidePageGuidePageTakeawayFiltersInput>>
    >
    not?: InputMaybe<ComponentGuidePageGuidePageTakeawayFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentGuidePageGuidePageTakeawayFiltersInput>>
    >
    takeaway?: InputMaybe<StringFilterInput>
}

export type ComponentGuidePageGuidePageTakeawayInput = {
    id?: InputMaybe<Scalars['ID']>
    takeaway?: InputMaybe<Scalars['String']>
}

export type ComponentGuidePageGuidePageVideo = {
    __typename?: 'ComponentGuidePageGuidePageVideo'
    id: Scalars['ID']
    text?: Maybe<Scalars['String']>
    video?: Maybe<UploadFileEntityResponse>
    videoName?: Maybe<Scalars['String']>
}

export type ComponentMsComponents1ColOverview = {
    __typename?: 'ComponentMsComponents1ColOverview'
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponents1Coloverview_Mediatype>
    subhead?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponents2ColFullBleedVisual = {
    __typename?: 'ComponentMsComponents2ColFullBleedVisual'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    headshots?: Maybe<UploadFileRelationResponseCollection>
    id: Scalars['ID']
    media?: Maybe<UploadFileRelationResponseCollection>
    mediaType?: Maybe<Enum_Componentmscomponents2Colfullbleedvisual_Mediatype>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponents2ColFullBleedVisualHeadshotsArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponents2ColFullBleedVisualMediaArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponents2ColOverview = {
    __typename?: 'ComponentMsComponents2ColOverview'
    article?: Maybe<CmsArticleEntityResponse>
    author?: Maybe<CmsContributorEntityResponse>
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    imageSide?: Maybe<Enum_Componentmscomponents2Coloverview_Imageside>
    journey?: Maybe<CmsJourneyEntityResponse>
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponents2Coloverview_Mediatype>
    subhead?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponents2ColToggleSection = {
    __typename?: 'ComponentMsComponents2ColToggleSection'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    subhead?: Maybe<Scalars['String']>
    toggleSectionCard?: Maybe<
        Array<Maybe<ComponentMsComponentsToggleSectionCard>>
    >
}

export type ComponentMsComponents2ColToggleSectionToggleSectionCardArgs = {
    filters?: InputMaybe<ComponentMsComponentsToggleSectionCardFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsApproachCta = {
    __typename?: 'ComponentMsComponentsApproachCta'
    bgColorHex?: Maybe<Scalars['String']>
    bgImg?: Maybe<UploadFileEntityResponse>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    linkText?: Maybe<Scalars['String']>
    linkURL?: Maybe<Scalars['String']>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsBlockquoteCard = {
    __typename?: 'ComponentMsComponentsBlockquoteCard'
    headshot?: Maybe<UploadFileEntityResponse>
    id: Scalars['ID']
    logo?: Maybe<UploadFileEntityResponse>
    name?: Maybe<Scalars['String']>
    quote?: Maybe<Scalars['String']>
    showCompanyLogo?: Maybe<Scalars['Boolean']>
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsBlockquoteCardFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsBlockquoteCardFiltersInput>>
    >
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsBlockquoteCardFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsBlockquoteCardFiltersInput>>
    >
    quote?: InputMaybe<StringFilterInput>
    showCompanyLogo?: InputMaybe<BooleanFilterInput>
    title?: InputMaybe<StringFilterInput>
}

export type ComponentMsComponentsBlockquoteSection = {
    __typename?: 'ComponentMsComponentsBlockquoteSection'
    blockquoteCard?: Maybe<Array<Maybe<ComponentMsComponentsBlockquoteCard>>>
    body?: Maybe<Scalars['String']>
    id: Scalars['ID']
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsBlockquoteSectionBlockquoteCardArgs = {
    filters?: InputMaybe<ComponentMsComponentsBlockquoteCardFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsCtaInlineCallout = {
    __typename?: 'ComponentMsComponentsCtaInlineCallout'
    ctaText: Scalars['String']
    ctaUrl?: Maybe<Scalars['String']>
    headline: Scalars['String']
    id: Scalars['ID']
    image: UploadFileEntityResponse
}

export type ComponentMsComponentsFeatureGridWithPhoto = {
    __typename?: 'ComponentMsComponentsFeatureGridWithPhoto'
    featureWithPhoto?: Maybe<
        Array<Maybe<ComponentMsComponentsFeatureWithPhoto>>
    >
    iconSize?: Maybe<Enum_Componentmscomponentsfeaturegridwithphoto_Iconsize>
    id: Scalars['ID']
    photo?: Maybe<UploadFileEntityResponse>
    photoLast?: Maybe<Scalars['Boolean']>
}

export type ComponentMsComponentsFeatureGridWithPhotoFeatureWithPhotoArgs = {
    filters?: InputMaybe<ComponentMsComponentsFeatureWithPhotoFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsFeatureWithPhoto = {
    __typename?: 'ComponentMsComponentsFeatureWithPhoto'
    body: Scalars['String']
    headline: Scalars['String']
    icon: UploadFileEntityResponse
    id: Scalars['ID']
}

export type ComponentMsComponentsFeatureWithPhotoFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsFeatureWithPhotoFiltersInput>>
    >
    body?: InputMaybe<StringFilterInput>
    headline?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsFeatureWithPhotoFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsFeatureWithPhotoFiltersInput>>
    >
}

export type ComponentMsComponentsFooter = {
    __typename?: 'ComponentMsComponentsFooter'
    id: Scalars['ID']
    theme?: Maybe<Enum_Componentmscomponentsfooter_Theme>
}

export type ComponentMsComponentsHeader = {
    __typename?: 'ComponentMsComponentsHeader'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    id: Scalars['ID']
    loginText?: Maybe<Scalars['String']>
    theme?: Maybe<Enum_Componentmscomponentsheader_Theme>
}

export type ComponentMsComponentsHeroBgVisual = {
    __typename?: 'ComponentMsComponentsHeroBgVisual'
    altCtaText?: Maybe<Scalars['String']>
    altCtaUrl?: Maybe<Scalars['String']>
    backgroundPosition: Enum_Componentmscomponentsherobgvisual_Backgroundposition
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    headlineSize?: Maybe<Enum_Componentmscomponentsherobgvisual_Headlinesize>
    headlineTextSize?: Maybe<Enum_Componentmscomponentsherobgvisual_Headlinetextsize>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponentsherobgvisual_Mediatype>
    mobileMedia?: Maybe<UploadFileEntityResponse>
    overline?: Maybe<Scalars['String']>
    subhead?: Maybe<Scalars['String']>
    textSize?: Maybe<Enum_Componentmscomponentsherobgvisual_Textsize>
    theme?: Maybe<Enum_Componentmscomponentsherobgvisual_Theme>
}

export type ComponentMsComponentsHeroInlineVisual = {
    __typename?: 'ComponentMsComponentsHeroInlineVisual'
    altCtaText?: Maybe<Scalars['String']>
    altCtaUrl?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    headlineSize?: Maybe<Enum_Componentmscomponentsheroinlinevisual_Headlinesize>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponentsheroinlinevisual_Mediatype>
    mobileMedia?: Maybe<UploadFileEntityResponse>
    showAltCta?: Maybe<Scalars['Boolean']>
    showCta?: Maybe<Scalars['Boolean']>
    subhead?: Maybe<Scalars['String']>
    textSize?: Maybe<Enum_Componentmscomponentsheroinlinevisual_Textsize>
}

export type ComponentMsComponentsHeroNoVisual = {
    __typename?: 'ComponentMsComponentsHeroNoVisual'
    bgColor?: Maybe<Enum_Componentmscomponentsheronovisual_Bgcolor>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    overline?: Maybe<Scalars['String']>
    stayInTouch?: Maybe<ComponentMsComponentsStayInTouchCta>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsHeroWithMedia = {
    __typename?: 'ComponentMsComponentsHeroWithMedia'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline: Scalars['String']
    headshot?: Maybe<UploadFileEntityResponse>
    id: Scalars['ID']
    media: UploadFileEntityResponse
    minToRead?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    overline?: Maybe<Scalars['String']>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsJobPostingList = {
    __typename?: 'ComponentMsComponentsJobPostingList'
    ctaText?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    roleCtaText?: Maybe<Scalars['String']>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsPartnerLogo = {
    __typename?: 'ComponentMsComponentsPartnerLogo'
    id: Scalars['ID']
    logo?: Maybe<UploadFileEntityResponse>
}

export type ComponentMsComponentsPartnerLogoFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsPartnerLogoFiltersInput>>
    >
    not?: InputMaybe<ComponentMsComponentsPartnerLogoFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsPartnerLogoFiltersInput>>
    >
}

export type ComponentMsComponentsPartnerLogos = {
    __typename?: 'ComponentMsComponentsPartnerLogos'
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    partnerLogos?: Maybe<Array<Maybe<ComponentMsComponentsPartnerLogo>>>
    text?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsPartnerLogosPartnerLogosArgs = {
    filters?: InputMaybe<ComponentMsComponentsPartnerLogoFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsPhotoCarousel = {
    __typename?: 'ComponentMsComponentsPhotoCarousel'
    id: Scalars['ID']
    photoCarousel?: Maybe<UploadFileRelationResponseCollection>
}

export type ComponentMsComponentsPhotoCarouselPhotoCarouselArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsPrefooterCta = {
    __typename?: 'ComponentMsComponentsPrefooterCta'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponentsprefootercta_Mediatype>
    mobileMedia?: Maybe<UploadFileEntityResponse>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsProductFeature = {
    __typename?: 'ComponentMsComponentsProductFeature'
    body?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    icon?: Maybe<UploadFileEntityResponse>
    id: Scalars['ID']
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsProductFeatureFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsProductFeatureFiltersInput>>
    >
    body?: InputMaybe<StringFilterInput>
    ctaText?: InputMaybe<StringFilterInput>
    ctaUrl?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsProductFeatureFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsProductFeatureFiltersInput>>
    >
    title?: InputMaybe<StringFilterInput>
}

export type ComponentMsComponentsProductFeatureGrid = {
    __typename?: 'ComponentMsComponentsProductFeatureGrid'
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    productFeature?: Maybe<Array<Maybe<ComponentMsComponentsProductFeature>>>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsProductFeatureGridProductFeatureArgs = {
    filters?: InputMaybe<ComponentMsComponentsProductFeatureFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsProductOverviewCta = {
    __typename?: 'ComponentMsComponentsProductOverviewCta'
    altCtaText?: Maybe<Scalars['String']>
    altCtaUrl?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsSectionIntro = {
    __typename?: 'ComponentMsComponentsSectionIntro'
    body: Scalars['String']
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    id: Scalars['ID']
    overlineContext?: Maybe<Scalars['String']>
    sectionHeadline: Scalars['String']
    sectionHeadlineStyle?: Maybe<Enum_Componentmscomponentssectionintro_Sectionheadlinestyle>
}

export type ComponentMsComponentsStat = {
    __typename?: 'ComponentMsComponentsStat'
    companyName?: Maybe<Scalars['String']>
    copy?: Maybe<Scalars['String']>
    id: Scalars['ID']
    percent?: Maybe<Scalars['Int']>
}

export type ComponentMsComponentsStatFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentMsComponentsStatFiltersInput>>>
    companyName?: InputMaybe<StringFilterInput>
    copy?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsStatFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentMsComponentsStatFiltersInput>>>
    percent?: InputMaybe<IntFilterInput>
}

export type ComponentMsComponentsStatsSection = {
    __typename?: 'ComponentMsComponentsStatsSection'
    Headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    stat?: Maybe<Array<Maybe<ComponentMsComponentsStat>>>
    theme?: Maybe<Enum_Componentmscomponentsstatssection_Theme>
}

export type ComponentMsComponentsStatsSectionStatArgs = {
    filters?: InputMaybe<ComponentMsComponentsStatFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsStayInTouchCta = {
    __typename?: 'ComponentMsComponentsStayInTouchCta'
    ctaText?: Maybe<Scalars['String']>
    headline: Scalars['String']
    id: Scalars['ID']
    subhead: Scalars['String']
    textFieldPlaceholder?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsStories = {
    __typename?: 'ComponentMsComponentsStories'
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    storiesSection: Array<Maybe<ComponentMsComponentsStory>>
    subhead?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsStoriesStoriesSectionArgs = {
    filters?: InputMaybe<ComponentMsComponentsStoryFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsStory = {
    __typename?: 'ComponentMsComponentsStory'
    article?: Maybe<CmsArticleEntityResponse>
    author?: Maybe<CmsContributorEntityResponse>
    date?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    id: Scalars['ID']
    image?: Maybe<UploadFileEntityResponse>
    journey?: Maybe<CmsJourneyEntityResponse>
    link?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsStoryFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<ComponentMsComponentsStoryFiltersInput>>>
    article?: InputMaybe<CmsArticleFiltersInput>
    author?: InputMaybe<CmsContributorFiltersInput>
    date?: InputMaybe<StringFilterInput>
    headline?: InputMaybe<StringFilterInput>
    journey?: InputMaybe<CmsJourneyFiltersInput>
    link?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsStoryFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentMsComponentsStoryFiltersInput>>>
}

export type ComponentMsComponentsTeamMemberRow = {
    __typename?: 'ComponentMsComponentsTeamMemberRow'
    bio?: Maybe<Scalars['String']>
    headshot?: Maybe<UploadFileEntityResponse>
    id: Scalars['ID']
    name?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsTeamMemberRowFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsTeamMemberRowFiltersInput>>
    >
    bio?: InputMaybe<StringFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsTeamMemberRowFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsTeamMemberRowFiltersInput>>
    >
    title?: InputMaybe<StringFilterInput>
}

export type ComponentMsComponentsTeamMemberSection = {
    __typename?: 'ComponentMsComponentsTeamMemberSection'
    body?: Maybe<Scalars['String']>
    headline: Scalars['String']
    id: Scalars['ID']
    teamMemberRow: Array<Maybe<ComponentMsComponentsTeamMemberRow>>
}

export type ComponentMsComponentsTeamMemberSectionTeamMemberRowArgs = {
    filters?: InputMaybe<ComponentMsComponentsTeamMemberRowFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentMsComponentsTextSection = {
    __typename?: 'ComponentMsComponentsTextSection'
    id: Scalars['ID']
    text: Scalars['String']
}

export type ComponentMsComponentsToggleSectionCard = {
    __typename?: 'ComponentMsComponentsToggleSectionCard'
    body?: Maybe<Scalars['String']>
    id: Scalars['ID']
    media?: Maybe<UploadFileEntityResponse>
    mediaType?: Maybe<Enum_Componentmscomponentstogglesectioncard_Mediatype>
    title?: Maybe<Scalars['String']>
}

export type ComponentMsComponentsToggleSectionCardFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsToggleSectionCardFiltersInput>>
    >
    body?: InputMaybe<StringFilterInput>
    mediaType?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentMsComponentsToggleSectionCardFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentMsComponentsToggleSectionCardFiltersInput>>
    >
    title?: InputMaybe<StringFilterInput>
}

export type ComponentSurveyChoiceChoices = {
    __typename?: 'ComponentSurveyChoiceChoices'
    id: Scalars['ID']
    text?: Maybe<Scalars['String']>
    value?: Maybe<Scalars['Int']>
}

export type ComponentSurveyChoiceChoicesFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentSurveyChoiceChoicesFiltersInput>>
    >
    not?: InputMaybe<ComponentSurveyChoiceChoicesFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentSurveyChoiceChoicesFiltersInput>>>
    text?: InputMaybe<StringFilterInput>
    value?: InputMaybe<IntFilterInput>
}

export type ComponentSurveyChoiceChoicesInput = {
    id?: InputMaybe<Scalars['ID']>
    text?: InputMaybe<Scalars['String']>
    value?: InputMaybe<Scalars['Int']>
}

export type ComponentWebinarWebinarEvent = {
    __typename?: 'ComponentWebinarWebinarEvent'
    id: Scalars['ID']
    image: UploadFileEntityResponse
    sessions: Array<Maybe<ComponentWebinarWebinarSession>>
    subtitle?: Maybe<Scalars['String']>
    tasks?: Maybe<CmsCourseResourceRelationResponseCollection>
    title: Scalars['String']
}

export type ComponentWebinarWebinarEventSessionsArgs = {
    filters?: InputMaybe<ComponentWebinarWebinarSessionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentWebinarWebinarEventTasksArgs = {
    filters?: InputMaybe<CmsCourseResourceFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentWebinarWebinarEventFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentWebinarWebinarEventFiltersInput>>
    >
    not?: InputMaybe<ComponentWebinarWebinarEventFiltersInput>
    or?: InputMaybe<Array<InputMaybe<ComponentWebinarWebinarEventFiltersInput>>>
    sessions?: InputMaybe<ComponentWebinarWebinarSessionFiltersInput>
    subtitle?: InputMaybe<StringFilterInput>
    tasks?: InputMaybe<CmsCourseResourceFiltersInput>
    title?: InputMaybe<StringFilterInput>
}

export type ComponentWebinarWebinarEventInput = {
    id?: InputMaybe<Scalars['ID']>
    image?: InputMaybe<Scalars['ID']>
    sessions?: InputMaybe<
        Array<InputMaybe<ComponentWebinarWebinarSessionInput>>
    >
    subtitle?: InputMaybe<Scalars['String']>
    tasks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    title?: InputMaybe<Scalars['String']>
}

export type ComponentWebinarWebinarSession = {
    __typename?: 'ComponentWebinarWebinarSession'
    endTime?: Maybe<Scalars['DateTime']>
    id: Scalars['ID']
    liveUrl: Scalars['String']
    startTime: Scalars['DateTime']
    videoUrl?: Maybe<Scalars['String']>
}

export type ComponentWebinarWebinarSessionFiltersInput = {
    and?: InputMaybe<
        Array<InputMaybe<ComponentWebinarWebinarSessionFiltersInput>>
    >
    endTime?: InputMaybe<DateTimeFilterInput>
    liveUrl?: InputMaybe<StringFilterInput>
    not?: InputMaybe<ComponentWebinarWebinarSessionFiltersInput>
    or?: InputMaybe<
        Array<InputMaybe<ComponentWebinarWebinarSessionFiltersInput>>
    >
    startTime?: InputMaybe<DateTimeFilterInput>
    videoUrl?: InputMaybe<StringFilterInput>
}

export type ComponentWebinarWebinarSessionInput = {
    endTime?: InputMaybe<Scalars['DateTime']>
    id?: InputMaybe<Scalars['ID']>
    liveUrl?: InputMaybe<Scalars['String']>
    startTime?: InputMaybe<Scalars['DateTime']>
    videoUrl?: InputMaybe<Scalars['String']>
}

export enum ContentDifficulty {
    Difficult = 'DIFFICULT',
    Easy = 'EASY',
    Moderate = 'MODERATE'
}

export type ContentExperienceQuery = {
    __typename?: 'ContentExperienceQuery'
    /** Acute Care Resource */
    acuteCareResource?: Maybe<AcuteCareResource>
    /**
     * DEPRECATED!!! Acute Care Resources
     * @deprecated No longer supported, returns empty page, replace with acuteCareResource
     */
    acuteCareResources: CompanyResourceSectionPage
    /** Get acute care banner for the user's locale */
    getAcuteCareBanner?: Maybe<AcuteCareBanner>
    /** Get journey progress for the user selected journey */
    getJourneyProgress?: Maybe<JourneyProgress>
}

export type ContentExperienceQueryAcuteCareResourceArgs = {
    pulseDimensionType: Scalars['String']
    pulseSurveyId: Scalars['String']
}

export type ContentExperienceQueryAcuteCareResourcesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ContentExperienceQueryGetJourneyProgressArgs = {
    journeyUUID: Scalars['UUID']
}

export enum ContentFeedback {
    Negative = 'Negative',
    Positive = 'Positive'
}

export enum ContentImpact {
    High = 'HIGH',
    Low = 'LOW',
    Moderate = 'MODERATE'
}

export type ContentList = {
    __typename?: 'ContentList'
    contents: LearnContentItemPage
    id: Scalars['UUID']
    title: Scalars['String']
}

export type ContentListContentsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ContentListPage = {
    __typename?: 'ContentListPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ContentList>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ContentListQuery = {
    __typename?: 'ContentListQuery'
    getList?: Maybe<ContentList>
    getLists: ContentListPage
}

export type ContentListQueryGetListArgs = {
    id: Scalars['UUID']
}

export type ContentListQueryGetListsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ContentMutation = {
    __typename?: 'ContentMutation'
    book: BookMutation
    journal: JournalMutation
    successStory: SuccessStoryMutation
}

export type ContentPack = {
    __typename?: 'ContentPack'
    content: Array<LearnContentItem>
    description: Scalars['String']
    title: Scalars['String']
}

export type ContentPackPage = {
    __typename?: 'ContentPackPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ContentPack>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ContentQuery = {
    __typename?: 'ContentQuery'
    journal: JournalQuery
    list: ContentListQuery
    successStory: SuccessStoryQuery
    thrive: ThriveContentQuery
}

export enum ContentType {
    Audio = 'AUDIO',
    Text = 'TEXT',
    Video = 'VIDEO'
}

export type Contributor = {
    __typename?: 'Contributor'
    bio: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    photoUrl: Scalars['String']
    title: Scalars['String']
}

export type Course = {
    __typename?: 'Course'
    about?: Maybe<Scalars['String']>
    blurbs?: Maybe<Array<Maybe<Blurb>>>
    experts?: Maybe<Array<Maybe<Expert>>>
    id: Scalars['ID']
    lessons: Array<Lesson>
    subTitle?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type CourseBlurbsArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type CourseExpertsArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type CourseLessonsArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type CourseModuleParent = {
    __typename?: 'CourseModuleParent'
    courseID: Scalars['ID']
    lessonID: Scalars['ID']
}

export type CourseMutation = {
    /** Mutation creating or updating course resource status (for course or lesson), based on userId from context */
    markCourseResourceAsCompleted: LearnContentStatus
}

export type CourseMutationMarkCourseResourceAsCompletedArgs = {
    contentId: Scalars['String']
}

export type CoursePage = {
    __typename?: 'CoursePage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<CourseV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type CourseQuery = {
    /** Get course by id */
    getCourse?: Maybe<CourseV2>
    /** Get courses by companyId and subscriptionId from context */
    getCourses: CoursePage
}

export type CourseQueryGetCourseArgs = {
    courseId: Scalars['String']
}

export type CourseQueryGetCoursesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    sort?: InputMaybe<Array<Scalars['String']>>
}

/** Supplementary Course Resource */
export type CourseResource = {
    __typename?: 'CourseResource'
    /** Course resource progress for user, based on userId from context. */
    contentStatus?: Maybe<LearnContentStatus>
    createdAt: Scalars['Instant']
    hasCompleted: Scalars['Boolean']
    /** CourseResource identifier, value of uuid from CMS */
    id: Scalars['ID']
    /** course resource type */
    resourceType: CourseResourceType
    /** link to the resource, if link contains '$companyId' tag it is replaced with user company id from context */
    source: Scalars['String']
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

/** Supplementary Course Resource Type */
export enum CourseResourceType {
    Link = 'Link',
    Pdf = 'PDF',
    Survey = 'Survey'
}

export type CourseV2 = {
    __typename?: 'CourseV2'
    about: Scalars['String']
    blurbs: Array<BlurbV2>
    bookmarked: Scalars['Boolean']
    contentType: LearnContentStatusType
    experts: Array<Contributor>
    id: Scalars['ID']
    isCompleted: Scalars['Boolean']
    lessons: Array<LessonV2>
    /** Course progress for user, based on userId from context. Checks progress on lessons. */
    progress?: Maybe<LearnContentProgress>
    resources: Array<CourseResource>
    subTitle: Scalars['String']
    thumbnail?: Maybe<Scalars['String']>
    title: Scalars['String']
}

export type CurrencyInfo = {
    __typename?: 'CurrencyInfo'
    /** The three letter code that represents this currency */
    code: Scalars['String']
    /** The key that points to the translated property in the i18n file */
    name: Scalars['String']
}

export type DailyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'DailyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type DailyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'DailyAssessmentStatsResponse'
    average: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    year: Scalars['Int']
}

export type DailyCheckInDto = {
    __typename?: 'DailyCheckInDTO'
    dispatchDate: Scalars['ISODate']
    id: Scalars['UUID']
    pulseUserId: Scalars['UUID']
    /** @deprecated Use response.recommendedContentDimension */
    recommendedContentDimension?: Maybe<Surveys>
    response?: Maybe<DailyCheckInResponseDto>
    status: Scalars['String']
    survey: PulseSurveyQuestionDto
    thriveId: Scalars['UUID']
}

export type DailyCheckInResponseDto = {
    __typename?: 'DailyCheckInResponseDTO'
    recommendAcuteCare: Scalars['Boolean']
    recommendedContentDimension: Surveys
    responseChannel: Scalars['String']
    selectedOption?: Maybe<OptionsDto>
}

export type DailyCheckInResponseInput = {
    /** The unique id of the Daily Check-In */
    id?: InputMaybe<Scalars['UUID']>
    /** The channel in which the Daily Check-In response is coming from */
    respondingChannel: Channel
    /** The date of the Daily Check-In response */
    responseDate: Scalars['ISODate']
    /** The Daily Check-In response value */
    responseValue: Scalars['Int']
    /** The list of desired recommended content types to be returned on success. Defaults to all */
    supportedContentTypes?: InputMaybe<Array<SupportedRecommendedContentType>>
}

export type DailyCheckInScoresQuery = {
    __typename?: 'DailyCheckInScoresQuery'
    /** Historic Daily Check In Scores for a company */
    historicDailyCheckInScores: HistoricDailyCheckInScoresResponse
    /** Recent Daily Check In Scores for a company */
    recentDailyCheckInScores?: Maybe<RecentDailyCheckInScoresResponse>
}

export type DailyCheckInScoresQueryHistoricDailyCheckInScoresArgs = {
    companyId: Scalars['UUID']
    dimension: Scalars['String']
    end: MonthYearInput
    start: MonthYearInput
}

export type DailyCheckInScoresQueryRecentDailyCheckInScoresArgs = {
    companyId: Scalars['UUID']
}

export type DailyCheckinDetail = {
    __typename?: 'DailyCheckinDetail'
    name?: Maybe<Scalars['String']>
}

export type DailySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'DailySleepSummaryStatsResponse'
    averageMinutesAsleep: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    year: Scalars['Int']
}

export enum DashboardViewType {
    Admin = 'ADMIN',
    Personal = 'PERSONAL'
}

export type DateFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
    contains?: InputMaybe<Scalars['Date']>
    containsi?: InputMaybe<Scalars['Date']>
    endsWith?: InputMaybe<Scalars['Date']>
    eq?: InputMaybe<Scalars['Date']>
    eqi?: InputMaybe<Scalars['Date']>
    gt?: InputMaybe<Scalars['Date']>
    gte?: InputMaybe<Scalars['Date']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
    lt?: InputMaybe<Scalars['Date']>
    lte?: InputMaybe<Scalars['Date']>
    ne?: InputMaybe<Scalars['Date']>
    not?: InputMaybe<DateFilterInput>
    notContains?: InputMaybe<Scalars['Date']>
    notContainsi?: InputMaybe<Scalars['Date']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>
    startsWith?: InputMaybe<Scalars['Date']>
}

export type DateTimeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
    contains?: InputMaybe<Scalars['DateTime']>
    containsi?: InputMaybe<Scalars['DateTime']>
    endsWith?: InputMaybe<Scalars['DateTime']>
    eq?: InputMaybe<Scalars['DateTime']>
    eqi?: InputMaybe<Scalars['DateTime']>
    gt?: InputMaybe<Scalars['DateTime']>
    gte?: InputMaybe<Scalars['DateTime']>
    in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
    lt?: InputMaybe<Scalars['DateTime']>
    lte?: InputMaybe<Scalars['DateTime']>
    ne?: InputMaybe<Scalars['DateTime']>
    not?: InputMaybe<DateTimeFilterInput>
    notContains?: InputMaybe<Scalars['DateTime']>
    notContainsi?: InputMaybe<Scalars['DateTime']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
    startsWith?: InputMaybe<Scalars['DateTime']>
}

export type DimensionTrendsDto = {
    __typename?: 'DimensionTrendsDTO'
    currentPeriodScore: Scalars['Int']
    difference: Scalars['Int']
    isZeroTrend: Scalars['Boolean']
    lastPeriodScore: Scalars['Int']
    optimalRange: OptimalRange
    type: Surveys
}

export enum Direction {
    Inbound = 'INBOUND',
    Na = 'NA',
    Outbound = 'OUTBOUND'
}

export type DisplayName = {
    __typename?: 'DisplayName'
    displayName?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    userId?: Maybe<Scalars['UUID']>
}

export type Division = {
    __typename?: 'Division'
    id?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
}

export enum Enum_Cmsachievement_Actiontype {
    ChallengeCompleted = 'CHALLENGE_COMPLETED',
    CheckIn = 'CHECK_IN'
}

export enum Enum_Cmsarticlearchive_Type {
    Audio = 'audio',
    Text = 'text',
    Video = 'video'
}

export enum Enum_Cmsarticle_Contenttype {
    Audio = 'Audio',
    Text = 'Text',
    Video = 'Video'
}

export enum Enum_Cmschallenge_Challenge_Type {
    Biotype = 'BIOTYPE',
    Journey = 'JOURNEY',
    Prize = 'PRIZE'
}

export enum Enum_Cmscourseresource_Resourcetype {
    Link = 'Link',
    Pdf = 'PDF',
    Survey = 'Survey'
}

export enum Enum_Cmsmicrostep_Effort {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM'
}

export enum Enum_Cmsmicrostep_Frequency {
    Daily = 'DAILY',
    Once = 'ONCE',
    Situational = 'SITUATIONAL',
    Weekly = 'WEEKLY'
}

export enum Enum_Cmsmicrostep_Timeofday {
    Afternoon = 'AFTERNOON',
    Anytime = 'ANYTIME',
    Evening = 'EVENING',
    Morning = 'MORNING'
}

export enum Enum_Cmsreset_Is_Public {
    No = 'No',
    Yes = 'Yes'
}

export enum Enum_Cmsreset_Theme {
    Animals = 'Animals',
    Breathing = 'Breathing',
    EscapeAndExplore = 'EscapeAndExplore',
    Gratitude = 'Gratitude',
    Meditation = 'Meditation',
    Purpose = 'Purpose',
    RestAndReflect = 'RestAndReflect',
    Seasons = 'Seasons',
    Sports = 'Sports',
    StressRelief = 'StressRelief',
    Stretching = 'Stretching',
    Thrive = 'Thrive',
    Wonder = 'Wonder'
}

export enum Enum_Componentcontributortype_Type {
    Author = 'Author',
    Expert = 'Expert',
    ThriveAuthor = 'Thrive_Author',
    ThriveEditor = 'Thrive_Editor',
    ThriveEmployee = 'Thrive_Employee'
}

export enum Enum_Componentcoursemodule_Type {
    Quiz = 'quiz',
    Text = 'text',
    Video = 'video'
}

export enum Enum_Componentmscomponents1Coloverview_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponents2Colfullbleedvisual_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponents2Coloverview_Imageside {
    Left = 'left',
    Right = 'right'
}

export enum Enum_Componentmscomponents2Coloverview_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponentsfeaturegridwithphoto_Iconsize {
    ExtraLarge = 'ExtraLarge',
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small'
}

export enum Enum_Componentmscomponentsfooter_Theme {
    Dark = 'dark',
    Light = 'light'
}

export enum Enum_Componentmscomponentsheader_Theme {
    Dark = 'dark',
    Light = 'light'
}

export enum Enum_Componentmscomponentsherobgvisual_Backgroundposition {
    BottomRight = 'Bottom_Right',
    BottomThird = 'Bottom_Third',
    CenterRight = 'Center_Right',
    TopRight = 'Top_Right',
    TopThird = 'Top_Third'
}

export enum Enum_Componentmscomponentsherobgvisual_Headlinesize {
    Lg = 'lg',
    Med = 'med',
    Sm = 'sm'
}

export enum Enum_Componentmscomponentsherobgvisual_Headlinetextsize {
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small'
}

export enum Enum_Componentmscomponentsherobgvisual_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponentsherobgvisual_Textsize {
    Lg = 'lg',
    Med = 'med',
    Sm = 'sm'
}

export enum Enum_Componentmscomponentsherobgvisual_Theme {
    Light = 'light'
}

export enum Enum_Componentmscomponentsheroinlinevisual_Headlinesize {
    Lg = 'lg',
    Med = 'med',
    Sm = 'sm'
}

export enum Enum_Componentmscomponentsheroinlinevisual_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponentsheroinlinevisual_Textsize {
    Lg = 'lg',
    Med = 'med',
    Sm = 'sm'
}

export enum Enum_Componentmscomponentsheronovisual_Bgcolor {
    Purple = 'purple',
    Teal = 'teal'
}

export enum Enum_Componentmscomponentsprefootercta_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Componentmscomponentssectionintro_Sectionheadlinestyle {
    Display = 'Display',
    Headline = 'Headline'
}

export enum Enum_Componentmscomponentsstatssection_Theme {
    Dark = 'dark',
    Light = 'light'
}

export enum Enum_Componentmscomponentstogglesectioncard_Mediatype {
    Image = 'image',
    Video = 'video'
}

export enum Enum_Pulsesurvey_Dimension {
    Allyship = 'ALLYSHIP',
    Efficacy = 'EFFICACY',
    EnergyLevels = 'ENERGY_LEVELS',
    Enthusiasm = 'ENTHUSIASM',
    Productivity = 'PRODUCTIVITY',
    Retention = 'RETENTION'
}

export type EnablingChallengeStatus = {
    __typename?: 'EnablingChallengeStatus'
    challengesInCompany: Array<UnifiedChallengeCompany>
}

export type EngagementLevelsQuery = {
    __typename?: 'EngagementLevelsQuery'
    /** Historical Engagement Levels for a company */
    historicEngagementLevels: HistoricEngagementLevelResponse
    /** Recent Engagement Levels for a company */
    recentEngagementLevels: RecentEngagementLevelResponse
}

export type EngagementLevelsQueryHistoricEngagementLevelsArgs = {
    companyId: Scalars['UUID']
    end: MonthYearInput
    start: MonthYearInput
}

export type EngagementLevelsQueryRecentEngagementLevelsArgs = {
    companyId: Scalars['UUID']
}

export type EngagementReportResponse = {
    __typename?: 'EngagementReportResponse'
    challengesStarted: Scalars['Long']
    checkins: Scalars['Long']
    companyId: Scalars['UUID']
    lastUpdated: Scalars['ISODate']
    learnModulesStarted: Scalars['Long']
    microstepsCheckedin: Scalars['Long']
    period: ReportPeriod
    resetsPlayed: Scalars['Long']
    uniqueChallengeUsers: Scalars['Long']
    uniqueCheckinUsers: Scalars['Long']
    uniqueLearnUsers: Scalars['Long']
    uniqueMicrostepUsers: Scalars['Long']
    uniqueResetUsers: Scalars['Long']
}

export type Error = {
    __typename?: 'Error'
    code: Scalars['String']
    message?: Maybe<Scalars['String']>
}

export type ErrorMessage = {
    __typename?: 'ErrorMessage'
    message: Scalars['String']
}

export type ErrorMessageCustomization = {
    __typename?: 'ErrorMessageCustomization'
    message: Scalars['String']
}

export type ErrorMessageTemplate = {
    __typename?: 'ErrorMessageTemplate'
    message: Scalars['String']
}

export type ErrorMessageWater = {
    __typename?: 'ErrorMessageWater'
    message: Scalars['String']
}

export type EthicsPage = {
    __typename?: 'EthicsPage'
    createdAt?: Maybe<Scalars['DateTime']>
    ethicsPageComponents: Array<
        Maybe<EthicsPageEthicsPageComponentsDynamicZone>
    >
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<EthicsPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type EthicsPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type EthicsPageEntity = {
    __typename?: 'EthicsPageEntity'
    attributes?: Maybe<EthicsPage>
    id?: Maybe<Scalars['ID']>
}

export type EthicsPageEntityResponse = {
    __typename?: 'EthicsPageEntityResponse'
    data?: Maybe<EthicsPageEntity>
}

export type EthicsPageEthicsPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type EthicsPageInput = {
    ethicsPageComponents?: InputMaybe<
        Array<Scalars['EthicsPageEthicsPageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type EthicsPageRelationResponseCollection = {
    __typename?: 'EthicsPageRelationResponseCollection'
    data: Array<EthicsPageEntity>
}

export type Expert = {
    __typename?: 'Expert'
    alt?: Maybe<Scalars['String']>
    bio?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    url?: Maybe<Scalars['String']>
}

export type ExternalLinkDetail = {
    __typename?: 'ExternalLinkDetail'
    name?: Maybe<Scalars['String']>
}

export type ExternalPulseMutation = {
    __typename?: 'ExternalPulseMutation'
    /** Create a pulse survey response */
    saveSurveyAnswer: SaveSurveyAnswerResponse
}

export type ExternalPulseMutationSaveSurveyAnswerArgs = {
    requestDate: Scalars['ISODate']
    responseValue: Scalars['Int']
    supportedContentTypes?: InputMaybe<Array<SupportedRecommendedContentType>>
    thriveUserId?: InputMaybe<Scalars['UUID']>
}

export type ExternalPulseQuery = {
    __typename?: 'ExternalPulseQuery'
    /** Company Information */
    company?: Maybe<PulseCompanyDto>
    /** Retrieve actionable Pulse recommendations for a company based on employee responses for a specified set of dimensions */
    getRecommendedContentForCompany: Array<RecommendedContent>
    /** Retrieves a survey for a Thrive user */
    getSurveyForUser: PulseOnDemand
    /** Get All Survey Questions */
    getSurveys: Array<PulseSurveyQuestionDto>
}

export type ExternalPulseQueryCompanyArgs = {
    companyId: Scalars['UUID']
}

export type ExternalPulseQueryGetRecommendedContentForCompanyArgs = {
    companyId: Scalars['UUID']
    dimensions: Array<Surveys>
    from: Scalars['String']
    to: Scalars['String']
}

export type ExternalPulseQueryGetSurveyForUserArgs = {
    channel: Channel
    companyId?: InputMaybe<Scalars['UUID']>
    requestDate: Scalars['ISODate']
    thriveUserId?: InputMaybe<Scalars['UUID']>
}

export type ExternalPulseQueryGetSurveysArgs = {
    activeOnly?: InputMaybe<Scalars['Boolean']>
}

export enum FeaturedItemStatusType {
    Cta = 'CTA',
    Dismiss = 'DISMISS'
}

export enum Feedback {
    Dislike = 'Dislike',
    Like = 'Like'
}

export type FeedbackQuestionResponse = FeedbackQuestionYesNoResponse

export enum FeedbackQuestionType {
    YesNo = 'YesNo'
}

export type FeedbackQuestionYesNoResponse = {
    __typename?: 'FeedbackQuestionYesNoResponse'
    response: Scalars['Boolean']
}

export type FeedbackWeightsInput = {
    negative: Scalars['Int']
    neutral: Scalars['Int']
    positive: Scalars['Int']
}

export type FileInfoInput = {
    alternativeText?: InputMaybe<Scalars['String']>
    caption?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
}

export type Flag = {
    __typename?: 'Flag'
    company?: Maybe<Company>
    flag?: Maybe<Scalars['String']>
    id: Scalars['ID']
}

export type FlagInput = {
    companyId?: InputMaybe<Scalars['ID']>
    flag?: InputMaybe<Scalars['String']>
}

export type FloatFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
    contains?: InputMaybe<Scalars['Float']>
    containsi?: InputMaybe<Scalars['Float']>
    endsWith?: InputMaybe<Scalars['Float']>
    eq?: InputMaybe<Scalars['Float']>
    eqi?: InputMaybe<Scalars['Float']>
    gt?: InputMaybe<Scalars['Float']>
    gte?: InputMaybe<Scalars['Float']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
    lt?: InputMaybe<Scalars['Float']>
    lte?: InputMaybe<Scalars['Float']>
    ne?: InputMaybe<Scalars['Float']>
    not?: InputMaybe<FloatFilterInput>
    notContains?: InputMaybe<Scalars['Float']>
    notContainsi?: InputMaybe<Scalars['Float']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
    startsWith?: InputMaybe<Scalars['Float']>
}

export type Frame = {
    _: Scalars['Boolean']
}

export type FrameInputIdInput = {
    frameType: FrameInputType
    id: Scalars['UUID']
}

export enum FrameInputType {
    Image = 'image',
    PersonalizedQuote = 'personalizedQuote',
    Quote = 'quote'
}

export type GenericMorph =
    | AboutPage
    | CareersPage
    | CmsAchievement
    | CmsArticle
    | CmsArticleArchive
    | CmsBiotype
    | CmsChallenge
    | CmsCompanyResourceSection
    | CmsCompanySubscription
    | CmsContributor
    | CmsCourse
    | CmsCourseId
    | CmsCourseResource
    | CmsJourney
    | CmsMicrostep
    | CmsPodcast
    | CmsPodcastEpisode
    | CmsPodcastSeason
    | CmsPulseDimension
    | CmsReset
    | CmsResetAudio
    | CmsResetStockAudio
    | CmsResetStockImage
    | CmsResetStockQuote
    | CmsWebinar
    | ComponentArticleAudioArticle
    | ComponentArticleAuthors
    | ComponentArticleTextArticle
    | ComponentArticleVideoArticle
    | ComponentCompanyCompanyResource
    | ComponentContributorType
    | ComponentCourseBlurb
    | ComponentCourseLesson
    | ComponentCourseModule
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFeatureGridWithPhoto
    | ComponentMsComponentsFeatureWithPhoto
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | ComponentSurveyChoiceChoices
    | ComponentWebinarWebinarEvent
    | ComponentWebinarWebinarSession
    | EthicsPage
    | Homepage
    | I18NLocale
    | MsTeamsSubscribe
    | MsTeamsWelcome
    | Persona
    | PrivacyPage
    | ProductPage
    | PulseSurvey
    | Quote
    | SecurityPage
    | StoriesPage
    | TermsPage
    | TodayFeaturedItem
    | UploadFile
    | UploadFolder
    | UsersPermissionsPermission
    | UsersPermissionsRole
    | UsersPermissionsUser

export type GenesysAgentMutation = {
    __typename?: 'GenesysAgentMutation'
    /**
     * Disconnects agent from the Reset Callback
     * @deprecated Not supported
     */
    disconnectAgentFromResetCallBack: Scalars['String']
    /**
     * Removes agent from the queue after receiving a Reset
     * @deprecated Moved to calls -> company -> getCompanyConfigurationForAgent, replace with getCompanyConfigurationForAgent(routingId, scriptId, agentId)
     */
    updateAgentResetTime: AgentLastReset
}

export type GenesysAgentMutationDisconnectAgentFromResetCallBackArgs = {
    agentId: Scalars['UUID']
    conversationId: Scalars['UUID']
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    resetScriptId: Scalars['UUID']
}

export type GenesysAgentMutationUpdateAgentResetTimeArgs = {
    agentId: Scalars['UUID']
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    resetScriptId: Scalars['UUID']
}

export type GenesysAnalyticsQuery = {
    __typename?: 'GenesysAnalyticsQuery'
    /** Get average reset call duration for the selected client and timeframe */
    getAverageResetCallDuration: GenesysAnalyticsResponse
    /**
     * Get a list of queues for the selected client
     * @deprecated Moved to calls -> agent -> getGroups, replace with getGroups(companyId, platformType, "QUEUE")
     */
    getClientCallQueues: Array<CallQueue>
    /**
     * Get a list of call skills for the selected client
     * @deprecated Moved to calls -> agent -> getGroups, replace with getGroups(companyId, platformType, "SKILL")
     */
    getClientCallSkills: Array<Skill>
    /**
     * Get multiple metrics for all queues configured for the selected client and timeframe
     * @deprecated No longer supported
     */
    getPerformanceMetricsForConfiguredQueues: GetPerformanceMetricsForConfiguredQueuesResponse
    /**
     * Get performance metrics for the selected queues
     * @deprecated Removed from reporting page
     */
    getQueuePerformance?: Maybe<GenesysAnalyticsResponse>
    /**
     * Get performance metrics for the selected queue, comparing agents with and without a skill
     * @deprecated Removed from reporting page
     */
    getQueuePerformanceSegmentedBySkill?: Maybe<GenesysAnalyticsResponse>
    /** Get answer rate for reset calls for the selected client and timeframe */
    getResetCallAnswerRate: GenesysAnalyticsResponse
    /** Get average reset call completion rate for the selected client and timeframe */
    getResetCallCompletionRate: GenesysAnalyticsResponse
    /** Get reset call count averages per agent for the selected client and timeframe */
    getResetCallCountAveragesPerAgent: GenesysAnalyticsResponse
    /** Get reset call counts for the selected client and timeframe */
    getResetCallCounts: GenesysAnalyticsResponse
}

export type GenesysAnalyticsQueryGetAverageResetCallDurationArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetClientCallQueuesArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysAnalyticsQueryGetClientCallSkillsArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysAnalyticsQueryGetPerformanceMetricsForConfiguredQueuesArgs =
    {
        request: GetPerformanceMetricsForConfiguredQueuesRequestInput
    }

export type GenesysAnalyticsQueryGetQueuePerformanceArgs = {
    request: GetQueuePerformanceRequestInput
}

export type GenesysAnalyticsQueryGetQueuePerformanceSegmentedBySkillArgs = {
    request: GetQueuePerformanceBySkillRequestInput
}

export type GenesysAnalyticsQueryGetResetCallAnswerRateArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCompletionRateArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCountAveragesPerAgentArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCountsArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsResponse = {
    __typename?: 'GenesysAnalyticsResponse'
    data: Array<MetricData>
    endDate: Scalars['String']
    granularity: ReportingGranularity
    reportName: Scalars['String']
    startDate: Scalars['String']
}

export type GenesysClient = {
    __typename?: 'GenesysClient'
    agentCallSkillId?: Maybe<Scalars['UUID']>
    agentTriggerLimit?: Maybe<Scalars['Int']>
    callDurationThresholdMinutes?: Maybe<Scalars['Int']>
    callThreshold?: Maybe<Scalars['Int']>
    clientId: Scalars['UUID']
    companyName?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    digitalResetRoutingId?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    isActive: Scalars['Boolean']
    isProcessing: Scalars['Boolean']
    lastQueriedAt: Scalars['Instant']
    numberLicensedAgents?: Maybe<Scalars['Int']>
    organizationId?: Maybe<Scalars['UUID']>
    queryAllQueues: Scalars['Boolean']
    queryQueueIds?: Maybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetBufferMinutes?: Maybe<Scalars['Int']>
    resetQueueId?: Maybe<Scalars['UUID']>
    rollout: Scalars['Boolean']
    scriptId?: Maybe<Scalars['UUID']>
    shiftHours?: Maybe<Scalars['Float']>
    sso: Scalars['Boolean']
    thriveCompanyId?: Maybe<Scalars['UUID']>
    updatedAt: Scalars['Instant']
    usesPilotPage: Scalars['Boolean']
    websocketChannelId?: Maybe<Scalars['String']>
    websocketConnectUri?: Maybe<Scalars['String']>
    wrapUpCodeId?: Maybe<Scalars['UUID']>
}

export type GenesysClientCreateInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callThreshold?: InputMaybe<Scalars['Int']>
    clientId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    numberLicensedAgents?: InputMaybe<Scalars['Int']>
    organizationId?: InputMaybe<Scalars['UUID']>
    queryAllQueues?: InputMaybe<Scalars['Boolean']>
    queryQueueIds?: InputMaybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    scriptId?: InputMaybe<Scalars['UUID']>
    secret: Scalars['String']
    shiftHours?: InputMaybe<Scalars['Float']>
    sso?: InputMaybe<Scalars['Boolean']>
    wrapUpCodeId?: InputMaybe<Scalars['UUID']>
}

export type GenesysClientMutation = {
    __typename?: 'GenesysClientMutation'
    /**
     * Adds Genesys configuration details for an organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.create
     */
    createClient: GenesysClient
    /**
     * Adds Genesys configuration details for an organization with multiple queues
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.upsert
     */
    createClientWithMultipleQueues: GenesysClientWithMultipleQueues
    /**
     * Delete configuration details for a Genesys organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.delete
     */
    deleteClient: Scalars['String']
    /**
     * Delete queue configuration for a Genesys organization
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.delete
     */
    deleteClientQueueConfig: Scalars['String']
    /** Imports a script */
    importScript: Script
    /**
     * Update a configuration details for a Genesys organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.update
     */
    updateClient: GenesysClient
    /**
     * Update a configuration details for a Genesys organization for multiple queues
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.upsert
     */
    updateClientWithMultipleQueues: GenesysClientWithMultipleQueues
}

export type GenesysClientMutationCreateClientArgs = {
    client: GenesysClientCreateInput
}

export type GenesysClientMutationCreateClientWithMultipleQueuesArgs = {
    client: GenesysClientCreateInput
    queueConfigs: Array<GenesysClientQueueConfigInput>
}

export type GenesysClientMutationDeleteClientArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationDeleteClientQueueConfigArgs = {
    clientId: Scalars['UUID']
    queryQueueId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationImportScriptArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationUpdateClientArgs = {
    client: GenesysClientUpdateInput
}

export type GenesysClientMutationUpdateClientWithMultipleQueuesArgs = {
    client: GenesysClientUpdateInput
    queueConfigs: Array<GenesysClientQueueConfigInput>
}

export type GenesysClientPage = {
    __typename?: 'GenesysClientPage'
    items: Array<GenesysClient>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type GenesysClientQuery = {
    __typename?: 'GenesysClientQuery'
    /**
     * Get all clients paginated
     * @deprecated Moved to calls -> company -> getAllCompanyConfiguration, replace with getAllCompanyConfiguration
     */
    getAllClients: GenesysClientPage
    /**
     * Get company information for all Genesys clients.
     * @deprecated Moved to calls -> company -> getAllCompanyConfiguration, replace with getAllCompanyConfiguration
     */
    getAllGenesysCompanyInformation?: Maybe<Array<GenesysCompanyInformation>>
    /**
     * Get clients by client id and region
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClient?: Maybe<GenesysClient>
    /**
     * Get client by id
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClientById?: Maybe<GenesysClientWithMultipleQueues>
    /**
     * Get clients and queues by client id and region
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClientWithMultipleQueues?: Maybe<GenesysClientWithMultipleQueues>
}

export type GenesysClientQueryGetAllClientsArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type GenesysClientQueryGetAllGenesysCompanyInformationArgs = {
    onlyActive: Scalars['Boolean']
}

export type GenesysClientQueryGetClientArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientQueryGetClientByIdArgs = {
    id: Scalars['UUID']
}

export type GenesysClientQueryGetClientWithMultipleQueuesArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientQueueConfig = {
    __typename?: 'GenesysClientQueueConfig'
    agentCallSkillId?: Maybe<Scalars['UUID']>
    callDurationThresholdMinutes?: Maybe<Scalars['Int']>
    callDurationThresholdMinutesEnabled: Scalars['Boolean']
    callThreshold?: Maybe<Scalars['Int']>
    callThresholdEnabled: Scalars['Boolean']
    clientId?: Maybe<Scalars['UUID']>
    digitalOnly?: Maybe<Scalars['Boolean']>
    direction?: Maybe<Array<Direction>>
    locale?: Maybe<Scalars['String']>
    mediaTypes?: Maybe<MediaTypeConfig>
    queryQueueId: Scalars['UUID']
}

export type GenesysClientQueueConfigInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callDurationThresholdMinutesEnabled: Scalars['Boolean']
    callThreshold?: InputMaybe<Scalars['Int']>
    callThresholdEnabled: Scalars['Boolean']
    clientId?: InputMaybe<Scalars['UUID']>
    digitalOnly?: InputMaybe<Scalars['Boolean']>
    direction?: InputMaybe<Array<Direction>>
    locale?: InputMaybe<Scalars['String']>
    mediaTypes?: InputMaybe<MediaTypeConfigInput>
    queryQueueId: Scalars['UUID']
}

export type GenesysClientUpdateInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callThreshold?: InputMaybe<Scalars['Int']>
    clientId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    isActive?: InputMaybe<Scalars['Boolean']>
    isProcessing?: InputMaybe<Scalars['Boolean']>
    numberLicensedAgents?: InputMaybe<Scalars['Int']>
    organizationId?: InputMaybe<Scalars['UUID']>
    queryQueueIds?: InputMaybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetQueueId?: InputMaybe<Scalars['UUID']>
    scriptId?: InputMaybe<Scalars['UUID']>
    shiftHours?: InputMaybe<Scalars['Float']>
    wrapUpCodeId?: InputMaybe<Scalars['UUID']>
}

export type GenesysClientWithMultipleQueues = {
    __typename?: 'GenesysClientWithMultipleQueues'
    genesysClient: GenesysClient
    queueConfigs: Array<GenesysClientQueueConfig>
}

export type GenesysCompanyInformation = {
    __typename?: 'GenesysCompanyInformation'
    companyName?: Maybe<Scalars['String']>
    genesysOrganizationId?: Maybe<Scalars['UUID']>
    oauthClientId: Scalars['UUID']
    region: GenesysRegion
    thriveCompanyId?: Maybe<Scalars['UUID']>
}

export type GenesysIntegrationMutation = {
    __typename?: 'GenesysIntegrationMutation'
    /** Disables Genesys integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Genesys integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type GenesysIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type GenesysIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    genesysOrgId: Scalars['String']
    iss: Scalars['String']
}

export enum GenesysRegion {
    ApNortheast_1 = 'AP_NORTHEAST_1',
    ApNortheast_2 = 'AP_NORTHEAST_2',
    ApSoutheast_2 = 'AP_SOUTHEAST_2',
    ApSouth_1 = 'AP_SOUTH_1',
    CaCentral_1 = 'CA_CENTRAL_1',
    EuCentral_1 = 'EU_CENTRAL_1',
    EuWest_1 = 'EU_WEST_1',
    EuWest_2 = 'EU_WEST_2',
    UsEast_1 = 'US_EAST_1',
    UsEast_2 = 'US_EAST_2',
    UsWest_2 = 'US_WEST_2',
    Wiremock = 'WIREMOCK'
}

export type GenesysResetEngagementRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetPerformanceMetricsForConfiguredQueuesRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    metrics: Array<MetricsEnum>
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetPerformanceMetricsForConfiguredQueuesResponse = {
    __typename?: 'GetPerformanceMetricsForConfiguredQueuesResponse'
    queueData: Array<AggregatePerformanceMetrics>
}

export type GetQueuePerformanceBySkillRequestInput = {
    callSkillName: Scalars['String']
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    interactionType: InteractionType
    metric: MetricsEnum
    queueId: Scalars['UUID']
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetQueuePerformanceRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    interactionType: InteractionType
    metric: MetricsEnum
    queueIds: Array<Scalars['UUID']>
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GoalEntityDetail =
    | ArticleDetail
    | DailyCheckinDetail
    | ExternalLinkDetail
    | MicrostepGoalDetail
    | ResetGoalDetail
    | StepsGoalDetail
    | WaterGoalDetail

export enum GoalType {
    ArticleRead = 'ARTICLE_READ',
    ArticleWatched = 'ARTICLE_WATCHED',
    CheckIn = 'CHECK_IN',
    ExternalLink = 'EXTERNAL_LINK',
    PulseCheckIn = 'PULSE_CHECK_IN',
    RecipeRead = 'RECIPE_READ',
    RecipeWatched = 'RECIPE_WATCHED',
    ResetWatched = 'RESET_WATCHED',
    RoleModelRead = 'ROLE_MODEL_READ',
    RoleModelWatched = 'ROLE_MODEL_WATCHED',
    Steps = 'STEPS',
    Water = 'WATER'
}

export type Google = CalendarServiceFields & {
    __typename?: 'Google'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type GraphQlFeaturedItem = {
    __typename?: 'GraphQlFeaturedItem'
    cta_link: Scalars['String']
    cta_text: Scalars['String']
    description: Scalars['String']
    header: Scalars['String']
    id: Scalars['ID']
    image: Scalars['String']
    title: Scalars['String']
}

export type GraphQlGuide = {
    __typename?: 'GraphQlGuide'
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    imgUrl: Scalars['String']
    pages: Array<GraphQlGuidePage>
    position: Scalars['Int']
    status?: Maybe<Scalars['String']>
    statusUpdatedAt?: Maybe<Scalars['Instant']>
    successMsg: Scalars['String']
    takeaways: Array<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type GraphQlGuidePage = {
    __typename?: 'GraphQlGuidePage'
    createdAt: Scalars['Instant']
    elements: Array<GraphQlGuidePageElement>
    id: Scalars['UUID']
    pageType: GraphQlGuidePageType
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type GraphQlGuidePageElement = {
    __typename?: 'GraphQlGuidePageElement'
    align: GraphQlGuidePageElementAlign
    alt: Scalars['String']
    createdAt: Scalars['Instant']
    elementType: GraphQlGuidePageElementType
    id: Scalars['UUID']
    src: Scalars['String']
    text: Scalars['String']
    updatedAt: Scalars['Instant']
}

export enum GraphQlGuidePageElementAlign {
    Center = 'CENTER',
    End = 'END',
    Start = 'START'
}

export enum GraphQlGuidePageElementType {
    Break = 'BREAK',
    ButtonQuiz = 'BUTTON_QUIZ',
    ButtonQuizCorrect = 'BUTTON_QUIZ_CORRECT',
    Image = 'IMAGE',
    ImageCard = 'IMAGE_CARD',
    ImageProfile = 'IMAGE_PROFILE',
    Quotation = 'QUOTATION',
    TextBody1 = 'TEXT_BODY1',
    TextBullet = 'TEXT_BULLET',
    TextDisplay4 = 'TEXT_DISPLAY4',
    TextForm = 'TEXT_FORM',
    TextH1 = 'TEXT_H1',
    TextOverline = 'TEXT_OVERLINE',
    TextP14 = 'TEXT_P14',
    TextVideo = 'TEXT_VIDEO',
    Video = 'VIDEO'
}

export enum GraphQlGuidePageType {
    Quiz = 'QUIZ',
    Text = 'TEXT',
    Video = 'VIDEO'
}

export type GraphQlGuideUserProgress = {
    __typename?: 'GraphQlGuideUserProgress'
    createdAt: Scalars['String']
    guideId: Scalars['UUID']
    id: Scalars['UUID']
    status: GraphQlGuideUserProgressStatus
    updatedAt: Scalars['String']
    userId: Scalars['UUID']
}

export enum GraphQlGuideUserProgressStatus {
    Finished = 'FINISHED',
    Progressed = 'PROGRESSED',
    Viewed = 'VIEWED'
}

export type GraphQlMicrostep = {
    __typename?: 'GraphQlMicrostep'
    action: Scalars['String']
    body: Scalars['String']
    cadence: Scalars['String']
    company_id: Scalars['ID']
    created_at: Scalars['String']
    duration: Scalars['Int']
    gender: Scalars['String']
    id: Scalars['ID']
    journeyIds?: Maybe<Array<Scalars['ID']>>
    journeys: Array<Maybe<Journey>>
    reminder_enabled: Scalars['Boolean']
    time_of_day: Scalars['String']
    time_unit: Scalars['String']
    title: Scalars['String']
    updated_at: Scalars['String']
    worker_type: Scalars['String']
}

export type GraphQlMicrostepMomentum = {
    __typename?: 'GraphQlMicrostepMomentum'
    checkins: Array<CheckinCount>
    total: Scalars['Int']
}

export type GraphQlSearchResult = {
    __typename?: 'GraphQlSearchResult'
    contentType: ContentType
    snippet: Scalars['String']
    title: Scalars['String']
    type: Type
    uuid: Scalars['UUID']
}

export type GroupChallengeTemplateCreateInput = {
    /** The id of the challenge template */
    challengeTemplateId?: InputMaybe<Scalars['UUID']>
    /** Optional prefix to be displayed before the challenge's name */
    namePrefix?: InputMaybe<Scalars['String']>
    /** The start date of the challenge */
    startDate?: InputMaybe<Scalars['ISODate']>
}

export enum GroupType {
    Group = 'GROUP',
    Queue = 'QUEUE',
    Skill = 'SKILL',
    Team = 'TEAM'
}

export type Guide = {
    __typename?: 'Guide'
    id?: Maybe<Scalars['Int']>
    link?: Maybe<Scalars['String']>
    pageCount?: Maybe<Scalars['Int']>
    thumbnail?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type GuidePagesDynamicZone =
    | ComponentGuidePageGuidePageInfo
    | ComponentGuidePageGuidePageQuiz
    | ComponentGuidePageGuidePageQuote
    | ComponentGuidePageGuidePageVideo
    | Error

export type HapiActivitySummaryModel = {
    __typename?: 'HAPIActivitySummaryModel'
    calories: Scalars['Int']
    createdAt: Scalars['String']
    date: Scalars['String']
    distance: Scalars['Int']
    duration: Scalars['Int']
    humanId: Scalars['String']
    id: Scalars['String']
    light: Scalars['Int']
    moderate: Scalars['Int']
    sedentary: Scalars['Int']
    source: Scalars['String']
    steps: Scalars['Int']
    updatedAt: Scalars['String']
    vigorous: Scalars['Int']
}

export type HapiAccessToken = {
    __typename?: 'HapiAccessToken'
    accessToken: Scalars['String']
    expiresIn: Scalars['Int']
    refreshToken: Scalars['String']
}

export type HapiIdToken = {
    __typename?: 'HapiIdToken'
    idRefreshToken: Scalars['String']
    idToken: Scalars['String']
    idTokenExpiresIn: Scalars['Int']
    tokenType: Scalars['String']
}

export type HapiSessionToken = {
    __typename?: 'HapiSessionToken'
    expiresIn: Scalars['Int']
    humanId: Scalars['String']
    sessionToken: Scalars['String']
}

export type HistoricAppUsageResponse = {
    __typename?: 'HistoricAppUsageResponse'
    data: Array<HistoricAppUsageStats>
    trends: AppUsageTrends
}

export type HistoricAppUsageStats = {
    __typename?: 'HistoricAppUsageStats'
    activeUsagePercentage: Scalars['Float']
    activeUsers: Scalars['Long']
    challengesStarted: Scalars['Long']
    checkins: Scalars['Long']
    cumlativeActiveUsers: Scalars['Long']
    learnModulesStarted: Scalars['Long']
    microstepsCheckedIn: Scalars['Long']
    period: MonthYear
    resetsPlayed: Scalars['Long']
    uniqueChallengeUsers: Scalars['Long']
    uniqueCheckinUsers: Scalars['Long']
    uniqueLearnUsers: Scalars['Long']
    uniqueMicrostepUsers: Scalars['Long']
    uniqueResetUsers: Scalars['Long']
}

export type HistoricDailyCheckInScoresResponse = {
    __typename?: 'HistoricDailyCheckInScoresResponse'
    data: Array<HistoricDailyCheckInScoresStats>
}

export type HistoricDailyCheckInScoresStats = {
    __typename?: 'HistoricDailyCheckInScoresStats'
    averageScore: Scalars['Float']
    averageScoreBenchmarkScorePercentageDifference: Scalars['Float']
    averageScorePercentage: Scalars['Float']
    benchmarkScore: Scalars['Float']
    benchmarkScorePercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    period: MonthYear
    responseCount: Scalars['Int']
}

export type HistoricEngagementLevelMonthlyStats = {
    __typename?: 'HistoricEngagementLevelMonthlyStats'
    benchmarkCount: Scalars['Int']
    benchmarkPercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    percentage: Scalars['Float']
}

export type HistoricEngagementLevelResponse = {
    __typename?: 'HistoricEngagementLevelResponse'
    data: Array<HistoricEngagementLevelStats>
}

export type HistoricEngagementLevelStats = {
    __typename?: 'HistoricEngagementLevelStats'
    atRisk: HistoricEngagementLevelMonthlyStats
    highlyEngaged: HistoricEngagementLevelMonthlyStats
    moderatelyEngaged: HistoricEngagementLevelMonthlyStats
    period: MonthYear
}

export type Homepage = {
    __typename?: 'Homepage'
    createdAt?: Maybe<Scalars['DateTime']>
    homepageComponents?: Maybe<
        Array<Maybe<HomepageHomepageComponentsDynamicZone>>
    >
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<HomepageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type HomepageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type HomepageEntity = {
    __typename?: 'HomepageEntity'
    attributes?: Maybe<Homepage>
    id?: Maybe<Scalars['ID']>
}

export type HomepageEntityResponse = {
    __typename?: 'HomepageEntityResponse'
    data?: Maybe<HomepageEntity>
}

export type HomepageHomepageComponentsDynamicZone =
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsToggleSectionCard
    | Error

export type HomepageInput = {
    homepageComponents?: InputMaybe<
        Array<Scalars['HomepageHomepageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type HomepageRelationResponseCollection = {
    __typename?: 'HomepageRelationResponseCollection'
    data: Array<HomepageEntity>
}

export type HumanApiQuery = {
    __typename?: 'HumanApiQuery'
    /** Get a list of HumanApi source information */
    sourceReference: Array<HumanApiSourceReferenceDto>
}

export type HumanApiSourceReferenceDto = {
    __typename?: 'HumanApiSourceReferenceDTO'
    humanApiSourceId: Scalars['String']
    sourceId: Scalars['UUID']
    sourceName: Scalars['String']
}

export type I18NLocale = {
    __typename?: 'I18NLocale'
    code?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['DateTime']>
    name?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type I18NLocaleEntity = {
    __typename?: 'I18NLocaleEntity'
    attributes?: Maybe<I18NLocale>
    id?: Maybe<Scalars['ID']>
}

export type I18NLocaleEntityResponse = {
    __typename?: 'I18NLocaleEntityResponse'
    data?: Maybe<I18NLocaleEntity>
}

export type I18NLocaleEntityResponseCollection = {
    __typename?: 'I18NLocaleEntityResponseCollection'
    data: Array<I18NLocaleEntity>
    meta: ResponseCollectionMeta
}

export type I18NLocaleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>
    code?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<I18NLocaleFiltersInput>
    or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type IdFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    contains?: InputMaybe<Scalars['ID']>
    containsi?: InputMaybe<Scalars['ID']>
    endsWith?: InputMaybe<Scalars['ID']>
    eq?: InputMaybe<Scalars['ID']>
    eqi?: InputMaybe<Scalars['ID']>
    gt?: InputMaybe<Scalars['ID']>
    gte?: InputMaybe<Scalars['ID']>
    in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    lt?: InputMaybe<Scalars['ID']>
    lte?: InputMaybe<Scalars['ID']>
    ne?: InputMaybe<Scalars['ID']>
    not?: InputMaybe<IdFilterInput>
    notContains?: InputMaybe<Scalars['ID']>
    notContainsi?: InputMaybe<Scalars['ID']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    startsWith?: InputMaybe<Scalars['ID']>
}

export type IdentityAccessTypeMutation = {
    __typename?: 'IdentityAccessTypeMutation'
    /** Create a new access type from the given input */
    create?: Maybe<Scalars['String']>
    /** Tries to delete existing access type by name */
    tryDelete: Scalars['Boolean']
}

export type IdentityAccessTypeMutationCreateArgs = {
    input: AccessTypeInput
}

export type IdentityAccessTypeMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityAccessTypeQuery = {
    __typename?: 'IdentityAccessTypeQuery'
    /** Returns all access types with optional pagination */
    getAll: AccessTypePage
}

export type IdentityAccessTypeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityBrandMutation = {
    __typename?: 'IdentityBrandMutation'
    /** Create a new brand from the given input */
    create: Brand
    /** Tries to delete existing brand by name */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing brand by the provided input */
    tryUpdate?: Maybe<Brand>
}

export type IdentityBrandMutationCreateArgs = {
    input: BrandInputGraphQlInput
}

export type IdentityBrandMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityBrandMutationTryUpdateArgs = {
    input: BrandInputGraphQlInput
    name: Scalars['String']
}

export type IdentityBrandQuery = {
    __typename?: 'IdentityBrandQuery'
    /** Returns all brands with optional pagination */
    getAll: BrandPage
    /** Tries to fetch existing brand by name */
    tryGet?: Maybe<Brand>
}

export type IdentityBrandQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    publicOnly?: InputMaybe<Scalars['Boolean']>
}

export type IdentityBrandQueryTryGetArgs = {
    name: Scalars['String']
}

export type IdentityCompanyMutation = {
    __typename?: 'IdentityCompanyMutation'
    /** Add new brands to the company, returning the company */
    addBrands?: Maybe<Company>
    /** Adds an email domain to owned email domains */
    addOwnedEmailDomain: OwnedEmailDomain
    /** Create a new company from the given input */
    create: Company
    /** Change company status. Enables IDP configs if new status is ACTIVE.  Disables IDP configs if new status is INACTIVE. Returns true if company was found and updated */
    tryChangeStatus: Scalars['Boolean']
    /** Tries to delete existing company by id */
    tryDelete: Scalars['Boolean']
    /** Deletes an email domain from owned email domains */
    tryDeleteOwnedEmailDomain: Scalars['Boolean']
    /** Tries to update existing company by id and provided input */
    tryUpdate?: Maybe<Company>
}

export type IdentityCompanyMutationAddBrandsArgs = {
    brands: Array<BrandInput>
    id: Scalars['UUID']
}

export type IdentityCompanyMutationAddOwnedEmailDomainArgs = {
    input: OwnedEmailDomainInput
}

export type IdentityCompanyMutationCreateArgs = {
    input: CompanyInputGraphQlInput
}

export type IdentityCompanyMutationTryChangeStatusArgs = {
    id: Scalars['UUID']
    status: CompanyStatus
}

export type IdentityCompanyMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityCompanyMutationTryDeleteOwnedEmailDomainArgs = {
    emailDomain: Scalars['String']
}

export type IdentityCompanyMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: CompanyInputGraphQlInput
}

export type IdentityCompanyQuery = {
    __typename?: 'IdentityCompanyQuery'
    /** Returns all company with optional pagination */
    getAll: CompanyPage
    /** Returns all owned email domains for a company */
    getOwnedEmailDomains: Array<OwnedEmailDomain>
    /** Tries to fetch existing company by id */
    tryGet?: Maybe<Company>
}

export type IdentityCompanyQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    publicOnly?: InputMaybe<Scalars['Boolean']>
}

export type IdentityCompanyQueryGetOwnedEmailDomainsArgs = {
    companyId: Scalars['UUID']
}

export type IdentityCompanyQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigMutation = {
    __typename?: 'IdentityIdpConfigMutation'
    /** Allow email domain for given IDP config */
    allowEmailDomain?: Maybe<IdpConfig>
    /** Change priority for IDP config for given company. Returns true if config does exist and was updated */
    changePriority: Scalars['Boolean']
    /** Create a new IDP Config from the given input */
    create: IdpConfig
    /** Disable IDP config for given company. Returns true if config does exist and was disabled */
    disable: Scalars['Boolean']
    /** Disallow email domain for given IDP config */
    disallowEmailDomain?: Maybe<IdpConfig>
    /** Enables IDP config for given company. Returns true if config does exist and was enabled */
    enable: Scalars['Boolean']
    /** Set (replace) email domain allowlist for given IDP config */
    setEmailAllowlist?: Maybe<IdpConfig>
    /** Tries to delete existing IDP Config by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing IDP Config by id and provided input */
    tryUpdate?: Maybe<IdpConfig>
}

export type IdentityIdpConfigMutationAllowEmailDomainArgs = {
    emailDomain: Scalars['String']
    idpConfigId: Scalars['UUID']
}

export type IdentityIdpConfigMutationChangePriorityArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
    priority: Scalars['Int']
}

export type IdentityIdpConfigMutationCreateArgs = {
    input: IdpConfigInputGraphQlInput
}

export type IdentityIdpConfigMutationDisableArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
}

export type IdentityIdpConfigMutationDisallowEmailDomainArgs = {
    emailDomain: Scalars['String']
    idpConfigId: Scalars['UUID']
}

export type IdentityIdpConfigMutationEnableArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
}

export type IdentityIdpConfigMutationSetEmailAllowlistArgs = {
    idpConfigId: Scalars['UUID']
    input: IdpConfigSetEmailAllowlistInput
}

export type IdentityIdpConfigMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: IdpConfigInputGraphQlInput
}

export type IdentityIdpConfigQuery = {
    __typename?: 'IdentityIdpConfigQuery'
    /** Returns all IDP Configs with optional pagination */
    getAll: IdpConfigPage
    /** Tries to fetch existing IDP Config by id */
    tryGet?: Maybe<IdpConfig>
    /** Tries to find an existing IDP Config by IDP Type + IDP Company ID (external company ID, or signupCode for ZP/cognito) */
    tryGetByIdpCompanyId?: Maybe<IdpConfig>
}

export type IdentityIdpConfigQueryGetAllArgs = {
    filters?: InputMaybe<IdpConfigFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityIdpConfigQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigQueryTryGetByIdpCompanyIdArgs = {
    idpCompanyId: Scalars['String']
    idpType: IdpType
}

export type IdentityJwkCacheMutation = {
    __typename?: 'IdentityJwkCacheMutation'
    /** Purge all JWK cache entries */
    purge: Scalars['Boolean']
}

export type IdentityJwkCacheQuery = {
    __typename?: 'IdentityJwkCacheQuery'
    /** Get all current cache entries from JWK cache */
    entries: Array<JwkCacheEntry>
}

export type IdentityMutation = {
    __typename?: 'IdentityMutation'
    accessType: IdentityAccessTypeMutation
    brand: IdentityBrandMutation
    company: IdentityCompanyMutation
    idpConfig: IdentityIdpConfigMutation
    idpProfile: IdpProfileMutation
    integration: IntegrationMutation
    jwkCache: IdentityJwkCacheMutation
    resourceType: IdentityResourceTypeMutation
    scope: IdentityScopeMutation
    signupCode: IdentitySignupCodeMutation
    subscription: IdentitySubscriptionMutation
    user: IdentityUserMutation
    /** Used to update attributes attached to this user (e.g. timezone, locale,...). Not to be confused with with attributes coming from IDP information. */
    userAttributes: UserAttributeMutation
}

export type IdentityQuery = {
    __typename?: 'IdentityQuery'
    accessType: IdentityAccessTypeQuery
    brand: IdentityBrandQuery
    company: IdentityCompanyQuery
    idpConfig: IdentityIdpConfigQuery
    jwkCache: IdentityJwkCacheQuery
    /** Returns authenticated user */
    me: User
    partner: PartnerQuery
    /** Used to query for referral information */
    referrals: IdentityReferralsQuery
    resourceType: IdentityResourceTypeQuery
    scope: IdentityScopeQuery
    signupCode: IdentitySignupCodeQuery
    subscription: IdentitySubscriptionQuery
    user: IdentityUserQuery
    /** Used to query attributes attached to this user (e.g. timezone, locale,...). Not to be confused with with attributes coming from IDP information (see 'me' query). */
    userAttributes: UserAttributeQuery
}

export type IdentityReferralsQuery = {
    __typename?: 'IdentityReferralsQuery'
    /** Retrieve subscriptions and signup codes for a users company */
    subscriptionGroupCodes: SubscriptionGroupCodesResponse
}

export type IdentityResourceTypeMutation = {
    __typename?: 'IdentityResourceTypeMutation'
    /** Create a new resource type from the given input */
    create?: Maybe<Scalars['String']>
    /** Tries to delete existing resource type by name */
    tryDelete: Scalars['Boolean']
}

export type IdentityResourceTypeMutationCreateArgs = {
    input: ResourceTypeInput
}

export type IdentityResourceTypeMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityResourceTypeQuery = {
    __typename?: 'IdentityResourceTypeQuery'
    /** Returns all resource types with optional pagination */
    getAll: ResourceTypePage
}

export type IdentityResourceTypeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityScopeMutation = {
    __typename?: 'IdentityScopeMutation'
    /** Create a new scope from the given input */
    create: Scope
    /** Tries to delete existing scope by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing scope by id and provided input */
    tryUpdate?: Maybe<Scope>
}

export type IdentityScopeMutationCreateArgs = {
    input: ScopeInputGraphQlInput
}

export type IdentityScopeMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityScopeMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: ScopeInputGraphQlInput
}

export type IdentityScopeQuery = {
    __typename?: 'IdentityScopeQuery'
    /** Returns all scopes with optional pagination and filters */
    getAll: ScopePage
    /** Tries to fetch existing scope by id */
    tryGet?: Maybe<Scope>
}

export type IdentityScopeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type IdentityScopeQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentitySignupCodeMutation = {
    __typename?: 'IdentitySignupCodeMutation'
    /** Create a new signup code from the given input */
    create: SignupCode
    /** Tries to delete existing signup code by name */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing signup code by name and provided input */
    tryUpdate?: Maybe<SignupCode>
}

export type IdentitySignupCodeMutationCreateArgs = {
    input: SignupCodeInput
}

export type IdentitySignupCodeMutationTryDeleteArgs = {
    signupCode: Scalars['String']
}

export type IdentitySignupCodeMutationTryUpdateArgs = {
    input: SignupCodeInput
    signupCode: Scalars['String']
}

export type IdentitySignupCodeQuery = {
    __typename?: 'IdentitySignupCodeQuery'
    /** Returns all signup codes with optional pagination */
    getAll: SignupCodePage
    /** Tries to fetch existing signup code by name */
    tryGet?: Maybe<SignupCode>
}

export type IdentitySignupCodeQueryGetAllArgs = {
    filters?: InputMaybe<SignupCodeFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentitySignupCodeQueryTryGetArgs = {
    signupCode: Scalars['String']
}

export type IdentitySubscriptionMutation = {
    __typename?: 'IdentitySubscriptionMutation'
    /** Create a new subscription from the given input */
    create: UserSubscription
    /** Sets the provided subscription as the default (will disable isDefault on all other subscriptions) */
    makeDefault: Scalars['UUID']
    /** Sets the isDefault flag if possible, returning the value of the flag (unchanged if it cannot be changed) */
    setDefault: Scalars['Boolean']
    /** Tries to delete existing subscription by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing subscription by id and provided input */
    tryUpdate?: Maybe<UserSubscription>
}

export type IdentitySubscriptionMutationCreateArgs = {
    input: SubscriptionInputGraphQlInput
}

export type IdentitySubscriptionMutationMakeDefaultArgs = {
    id: Scalars['UUID']
}

export type IdentitySubscriptionMutationSetDefaultArgs = {
    id: Scalars['UUID']
    isDefault: Scalars['Boolean']
}

export type IdentitySubscriptionMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentitySubscriptionMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: SubscriptionInputGraphQlInput
}

export type IdentitySubscriptionQuery = {
    __typename?: 'IdentitySubscriptionQuery'
    /** Returns all subscriptions with optional pagination */
    getAll: SubscriptionPage
    /** Tries to fetch existing subscription by id */
    tryGet?: Maybe<UserSubscription>
}

export type IdentitySubscriptionQueryGetAllArgs = {
    filters?: InputMaybe<SubscriptionFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentitySubscriptionQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityUserAttribute = {
    __typename?: 'IdentityUserAttribute'
    name: Scalars['String']
    userId: Scalars['UUID']
    value: Scalars['String']
}

export type IdentityUserMutation = {
    __typename?: 'IdentityUserMutation'
    /** Accept the Terms of Service for the authenticated user. Returns the date and time the user accepted the ToS. If the user has already accepted the ToS, returns the date and time the user accepted the ToS previously. */
    acceptToS: WhenToSAccepted
    /** Change an attribute of an existing user */
    changeAttributeOfAnExistingUser: Scalars['Boolean']
    /** Change the email address of an existing user */
    changeEmailAddressOfAnExistingUser: Scalars['Boolean']
    /** Change a subscription of an existing user */
    changeSubscriptionOfAnExistingUser: Scalars['Boolean']
    /** Create a new partner user for the given input */
    createPartnerUser: IdpProfile
    impersonate: TokenResponse
    /** Initiate the deletion process for the authenticated user's account, using the supplied email as a point of contact.  Email must match hash, or for users with no email hash, email must not match any other users.  Throws an error if email doesn't match or user cannot be found.  Returns 'true' if the delete initiation was successful, or 'false' if the initiation failed given a valid user. */
    initiateDeletion: Scalars['Boolean']
    /** Request one-time upload URL for Cloudflare Images */
    initiateDirectUpload?: Maybe<Scalars['String']>
    refreshToken: TokenResponse
    /** Tries to delete existing user by id */
    tryDelete: Scalars['Boolean']
    /** Update the verification status of an existing user */
    updateVerificationStatus?: Maybe<User>
    /** Get existing or create new user object within Identity Database based purely on email hash. Will throw an error if subscription associated with given ID couldn't be found. Will TRY to update subscription (depends on is-default flag) if subscription Id is present on fetching */
    upsert: User
    /** Write an event acknowledging that a user referred another user */
    writeReferral: Scalars['Boolean']
}

export type IdentityUserMutationAcceptToSArgs = {
    input?: InputMaybe<AcceptToSInput>
}

export type IdentityUserMutationChangeAttributeOfAnExistingUserArgs = {
    attribute: VisibleProfileAttribute
    userId: Scalars['UUID']
    value: Scalars['String']
}

export type IdentityUserMutationChangeEmailAddressOfAnExistingUserArgs = {
    newEmail: Scalars['String']
    oldEmail: Scalars['String']
}

export type IdentityUserMutationChangeSubscriptionOfAnExistingUserArgs = {
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type IdentityUserMutationCreatePartnerUserArgs = {
    input: PartnerUserInputGraphQlInput
}

export type IdentityUserMutationImpersonateArgs = {
    attributes?: InputMaybe<Array<UserAttributeInput>>
    companyName: Scalars['String']
    email?: InputMaybe<Scalars['String']>
    firstName?: InputMaybe<Scalars['String']>
    idpId: Scalars['String']
    idpType: IdpType
    ignoreKcUpdate?: InputMaybe<Scalars['Boolean']>
    issuer?: InputMaybe<Scalars['String']>
    lastName?: InputMaybe<Scalars['String']>
    realm?: InputMaybe<Realm>
    userId?: InputMaybe<Scalars['UUID']>
}

export type IdentityUserMutationInitiateDeletionArgs = {
    email: Scalars['String']
}

export type IdentityUserMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityUserMutationUpdateVerificationStatusArgs = {
    userId: Scalars['UUID']
    verificationStatus: VerificationStatus
}

export type IdentityUserMutationUpsertArgs = {
    input: UpsertUserInput
}

export type IdentityUserMutationWriteReferralArgs = {
    referringUserId: Scalars['UUID']
}

export type IdentityUserQuery = {
    __typename?: 'IdentityUserQuery'
    /** Tries to fetch existing user by id */
    tryGet?: Maybe<User>
    /** Tries to fetch existing user by email */
    tryGetByEmail?: Maybe<User>
    /** Tries to fetch existing user by idp id, idp type and company name in idp config */
    tryGetByIdpId?: Maybe<User>
    /** Tries to fetch existing user using IDP id and IDP type */
    tryGetByIdpIdAndIdpType?: Maybe<User>
    /** Tries to get when Terms of Service was accepted for the current user. If the user has not accepted the Terms of Service, returns null. */
    whenToSAccepted?: Maybe<WhenToSAccepted>
    /** Returns the view we would have of an authenticated user */
    you: User
}

export type IdentityUserQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityUserQueryTryGetByEmailArgs = {
    email: Scalars['String']
    realm?: InputMaybe<Realm>
}

export type IdentityUserQueryTryGetByIdpIdArgs = {
    idpCompanyName: Scalars['String']
    idpId: Scalars['String']
    idpType: IdpType
}

export type IdentityUserQueryTryGetByIdpIdAndIdpTypeArgs = {
    idpId: Scalars['String']
    idpType: IdpType
    lookupKeycloak: Scalars['Boolean']
}

export type IdentityUserQueryYouArgs = {
    idpId?: InputMaybe<Scalars['String']>
    idpType?: InputMaybe<IdpType>
    userId: Scalars['UUID']
}

export type IdpConfig = {
    __typename?: 'IdpConfig'
    companyId: Scalars['UUID']
    configuration: Scalars['String']
    emailAllowList: Array<Scalars['String']>
    enabled: Scalars['Boolean']
    id: Scalars['UUID']
    idpCompanyId?: Maybe<Scalars['String']>
    idpType: Scalars['String']
    loginMethod: Scalars['String']
}

export type IdpConfigFiltersInput = {
    companyId?: InputMaybe<Scalars['UUID']>
}

export type IdpConfigInputGraphQlInput = {
    companyId: Scalars['UUID']
    configuration: Scalars['String']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    enabled?: InputMaybe<Scalars['Boolean']>
    idpCompanyId?: InputMaybe<Scalars['String']>
    idpType: IdpType
    loginMethod: LoginMethod
}

export type IdpConfigPage = {
    __typename?: 'IdpConfigPage'
    items: Array<IdpConfig>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type IdpConfigSetEmailAllowlistInput = {
    emailAllowlist: Array<Scalars['String']>
}

export type IdpIdEntry = {
    __typename?: 'IdpIdEntry'
    idpId?: Maybe<Scalars['String']>
    idpType: IdpType
}

export type IdpProfile = {
    __typename?: 'IdpProfile'
    idpId: Scalars['String']
    idpType: Scalars['String']
    userId: Scalars['UUID']
}

export type IdpProfileMutation = {
    __typename?: 'IdpProfileMutation'
    /** Links authenticated user with defined provider using token in body */
    link: IdpProfile
}

export type IdpProfileMutationLinkArgs = {
    token: Scalars['String']
    type: IdpType
}

export enum IdpType {
    Awscognito = 'awscognito',
    Azuread = 'azuread',
    Genesys = 'genesys',
    HumanApi = 'human_api',
    KeycloakDirectPassword = 'keycloak_direct_password',
    KeycloakDirectWebauthn = 'keycloak_direct_webauthn',
    KeycloakOktaSso = 'keycloak_okta_sso',
    KeycloakSso = 'keycloak_sso',
    KeycloakTokenExchange = 'keycloak_token_exchange',
    Magiclink = 'magiclink',
    Nice = 'nice',
    Okta = 'okta',
    OktaZp = 'okta_zp',
    Partner = 'partner',
    Slack = 'slack',
    Webex = 'webex',
    Zoom = 'zoom'
}

export type Image = Frame & {
    __typename?: 'Image'
    _: Scalars['Boolean']
    accentColor?: Maybe<Scalars['Color']>
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    dominantColors: Array<Scalars['Color']>
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    resets: Array<PersonalizedReset>
    tag?: Maybe<ImageTag>
    /** @deprecated this field is deprecated, replace with urls.xs */
    thumbnailUrl?: Maybe<Scalars['Url']>
    updatedAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with urls.xl */
    url: Scalars['Url']
    urls: ImageUrls
    user?: Maybe<ResetApiUser>
}

export type ImageQuery = {
    __typename?: 'ImageQuery'
    personalized: PersonalizedImageQuery
    thrive: ThriveImageQuery
}

export type ImageSizes = {
    __typename?: 'ImageSizes'
    card?: Maybe<Scalars['String']>
    full?: Maybe<Scalars['String']>
    large?: Maybe<Scalars['String']>
    medium?: Maybe<Scalars['String']>
    mediumLarge?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['String']>
}

export enum ImageTag {
    Animal = 'Animal',
    Family = 'Family',
    Nature = 'Nature'
}

export type ImageUrls = {
    __typename?: 'ImageUrls'
    lg: Scalars['Url']
    md: Scalars['Url']
    sm: Scalars['Url']
    xl: Scalars['Url']
    xs: Scalars['Url']
}

export type ImagesMutation = {
    __typename?: 'ImagesMutation'
    personalized: PersonalizedImagesMutation
}

export type InputFeedbackInput = {
    answer: ContentFeedback
    question: Scalars['String']
}

export type InsightsMutation = {
    __typename?: 'InsightsMutation'
    /** Update the last time a user played the tutorial to now */
    setTutorialLastPlayedToNow?: Maybe<TutorialConfig>
}

export type InsightsQuery = {
    __typename?: 'InsightsQuery'
    /** Get assessment statistics for the given time range and interval */
    assessmentStats?: Maybe<Array<AssessmentStats>>
    /** Get total completed assessments over the given range for survey dimensions */
    completedAssessments: Scalars['Int']
    /** Get changes in a dimension over a given date range */
    dimensionTrends?: Maybe<Array<DimensionTrendsDto>>
    /** Get risk cohort statistics over the specified range */
    riskCohortStatistics: Array<RiskCohortStats>
    /** Get a users trend highlights */
    trendHighlights: TrendHighlights
    /** Get user checkin maturity status, determines whether the user can see their individual dashboard and - if they can't - how many responses are left until their account is considered mature */
    userCheckinMaturity?: Maybe<UserCheckinMaturity>
    /** Get a users tutorial config */
    userTutorialConfig?: Maybe<TutorialConfig>
}

export type InsightsQueryAssessmentStatsArgs = {
    dashboardViewType?: InputMaybe<DashboardViewType>
    dimensions: Array<Surveys>
    intervalType: ReportInterval
}

export type InsightsQueryCompletedAssessmentsArgs = {
    dashboardViewType?: InputMaybe<DashboardViewType>
    dimensions?: InputMaybe<Array<Surveys>>
}

export type InsightsQueryDimensionTrendsArgs = {
    dimensions: Array<Surveys>
}

export type InsightsQueryRiskCohortStatisticsArgs = {
    dimension: Surveys
}

export type InsightsQueryTrendHighlightsArgs = {
    dimensions: Array<Surveys>
}

export type IntFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
    contains?: InputMaybe<Scalars['Int']>
    containsi?: InputMaybe<Scalars['Int']>
    endsWith?: InputMaybe<Scalars['Int']>
    eq?: InputMaybe<Scalars['Int']>
    eqi?: InputMaybe<Scalars['Int']>
    gt?: InputMaybe<Scalars['Int']>
    gte?: InputMaybe<Scalars['Int']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
    lt?: InputMaybe<Scalars['Int']>
    lte?: InputMaybe<Scalars['Int']>
    ne?: InputMaybe<Scalars['Int']>
    not?: InputMaybe<IntFilterInput>
    notContains?: InputMaybe<Scalars['Int']>
    notContainsi?: InputMaybe<Scalars['Int']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
    startsWith?: InputMaybe<Scalars['Int']>
}

export type IntakeResult = {
    __typename?: 'IntakeResult'
    waterIntake: WaterIntake
}

export type IntegrationMutation = {
    __typename?: 'IntegrationMutation'
    cognito: CognitoIntegrationMutation
    genesys: GenesysIntegrationMutation
    keycloakDirectPassword: KeycloakDirectPasswordIntegrationMutation
    keycloakOktaSso: KeycloakOktaSsoIntegrationMutation
    keycloakSso: KeycloakSsoIntegrationMutation
    magicLink: MagicLinkIntegrationMutation
    msTeams: MsTeamsIntegrationMutation
    nice: NiceIntegrationMutation
    oktaSso: OktaIntegrationMutation
    partner: PartnerIntegrationMutation
    slack: SlackIntegrationMutation
}

export enum InteractionType {
    Chat = 'CHAT',
    Message = 'MESSAGE',
    Voice = 'VOICE'
}

export type InvalidAction = {
    __typename?: 'InvalidAction'
    message: Scalars['String']
}

export type InvalidField = {
    __typename?: 'InvalidField'
    field: InvalidFieldIdentifier
    message: Scalars['String']
}

export enum InvalidFieldIdentifier {
    Id = 'ID',
    Name = 'NAME'
}

export type InvalidInput = {
    __typename?: 'InvalidInput'
    invalidFields: Array<InvalidField>
}

export type JsonFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
    contains?: InputMaybe<Scalars['JSON']>
    containsi?: InputMaybe<Scalars['JSON']>
    endsWith?: InputMaybe<Scalars['JSON']>
    eq?: InputMaybe<Scalars['JSON']>
    eqi?: InputMaybe<Scalars['JSON']>
    gt?: InputMaybe<Scalars['JSON']>
    gte?: InputMaybe<Scalars['JSON']>
    in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
    lt?: InputMaybe<Scalars['JSON']>
    lte?: InputMaybe<Scalars['JSON']>
    ne?: InputMaybe<Scalars['JSON']>
    not?: InputMaybe<JsonFilterInput>
    notContains?: InputMaybe<Scalars['JSON']>
    notContainsi?: InputMaybe<Scalars['JSON']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>
    startsWith?: InputMaybe<Scalars['JSON']>
}

export type JoinChallengeSucceeded = {
    __typename?: 'JoinChallengeSucceeded'
    challengeActivity: UnifiedUserChallengeActivity
}

export type JournalMutation = {
    __typename?: 'JournalMutation'
    saveMySurveyJournal?: Maybe<MySurveyJournal>
}

export type JournalMutationSaveMySurveyJournalArgs = {
    input: MySurveyJournalInput
}

export type JournalQuery = {
    __typename?: 'JournalQuery'
    getMySurveyJournal?: Maybe<MySurveyJournal>
    getMySurveyJournals: MySurveyJournalPage
}

export type JournalQueryGetMySurveyJournalArgs = {
    id: Scalars['UUID']
}

export type JournalQueryGetMySurveyJournalsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type Journey = {
    __typename?: 'Journey'
    colorPalette: JourneyColorPalette
    coreType?: Maybe<JourneyCoreType>
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    emoji?: Maybe<Scalars['String']>
    enabled: Scalars['Boolean']
    hasMicrosteps: Scalars['Boolean']
    id: Scalars['ID']
    /** @deprecated Use imageUrl */
    image?: Maybe<Scalars['String']>
    imageUrl: Scalars['String']
    isSelected: Scalars['Boolean']
    journeyProgress?: Maybe<JourneyProgress>
    journeyProgressForGivenUser?: Maybe<JourneyProgress>
    name: Scalars['String']
    preferredOrder?: Maybe<Scalars['Int']>
    shortName?: Maybe<Scalars['String']>
    thumbnailUrl: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type JourneyJourneyProgressForGivenUserArgs = {
    userId: Scalars['UUID']
}

export type JourneyColorPalette = {
    __typename?: 'JourneyColorPalette'
    contrastText: Scalars['Color']
    dark: Scalars['Color']
    light: Scalars['Color']
    main: Scalars['Color']
}

export enum JourneyCoreType {
    Connect = 'Connect',
    Focus = 'Focus',
    Food = 'Food',
    Money = 'Money',
    Move = 'Move',
    Recharge = 'Recharge',
    StressManagement = 'Stress_Management'
}

export type JourneyDailyStep = {
    __typename?: 'JourneyDailyStep'
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    day: Scalars['Int']
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    id: Scalars['ID']
    iframeUrl: Scalars['String']
    imageUrl: Scalars['String']
    isCompleted: Scalars['Boolean']
    journey?: Maybe<Journey>
    level: Scalars['Int']
    microsteps: Array<GraphQlMicrostep>
    /** MicrostepsV2 filter by subscriptionId */
    microstepsV2: Array<MicrostepV2>
    /** calculated based on the 'order'; example 'Day 3' */
    name: Scalars['String']
    title: Scalars['String']
    videoUrl: Scalars['String']
}

export type JourneyDailyStepIframeUrlArgs = {
    userId: Scalars['UUID']
    utmSource: Scalars['String']
}

export type JourneyDailyStepContentStatusInput = {
    /** JourneyDailyStep id */
    contentId: Scalars['String']
    /** JourneyDailyStep progress in seconds */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    totalInSeconds?: InputMaybe<Scalars['Int']>
}

export type JourneyLevel = {
    __typename?: 'JourneyLevel'
    /** collection of daily steps, usually 7 */
    dailySteps: Array<JourneyDailyStep>
    /** Returns JourneyLevelCompletionFeedback from CMS when level is completed, otherwise returns null.Feedback question response is populated if user submitted their response */
    feedback?: Maybe<JourneyLevelCompletionFeedback>
    journey?: Maybe<Journey>
    journeyLevelProgress: JourneyLevelProgress
    level: Scalars['Int']
    /** calculated based on the 'order'; example 'Level 2' */
    name: Scalars['String']
}

export type JourneyLevelCompletionFeedback = {
    __typename?: 'JourneyLevelCompletionFeedback'
    isSkipped?: Maybe<Scalars['Boolean']>
    isSubmitted?: Maybe<Scalars['Boolean']>
    questions: Array<JourneyLevelCompletionFeedbackQuestion>
    uuid: Scalars['UUID']
}

export type JourneyLevelCompletionFeedbackQuestion = {
    __typename?: 'JourneyLevelCompletionFeedbackQuestion'
    question: Scalars['String']
    /** @deprecated No longer supported */
    response?: Maybe<FeedbackQuestionResponse>
    type: FeedbackQuestionType
}

export type JourneyLevelProgress = {
    __typename?: 'JourneyLevelProgress'
    isCompleted: Scalars['Boolean']
    numberOfCompletedSteps: Scalars['Int']
    progressInPercent: Scalars['Int']
    totalNumberOfSteps: Scalars['Int']
}

export type JourneyMutation = {
    __typename?: 'JourneyMutation'
    /** Save JourneyLevelCompletionFeedback for a journey and level */
    saveJourneyLevelCompletionFeedback: Scalars['String']
    /** User mutation to select one of the Core Journeys, userId is taken from the jwt token */
    selectCoreJourney: Scalars['String']
    /** User mutation to select one of the Core Journeys, userId is passed as a parameter (for Chat) */
    selectCoreJourneyForGivenUser: Scalars['String']
    /** @deprecated No longer supported */
    selectPreferredJourney: Scalars['String']
    /** @deprecated No longer supported */
    selectPreferredJourneys: Scalars['String']
    /** Skips JourneyLevelCompletionFeedback (saves user skip action) for a journey and level */
    skipJourneyLevelCompletionFeedback: Scalars['String']
    /** User mutation to unselect user's Core journey */
    unselectCoreJourneyForGivenUser: Scalars['String']
}

export type JourneyMutationSaveJourneyLevelCompletionFeedbackArgs = {
    feedback?: InputMaybe<Array<InputFeedbackInput>>
    journeyId: Scalars['UUID']
    level: Scalars['Int']
}

export type JourneyMutationSelectCoreJourneyArgs = {
    journeyId: Scalars['UUID']
}

export type JourneyMutationSelectCoreJourneyForGivenUserArgs = {
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type JourneyMutationSelectPreferredJourneyArgs = {
    journeyId: Scalars['UUID']
}

export type JourneyMutationSelectPreferredJourneysArgs = {
    journeyIds: Array<Scalars['UUID']>
}

export type JourneyMutationSkipJourneyLevelCompletionFeedbackArgs = {
    journeyId: Scalars['UUID']
    level: Scalars['Int']
}

export type JourneyMutationUnselectCoreJourneyForGivenUserArgs = {
    userId: Scalars['UUID']
}

export type JourneyProgress = {
    __typename?: 'JourneyProgress'
    currentLevel?: Maybe<JourneyLevel>
    currentStep?: Maybe<JourneyDailyStep>
    dailySteps: Array<JourneyDailyStep>
    isCompleted: Scalars['Boolean']
    isTodaysProgress: Scalars['Boolean']
    journey?: Maybe<Journey>
    journeyLevel?: Maybe<JourneyLevel>
    stepCompletedToday?: Maybe<JourneyDailyStep>
}

export type JourneyProgressJourneyLevelArgs = {
    level: Scalars['Int']
}

export type JourneyQuery = {
    __typename?: 'JourneyQuery'
    getCoreJourneys: Array<Journey>
    /** Individual Journey information */
    journey?: Maybe<Journey>
    /** Individual Journey information */
    journeys: Array<Journey>
    /**
     * Journeys available to a Subscription, changed to return core journeys only
     * @deprecated No longer supported, use getCoreJourneys() instead
     */
    journeysForSubscription: Array<Journey>
    /** A users preferred journeys and the journeys available to them. */
    myJourneyPreferences: UserJourneys
    /** A users preferred journeys and the journeys available to them for a given user id. */
    myJourneyPreferencesForGivenUserId: UserJourneys
}

export type JourneyQueryJourneyArgs = {
    id: Scalars['ID']
}

export type JourneyQueryJourneysArgs = {
    ids: Array<Scalars['ID']>
}

export type JourneyQueryJourneysForSubscriptionArgs = {
    companyId: Scalars['UUID']
    subscriptionId: Scalars['UUID']
}

export type JourneyQueryMyJourneyPreferencesForGivenUserIdArgs = {
    userID: Scalars['UUID']
}

export type JwkCacheEntry = {
    __typename?: 'JwkCacheEntry'
    algoName?: Maybe<Scalars['String']>
    e?: Maybe<Scalars['String']>
    issuer: Scalars['String']
    jwkUrl: Scalars['Url']
    kid?: Maybe<Scalars['String']>
    n?: Maybe<Scalars['String']>
}

export type KeycloakDirectPasswordIntegrationMutation = {
    __typename?: 'KeycloakDirectPasswordIntegrationMutation'
    /** Disables Keycloak Direct Password integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak Direct Password integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. */
    enable: Scalars['Boolean']
}

export type KeycloakDirectPasswordIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakDirectPasswordIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
}

export type KeycloakOktaSsoIntegrationMutation = {
    __typename?: 'KeycloakOktaSsoIntegrationMutation'
    /** Disables Keycloak Okta SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak Okta SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. IdpHint is a unique identifier of SSO connection in the Keycloak */
    enable: Scalars['Boolean']
}

export type KeycloakOktaSsoIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakOktaSsoIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpHint: Scalars['String']
}

export type KeycloakSsoIntegrationMutation = {
    __typename?: 'KeycloakSsoIntegrationMutation'
    /** Disables Keycloak SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. IdpHint is a unique identifier of SSO connection in the Keycloak */
    enable: Scalars['Boolean']
}

export type KeycloakSsoIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakSsoIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpHint: Scalars['String']
}

export type Leaderboard = {
    __typename?: 'Leaderboard'
    rankedList: Array<ScoreRanked>
    /** The requester entity ranked position */
    requesterRank: Scalars['Int']
    totalEntries: Scalars['Int']
}

export type LearnContentBookmark = {
    __typename?: 'LearnContentBookmark'
    bookmarked: Scalars['Boolean']
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    createdAt: Scalars['Instant']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentByTopicOutput = {
    __typename?: 'LearnContentByTopicOutput'
    itemPage: LearnContentItemPage
    topic: Topic
}

export type LearnContentFeedback = {
    __typename?: 'LearnContentFeedback'
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    feedbackAnswer: ContentFeedback
    feedbackQuestion: Scalars['String']
    openAnswer?: Maybe<Scalars['String']>
    openQuestion?: Maybe<Scalars['String']>
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type LearnContentItem = {
    __typename?: 'LearnContentItem'
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    durationInSeconds?: Maybe<Scalars['Int']>
    id: Scalars['ID']
    imageUrl: Scalars['String']
    parent?: Maybe<LearnContentParent>
    title: Scalars['String']
}

export type LearnContentItemPage = {
    __typename?: 'LearnContentItemPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<LearnContentItem>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type LearnContentLikes = {
    __typename?: 'LearnContentLikes'
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    createdAt: Scalars['Instant']
    likes: Scalars['Int']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentParent = CourseModuleParent | PodcastEpisodeParent

/** Represents progress of a content */
export type LearnContentProgress = {
    __typename?: 'LearnContentProgress'
    /** Calculated, returns true if content is completed or if both numberOfCompletedItems and totalNumberOfItems is 0 */
    isCompleted: Scalars['Boolean']
    numberOfCompletedItems: Scalars['Int']
    /** Calculated, returns percentage of completion */
    progressInPercent: Scalars['Int']
    totalNumberOfItems: Scalars['Int']
}

export type LearnContentStatus = {
    __typename?: 'LearnContentStatus'
    contentId: Scalars['String']
    createdAt: Scalars['Instant']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    key: LearnContentStatusKey
    progressInPercent: Scalars['Int']
    progressInSeconds?: Maybe<Scalars['Int']>
    progressInSecondsOrDefault: Scalars['Int']
    progressType: LearnContentStatusProgressType
    subscriptionId?: Maybe<Scalars['UUID']>
    subtype?: Maybe<LearnContentStatusSubtype>
    totalInSeconds?: Maybe<Scalars['Int']>
    totalInSecondsOrDefault: Scalars['Int']
    type: LearnContentStatusType
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentStatusKey = {
    __typename?: 'LearnContentStatusKey'
    contentId: Scalars['String']
    type: LearnContentStatusType
    userId: Scalars['UUID']
}

export enum LearnContentStatusProgressType {
    Finished = 'Finished',
    InProgress = 'InProgress',
    Opened = 'Opened'
}

export enum LearnContentStatusSubtype {
    Audio = 'Audio',
    Text = 'Text',
    Video = 'Video'
}

export enum LearnContentStatusType {
    AcuteCareResource = 'AcuteCareResource',
    Article = 'Article',
    AudioBookChapterPart = 'AudioBookChapterPart',
    Course = 'Course',
    CourseResource = 'CourseResource',
    JourneyDailyStep = 'JourneyDailyStep',
    Lesson = 'Lesson',
    Microstep = 'Microstep',
    Module = 'Module',
    Podcast = 'Podcast',
    PodcastEpisode = 'PodcastEpisode',
    Recipe = 'Recipe',
    Reset = 'Reset',
    RoleModel = 'RoleModel',
    WatchMeThrive = 'WatchMeThrive',
    Webinar = 'Webinar'
}

export type LearnCourseModuleProgress = {
    __typename?: 'LearnCourseModuleProgress'
    progress: Scalars['Int']
    total: Scalars['Int']
    updatedAt: Scalars['String']
}

export type LearnFeaturedContent = {
    __typename?: 'LearnFeaturedContent'
    featuredArticle?: Maybe<ArticleV2>
    featuredArticleID?: Maybe<Scalars['ID']>
    featuredCourse?: Maybe<CourseV2>
    featuredCourseID?: Maybe<Scalars['ID']>
    featuredPodcastEpisode?: Maybe<PodcastEpisode>
    featuredPodcastEpisodeID?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    isUnrestricted: Scalars['Boolean']
    trendingArticle?: Maybe<ArticleV2>
    trendingArticleID?: Maybe<Scalars['ID']>
    trendingCourse?: Maybe<CourseV2>
    trendingCourseID?: Maybe<Scalars['ID']>
    trendingPodcastEpisode?: Maybe<PodcastEpisode>
    trendingPodcastEpisodeID?: Maybe<Scalars['ID']>
}

export type LearnMutation = {
    __typename?: 'LearnMutation'
    updateModuleProgress?: Maybe<LearnCourseModuleProgress>
}

export type LearnMutationUpdateModuleProgressArgs = {
    courseId: Scalars['String']
    lessonId: Scalars['String']
    moduleId: Scalars['String']
    progress: Scalars['Int']
    total: Scalars['Int']
}

export type LearnMutationV2 = CourseMutation &
    ModuleMutation & {
        __typename?: 'LearnMutationV2'
        /** Mutation to create a bookmark, based on userId from context */
        createBookmark: LearnContentBookmark
        /** Mutation to delete a bookmark, based on userId from context */
        deleteBookmark?: Maybe<LearnContentBookmark>
        /** Mutation creating or updating journey daily step status, for a given user. Emits event 'JourneyLevelCompleted'. */
        markAcuteCareResourceAsSeen: LearnContentStatus
        /** Mutation creating or updating course resource status (for course or lesson), based on userId from context */
        markCourseResourceAsCompleted: LearnContentStatus
        /** Mutation creating or updating journey daily step status, based on userId from context. Emits event 'JourneyLevelCompleted'. */
        markJourneyDailyStepAsCompleted: LearnContentStatus
        /** Mutation creating or updating journey daily step status, for a given user. Emits event 'JourneyLevelCompleted'. */
        markJourneyDailyStepAsCompletedForGivenUser: LearnContentStatus
        /** Mutation to register a user to a webinar using webinarId and userId from context */
        registerForWebinar: WebinarRegistrationResponse
        /** Save feedback of any type of content from questions at the end, one positive/negative feedback plus a free text field question */
        saveLearnContentFeedback: LearnContentFeedback
        /** Mutation setting the current journey daily step for a given user. Emits event 'JourneyLevelCompleted' for all steps completed. */
        setCurrentJourneyDailyStepForGivenUser?: Maybe<LearnContentStatus>
        /** Mutation to unmark a daily step for a given user. */
        unmarkJourneyDailyStepAsCompletedForGivenUser?: Maybe<LearnContentStatus>
        /** Mutation creating or updating article status, based on userId from context */
        updateArticleStatus: LearnContentStatus
        /** Mutation creating or updating content feedback, based on userId from context */
        updateContentLikes: LearnContentLikes
        /** Mutation creating or updating JourneyDailyStep status, based on userId from context */
        updateJourneyDailyStepStatus: LearnContentStatus
        /** Mutation creating or updating module status, based on userId from context */
        updateModuleStatus: LearnContentStatus
        /** Mutation creating or updating podcast status, based on userId from context */
        updatePodcastStatus: LearnContentStatus
        /** Mutation to set the attendance of a user to a webinar session id, based on userId from context */
        updateWebinarSessionStatus: Scalars['Boolean']
        /** Mutation creating or updating video status, based on userId from context */
        updateWebinarVideoStatus: LearnContentStatus
    }

export type LearnMutationV2CreateBookmarkArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
}

export type LearnMutationV2DeleteBookmarkArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
}

export type LearnMutationV2MarkAcuteCareResourceAsSeenArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkCourseResourceAsCompletedArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkJourneyDailyStepAsCompletedArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkJourneyDailyStepAsCompletedForGivenUserArgs = {
    contentId: Scalars['String']
    userId: Scalars['UUID']
}

export type LearnMutationV2RegisterForWebinarArgs = {
    webinarId: Scalars['String']
}

export type LearnMutationV2SaveLearnContentFeedbackArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    feedbackAnswer: ContentFeedback
    feedbackQuestion: Scalars['String']
    openAnswer?: InputMaybe<Scalars['String']>
    openQuestion?: InputMaybe<Scalars['String']>
}

export type LearnMutationV2SetCurrentJourneyDailyStepForGivenUserArgs = {
    dailyStepId: Scalars['UUID']
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type LearnMutationV2UnmarkJourneyDailyStepAsCompletedForGivenUserArgs = {
    contentId: Scalars['String']
    userId: Scalars['UUID']
}

export type LearnMutationV2UpdateArticleStatusArgs = {
    input: ArticleContentStatusInput
}

export type LearnMutationV2UpdateContentLikesArgs = {
    input: LikesInput
}

export type LearnMutationV2UpdateJourneyDailyStepStatusArgs = {
    input: JourneyDailyStepContentStatusInput
}

export type LearnMutationV2UpdateModuleStatusArgs = {
    input: ModuleContentStatusInput
}

export type LearnMutationV2UpdatePodcastStatusArgs = {
    input: PodcastContentStatusInput
}

export type LearnMutationV2UpdateWebinarSessionStatusArgs = {
    sessionId: Scalars['String']
}

export type LearnMutationV2UpdateWebinarVideoStatusArgs = {
    input: WebinarVideoContentStatusInput
}

export type LearnQuery = {
    __typename?: 'LearnQuery'
    course?: Maybe<Course>
    courses: Array<Course>
    featuredCourses: Array<Course>
    recentCourses: Array<Course>
}

export type LearnQueryCourseArgs = {
    id: Scalars['String']
}

export type LearnQueryRecentCoursesArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2 = CourseQuery & {
    __typename?: 'LearnQueryV2'
    /**
     * Get article by id from CMS and if not found from Hermes
     * @deprecated No longer supported, replace with getArticleV2
     */
    getArticle?: Maybe<Article>
    /**
     * Get Article of the Day for a specific user and date
     * @deprecated No longer supported, replace with getArticlesV2
     */
    getArticleOfTheDay?: Maybe<Article>
    /** Get articleV2 by id from CMS cache */
    getArticleV2?: Maybe<ArticleV2>
    /** Get articleV2 by id and subscriptionId from CMS cache for default locale (used by Admin Tool) */
    getArticleV2BySubscription?: Maybe<ArticleV2>
    /**
     * Get articles, returns articles or role models for a given subscription from CMS
     * @deprecated No longer supported, replace with getArticlesV2
     */
    getArticles: ArticlePage
    /** Get articles, returns articles, recipes or role models for a given subscription from CMS */
    getArticlesV2: ArticlePageV2
    /**
     * Get bookmarked items for a user
     * @deprecated No longer supported, replace with /content/thrive/getBookmarkedContent
     */
    getBookmarkedItems: LearnContentItemPage
    /** Get company resource sections by subscription Id */
    getCompanyResourceSections: CompanyResourceSectionPage
    /** Get course by id */
    getCourse?: Maybe<CourseV2>
    /** Get courses by companyId and subscriptionId from context */
    getCourses: CoursePage
    /** Get featured content from CMS based on subscriptionId */
    getFeaturedContent?: Maybe<LearnFeaturedContent>
    /**
     * Get Learn Content by Topic. A topic can have Journey UUID or a Pulse Dimension Type.
     * @deprecated No longer supported, replace with /content/thrive/getContentByTopic
     */
    getLearnContentByTopic: LearnContentByTopicOutput
    /** Get podcasts for Overview page */
    getOverviewPodcasts: PodcastPage
    /** Get podcast by id */
    getPodcast?: Maybe<Podcast>
    /** Get Podcast of the Day for a specific user and date */
    getPodcastOfTheDay?: Maybe<Podcast>
    /** Get podcasts */
    getPodcasts: PodcastPage
    /** Get Pulse Dimensions */
    getPulseDimensions: Array<PulseDimension>
    /** Get recently played Podcast episodes, returns episodes or empty if there are no played episodes */
    getRecentlyPlayedPodcastEpisodes: PodcastEpisodePage
    /** Get recommended article by user id, currently using Algolia recommendations */
    getRecommendedArticle?: Maybe<ArticleV2>
    /** Get recommended content */
    getRecommendedContent?: Maybe<RecommendedContentItem>
    /** Get recommended article by user id, currently using Algolia recommendations */
    getRelatedArticles: Array<ArticleV2>
    /** Get webinar by id */
    getWebinar?: Maybe<Webinar>
    /** Get webinars registered to a user id */
    getWebinars: WebinarPage
    /** Get recommended content by pulse dimensions */
    recommendByPulseDimension: RecommendedContentByPulseDimension
}

export type LearnQueryV2GetArticleArgs = {
    id: Scalars['String']
}

export type LearnQueryV2GetArticleOfTheDayArgs = {
    timezone?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetArticleV2Args = {
    id: Scalars['String']
}

export type LearnQueryV2GetArticleV2BySubscriptionArgs = {
    id: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type LearnQueryV2GetArticlesArgs = {
    contentTypes?: InputMaybe<Array<Scalars['String']>>
    isRoleModel?: InputMaybe<Scalars['Boolean']>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetArticlesV2Args = {
    articleTypes?: InputMaybe<Array<ArticleType>>
    contentTypes?: InputMaybe<Array<ArticleContentType>>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetBookmarkedItemsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetCompanyResourceSectionsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetCourseArgs = {
    courseId: Scalars['String']
}

export type LearnQueryV2GetCoursesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    sort?: InputMaybe<Array<Scalars['String']>>
}

export type LearnQueryV2GetLearnContentByTopicArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    topic: TopicInput
}

export type LearnQueryV2GetOverviewPodcastsArgs = {
    numberOfPodcasts?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetPodcastArgs = {
    podcastId: Scalars['String']
}

export type LearnQueryV2GetPodcastOfTheDayArgs = {
    timezone?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetPodcastsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetRecentlyPlayedPodcastEpisodesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetRecommendedArticleArgs = {
    requesterApp?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetRecommendedContentArgs = {
    pulseDimensions: Array<Scalars['String']>
    recommendedContentTypes: Array<RecommendedContentType>
    requesterApp?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetRelatedArticlesArgs = {
    articleId: Scalars['String']
}

export type LearnQueryV2GetWebinarArgs = {
    webinarId: Scalars['String']
}

export type LearnQueryV2GetWebinarsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2RecommendByPulseDimensionArgs = {
    limit?: InputMaybe<Scalars['Int']>
    pulseDimensions: Array<Scalars['String']>
    requesterApp?: InputMaybe<Scalars['String']>
}

export type LearnQuote = {
    __typename?: 'LearnQuote'
    author: Scalars['String']
    backgroundColor: Scalars['String']
    backgroundImageUrl: Scalars['String']
    body: Scalars['String']
    date: Scalars['ISODate']
    id: Scalars['ID']
    imageWithTextUrl: Scalars['String']
    textColor: Scalars['String']
}

export type Lesson = {
    __typename?: 'Lesson'
    courseId?: Maybe<Scalars['String']>
    guides?: Maybe<Array<Maybe<Guide>>>
    id: Scalars['ID']
    modules?: Maybe<Array<Module>>
    order?: Maybe<Scalars['Int']>
    thumbnail?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type LessonGuidesArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type LessonModulesArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type LessonFeedback = {
    __typename?: 'LessonFeedback'
    feedbackQuestion: Scalars['String']
    openQuestion: Scalars['String']
}

export type LessonV2 = {
    __typename?: 'LessonV2'
    courseID?: Maybe<Scalars['String']>
    feedback?: Maybe<LessonFeedback>
    id: Scalars['ID']
    moduleIds: Array<Scalars['ID']>
    modules: Array<ModuleV2>
    order: Scalars['Int']
    /** Course Lesson progress for user, based on userId from context. Checks progress on modules and lesson resources. */
    progress?: Maybe<LearnContentProgress>
    resources: Array<CourseResource>
    title: Scalars['String']
}

export enum LicenseType {
    /** @deprecated Old name */
    Lite = 'lite',
    Standard = 'standard',
    ThriveResetForTeams = 'thriveResetForTeams'
}

export type LikeMutation = {
    __typename?: 'LikeMutation'
    create: Scalars['Boolean']
    delete: Scalars['Boolean']
}

export type LikeMutationCreateArgs = {
    id: Scalars['UUID']
}

export type LikeMutationDeleteArgs = {
    id: Scalars['UUID']
}

export type LikeQuery = {
    __typename?: 'LikeQuery'
    isLiked: Scalars['Boolean']
}

export type LikeQueryIsLikedArgs = {
    id: Scalars['UUID']
}

export type Likes = {
    __typename?: 'Likes'
    isLiked: Scalars['Boolean']
    numberOfLikes: Scalars['Int']
    totalNumberOfLikes: Scalars['Int']
}

export type LikesInput = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    likes: Scalars['Int']
}

export type LinkedAccountDto = {
    __typename?: 'LinkedAccountDTO'
    /** The type of linked account */
    channel: Channel
    /** When this account was created in Pulse */
    createdAt: Scalars['Instant']
    /** If that user has an admin account on the tenant workspace */
    isAdmin: Scalars['Boolean']
    /** If that user is marked as deleted on the account */
    isDeleted: Scalars['Boolean']
    /** If that user is subscribed to Pulse on the account */
    subscribed: Scalars['Boolean']
    /** The tenant/team id for the account */
    tenantId: Scalars['String']
    /** The user's timezone for the account */
    timezone: Scalars['String']
    /** When this account was last updated in Pulse */
    updatedAt: Scalars['Instant']
    /** The user id for the account */
    userId: Scalars['String']
}

export type LocalizedThriveResetTag = {
    __typename?: 'LocalizedThriveResetTag'
    localized: Scalars['String']
    value: ThriveResetTag
}

export enum LoginMethod {
    Credentials = 'credentials',
    Sso = 'sso'
}

export type MagicLinkIntegrationMutation = {
    __typename?: 'MagicLinkIntegrationMutation'
    /** Disables MagicLink integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new MagicLink integration to given company. If config already exists, it will be enabled without changing anything else. */
    enable: Scalars['Boolean']
}

export type MagicLinkIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type MagicLinkIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
}

export enum MeasureUnit {
    Ml = 'ML',
    Oz = 'OZ'
}

export enum MediaType {
    Chat = 'CHAT',
    Message = 'MESSAGE',
    None = 'NONE',
    Voice = 'VOICE'
}

export type MediaTypeConfig = {
    __typename?: 'MediaTypeConfig'
    chat: Scalars['Boolean']
    message?: Maybe<Scalars['Boolean']>
    voice: Scalars['Boolean']
}

export type MediaTypeConfigInput = {
    chat: Scalars['Boolean']
    message?: InputMaybe<Scalars['Boolean']>
    voice: Scalars['Boolean']
}

export type MetricData = {
    __typename?: 'MetricData'
    coordinates: Array<ReportingCoordinates>
    description?: Maybe<Scalars['String']>
    metric: Scalars['String']
}

export enum MetricsEnum {
    Nblindtransferred = 'NBLINDTRANSFERRED',
    Ncobrowsesessions = 'NCOBROWSESESSIONS',
    Nconnected = 'NCONNECTED',
    Nconsult = 'NCONSULT',
    Nconsulttransferred = 'NCONSULTTRANSFERRED',
    Nerror = 'NERROR',
    Noffered = 'NOFFERED',
    Noutbound = 'NOUTBOUND',
    Noutboundabandoned = 'NOUTBOUNDABANDONED',
    Noutboundattempted = 'NOUTBOUNDATTEMPTED',
    Noutboundconnected = 'NOUTBOUNDCONNECTED',
    Noversla = 'NOVERSLA',
    Nstatetransitionerror = 'NSTATETRANSITIONERROR',
    Ntransferred = 'NTRANSFERRED',
    Oexternalmediacount = 'OEXTERNALMEDIACOUNT',
    Omediacount = 'OMEDIACOUNT',
    Omessageturn = 'OMESSAGETURN',
    Oservicelevel = 'OSERVICELEVEL',
    Oservicetarget = 'OSERVICETARGET',
    Tabandon = 'TABANDON',
    Tacd = 'TACD',
    Tacw = 'TACW',
    Tagentresponsetime = 'TAGENTRESPONSETIME',
    Talert = 'TALERT',
    Tanswered = 'TANSWERED',
    Tbarging = 'TBARGING',
    Tcallback = 'TCALLBACK',
    Tcallbackcomplete = 'TCALLBACKCOMPLETE',
    Tcoaching = 'TCOACHING',
    Tcoachingcomplete = 'TCOACHINGCOMPLETE',
    Tconnected = 'TCONNECTED',
    Tcontacting = 'TCONTACTING',
    Tdialing = 'TDIALING',
    Tfirstconnect = 'TFIRSTCONNECT',
    Tfirstdial = 'TFIRSTDIAL',
    Tflowout = 'TFLOWOUT',
    Thandle = 'THANDLE',
    Theld = 'THELD',
    Theldcomplete = 'THELDCOMPLETE',
    Tivr = 'TIVR',
    Tmonitoring = 'TMONITORING',
    Tmonitoringcomplete = 'TMONITORINGCOMPLETE',
    Tnotresponding = 'TNOTRESPONDING',
    Tshortabandon = 'TSHORTABANDON',
    Ttalk = 'TTALK',
    Ttalkcomplete = 'TTALKCOMPLETE',
    Tuserresponsetime = 'TUSERRESPONSETIME',
    Tvoicemail = 'TVOICEMAIL',
    Twait = 'TWAIT'
}

export type Microstep = {
    __typename?: 'Microstep'
    action?: Maybe<Scalars['String']>
    body: Scalars['String']
    createdAt: Scalars['Date']
    deprecateAt?: Maybe<Scalars['Date']>
    duration: Scalars['Int']
    id: Scalars['ID']
    reminderEnabled: Scalars['Boolean']
    timeUnit: Scalars['String']
    title: Scalars['String']
    updatedAt: Scalars['Date']
    wpId: Scalars['Int']
}

export type MicrostepActivities = {
    __typename?: 'MicrostepActivities'
    checkIns?: Maybe<Array<Maybe<Scalars['Date']>>>
    microstep?: Maybe<Microstep>
}

export type MicrostepAndRemovalReasonInput = {
    microstepId: Scalars['ID']
    removalReason?: InputMaybe<MicrostepRemovalReason>
}

export type MicrostepCheckin = {
    __typename?: 'MicrostepCheckin'
    checkInDate: Scalars['ISODate']
    dateTime: Scalars['String']
    firstTimeCheckin?: Maybe<Scalars['Boolean']>
    microstepId: Scalars['ID']
    status: Scalars['String']
    userId: Scalars['ID']
}

export type MicrostepCheckinDateList = {
    __typename?: 'MicrostepCheckinDateList'
    checkinDates: Array<Scalars['ISODate']>
    microstepId: Scalars['ID']
}

export type MicrostepCheckinProgressDate = {
    __typename?: 'MicrostepCheckinProgressDate'
    date: Scalars['String']
    microstepsCheckedIn: Array<Scalars['ID']>
    microstepsCheckedInCount: Scalars['Int']
    microstepsCommittedCount: Scalars['Int']
    microstepsCommittedIds: Array<Scalars['ID']>
}

export type MicrostepDetail = {
    __typename?: 'MicrostepDetail'
    id: Scalars['UUID']
    title?: Maybe<Scalars['String']>
}

export type MicrostepGoalDetail = {
    __typename?: 'MicrostepGoalDetail'
    microstep: GraphQlMicrostep
    microstepInfo: MicrostepDetail
}

export enum MicrostepMomentumError {
    DateRangeNotValid = 'DATE_RANGE_NOT_VALID'
}

export type MicrostepMomentumErrorResponse = {
    __typename?: 'MicrostepMomentumErrorResponse'
    error: MicrostepMomentumError
}

export type MicrostepMomentumResult =
    | GraphQlMicrostepMomentum
    | MicrostepMomentumErrorResponse

export enum MicrostepRemovalReason {
    Accomplished = 'ACCOMPLISHED',
    NotInterested = 'NOT_INTERESTED'
}

export type MicrostepSubscription = {
    __typename?: 'MicrostepSubscription'
    microstepId: Scalars['ID']
    status: Scalars['String']
    userId: Scalars['ID']
}

export type MicrostepUserProgress = {
    __typename?: 'MicrostepUserProgress'
    checkedInCount: Scalars['Int']
    microstepId: Scalars['ID']
}

export type MicrostepUserStatus = {
    __typename?: 'MicrostepUserStatus'
    checkedIn: Scalars['Boolean']
    committed: Scalars['Boolean']
    microstep: GraphQlMicrostep
}

export type MicrostepUserTotal = {
    __typename?: 'MicrostepUserTotal'
    checkedInCount: Scalars['Int']
    userId: Scalars['ID']
}

export type MicrostepV2 = {
    __typename?: 'MicrostepV2'
    action: Scalars['String']
    body: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    deprecateAt?: Maybe<Scalars['ISODate']>
    duration: Scalars['Int']
    id: Scalars['UUID']
    journeyIds: Array<Scalars['UUID']>
    journeys: Array<Maybe<Journey>>
    timeUnit: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
}

export type Module = {
    __typename?: 'Module'
    byline: Scalars['String']
    courseId: Scalars['Int']
    displayName?: Maybe<Scalars['String']>
    id: Scalars['ID']
    lessonId: Scalars['Int']
    moduleProgress?: Maybe<LearnCourseModuleProgress>
    order: Scalars['Int']
    progress?: Maybe<Scalars['Int']>
    thumbnail?: Maybe<Scalars['String']>
    title: Scalars['String']
    type: Scalars['String']
    videoLink: Scalars['String']
}

export type ModuleProgressArgs = {
    userId?: InputMaybe<Scalars['String']>
}

export type ModuleContentStatusInput = {
    /** module id */
    contentId: Scalars['String']
    /** module progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of module in seconds */
    totalInSeconds: Scalars['Int']
}

export type ModuleMutation = {
    /** Mutation creating or updating module status, based on userId from context */
    updateModuleStatus: LearnContentStatus
}

export type ModuleMutationUpdateModuleStatusArgs = {
    input: ModuleContentStatusInput
}

export type ModuleProgress = {
    __typename?: 'ModuleProgress'
    companyId?: Maybe<Scalars['String']>
    courseId?: Maybe<Scalars['String']>
    lessonId?: Maybe<Scalars['String']>
    moduleId?: Maybe<Scalars['String']>
    progress?: Maybe<Scalars['Int']>
    total?: Maybe<Scalars['Int']>
    userId?: Maybe<Scalars['String']>
}

export type ModuleV2 = {
    __typename?: 'ModuleV2'
    bookmarked: Scalars['Boolean']
    byline: Scalars['String']
    contentStatus?: Maybe<LearnContentStatus>
    courseID?: Maybe<Scalars['String']>
    /** Display name, directly from CMS */
    displayName: Scalars['String']
    id: Scalars['ID']
    /** Label calculated from CMS 'displayName' minus ': title' */
    label: Scalars['String']
    lessonID?: Maybe<Scalars['String']>
    likes: Likes
    /** Order, order of the component in CMS */
    order: Scalars['Int']
    thumbnail: Scalars['String']
    title: Scalars['String']
    type: Scalars['String']
    videoLink: Scalars['String']
}

export type MonthYear = {
    __typename?: 'MonthYear'
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthYearInput = {
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'MonthlyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'MonthlyAssessmentStatsResponse'
    average: Scalars['Float']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'MonthlySleepSummaryStatsResponse'
    averageMinutesAsleep: Scalars['Float']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MsTeamsIntegrationMutation = {
    __typename?: 'MsTeamsIntegrationMutation'
    /** Disables Microsoft Teams integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Microsoft Teams integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type MsTeamsIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type MsTeamsIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    tenantId: Scalars['UUID']
}

export type MsTeamsSubscribe = {
    __typename?: 'MsTeamsSubscribe'
    bodyText: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    headerText: Scalars['String']
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<MsTeamsSubscribeRelationResponseCollection>
    maybeLaterButtonText: Scalars['String']
    publishedAt?: Maybe<Scalars['DateTime']>
    subheaderText?: Maybe<Scalars['String']>
    subscribeButtonText: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type MsTeamsSubscribeLocalizationsArgs = {
    filters?: InputMaybe<MsTeamsSubscribeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MsTeamsSubscribeEntity = {
    __typename?: 'MsTeamsSubscribeEntity'
    attributes?: Maybe<MsTeamsSubscribe>
    id?: Maybe<Scalars['ID']>
}

export type MsTeamsSubscribeEntityResponse = {
    __typename?: 'MsTeamsSubscribeEntityResponse'
    data?: Maybe<MsTeamsSubscribeEntity>
}

export type MsTeamsSubscribeEntityResponseCollection = {
    __typename?: 'MsTeamsSubscribeEntityResponseCollection'
    data: Array<MsTeamsSubscribeEntity>
    meta: ResponseCollectionMeta
}

export type MsTeamsSubscribeFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<MsTeamsSubscribeFiltersInput>>>
    bodyText?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    headerText?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<MsTeamsSubscribeFiltersInput>
    maybeLaterButtonText?: InputMaybe<StringFilterInput>
    not?: InputMaybe<MsTeamsSubscribeFiltersInput>
    or?: InputMaybe<Array<InputMaybe<MsTeamsSubscribeFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    subheaderText?: InputMaybe<StringFilterInput>
    subscribeButtonText?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type MsTeamsSubscribeInput = {
    bodyText?: InputMaybe<Scalars['String']>
    headerText?: InputMaybe<Scalars['String']>
    maybeLaterButtonText?: InputMaybe<Scalars['String']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    subheaderText?: InputMaybe<Scalars['String']>
    subscribeButtonText?: InputMaybe<Scalars['String']>
}

export type MsTeamsSubscribeRelationResponseCollection = {
    __typename?: 'MsTeamsSubscribeRelationResponseCollection'
    data: Array<MsTeamsSubscribeEntity>
}

export type MsTeamsWelcome = {
    __typename?: 'MsTeamsWelcome'
    bodyText: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    headerText: Scalars['String']
    helloText: Scalars['String']
    image: UploadFileEntityResponse
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<MsTeamsWelcomeRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    subheaderText: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type MsTeamsWelcomeLocalizationsArgs = {
    filters?: InputMaybe<MsTeamsWelcomeFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MsTeamsWelcomeEntity = {
    __typename?: 'MsTeamsWelcomeEntity'
    attributes?: Maybe<MsTeamsWelcome>
    id?: Maybe<Scalars['ID']>
}

export type MsTeamsWelcomeEntityResponse = {
    __typename?: 'MsTeamsWelcomeEntityResponse'
    data?: Maybe<MsTeamsWelcomeEntity>
}

export type MsTeamsWelcomeEntityResponseCollection = {
    __typename?: 'MsTeamsWelcomeEntityResponseCollection'
    data: Array<MsTeamsWelcomeEntity>
    meta: ResponseCollectionMeta
}

export type MsTeamsWelcomeFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<MsTeamsWelcomeFiltersInput>>>
    bodyText?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    headerText?: InputMaybe<StringFilterInput>
    helloText?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<MsTeamsWelcomeFiltersInput>
    not?: InputMaybe<MsTeamsWelcomeFiltersInput>
    or?: InputMaybe<Array<InputMaybe<MsTeamsWelcomeFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    subheaderText?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type MsTeamsWelcomeInput = {
    bodyText?: InputMaybe<Scalars['String']>
    headerText?: InputMaybe<Scalars['String']>
    helloText?: InputMaybe<Scalars['String']>
    image?: InputMaybe<Scalars['ID']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    subheaderText?: InputMaybe<Scalars['String']>
}

export type MsTeamsWelcomeRelationResponseCollection = {
    __typename?: 'MsTeamsWelcomeRelationResponseCollection'
    data: Array<MsTeamsWelcomeEntity>
}

export type Mutation = {
    __typename?: 'Mutation'
    /** Achievements Mutation */
    achievements: AchievementsMutation
    /** Lets user assign microsteps to challenge */
    addMicrosteps?: Maybe<Challenge>
    /** Thrive for Support Teams Mutations */
    calls: CallsMutation
    /**
     * To let user mark a challenge as completed with given challenge \`ID\`
     * User's ID will be retrieved through identity service using authentication header
     */
    completeChallenge?: Maybe<Challenge>
    /** Content Root Mutation */
    content: ContentMutation
    /** Creates a new badge with given 'BadgeInput' and return the entity with generated \`ID\` */
    createBadge?: Maybe<Badge>
    /** Creates a challenge and returns new entity with \`ID\` if requester has access rights */
    createChallenge?: Maybe<Challenge>
    createCmsCompanySubscription?: Maybe<CmsCompanySubscriptionEntityResponse>
    /** Creates a flag and returns new entity with \`ID\` if requester has access rights */
    createFlag?: Maybe<Flag>
    /** Create or Update a list of streaks with the passed microstepIds, if the microstep is not associated any user-challenge it returns `null` */
    createOrUpdateStreak?: Maybe<Array<Maybe<Streak>>>
    /** Deletes badge(s) with given \`ID\` */
    deleteBadge?: Maybe<Array<Maybe<Badge>>>
    /**
     * Deletes a flag with given \`ID\`. Returns removed entity.
     * Can be invoked only if requester has access rights
     */
    deleteFlag?: Maybe<Flag>
    /**
     * Disables a challenge with given \`ID\`. Returns deprecated entity.
     * Can be invoked only if requester has access rights
     */
    disableChallenge?: Maybe<Challenge>
    /**
     * Enables a challenge with given \`ID\`. Returns un-deprecated entity.
     * Can be invoked only if requester has access rights
     */
    enableChallenge?: Maybe<Challenge>
    /**
     * Genesys Agent Mutations
     * @deprecated Deprecated
     */
    genesysAgents: GenesysAgentMutation
    /** Genesys Customer Onboarding */
    genesysClients: GenesysClientMutation
    identity: IdentityMutation
    /**
     * To let user join a challenge with given challenge \`ID\`
     * User's ID will be retrieved through identity service using authentication header
     */
    joinChallenge?: Maybe<Challenge>
    /** Journeys Root Mutation */
    journeys: JourneyMutation
    learn?: Maybe<LearnMutation>
    /** Learn V2 Root Mutation */
    learnV2: LearnMutationV2
    /**
     * To let user leave a challenge with given challenge \`ID\`
     * User's ID will be retrieved through identity service using authentication header
     */
    leaveChallenge?: Maybe<Challenge>
    notification: NotificationMutation
    progress?: Maybe<ModuleProgress>
    /** Update Thrive Pulse data */
    pulse: ThrivePulseMutation
    /** Pulse Reporting Root Mutation */
    pulseReporting: PulseReportingMutation
    /**
     * External Pulse Root Mutation
     * @deprecated Still work but latest APIs are now in pulse, replace with pulse
     */
    pulseV2: ExternalPulseMutation
    /** Lets user remove microsteps from challnege */
    removeMicrosteps?: Maybe<Challenge>
    reset: ResetMutation
    /** Reset Root Mutation */
    resetV2: ResetMutationV2
    /** Retail Mutation */
    retail: RetailMutation
    search: SearchMutation
    /** Slack Root Mutation */
    slack: SlackMutation
    /** Social Groups Root Mutation */
    socialGroups: SocialGroupMutation
    /** Teams Root Mutation */
    teams: TeamsMutation
    today: TodayMutation
    /** Unified Challenges Root Mutation */
    unifiedChallenges: UnifiedChallengesMutation
    /** Updates an existing badge with given 'ID' and returns the updated entity */
    updateBadge?: Maybe<Badge>
    /**
     * Updates a challenge with given \`ID\` and details. Returns updated entity.
     * Can be invoked only if requester has access rights
     */
    updateChallenge?: Maybe<Challenge>
    updateCmsCompanySubscription?: Maybe<CmsCompanySubscriptionEntityResponse>
    /** Wearables Platform Root Mutation */
    wearables: WearablesPlatformMutation
}

export type MutationAddMicrostepsArgs = {
    challengeId: Scalars['ID']
    microstepIds: Array<Scalars['ID']>
}

export type MutationCompleteChallengeArgs = {
    challengeId: Scalars['ID']
}

export type MutationCreateBadgeArgs = {
    badge: BadgeInput
}

export type MutationCreateChallengeArgs = {
    challenge: ChallengeInput
}

export type MutationCreateCmsCompanySubscriptionArgs = {
    data: CmsCompanySubscriptionInput
}

export type MutationCreateFlagArgs = {
    flag: FlagInput
}

export type MutationCreateOrUpdateStreakArgs = {
    streak: StreakInput
}

export type MutationDeleteBadgeArgs = {
    badgeId: Scalars['ID']
}

export type MutationDeleteFlagArgs = {
    flagId: Scalars['ID']
}

export type MutationDisableChallengeArgs = {
    challengeId: Scalars['ID']
}

export type MutationEnableChallengeArgs = {
    challengeId: Scalars['ID']
}

export type MutationJoinChallengeArgs = {
    challengeId: Scalars['ID']
}

export type MutationLeaveChallengeArgs = {
    challengeId: Scalars['ID']
}

export type MutationProgressArgs = {
    companyId?: InputMaybe<Scalars['String']>
    courseId?: InputMaybe<Scalars['String']>
    lessonId?: InputMaybe<Scalars['String']>
    moduleId?: InputMaybe<Scalars['String']>
    progress?: InputMaybe<Scalars['Int']>
    total?: InputMaybe<Scalars['Int']>
    userId?: InputMaybe<Scalars['String']>
}

export type MutationRemoveMicrostepsArgs = {
    challengeId: Scalars['ID']
    microstepIds: Array<Scalars['ID']>
}

export type MutationUpdateBadgeArgs = {
    badge: BadgeInput
    badgeId: Scalars['ID']
}

export type MutationUpdateChallengeArgs = {
    challenge: ChallengeInput
    challengeId: Scalars['ID']
}

export type MutationUpdateCmsCompanySubscriptionArgs = {
    data: CmsCompanySubscriptionInput
    id: Scalars['ID']
}

export type MySuccessStory = {
    __typename?: 'MySuccessStory'
    /** Unique id of the content related to the story */
    contentId?: Maybe<Scalars['String']>
    /** Content type of the content related to the story */
    contentType?: Maybe<LearnContentStatusType>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    /** Url of an image for my success story */
    imageUrl?: Maybe<Scalars['String']>
    /** Defaults to private story, not shared with Thrive nor employer */
    isPrivate: Scalars['Boolean']
    /** Text of my success story */
    story: Scalars['String']
    /** Title of My Success Story */
    title: Scalars['String']
    type: SuccessStoryFeedbackType
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type MySuccessStoryInput = {
    /** Unique id of the content related to the story */
    contentId?: InputMaybe<Scalars['String']>
    /** Content type of the content related to the story */
    contentType?: InputMaybe<LearnContentStatusType>
    /** Url of an image for my success story */
    imageUrl?: InputMaybe<Scalars['String']>
    /** Defaults to private story, not shared with Thrive nor employer */
    isPrivate: Scalars['Boolean']
    /** Text of my success story */
    story: Scalars['String']
    /** Title of My Success Story */
    title: Scalars['String']
    /** Type of Success Story feedback */
    type: SuccessStoryFeedbackType
}

export type MySuccessStoryPage = {
    __typename?: 'MySuccessStoryPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<MySuccessStory>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

/** User journal related to Pulse survey question (Pulse Check In) */
export type MySurveyJournal = {
    __typename?: 'MySurveyJournal'
    /** Content of my journal */
    content: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    surveyId: Scalars['UUID']
}

export type MySurveyJournalInput = {
    /** Content of my journal */
    content: Scalars['String']
    surveyId: Scalars['UUID']
}

export type MySurveyJournalPage = {
    __typename?: 'MySurveyJournalPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<MySurveyJournal>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type NiceIntegrationMutation = {
    __typename?: 'NiceIntegrationMutation'
    /** Disables NICE integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Nice integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type NiceIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type NiceIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    tenantId: Scalars['String']
}

export type NotificationMutation = {
    __typename?: 'NotificationMutation'
    /** Adds a deviceId (OneSignal id) to a user */
    addDevice: Scalars['Boolean']
    /** Sends a notification with a custom title and message to a list of user_ids with company_ids. Notifications won't be send to user_ids not registered in the third party message providerMax 2000 users per call */
    sendCustomNotificationV2: Scalars['Boolean']
    /** Sets the notificationType activated/deactivated for the logged in user */
    setNotificationStatus: Scalars['Boolean']
}

export type NotificationMutationAddDeviceArgs = {
    deviceId: Scalars['String']
}

export type NotificationMutationSendCustomNotificationV2Args = {
    message: Scalars['String']
    notificationType: NotificationType
    title: Scalars['String']
    users: Array<UserInfoInput>
}

export type NotificationMutationSetNotificationStatusArgs = {
    active: Scalars['Boolean']
    notificationType: NotificationType
}

export type NotificationQuery = {
    __typename?: 'NotificationQuery'
    /** Gets the playerId (OneSignal id) for the logged in user */
    getDevices: Array<Scalars['String']>
    /** Gets true if the notification type is active for the logged in user */
    getNotificationStatus: Scalars['Boolean']
}

export type NotificationQueryGetNotificationStatusArgs = {
    notificationType: NotificationType
}

export enum NotificationType {
    CompanyChallengeReminder = 'COMPANY_CHALLENGE_REMINDER',
    PulseReminder = 'PULSE_REMINDER'
}

export type NotifyChallengeUserNonBlockingLaunched = {
    __typename?: 'NotifyChallengeUserNonBlockingLaunched'
    launched: Scalars['Boolean']
}

export type NotifyChallengeUserResult = {
    __typename?: 'NotifyChallengeUserResult'
    error?: Maybe<Scalars['String']>
    numberOfUsersNotified: Scalars['Int']
}

export type NotifyMutation = {
    __typename?: 'NotifyMutation'
    /** Send a notification to all users in a company about the challenge */
    notifyChallengeUsers: NotifyChallengeUserResult
    /** Send a notification to all users in a company about the challenge; doesn't block on the GQL request (use this one for large companies) */
    notifyChallengeUsersNonBlocking: NotifyChallengeUserNonBlockingLaunched
    /** Send a reminder to all users in a challenge to complete the daily goals for the challenge */
    notifyDailyGoalsChallengeUsers: NotifyChallengeUserResult
}

export type NotifyMutationNotifyChallengeUsersArgs = {
    input: ThriveChallengeNotificationInput
}

export type NotifyMutationNotifyChallengeUsersNonBlockingArgs = {
    input: ThriveChallengeNotificationInput
}

export type NotifyMutationNotifyDailyGoalsChallengeUsersArgs = {
    input: ThriveChallengeDailyGoalNotificationInput
}

export type Office365 = CalendarServiceFields & {
    __typename?: 'Office365'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type OktaIntegrationMutation = {
    __typename?: 'OktaIntegrationMutation'
    /** Disables Okta SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Okta SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type OktaIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type OktaIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpId: Scalars['String']
}

export type OptimalRange = {
    __typename?: 'OptimalRange'
    dimension: Surveys
    high: Scalars['Int']
    low: Scalars['Int']
}

export type OptionsDto = {
    __typename?: 'OptionsDTO'
    text: Scalars['String']
    value: Scalars['Int']
}

export type OracleQuery = {
    __typename?: 'OracleQuery'
    /** Ask ChatGPT to give you insights based on your daily check-in responses */
    dailyCheckInGuidance: OracleTextResponse
    /** Ask a generic question to ChatGPT */
    genericPrompt: OracleTextResponse
}

export type OracleQueryGenericPromptArgs = {
    locale: Scalars['Locale']
    prompt: Scalars['String']
}

export type OracleTextResponse = {
    __typename?: 'OracleTextResponse'
    generationComplete: Scalars['Boolean']
    message: Scalars['String']
}

export type Outlook = CalendarServiceFields & {
    __typename?: 'Outlook'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type OwnedEmailDomain = {
    __typename?: 'OwnedEmailDomain'
    company: Company
    createdAt: Scalars['Instant']
    emailDomain: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type OwnedEmailDomainInput = {
    companyId: Scalars['UUID']
    emailDomain: Scalars['String']
}

export type PageInfo = {
    __typename?: 'PageInfo'
    endCursor?: Maybe<Scalars['String']>
    hasNextPage: Scalars['Boolean']
    hasPreviousPage: Scalars['Boolean']
    startCursor?: Maybe<Scalars['String']>
}

export enum PageSortOrder {
    Asc = 'ASC',
    Desc = 'DESC'
}

export type Pagination = {
    __typename?: 'Pagination'
    page: Scalars['Int']
    pageCount: Scalars['Int']
    pageSize: Scalars['Int']
    total: Scalars['Int']
}

export type PaginationArg = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    start?: InputMaybe<Scalars['Int']>
}

export type PaginationInput = {
    page?: InputMaybe<Scalars['Long']>
    pageSize?: InputMaybe<Scalars['Int']>
    sortOrder?: InputMaybe<PageSortOrder>
}

export enum Partner {
    Azure = 'azure',
    Coda = 'coda',
    Cognito = 'cognito',
    Hermes = 'hermes',
    Okta = 'okta',
    Thriveglobal = 'thriveglobal',
    Thriveglobalidentity = 'thriveglobalidentity',
    /** @deprecated Use thriveglobalidentity, replace with thriveglobalidentity */
    Thriveglobalkeycloak = 'thriveglobalkeycloak',
    Thriveglobalweb = 'thriveglobalweb',
    Webex = 'webex',
    Westvirginiaunivercity = 'westvirginiaunivercity',
    Zoom = 'zoom'
}

export type PartnerEnableResponse = {
    __typename?: 'PartnerEnableResponse'
    enabled: Scalars['Boolean']
    token?: Maybe<Scalars['String']>
}

export type PartnerIntegrationMutation = {
    __typename?: 'PartnerIntegrationMutation'
    /** Disables Partner integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new partner integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. If enabled or created, a response will contain a token for the partner. */
    enable: PartnerEnableResponse
}

export type PartnerIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type PartnerIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
}

export type PartnerQuery = {
    __typename?: 'PartnerQuery'
    sign: TokenResponse
}

export type PartnerQuerySignArgs = {
    companyName: Scalars['String']
}

export type PartnerUserInputGraphQlInput = {
    companyId?: InputMaybe<Scalars['UUID']>
    scope: Scalars['String']
    userId: Scalars['String']
}

export type Persona = {
    __typename?: 'Persona'
    Type?: Maybe<Scalars['String']>
    article?: Maybe<CmsArticleEntityResponse>
    createdAt?: Maybe<Scalars['DateTime']>
    microsteps?: Maybe<CmsMicrostepRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type PersonaMicrostepsArgs = {
    filters?: InputMaybe<CmsMicrostepFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PersonaEntity = {
    __typename?: 'PersonaEntity'
    attributes?: Maybe<Persona>
    id?: Maybe<Scalars['ID']>
}

export type PersonaEntityResponse = {
    __typename?: 'PersonaEntityResponse'
    data?: Maybe<PersonaEntity>
}

export type PersonaEntityResponseCollection = {
    __typename?: 'PersonaEntityResponseCollection'
    data: Array<PersonaEntity>
    meta: ResponseCollectionMeta
}

export type PersonaFiltersInput = {
    Type?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<PersonaFiltersInput>>>
    article?: InputMaybe<CmsArticleFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    microsteps?: InputMaybe<CmsMicrostepFiltersInput>
    not?: InputMaybe<PersonaFiltersInput>
    or?: InputMaybe<Array<InputMaybe<PersonaFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type PersonaInput = {
    Type?: InputMaybe<Scalars['String']>
    article?: InputMaybe<Scalars['ID']>
    microsteps?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type PersonaRelationResponseCollection = {
    __typename?: 'PersonaRelationResponseCollection'
    data: Array<PersonaEntity>
}

export type PersonalizedImageConnection = {
    __typename?: 'PersonalizedImageConnection'
    edges: Array<PersonalizedImageEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<Image>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<Image>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type PersonalizedImageEdge = {
    __typename?: 'PersonalizedImageEdge'
    cursor: Scalars['String']
    node: Image
}

export type PersonalizedImageQuery = {
    __typename?: 'PersonalizedImageQuery'
    get: PersonalizedImageConnection
}

export type PersonalizedImageQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedImagesMutation = {
    __typename?: 'PersonalizedImagesMutation'
    delete: Scalars['Boolean']
}

export type PersonalizedImagesMutationDeleteArgs = {
    imageId: Scalars['UUID']
}

export type PersonalizedQuote = Frame & {
    __typename?: 'PersonalizedQuote'
    _: Scalars['Boolean']
    author: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    text: Scalars['String']
    updatedAt: Scalars['Instant']
    user: ResetApiUser
}

export type PersonalizedQuoteConnection = {
    __typename?: 'PersonalizedQuoteConnection'
    edges: Array<PersonalizedQuoteEdge>
    nodes: Array<PersonalizedQuote>
    pageInfo: PageInfo
}

export type PersonalizedQuoteEdge = {
    __typename?: 'PersonalizedQuoteEdge'
    cursor: Scalars['String']
    node: PersonalizedQuote
}

export type PersonalizedQuoteInput = {
    author: Scalars['String']
    backgroundColor?: InputMaybe<Scalars['Color']>
    text: Scalars['String']
    textColor?: InputMaybe<Scalars['Color']>
}

export type PersonalizedQuoteQuery = {
    __typename?: 'PersonalizedQuoteQuery'
    get: PersonalizedQuoteConnection
}

export type PersonalizedQuoteQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedQuotesMutation = {
    __typename?: 'PersonalizedQuotesMutation'
    create: PersonalizedQuote
    delete: Scalars['Boolean']
    update?: Maybe<PersonalizedQuote>
}

export type PersonalizedQuotesMutationCreateArgs = {
    quote: PersonalizedQuoteInput
}

export type PersonalizedQuotesMutationDeleteArgs = {
    quoteId: Scalars['UUID']
}

export type PersonalizedQuotesMutationUpdateArgs = {
    quote: PersonalizedQuoteInput
    quoteId: Scalars['UUID']
}

export type PersonalizedReset = Reset & {
    __typename?: 'PersonalizedReset'
    _: Scalars['Boolean']
    audio: Audio
    createdAt: Scalars['Instant']
    durationInSeconds: Scalars['Int']
    frames: Array<Frame>
    id: Scalars['UUID']
    name: Scalars['String']
    shared: Array<SharedPersonalizedReset>
    updatedAt: Scalars['Instant']
    user: ResetApiUser
}

export type PersonalizedResetInput = {
    audioId: Scalars['UUID']
    frames: Array<FrameInputIdInput>
    name: Scalars['String']
}

export type PersonalizedResetPage = {
    __typename?: 'PersonalizedResetPage'
    items: Array<PersonalizedReset>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type PersonalizedResetQuery = {
    __typename?: 'PersonalizedResetQuery'
    get: PersonalizedResetPage
    getByIds: Array<PersonalizedReset>
}

export type PersonalizedResetQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedResetQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
}

export type PersonalizedResetsMutation = {
    __typename?: 'PersonalizedResetsMutation'
    create: PersonalizedReset
    delete: Scalars['Boolean']
    share?: Maybe<SharedPersonalizedReset>
    unshare: Scalars['Boolean']
    update?: Maybe<PersonalizedReset>
}

export type PersonalizedResetsMutationCreateArgs = {
    reset: PersonalizedResetInput
}

export type PersonalizedResetsMutationDeleteArgs = {
    resetId: Scalars['UUID']
}

export type PersonalizedResetsMutationShareArgs = {
    resetId: Scalars['UUID']
}

export type PersonalizedResetsMutationUnshareArgs = {
    sharedId: Scalars['UUID']
}

export type PersonalizedResetsMutationUpdateArgs = {
    reset: PersonalizedResetInput
    resetId: Scalars['UUID']
}

export enum PlantActionError {
    ErrorProcessingPlantAction = 'ERROR_PROCESSING_PLANT_ACTION',
    PlantAlreadyPlanted = 'PLANT_ALREADY_PLANTED',
    PlantGrowthNotComplete = 'PLANT_GROWTH_NOT_COMPLETE',
    PlantNotFoundForUser = 'PLANT_NOT_FOUND_FOR_USER'
}

export type PlantActionErrorResult = {
    __typename?: 'PlantActionErrorResult'
    error: PlantActionError
}

export type PlantActionResponse = PlantActionErrorResult | PlantActionResult

export type PlantActionResult = {
    __typename?: 'PlantActionResult'
    userGardenId?: Maybe<Scalars['UUID']>
    userPlants?: Maybe<Array<UserPlantInfo>>
}

export type PlantInfo = {
    __typename?: 'PlantInfo'
    /** Plant color for layer 1 */
    colorLayer1?: Maybe<Scalars['String']>
    /** Plant color for layer 2 */
    colorLayer2?: Maybe<Scalars['String']>
    /** Plant color for layer 3 */
    colorLayer3?: Maybe<Scalars['String']>
    /** Plant color for layer 4 */
    colorLayer4?: Maybe<Scalars['String']>
    /** The product upc associated to the plant */
    productUpc: Scalars['String']
}

export type PlantsLeaderboard = {
    __typename?: 'PlantsLeaderboard'
    rankedList: Array<PlantsRanked>
    /** The requester entity ranked position */
    requesterRank: Scalars['Int']
    totalEntries: Scalars['Int']
}

export type PlantsRanked = {
    __typename?: 'PlantsRanked'
    /** The entity display name */
    displayName: Scalars['String']
    /** The entity id */
    entityId: Scalars['UUID']
    /** The rank position on the leaderboard */
    rank: Scalars['Int']
    /** Total plants number */
    totalPlants: Scalars['Int']
}

export type PlatformAgent = {
    __typename?: 'PlatformAgent'
    email?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    id: Scalars['String']
    isActive: Scalars['Boolean']
    lastName?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
}

export type PlatformGroup = {
    __typename?: 'PlatformGroup'
    groupType: GroupType
    groups: Array<AgentGroup>
}

export enum PlatformType {
    Genesys = 'GENESYS',
    Nice = 'NICE'
}

export type Podcast = {
    __typename?: 'Podcast'
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    numberOfEpisodes: Scalars['Int']
    numberOfSeasons: Scalars['Int']
    /** @deprecated Not supported, replace with LearnContentProgress */
    podcastProgress: PodcastProgress
    /** Podcast progress for user, based on userId from context. Checks progress on seasons. */
    progress?: Maybe<LearnContentProgress>
    seasons: Array<PodcastSeason>
    summary: Scalars['String']
    title: Scalars['String']
}

/** Progress input for Podcast Episode */
export type PodcastContentStatusInput = {
    /** podcast episode id */
    contentId: Scalars['String']
    /** podcast episode progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of podcast episode in seconds */
    totalInSeconds: Scalars['Int']
}

export type PodcastEpisode = {
    __typename?: 'PodcastEpisode'
    audioUrl?: Maybe<Scalars['String']>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    guests: Array<Contributor>
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    likes: Likes
    number: Scalars['Int']
    podcast?: Maybe<Podcast>
    podcastEpisodeProgress?: Maybe<PodcastEpisodeProgress>
    podcastId?: Maybe<Scalars['ID']>
    season?: Maybe<PodcastSeason>
    seasonId?: Maybe<Scalars['ID']>
    summary: Scalars['String']
    title: Scalars['String']
}

export type PodcastEpisodePage = {
    __typename?: 'PodcastEpisodePage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<PodcastEpisode>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type PodcastEpisodeParent = {
    __typename?: 'PodcastEpisodeParent'
    podcastID: Scalars['ID']
    seasonID: Scalars['ID']
}

export type PodcastEpisodeProgress = {
    __typename?: 'PodcastEpisodeProgress'
    durationInSeconds: Scalars['Int']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type PodcastPage = {
    __typename?: 'PodcastPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Podcast>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type PodcastProgress = {
    __typename?: 'PodcastProgress'
    durationInSeconds: Scalars['Int']
    inProgress: Scalars['Boolean']
    isPreviouslyCompleted: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfEpisodesInProgress: Scalars['Int']
    numberOfEpisodesPreviouslyCompleted: Scalars['Int']
    numberOfPristineEpisodes: Scalars['Int']
    numberOfPristineSeasons: Scalars['Int']
    numberOfSeasonsInProgress: Scalars['Int']
    numberOfSeasonsPreviouslyCompleted: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type PodcastSeason = {
    __typename?: 'PodcastSeason'
    episodes: Array<PodcastEpisode>
    id: Scalars['ID']
    number: Scalars['Int']
    numberOfEpisodes: Scalars['Int']
    podcast?: Maybe<Podcast>
    /** @deprecated Not supported, replace with LearnContentProgress */
    podcastSeasonProgress?: Maybe<PodcastSeasonProgress>
    /** Podcast season progress for user, based on userId from context. Checks progress on podcast episodes. */
    progress?: Maybe<LearnContentProgress>
    summary: Scalars['String']
    title: Scalars['String']
}

export type PodcastSeasonProgress = {
    __typename?: 'PodcastSeasonProgress'
    durationInSeconds: Scalars['Int']
    inProgress: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfEpisodesInProgress: Scalars['Int']
    numberOfEpisodesPreviouslyCompleted: Scalars['Int']
    numberOfPristineEpisodes: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    statusList: Array<Maybe<LearnContentStatus>>
    updatedAt: Scalars['Instant']
}

export type Post = {
    __typename?: 'Post'
    /** The id of this challenge */
    challengeId?: Maybe<Scalars['UUID']>
    createdAt?: Maybe<Scalars['Instant']>
    createdBy?: Maybe<Scalars['UUID']>
    createdBySocialGroup?: Maybe<Scalars['String']>
    /** The id of the entity that originated the post */
    entityId?: Maybe<Scalars['String']>
    /** The name of the entity that originated the post */
    entityName?: Maybe<Scalars['String']>
    /** The thumbnail of the entity that originated the post */
    entityThumbnail?: Maybe<Scalars['String']>
    /** The post of the entity that originated the post */
    entityType?: Maybe<Scalars['String']>
    /** The id of this post */
    id: Scalars['UUID']
    lastUpdatedBy?: Maybe<Scalars['UUID']>
    postType: PostType
    profilePhotoUrl?: Maybe<Scalars['String']>
    reactions?: Maybe<PostReactions>
    /** The id of this social group */
    socialGroupId: Scalars['UUID']
    updatedAt?: Maybe<Scalars['Instant']>
    userDisplayName?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['UUID']>
}

export type PostCreateInput = {
    /** Challenge Id of the post */
    challengeId?: InputMaybe<Scalars['UUID']>
    /** The date when this post was created */
    createdAt?: InputMaybe<Scalars['String']>
    createdBySocialGroupId?: InputMaybe<Scalars['UUID']>
    /** Entity id related to the post */
    entityId?: InputMaybe<Scalars['String']>
    entityName?: InputMaybe<Scalars['String']>
    /** Entity type related to the post */
    entityType?: InputMaybe<Scalars['String']>
    /** The type of this post */
    postType: Scalars['String']
    /** The social group Id */
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type PostOperationSucceeded = {
    __typename?: 'PostOperationSucceeded'
    /** The ID of the post for which the operation succeeded */
    postId: Scalars['UUID']
}

export type PostReaction = {
    __typename?: 'PostReaction'
    /** The id of this reaction */
    id: Scalars['UUID']
    /** The id of this post */
    postId: Scalars['UUID']
    /** The type of this reaction */
    postReactionTypeId: Scalars['String']
    /** The user display name who created the reaction */
    userDisplayName?: Maybe<Scalars['String']>
    /** The id of the user who created the reaction */
    userId: Scalars['UUID']
}

export type PostReactionCreationSucceeded = {
    __typename?: 'PostReactionCreationSucceeded'
    /** The post Item ID for  which the operation succeeded */
    postReactionId: Scalars['UUID']
}

export type PostReactionInput = {
    /** The id of this post */
    postId: Scalars['UUID']
    /** The type of this reaction */
    postTypeId: Scalars['String']
}

export type PostReactions = {
    __typename?: 'PostReactions'
    celebrates?: Maybe<Array<PostReaction>>
    likes?: Maybe<Array<PostReaction>>
    loves?: Maybe<Array<PostReaction>>
}

export type PostType = {
    __typename?: 'PostType'
    language: Scalars['String']
    message: Scalars['String']
    postTypeId: Scalars['String']
    region: Scalars['String']
}

export type PrivacyPage = {
    __typename?: 'PrivacyPage'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<PrivacyPageRelationResponseCollection>
    privacyPageComponents: Array<
        Maybe<PrivacyPagePrivacyPageComponentsDynamicZone>
    >
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type PrivacyPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type PrivacyPageEntity = {
    __typename?: 'PrivacyPageEntity'
    attributes?: Maybe<PrivacyPage>
    id?: Maybe<Scalars['ID']>
}

export type PrivacyPageEntityResponse = {
    __typename?: 'PrivacyPageEntityResponse'
    data?: Maybe<PrivacyPageEntity>
}

export type PrivacyPageInput = {
    privacyPageComponents?: InputMaybe<
        Array<Scalars['PrivacyPagePrivacyPageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type PrivacyPagePrivacyPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type PrivacyPageRelationResponseCollection = {
    __typename?: 'PrivacyPageRelationResponseCollection'
    data: Array<PrivacyPageEntity>
}

export type ProductInfo = {
    __typename?: 'ProductInfo'
    /** The product cost */
    cost?: Maybe<Scalars['Int']>
    createdAt?: Maybe<Scalars['Instant']>
    /** The currency code */
    currencyCode?: Maybe<Scalars['String']>
    /** The description of the product */
    description?: Maybe<Scalars['String']>
    /** For plants, number of steps to fully grow */
    plantWateringSteps?: Maybe<Scalars['Int']>
    /** The product type */
    productType?: Maybe<Scalars['String']>
    /** The title of the product */
    title?: Maybe<Scalars['String']>
    /** The product id */
    upc?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type ProductPage = {
    __typename?: 'ProductPage'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<ProductPageRelationResponseCollection>
    productPageComponents?: Maybe<
        Array<Maybe<ProductPageProductPageComponentsDynamicZone>>
    >
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProductPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type ProductPageEntity = {
    __typename?: 'ProductPageEntity'
    attributes?: Maybe<ProductPage>
    id?: Maybe<Scalars['ID']>
}

export type ProductPageEntityResponse = {
    __typename?: 'ProductPageEntityResponse'
    data?: Maybe<ProductPageEntity>
}

export type ProductPageInput = {
    productPageComponents?: InputMaybe<
        Array<Scalars['ProductPageProductPageComponentsDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type ProductPageProductPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColOverview
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | Error

export type ProductPageRelationResponseCollection = {
    __typename?: 'ProductPageRelationResponseCollection'
    data: Array<ProductPageEntity>
}

export enum PublicationState {
    Live = 'LIVE',
    Preview = 'PREVIEW'
}

export type PulseCompanyDto = {
    __typename?: 'PulseCompanyDTO'
    age?: Maybe<Scalars['Int']>
    /** @deprecated No longer supported. Will be removed in a future release */
    cadenceOption: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    matured?: Maybe<Scalars['Boolean']>
    totalEmployees: Scalars['Int']
}

export type PulseDimension = {
    __typename?: 'PulseDimension'
    name: Scalars['String']
    type: Scalars['String']
}

export type PulseDispatchEventResponse = {
    __typename?: 'PulseDispatchEventResponse'
    channel: Channel
    cleared: Scalars['Boolean']
    eligibility: SurveyEligibility
    expiresAt: Scalars['Instant']
    id: Scalars['UUID']
    pulseUserId: Scalars['UUID']
    responded: Scalars['Boolean']
    responseValue?: Maybe<Scalars['Int']>
    survey?: Maybe<PulseSurveyQuestionDto>
    surveyIssueDate: Scalars['ISODate']
}

export type PulseOnDemand =
    | PulseDispatchEventResponse
    | PulseOnDemandErrorResponse

export type PulseOnDemandErrorResponse = {
    __typename?: 'PulseOnDemandErrorResponse'
    message: Scalars['String']
}

export type PulseReportingAdminMutation = {
    __typename?: 'PulseReportingAdminMutation'
    /** Manually Trigger Refresh of Engagement Reports */
    refreshEngagementMetrics: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic App Usage */
    refreshHistoricAppUsage: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Daily Check In Benchmarks */
    refreshHistoricDailyCheckInBenchmarks: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Daily Check In Scores */
    refreshHistoricDailyCheckInScores: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Engagement Levels */
    refreshHistoricEngagementLevels: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Daily Check In Benchmarks */
    refreshRecentDailyCheckInBenchmarks: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Daily Check In Scores */
    refreshRecentDailyCheckInScores: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Engagement Levels */
    refreshRecentEngagementLevels: RefreshEngagementReportResponse
}

export type PulseReportingEngagementReportQuery = {
    __typename?: 'PulseReportingEngagementReportQuery'
    /** Engagement Reports */
    engagementReport?: Maybe<EngagementReportResponse>
}

export type PulseReportingEngagementReportQueryEngagementReportArgs = {
    companyId: Scalars['UUID']
    period: ReportPeriod
}

export type PulseReportingMutation = {
    __typename?: 'PulseReportingMutation'
    /** Manually Trigger Reports */
    admin: PulseReportingAdminMutation
}

export type PulseReportingQuery = {
    __typename?: 'PulseReportingQuery'
    dailyCheckInScores: DailyCheckInScoresQuery
    /** Engagement metrics for a company */
    engagement: PulseReportingEngagementReportQuery
    engagementLevels: EngagementLevelsQuery
    usage: UsageQuery
}

export type PulseStreakDto = {
    __typename?: 'PulseStreakDTO'
    current: Scalars['Int']
    max: Scalars['Int']
}

export type PulseSurvey = {
    __typename?: 'PulseSurvey'
    active: Scalars['Boolean']
    createdAt?: Maybe<Scalars['DateTime']>
    dimension?: Maybe<Enum_Pulsesurvey_Dimension>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<PulseSurveyRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    question?: Maybe<Scalars['String']>
    surveyChoice?: Maybe<Array<Maybe<ComponentSurveyChoiceChoices>>>
    surveyId: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type PulseSurveyLocalizationsArgs = {
    filters?: InputMaybe<PulseSurveyFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PulseSurveySurveyChoiceArgs = {
    filters?: InputMaybe<ComponentSurveyChoiceChoicesFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PulseSurveyEntity = {
    __typename?: 'PulseSurveyEntity'
    attributes?: Maybe<PulseSurvey>
    id?: Maybe<Scalars['ID']>
}

export type PulseSurveyEntityResponse = {
    __typename?: 'PulseSurveyEntityResponse'
    data?: Maybe<PulseSurveyEntity>
}

export type PulseSurveyEntityResponseCollection = {
    __typename?: 'PulseSurveyEntityResponseCollection'
    data: Array<PulseSurveyEntity>
    meta: ResponseCollectionMeta
}

export type PulseSurveyFiltersInput = {
    active?: InputMaybe<BooleanFilterInput>
    and?: InputMaybe<Array<InputMaybe<PulseSurveyFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    dimension?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<PulseSurveyFiltersInput>
    not?: InputMaybe<PulseSurveyFiltersInput>
    or?: InputMaybe<Array<InputMaybe<PulseSurveyFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    question?: InputMaybe<StringFilterInput>
    surveyChoice?: InputMaybe<ComponentSurveyChoiceChoicesFiltersInput>
    surveyId?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type PulseSurveyInput = {
    active?: InputMaybe<Scalars['Boolean']>
    dimension?: InputMaybe<Enum_Pulsesurvey_Dimension>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    question?: InputMaybe<Scalars['String']>
    surveyChoice?: InputMaybe<
        Array<InputMaybe<ComponentSurveyChoiceChoicesInput>>
    >
    surveyId?: InputMaybe<Scalars['String']>
}

export type PulseSurveyQuestionDto = {
    __typename?: 'PulseSurveyQuestionDTO'
    displayName: Scalars['String']
    id: Scalars['UUID']
    label: Scalars['String']
    locale: Scalars['String']
    name: Scalars['String']
    question: QuestionDto
}

export type PulseSurveyRelationResponseCollection = {
    __typename?: 'PulseSurveyRelationResponseCollection'
    data: Array<PulseSurveyEntity>
}

export enum PurchaseError {
    CantPurchaseCommunityPlants = 'CANT_PURCHASE_COMMUNITY_PLANTS',
    CantPurchaseNewPlants = 'CANT_PURCHASE_NEW_PLANTS',
    ErrorProcessingPurchase = 'ERROR_PROCESSING_PURCHASE',
    InsufficientFoundsForThatCurrency = 'INSUFFICIENT_FOUNDS_FOR_THAT_CURRENCY',
    ProductDoesntExist = 'PRODUCT_DOESNT_EXIST'
}

export type PurchaseErrorResult = {
    __typename?: 'PurchaseErrorResult'
    error: PurchaseError
}

export type PurchaseResponse = PurchaseErrorResult | PurchaseResult

export type PurchaseResult = {
    __typename?: 'PurchaseResult'
    userBalance?: Maybe<Array<BalanceInfo>>
    userInventory: Array<UserInventoryInfo>
}

export type Query = {
    __typename?: 'Query'
    _?: Maybe<Scalars['Boolean']>
    /** Achievements Query */
    achievements: AchievementsQuery
    /** Provides all the available categories */
    allCategories?: Maybe<Array<Maybe<Category>>>
    /** Searches a badge with provided \`ID\` */
    badge?: Maybe<Badge>
    /** Provides all the available badges if requester has access rights */
    badges?: Maybe<Array<Maybe<Badge>>>
    /** Thrive for Support Teams Queries */
    calls: CallsQuery
    /** Provides categories with associated id's */
    categories?: Maybe<Array<Maybe<Category>>>
    /** Searches a category with provided \`ID\` */
    category?: Maybe<Category>
    /** Searches a challenge with provided \`ID\` */
    challenge?: Maybe<Challenge>
    /**
     * Provides all the available challenges associated with requestor's User and Company
     * which will be retrieved through identity service using authentication header
     */
    challenges?: Maybe<Array<Maybe<Challenge>>>
    cmsCompanySubscription?: Maybe<CmsCompanySubscriptionEntityResponse>
    cmsCompanySubscriptions?: Maybe<CmsCompanySubscriptionEntityResponseCollection>
    content: ContentQuery
    contentExperience: ContentExperienceQuery
    course?: Maybe<Course>
    courses?: Maybe<Array<Maybe<Course>>>
    experts?: Maybe<Array<Maybe<Expert>>>
    /** Searches a flag with provided \`ID\` */
    flag?: Maybe<Flag>
    /** Provides all flags for provided company \`ID\` which requester has access rights */
    flags?: Maybe<Array<Maybe<Flag>>>
    /** Genesys Analytics Queries */
    genesysAnalytics: GenesysAnalyticsQuery
    /**
     * Genesys Client Queries
     * @deprecated Moved to calls queries
     */
    genesysClients: GenesysClientQuery
    identity: IdentityQuery
    /** Journeys Root Query */
    journeys: JourneyQuery
    learn?: Maybe<LearnQuery>
    /** Learn Root Query - Version 2 */
    learnV2: LearnQueryV2
    /** Provides a microstep with given \`ID\` through Hermes API */
    microstep?: Maybe<Microstep>
    notification: NotificationQuery
    /** Access to Thrive Pulse data */
    pulse: ThrivePulseQuery
    /** Pulse Reporting Root Query */
    pulseReporting: PulseReportingQuery
    /**
     * External Pulse Root Query
     * @deprecated Still work but latest APIs are now in pulse, replace with pulse
     */
    pulseV2: ExternalPulseQuery
    /** @deprecated Use Reset to fetch Quotes, not the CMS! */
    quotes?: Maybe<QuoteEntityResponseCollection>
    reset: ResetQuery
    /**
     * Reset Root Query
     * @deprecated Use reset under content.thrive
     */
    resetV2: ResetQueryV2
    /** Retail Query */
    retail: RetailQuery
    search: SearchQuery
    /** Slack Root Query */
    slack: SlackQuery
    /** Social Groups Root Query */
    socialGroups: SocialGroupQuery
    /** Provides a streak with given \`ID\` */
    streak?: Maybe<Streak>
    /** Provides a streak for provided userChallengeActivityId \`ID\` */
    streakByUserChallengeActivityId?: Maybe<Streak>
    /** Provides a list of streaks for provided challenge \`ID\` */
    streaksByChallengeId?: Maybe<Array<Maybe<Streak>>>
    /** Teams Root Query */
    teams: TeamsQuery
    today: TodayQuery
    /** Unified Challenges Root Query */
    unifiedChallenges: UnifiedChallengesQuery
    /**
     * For user to retrieve his/her participation filtered by requestor's User and Company
     * which will be retrieved through identity service using authentication header.
     * Returns \`ChallengeParticipation\` which contains participation: as an array of join
     * and leave date pairs ordered by join date ascending. And microsteps checkins within the
     * participation timespan.
     */
    userChallenges?: Maybe<Array<Maybe<ChallengeParticipation>>>
    /** Wearables Platform Root Query */
    wearables: WearablesPlatformQuery
}

export type QueryBadgeArgs = {
    id: Scalars['ID']
}

export type QueryCategoriesArgs = {
    categoryIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryCategoryArgs = {
    id: Scalars['ID']
}

export type QueryChallengeArgs = {
    id: Scalars['ID']
}

export type QueryCmsCompanySubscriptionArgs = {
    id?: InputMaybe<Scalars['ID']>
}

export type QueryCmsCompanySubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryCourseArgs = {
    id: Scalars['String']
}

export type QueryCoursesArgs = {
    companyId?: InputMaybe<Scalars['String']>
    featuredOnly?: InputMaybe<Scalars['Boolean']>
    limit?: InputMaybe<Scalars['Int']>
}

export type QueryExpertsArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type QueryFlagArgs = {
    id: Scalars['ID']
}

export type QueryFlagsArgs = {
    companyId?: InputMaybe<Scalars['ID']>
}

export type QueryMicrostepArgs = {
    id: Scalars['ID']
}

export type QueryPulseV2Args = {
    companyId?: InputMaybe<Scalars['UUID']>
}

export type QueryQuotesArgs = {
    filters?: InputMaybe<QuoteFiltersInput>
    locale?: InputMaybe<Scalars['I18NLocaleCode']>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryStreakArgs = {
    id: Scalars['ID']
}

export type QueryStreakByUserChallengeActivityIdArgs = {
    userChallengeActivityId: Scalars['ID']
}

export type QueryStreaksByChallengeIdArgs = {
    challengeId: Scalars['ID']
}

export type QueryUserChallengesArgs = {
    filter?: InputMaybe<UserChallengeFilter>
}

export type QuestionDto = {
    __typename?: 'QuestionDTO'
    options: Array<OptionsDto>
    text: Scalars['String']
}

export type Quote = {
    __typename?: 'Quote'
    author: Scalars['String']
    backgroundColor?: Maybe<Scalars['String']>
    backgroundImage?: Maybe<UploadFileEntityResponse>
    body: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    date: Scalars['Date']
    imageWithText?: Maybe<UploadFileEntityResponse>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<QuoteRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    pulseDimensions?: Maybe<CmsPulseDimensionRelationResponseCollection>
    textColor?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type QuoteLocalizationsArgs = {
    filters?: InputMaybe<QuoteFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QuotePulseDimensionsArgs = {
    filters?: InputMaybe<CmsPulseDimensionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QuoteEntity = {
    __typename?: 'QuoteEntity'
    attributes?: Maybe<Quote>
    id?: Maybe<Scalars['ID']>
}

export type QuoteEntityResponse = {
    __typename?: 'QuoteEntityResponse'
    data?: Maybe<QuoteEntity>
}

export type QuoteEntityResponseCollection = {
    __typename?: 'QuoteEntityResponseCollection'
    data: Array<QuoteEntity>
    meta: ResponseCollectionMeta
}

export type QuoteFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<QuoteFiltersInput>>>
    author?: InputMaybe<StringFilterInput>
    backgroundColor?: InputMaybe<StringFilterInput>
    body?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    date?: InputMaybe<DateFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<QuoteFiltersInput>
    not?: InputMaybe<QuoteFiltersInput>
    or?: InputMaybe<Array<InputMaybe<QuoteFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    pulseDimensions?: InputMaybe<CmsPulseDimensionFiltersInput>
    textColor?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type QuoteInput = {
    author?: InputMaybe<Scalars['String']>
    backgroundColor?: InputMaybe<Scalars['String']>
    backgroundImage?: InputMaybe<Scalars['ID']>
    body?: InputMaybe<Scalars['String']>
    date?: InputMaybe<Scalars['Date']>
    imageWithText?: InputMaybe<Scalars['ID']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    pulseDimensions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    textColor?: InputMaybe<Scalars['String']>
}

export type QuoteQuery = {
    __typename?: 'QuoteQuery'
    personalized: PersonalizedQuoteQuery
    thrive: ThriveQuoteQuery
}

export type QuoteRelationResponseCollection = {
    __typename?: 'QuoteRelationResponseCollection'
    data: Array<QuoteEntity>
}

export type QuotesMutation = {
    __typename?: 'QuotesMutation'
    personalized: PersonalizedQuotesMutation
}

export enum Realm {
    ThriveGlobal = 'ThriveGlobal',
    Master = 'master'
}

export type RecentDailyCheckInScores = {
    __typename?: 'RecentDailyCheckInScores'
    aboveBenchmark: Scalars['Boolean']
    averageScore: Scalars['Float']
    averageScorePercentage: Scalars['Float']
    benchmarkScore: Scalars['Float']
    benchmarkScorePercentage: Scalars['Float']
}

export type RecentDailyCheckInScoresResponse = {
    __typename?: 'RecentDailyCheckInScoresResponse'
    belonging?: Maybe<RecentDailyCheckInScores>
    effectiveness?: Maybe<RecentDailyCheckInScores>
    employeeCount: Scalars['Int']
    energy?: Maybe<RecentDailyCheckInScores>
    enthusiasm?: Maybe<RecentDailyCheckInScores>
    productivity?: Maybe<RecentDailyCheckInScores>
    responseCount: Scalars['Int']
    retention?: Maybe<RecentDailyCheckInScores>
}

export type RecentEngagementLevelResponse = {
    __typename?: 'RecentEngagementLevelResponse'
    atRisk: RecentEngagementLevelStats
    employeeCount: Scalars['Int']
    highlyEngaged: RecentEngagementLevelStats
    moderatelyEngaged: RecentEngagementLevelStats
}

export type RecentEngagementLevelStats = {
    __typename?: 'RecentEngagementLevelStats'
    changeInPercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    percentage: Scalars['Float']
}

export type RecommendationsQuery = {
    __typename?: 'RecommendationsQuery'
    /** Retrieve actionable Pulse recommendations for a company based on employee responses for a specified set of dimensions */
    byRangeAndDimensions: Array<RecommendedContent>
}

export type RecommendationsQueryByRangeAndDimensionsArgs = {
    dimensions: Array<Surveys>
    from: Scalars['String']
    to: Scalars['String']
}

export type RecommendedContent = {
    __typename?: 'RecommendedContent'
    URL: Scalars['String']
    contentType: SupportedRecommendedContentType
    description: Scalars['String']
    difficulty: ContentDifficulty
    dimension: Surveys
    impact: ContentImpact
    reason: Scalars['String']
    riskProfile: RiskProfile
}

export type RecommendedContentByPulseDimension = {
    __typename?: 'RecommendedContentByPulseDimension'
    articles: Array<Maybe<ArticleV2>>
    courseModules: Array<ModuleV2>
    microsteps: Array<GraphQlMicrostep>
    quotes: Array<Maybe<LearnQuote>>
    resets: Array<ThriveReset>
    roleModels: Array<Maybe<ArticleV2>>
}

export type RecommendedContentDto = {
    __typename?: 'RecommendedContentDTO'
    article?: Maybe<ArticleDto>
    contentType?: Maybe<SupportedRecommendedContentType>
    reset?: Maybe<ResetDto>
}

export type RecommendedContentItem = {
    __typename?: 'RecommendedContentItem'
    recommendedArticle?: Maybe<ArticleV2>
    recommendedContentID: Scalars['ID']
    recommendedContentType: RecommendedContentType
    recommendedCourseModule?: Maybe<ModuleV2>
    recommendedMicrostep?: Maybe<GraphQlMicrostep>
    recommendedQuote?: Maybe<LearnQuote>
    recommendedReset?: Maybe<ThriveReset>
    recommendedRoleModel?: Maybe<ArticleV2>
}

export enum RecommendedContentType {
    Article = 'Article',
    CourseModule = 'CourseModule',
    Microstep = 'Microstep',
    Quote = 'Quote',
    Reset = 'Reset',
    RoleModel = 'RoleModel'
}

export type RefreshEngagementReportResponse = {
    __typename?: 'RefreshEngagementReportResponse'
    recordsUpdated: Scalars['Int']
}

export type RemoveChallengeResult = RemoveErrorMessage | RemoveSuccess

export type RemoveErrorMessage = {
    __typename?: 'RemoveErrorMessage'
    message: Scalars['String']
}

export type RemoveSuccess = {
    __typename?: 'RemoveSuccess'
    message: Scalars['String']
}

export enum ReportInterval {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Ungrouped = 'UNGROUPED',
    Weekly = 'WEEKLY'
}

export enum ReportPeriod {
    OneHundredEighty = 'ONE_HUNDRED_EIGHTY',
    Sixty = 'SIXTY',
    Thirty = 'THIRTY'
}

export type ReportingCoordinates = {
    __typename?: 'ReportingCoordinates'
    x: Scalars['String']
    y: Scalars['Float']
}

export enum ReportingGranularity {
    Day = 'DAY',
    Hour = 'HOUR',
    Week = 'WEEK'
}

export type ReportingQuery = {
    __typename?: 'ReportingQuery'
    /** Returns agent analytics */
    getAgentAnalytics: AgentAnalyticsResponse
    /** Returns a high-level overview of each agent's engagement with Thrive Resets */
    getAgentEngagement?: Maybe<AgentEngagementReport>
    /** Returns the percentage of agents that have received a Thrive Reset */
    getAgentReach?: Maybe<SingleMetricResult>
    /** Returns agent reset completion rate */
    getAgentResetCompletionRate: SingleMetricResult
    /** Returns resets accepted */
    getAgentResetsAccepted: SingleMetricResult
    /** Returns resets received */
    getAgentResetsReceived: SingleMetricResult
    /** Returns agent stat for total interactions */
    getAgentTotalInteractions: SingleMetricResult
    /** Get the percentage of Thrive Resets that were viewed for longer than 50 seconds */
    getResetCompletionRate?: Maybe<SingleMetricResult>
    /** Returns the number of interactions handled by Thrive agents */
    getTotalInteractions?: Maybe<SingleMetricResult>
}

export type ReportingQueryGetAgentAnalyticsArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentEngagementArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentReachArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetCompletionRateArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetsAcceptedArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetsReceivedArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentTotalInteractionsArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCompletionRateArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetTotalInteractionsArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type Reset = {
    _: Scalars['Boolean']
}

export type ResetApiUser = {
    __typename?: 'ResetApiUser'
    companyId?: Maybe<CompanyId>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    partner: Partner
    partnerUserId: Scalars['PartnerUserId']
    subscriptionId?: Maybe<SubscriptionId>
    updatedAt: Scalars['Instant']
}

export type ResetAudioMutationV2 = WithBookmarkMutation &
    WithLikeMutation & {
        __typename?: 'ResetAudioMutationV2'
        bookmark: BookmarkMutation
        like: LikeMutation
    }

export type ResetAudioQueryV2 = WithBookmarkQuery &
    WithLikeQuery & {
        __typename?: 'ResetAudioQueryV2'
        bookmark: BookmarkQuery
        /** Get reset audio */
        getAudios: ThriveResetAudioPageV2
        like: LikeQuery
    }

export type ResetAudioQueryV2GetAudiosArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ResetDto = {
    __typename?: 'ResetDTO'
    closedCaption?: Maybe<Scalars['String']>
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    iframeUrlLandscape?: Maybe<Scalars['String']>
    iframeUrlPortrait?: Maybe<Scalars['String']>
    manifestUrlLandscape?: Maybe<Scalars['String']>
    manifestUrlPortrait?: Maybe<Scalars['String']>
    name: Scalars['String']
    thumbnailUrlLandscape?: Maybe<Scalars['String']>
    thumbnailUrlPortrait?: Maybe<Scalars['String']>
    uuid: Scalars['String']
}

export type ResetDetail = {
    __typename?: 'ResetDetail'
    description?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    landscape?: Maybe<ResetLandscape>
    name?: Maybe<Scalars['String']>
}

export type ResetGoalDetail = {
    __typename?: 'ResetGoalDetail'
    reset: ThriveReset
    resetInfo: ResetDetail
}

export type ResetLandscape = {
    __typename?: 'ResetLandscape'
    iframe?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['String']>
}

export type ResetMessage = {
    __typename?: 'ResetMessage'
    postResetMessage: Scalars['String']
    preResetBody: Scalars['String']
    preResetHeader: Scalars['String']
    uuid: Scalars['UUID']
}

export type ResetMessageQueryV2 = {
    __typename?: 'ResetMessageQueryV2'
    random?: Maybe<ResetMessage>
}

export type ResetMutation = {
    __typename?: 'ResetMutation'
    image: ImagesMutation
    personalized: PersonalizedResetsMutation
    quote: QuotesMutation
    thrive: ThriveResetsMutation
    thriveAudio: ThriveResetAudioMutation
}

export type ResetMutationV2 = {
    __typename?: 'ResetMutationV2'
    audio: ResetAudioMutationV2
    video: ResetVideoMutationV2
}

export type ResetQuery = {
    __typename?: 'ResetQuery'
    audio: AudioQuery
    banner: BannerQuery
    calendar: CalendarQuery
    image: ImageQuery
    personalized: PersonalizedResetQuery
    quote: QuoteQuery
    thrive: ThriveResetQuery
    thriveAudio: ThriveResetAudioQuery
}

export type ResetQueryV2 = {
    __typename?: 'ResetQueryV2'
    audio: ResetAudioQueryV2
    message: ResetMessageQueryV2
    video: ResetVideoQueryV2
}

export type ResetVideoMutationV2 = WithBookmarkMutation &
    WithLikeMutation & {
        __typename?: 'ResetVideoMutationV2'
        bookmark: BookmarkMutation
        like: LikeMutation
    }

export type ResetVideoQueryV2 = WithBookmarkQuery &
    WithLikeQuery & {
        __typename?: 'ResetVideoQueryV2'
        bookmark: BookmarkQuery
        /** Get reset video */
        getVideos: ThriveResetPageV2
        like: LikeQuery
    }

export type ResetVideoQueryV2GetVideosArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ResourceTypeInput = {
    name: Scalars['String']
}

export type ResourceTypePage = {
    __typename?: 'ResourceTypePage'
    items: Array<Scalars['String']>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ResponseCollectionMeta = {
    __typename?: 'ResponseCollectionMeta'
    pagination: Pagination
}

export type RetailMutation = {
    __typename?: 'RetailMutation'
    /** Create new currency */
    createCurrency?: Maybe<CurrencyInfo>
    /** Create new product */
    createProduct?: Maybe<ProductInfo>
    /** Delete a currency */
    deleteCurrency: Scalars['String']
    /** Delete product */
    deleteProduct: Scalars['String']
    grantCommunityPlant?: Maybe<UserPlant>
    /**
     * Grants a personal plant for a user. Available UPC:
     *         plant.beginner
     *         plant.intermediate
     *         plant.advanced
     *         plant.beginner.rare
     *         plant.intermediate.rare
     *         plant.advanced.rare
     *         plant.resiliflora.rare
     *         plant.nutrivora
     */
    grantPersonalPlant?: Maybe<UserPlant>
    grantTokens: Scalars['String']
    /** plant in personal garden */
    plantPlant: PlantActionResponse
    /** purchase product */
    purchaseProduct: PurchaseResponse
    refillWaterCan: Array<UserPlantInfo>
    /** add water to plant */
    waterPlant: WaterPlantResponse
}

export type RetailMutationCreateCurrencyArgs = {
    code: Scalars['String']
    name: Scalars['String']
}

export type RetailMutationCreateProductArgs = {
    cost: Scalars['Int']
    currencyCode: Scalars['String']
    description: Scalars['String']
    plantWateringSteps?: InputMaybe<Scalars['Int']>
    productType: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type RetailMutationDeleteCurrencyArgs = {
    code: Scalars['String']
}

export type RetailMutationDeleteProductArgs = {
    upc: Scalars['String']
}

export type RetailMutationGrantCommunityPlantArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type RetailMutationGrantPersonalPlantArgs = {
    plantUpc: Scalars['String']
    userId: Scalars['UUID']
}

export type RetailMutationGrantTokensArgs = {
    amount: Scalars['Int']
    userId: Scalars['UUID']
}

export type RetailMutationPlantPlantArgs = {
    plantId: Scalars['UUID']
}

export type RetailMutationPurchaseProductArgs = {
    upc: Scalars['String']
}

export type RetailMutationRefillWaterCanArgs = {
    userId: Scalars['UUID']
}

export type RetailMutationWaterPlantArgs = {
    plantId: Scalars['UUID']
}

export type RetailQuery = {
    __typename?: 'RetailQuery'
    /** Get user's balance */
    getBalance: Array<BalanceInfo>
    /** Get currencies defined in the system */
    getCurrencies: Array<CurrencyInfo>
    /** Get grouped community challenge plants for whole challenge */
    getGroupedCommunityChallengePlants: Array<CommunityGardenInfo>
    /** Get user's inventory */
    getInventory: Array<UserInventoryInfo>
    /** Get user's plants */
    getPlants: Array<UserPlantInfo>
    /** Get products defined in the system */
    getProducts: Array<ProductInfo>
    /** Returns the user's plants rank if participating solo on a given challenge */
    getSoloPlantsRank: PlantsRanked
    /** Get team's community challenge plants */
    getTeamCommunityChallengePlants: Array<UserPlantInfo>
    /** Returns the social group plants rank on a given challenge */
    getTeamPlantsRank: PlantsRanked
    /** Returns the team players plants leaderboard for a challenge */
    getTeamsPlantsLeaderboard: PlantsLeaderboard
    /** Get user's transaction history */
    getTransactionHistory: Array<TransactionHistoryInfo>
    /** Get user's community challenge plants */
    getUserCommunityChallengePlants: Array<UserPlantInfo>
    /** Returns the solo players plants leaderboard for a challenge */
    getUsersPlantsLeaderboard: PlantsLeaderboard
}

export type RetailQueryGetGroupedCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetSoloPlantsRankArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetTeamCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type RetailQueryGetTeamPlantsRankArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type RetailQueryGetTeamsPlantsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type RetailQueryGetUserCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetUsersPlantsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
}

export enum RiskCohort {
    HighRisk = 'HighRisk',
    MediumRisk = 'MediumRisk',
    Thriving = 'Thriving'
}

export type RiskCohortStats = {
    __typename?: 'RiskCohortStats'
    absoluteSize: Scalars['Int']
    changeSinceLastPeriod: Scalars['Float']
    relativeSizePercentage: Scalars['Float']
    riskCohort: RiskCohort
    totalEmployees: Scalars['Int']
}

export enum RiskProfile {
    HighRisk = 'HIGH_RISK',
    ModerateRisk = 'MODERATE_RISK',
    Thriving = 'THRIVING'
}

export enum Role {
    Admin = 'ADMIN',
    User = 'USER'
}

export type SaveSurveyAnswerResponse = {
    __typename?: 'SaveSurveyAnswerResponse'
    currentStreak: Scalars['Int']
    isResponseSaved: Scalars['Boolean']
    maxStreak: Scalars['Int']
    recommendedContent?: Maybe<SimpleRecommendedContent>
}

export type SavedCheckInDto = {
    __typename?: 'SavedCheckInDTO'
    recommendedContent: RecommendedContentDto
    /** @deprecated Use savedCheckIn.response.recommendedContentDimension */
    recommendedContentDimension: Surveys
    savedCheckIn: DailyCheckInDto
    streak: PulseStreakDto
}

export type Scope = {
    __typename?: 'Scope'
    accessType: Scalars['String']
    entitlement?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    resourceType: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type ScopeInputGraphQlInput = {
    scope: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type ScopePage = {
    __typename?: 'ScopePage'
    items: Array<Scope>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ScoreRanked = {
    __typename?: 'ScoreRanked'
    /** The entity id */
    entityId?: Maybe<Scalars['UUID']>
    /** The rank position on the leaderboard */
    rank: Scalars['Int']
    /** The requester entity display name */
    requesterDisplayName: Scalars['String']
    /** Total steps number */
    totalSteps: Scalars['Int']
}

export type Script = {
    __typename?: 'Script'
    id: Scalars['UUID']
    name: Scalars['String']
}

export type SearchMutation = {
    __typename?: 'SearchMutation'
    /** Update the Algolia Search Index */
    populateAlgolia: Scalars['Int']
}

export type SearchMutationPopulateAlgoliaArgs = {
    contentType?: InputMaybe<CmsContentType>
}

export type SearchQuery = {
    __typename?: 'SearchQuery'
    /** Get a Read-Only key for accessing Algolia which enforces filter requirements */
    getAlgoliaKey: Scalars['String']
    /** Dummy endpoint for exposing Event type model */
    getCmsEventModel: CmsEvent
    /** Search for given Type using the input search test */
    search: Array<GraphQlSearchResult>
    /** Search for all types of content */
    searchAll: Array<GraphQlSearchResult>
}

export type SearchQuerySearchArgs = {
    searchText: Scalars['String']
    types: Array<Type>
}

export type SearchQuerySearchAllArgs = {
    searchText: Scalars['String']
}

export type SecurityPage = {
    __typename?: 'SecurityPage'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<SecurityPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    securityPageComponents: Array<
        Maybe<SecurityPageSecurityPageComponentsDynamicZone>
    >
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type SecurityPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type SecurityPageEntity = {
    __typename?: 'SecurityPageEntity'
    attributes?: Maybe<SecurityPage>
    id?: Maybe<Scalars['ID']>
}

export type SecurityPageEntityResponse = {
    __typename?: 'SecurityPageEntityResponse'
    data?: Maybe<SecurityPageEntity>
}

export type SecurityPageInput = {
    publishedAt?: InputMaybe<Scalars['DateTime']>
    securityPageComponents?: InputMaybe<
        Array<Scalars['SecurityPageSecurityPageComponentsDynamicZoneInput']>
    >
}

export type SecurityPageRelationResponseCollection = {
    __typename?: 'SecurityPageRelationResponseCollection'
    data: Array<SecurityPageEntity>
}

export type SecurityPageSecurityPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type SharedPersonalizedReset = {
    __typename?: 'SharedPersonalizedReset'
    id: Scalars['UUID']
    personalizedResetId: Scalars['UUID']
}

export type SignupCode = {
    __typename?: 'SignupCode'
    code: Scalars['String']
    subscriptionId: Scalars['UUID']
    validFrom: Scalars['Instant']
    validUntil: Scalars['Instant']
}

export type SignupCodeFiltersInput = {
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type SignupCodeInput = {
    code: Scalars['String']
    subscriptionId: Scalars['UUID']
    validFrom: Scalars['Instant']
    validUntil: Scalars['Instant']
}

export type SignupCodePage = {
    __typename?: 'SignupCodePage'
    items: Array<SignupCode>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type SimpleRecommendedContent = {
    __typename?: 'SimpleRecommendedContent'
    contentType: SupportedRecommendedContentType
    domainId: Scalars['String']
    gqlQueryString: Scalars['String']
    title: Scalars['String']
}

export type SingleMetricResult = {
    __typename?: 'SingleMetricResult'
    count?: Maybe<Scalars['Int']>
    countDescription?: Maybe<Scalars['String']>
    percentChange?: Maybe<Scalars['Float']>
    result: Scalars['Float']
}

export type Skill = {
    __typename?: 'Skill'
    id: Scalars['String']
    name: Scalars['String']
}

export type SlackIntegrationMutation = {
    __typename?: 'SlackIntegrationMutation'
    /** Disables Slack integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Slack integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type SlackIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type SlackIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    workspaceId: Scalars['String']
}

export type SlackMutation = {
    __typename?: 'SlackMutation'
    notify: NotifyMutation
}

export type SlackQuery = {
    __typename?: 'SlackQuery'
    firstQuery: Scalars['String']
    team: SlackTeamQuery
}

export type SlackQueryTeamArgs = {
    teamId: Scalars['String']
}

export type SlackTeamBotInfo = {
    __typename?: 'SlackTeamBotInfo'
    appId?: Maybe<Scalars['String']>
    botId?: Maybe<Scalars['String']>
    botUserId?: Maybe<Scalars['String']>
    companyId: Scalars['UUID']
    enterpriseId?: Maybe<Scalars['String']>
    enterpriseName?: Maybe<Scalars['String']>
    enterpriseUrl?: Maybe<Scalars['String']>
    installedAt?: Maybe<Scalars['Instant']>
    isEnterpriseInstall?: Maybe<Scalars['Boolean']>
    teamId?: Maybe<Scalars['String']>
    teamName?: Maybe<Scalars['String']>
}

export type SlackTeamBotTokens = {
    __typename?: 'SlackTeamBotTokens'
    botAccessToken?: Maybe<Scalars['String']>
    botRefreshToken?: Maybe<Scalars['String']>
    botScope?: Maybe<Scalars['String']>
    botTokenExpiresAt?: Maybe<Scalars['Instant']>
    companyId?: Maybe<Scalars['UUID']>
    scope?: Maybe<Scalars['String']>
    teamId?: Maybe<Scalars['String']>
    tokenType?: Maybe<Scalars['String']>
}

export type SlackTeamProfileField = {
    __typename?: 'SlackTeamProfileField'
    fieldName?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['String']>
    isHidden?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    ordering?: Maybe<Scalars['Int']>
    possibleValues?: Maybe<Array<Scalars['String']>>
    type?: Maybe<Scalars['String']>
}

export type SlackTeamProfileFields = {
    __typename?: 'SlackTeamProfileFields'
    fieldsBySection: Array<SlackTeamProfileSection>
}

export type SlackTeamProfileSection = {
    __typename?: 'SlackTeamProfileSection'
    fields: Array<SlackTeamProfileField>
    id?: Maybe<Scalars['String']>
    isHidden?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    order?: Maybe<Scalars['Int']>
}

export type SlackTeamQuery = {
    __typename?: 'SlackTeamQuery'
    botInfo?: Maybe<SlackTeamBotInfo>
    botTokens?: Maybe<SlackTeamBotTokens>
    profileFields?: Maybe<SlackTeamProfileFields>
    teamId: Scalars['String']
}

export type SleepMutation = {
    __typename?: 'SleepMutation'
    /** Save an updated Sleep Count from Apple Health */
    saveAppleHealthSleepBlocks: Scalars['Boolean']
}

export type SleepMutationSaveAppleHealthSleepBlocksArgs = {
    sleepRecords: Array<AppleHealthSleepBlocksInput>
}

export type SleepSummaryStats = {
    averageMinutesAsleep: Scalars['Float']
}

export type SleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'SleepSummaryStatsResponse'
    averageMinutesAsleep: Scalars['Float']
}

export type SocialGroup = {
    __typename?: 'SocialGroup'
    /** the challengeId of the group */
    challengeId?: Maybe<Scalars['UUID']>
    /** When this social group was created */
    createdAt: Scalars['Instant']
    /** User id of who created this social group */
    createdBy: Scalars['String']
    /** The id of this social group */
    id: Scalars['UUID']
    /** if the group is the main community challenge group */
    isMainCommunitySocialGroup?: Maybe<Scalars['Boolean']>
    /** if the group is a community challenge solo group */
    isSoloChallenge?: Maybe<Scalars['Boolean']>
    /** the expiration date for the social group */
    isSubscriptionGroup: Scalars['Boolean']
    /** the join date of the user */
    joinDate?: Maybe<Scalars['Instant']>
    /** User id of who last updated this social group */
    lastUpdatedBy?: Maybe<Scalars['String']>
    /** the total member count for the group */
    memberCount?: Maybe<Scalars['Int']>
    /**
     * Current members of this social group
     * @deprecated this dataloader is deprecated, use `members` with pagination, replace with paginatedMembers
     */
    members: Array<SocialGroupMember>
    /** The name of this social group */
    name: Scalars['String']
    /** Current members of this social group, paginated */
    paginatedMembers: Array<SocialGroupMember>
    /** the stats for the social group */
    socialGroupStats?: Maybe<SocialGroupStats>
    /** The subscription id */
    subscriptionId?: Maybe<Scalars['UUID']>
    /** When this social group was last updated */
    updatedAt?: Maybe<Scalars['Instant']>
    /** the expiration date for the social group */
    validTo?: Maybe<Scalars['Instant']>
}

export type SocialGroupPaginatedMembersArgs = {
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupCreateInput = {
    /** the challenge id */
    challengeId?: InputMaybe<Scalars['UUID']>
    /** The name for the new social group */
    name: Scalars['String']
    /** the expiration date for the social group */
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupEditInput = {
    /** The updated name for this social group */
    name: Scalars['String']
    /** The ID of the social group to edit */
    socialGroupId: Scalars['UUID']
}

export type SocialGroupMember = {
    __typename?: 'SocialGroupMember'
    createdAt: Scalars['Instant']
    /** The user's display name */
    displayName: Scalars['String']
    updatedAt: Scalars['Instant']
    /** The user object for this member */
    user: User
}

export type SocialGroupMutation = {
    __typename?: 'SocialGroupMutation'
    createMultiplePostInAllUserGroups: Array<SocialGroupsResult>
    /** @deprecated this mutation is deprecated, replace with createMultiplePostInAllUserGroups */
    createPostInAllUserGroups?: Maybe<Array<SocialGroupsResult>>
    createPostInGroup?: Maybe<SocialGroupsResult>
    createSocialGroup?: Maybe<SocialGroupsResult>
    deleteSocialGroup?: Maybe<SocialGroupsResult>
    editDisplayName: SocialGroupsResult
    editIsPublic: SocialGroupsResult
    editSocialGroup?: Maybe<SocialGroupsResult>
    joinSocialGroup: SocialGroupsResult
    joinSocialGroupAdmin: SocialGroupsResult
    joinSocialGroups: Array<SocialGroupsResult>
    joinSoloSocialGroup?: Maybe<SocialGroupsResult>
    joinSoloSocialGroupAdmin?: Maybe<SocialGroupsResult>
    leaveSocialGroup: SocialGroupsResult
    leaveSocialGroupAdmin: SocialGroupsResult
    leaveSoloSocialGroupAdmin?: Maybe<SocialGroupsResult>
    reactToPost: SocialGroupsResult
    undoSocialGroupPostReaction: SocialGroupsResult
    updateDisplayName: SocialGroupsResult
    validateDisplayName: SocialGroupsResult
}

export type SocialGroupMutationCreateMultiplePostInAllUserGroupsArgs = {
    postCreateInputs: Array<PostCreateInput>
}

export type SocialGroupMutationCreatePostInAllUserGroupsArgs = {
    postCreateInput: PostCreateInput
}

export type SocialGroupMutationCreatePostInGroupArgs = {
    postCreateInput: PostCreateInput
}

export type SocialGroupMutationCreateSocialGroupArgs = {
    newSocialGroup: SocialGroupCreateInput
}

export type SocialGroupMutationDeleteSocialGroupArgs = {
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationEditDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupMutationEditIsPublicArgs = {
    isPublic: Scalars['Boolean']
}

export type SocialGroupMutationEditSocialGroupArgs = {
    updatedSocialGroup: SocialGroupEditInput
}

export type SocialGroupMutationJoinSocialGroupArgs = {
    publishPost?: InputMaybe<Scalars['Boolean']>
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationJoinSocialGroupAdminArgs = {
    socialGroupID: Scalars['UUID']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationJoinSocialGroupsArgs = {
    socialGroupIDs: Array<Scalars['UUID']>
}

export type SocialGroupMutationJoinSoloSocialGroupArgs = {
    challengeId: Scalars['UUID']
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupMutationJoinSoloSocialGroupAdminArgs = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupMutationLeaveSocialGroupArgs = {
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationLeaveSocialGroupAdminArgs = {
    socialGroupID: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationLeaveSoloSocialGroupAdminArgs = {
    challengeId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationReactToPostArgs = {
    postReactionInput: PostReactionInput
}

export type SocialGroupMutationUndoSocialGroupPostReactionArgs = {
    reactionId: Scalars['UUID']
}

export type SocialGroupMutationUpdateDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupMutationValidateDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupOperationSucceeded = {
    __typename?: 'SocialGroupOperationSucceeded'
    /** The ID of the social group for which the operation succeeded */
    socialGroupId: Scalars['UUID']
}

export type SocialGroupQuery = {
    __typename?: 'SocialGroupQuery'
    /** Get all subscription social groups */
    allSocialGroupsForCompany: Array<SocialGroup>
    /** Get cache stats */
    cacheStats: Scalars['String']
    /** Get the posts of a given challengeId */
    communitySocialGroupActivityFeed: Array<Post>
    /**
     * Get community social groups
     * @deprecated Use companyChallengeSocialGroups instead, replace with companyChallengeSocialGroups
     */
    communitySocialGroups: Array<SocialGroup>
    /** Get all company challenge social groups */
    companyChallengeSocialGroups: Array<SocialGroup>
    /** Get the posts of the user's subscriptionId */
    companySocialGroupActivityFeed: Array<Post>
    /** Get the display name of the user */
    displayName: DisplayName
    /** Get all display names for the given user ids */
    getDisplayNames: Array<DisplayName>
    /** Get reactions */
    getReactionsForCompanyFeed: Array<PostReaction>
    /** Get profanity words */
    profanities: Array<Scalars['String']>
    /** Get social group by ID */
    socialGroup?: Maybe<SocialGroupsResult>
    /** Get the posts of a given group id and optionally for a given challenge id */
    socialGroupActivityFeed: Array<Post>
    /** Get all social groups for subscription except challenge groups */
    socialGroupsForCompany: Array<SocialGroup>
    /** Get all social groups for subscription */
    socialGroupsForCompanyV2: SocialGroupResponse
    /** Get all social groups for user */
    socialGroupsForUser: Array<SocialGroup>
    /** Get all social groups for user */
    socialGroupsForUserV2: SocialGroupResponse
}

export type SocialGroupQueryCacheStatsArgs = {
    cacheType: CacheTypes
}

export type SocialGroupQueryCommunitySocialGroupActivityFeedArgs = {
    challengeId: Scalars['UUID']
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupQueryCommunitySocialGroupsArgs = {
    challengeId: Scalars['UUID']
}

export type SocialGroupQueryCompanyChallengeSocialGroupsArgs = {
    challengeId: Scalars['UUID']
    excludeFullGroups?: InputMaybe<Scalars['Boolean']>
}

export type SocialGroupQueryCompanySocialGroupActivityFeedArgs = {
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupQueryGetDisplayNamesArgs = {
    userIds: Array<Scalars['UUID']>
}

export type SocialGroupQueryGetReactionsForCompanyFeedArgs = {
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupQueryProfanitiesArgs = {
    language: Scalars['String']
}

export type SocialGroupQuerySocialGroupArgs = {
    id: Scalars['UUID']
}

export type SocialGroupQuerySocialGroupActivityFeedArgs = {
    challengeId?: InputMaybe<Scalars['UUID']>
    limit: Scalars['Int']
    offset: Scalars['Int']
    socialGroupId: Scalars['UUID']
}

export type SocialGroupQuerySocialGroupsForUserArgs = {
    challengeId?: InputMaybe<Scalars['UUID']>
}

export type SocialGroupResponse = {
    __typename?: 'SocialGroupResponse'
    /** the social groups for the company or the user */
    socialGroups: Array<SocialGroup>
    /** total number of groups in company */
    totalGroupsForCompany: Scalars['Int']
    /** total social groups the user has joined */
    totalGroupsUserJoined: Scalars['Int']
}

export type SocialGroupStats = {
    __typename?: 'SocialGroupStats'
    /** total number of posts in the social group */
    totalPostCount: Scalars['Int']
    /** total number celebrations given in the social group */
    totalReactionCount: Scalars['Int']
}

export type SocialGroupUndoPostReactionSucceed = {
    __typename?: 'SocialGroupUndoPostReactionSucceed'
    /** The ID of the reaction for which the operation succeeded */
    reactionId: Scalars['UUID']
}

export type SocialGroupsResult =
    | InvalidAction
    | InvalidInput
    | PostOperationSucceeded
    | PostReactionCreationSucceeded
    | SocialGroup
    | SocialGroupOperationSucceeded
    | SocialGroupUndoPostReactionSucceed
    | UpdateDisplayNameOperationSucceeded
    | UpdateIsPublicOperationSucceeded
    | ValidateDisplayNameOperationSucceeded

export type StepsGoalDetail = {
    __typename?: 'StepsGoalDetail'
    steps: Scalars['Int']
}

export type StepsInfo = {
    __typename?: 'StepsInfo'
    /** User's today steps */
    todaySteps: Scalars['Int']
    /** User's total steps */
    totalSteps: Scalars['Int']
}

export type StoriesPage = {
    __typename?: 'StoriesPage'
    StoriesPage?: Maybe<Array<Maybe<StoriesPageStoriesPageDynamicZone>>>
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<StoriesPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type StoriesPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type StoriesPageEntity = {
    __typename?: 'StoriesPageEntity'
    attributes?: Maybe<StoriesPage>
    id?: Maybe<Scalars['ID']>
}

export type StoriesPageEntityResponse = {
    __typename?: 'StoriesPageEntityResponse'
    data?: Maybe<StoriesPageEntity>
}

export type StoriesPageInput = {
    StoriesPage?: InputMaybe<
        Array<Scalars['StoriesPageStoriesPageDynamicZoneInput']>
    >
    publishedAt?: InputMaybe<Scalars['DateTime']>
}

export type StoriesPageRelationResponseCollection = {
    __typename?: 'StoriesPageRelationResponseCollection'
    data: Array<StoriesPageEntity>
}

export type StoriesPageStoriesPageDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFeatureGridWithPhoto
    | ComponentMsComponentsFeatureWithPhoto
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type Story = {
    __typename?: 'Story'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story */
    id: Scalars['UUID']
    /** The story */
    story?: Maybe<Scalars['String']>
    /** The id of story type */
    storyTypeId: Scalars['UUID']
    updatedAt?: Maybe<Scalars['Instant']>
    /** The id of the user challenge activity */
    userChallengeActivityId: Scalars['UUID']
    /** The visiblity of the story */
    visibility?: Maybe<Visibility>
}

export type StoryCreateInput = {
    /** The story to create */
    message: Scalars['String']
    /** The story type ID of the story to create */
    storyTypeId: Scalars['UUID']
    /** The user-challenge ID of the participation */
    userChallengeActivityId: Scalars['UUID']
    /** The visibility of the story */
    visibility: Visibility
}

export type StoryOperationSucceeded = {
    __typename?: 'StoryOperationSucceeded'
    /** The ID of the story for which the operation succeeded */
    storyId: Scalars['UUID']
}

export type StoryQuestionCreateInput = {
    id?: InputMaybe<Scalars['UUID']>
    kind?: InputMaybe<Scalars['String']>
    language: Scalars['String']
    questions: Scalars['String']
    region: Scalars['String']
}

export type StoryType = {
    __typename?: 'StoryType'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story type */
    id: Scalars['UUID']
    kind?: Maybe<Scalars['String']>
    language: Scalars['String']
    questions?: Maybe<Scalars['String']>
    region: Scalars['String']
    subTitle?: Maybe<Scalars['String']>
    termsAndConditionsUrl?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type StoryTypeAdmin = {
    __typename?: 'StoryTypeAdmin'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story type */
    id: Scalars['UUID']
    /** The kind of this story type */
    kind?: Maybe<Scalars['String']>
    /** The name of this story type */
    name?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type Streak = {
    __typename?: 'Streak'
    currentStreak: Scalars['Int']
    id: Scalars['ID']
    maxStreak: Scalars['Int']
    updatedAt: Scalars['Date']
    user?: Maybe<User>
    userChallengeActivityId: Scalars['ID']
}

export type StreakInput = {
    microstepIds: Array<Scalars['ID']>
}

export type StringFilterInput = {
    and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
    between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
    contains?: InputMaybe<Scalars['String']>
    containsi?: InputMaybe<Scalars['String']>
    endsWith?: InputMaybe<Scalars['String']>
    eq?: InputMaybe<Scalars['String']>
    eqi?: InputMaybe<Scalars['String']>
    gt?: InputMaybe<Scalars['String']>
    gte?: InputMaybe<Scalars['String']>
    in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
    lt?: InputMaybe<Scalars['String']>
    lte?: InputMaybe<Scalars['String']>
    ne?: InputMaybe<Scalars['String']>
    not?: InputMaybe<StringFilterInput>
    notContains?: InputMaybe<Scalars['String']>
    notContainsi?: InputMaybe<Scalars['String']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
    notNull?: InputMaybe<Scalars['Boolean']>
    null?: InputMaybe<Scalars['Boolean']>
    or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
    startsWith?: InputMaybe<Scalars['String']>
}

export type SubscriptionCompanyInput = {
    /** The company id */
    companyId: Scalars['UUID']
    /** The subscription id */
    subscriptionId: Scalars['UUID']
}

export type SubscriptionFiltersInput = {
    companyId?: InputMaybe<Scalars['UUID']>
}

export type SubscriptionGroupCodes = {
    __typename?: 'SubscriptionGroupCodes'
    groupCodes: Array<Scalars['String']>
    subscription: UserSubscription
}

export type SubscriptionGroupCodesResponse = {
    __typename?: 'SubscriptionGroupCodesResponse'
    items: Array<SubscriptionGroupCodes>
}

export type SubscriptionId = {
    __typename?: 'SubscriptionId'
    id: Scalars['UUID']
}

export type SubscriptionInputGraphQlInput = {
    allowReferrals?: InputMaybe<Scalars['Boolean']>
    companyId: Scalars['UUID']
    enabled?: InputMaybe<Scalars['Boolean']>
    expiryDate?: InputMaybe<Scalars['Instant']>
    internalDescription?: InputMaybe<Scalars['String']>
    isDefault: Scalars['Boolean']
    license?: InputMaybe<LicenseType>
    seatsAllowed?: InputMaybe<Scalars['Int']>
    startDate?: InputMaybe<Scalars['Instant']>
    subscriptionType?: InputMaybe<SubscriptionType>
}

export type SubscriptionPage = {
    __typename?: 'SubscriptionPage'
    items: Array<UserSubscription>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export enum SubscriptionType {
    Employee = 'employee',
    FriendsAndFamily = 'friendsAndFamily',
    Other = 'other'
}

export type SuccessStoryFeedback = {
    __typename?: 'SuccessStoryFeedback'
    featuredTextFull: Scalars['String']
    featuredTextShort: Scalars['String']
    featuredTitle: Scalars['String']
    hint: Scalars['String']
    id: Scalars['UUID']
    photoUploadText: Scalars['String']
    savedFooter: Scalars['String']
    savedText: Scalars['String']
    savedTitle: Scalars['String']
    submittedFooter: Scalars['String']
    submittedText: Scalars['String']
    submittedTitle: Scalars['String']
    subtitle: Scalars['String']
    suggestions: Array<Scalars['String']>
    suggestionsTitle: Scalars['String']
    title: Scalars['String']
    type: SuccessStoryFeedbackType
}

export enum SuccessStoryFeedbackType {
    CourseLesson = 'CourseLesson',
    JourneyLevel = 'JourneyLevel',
    RoleModel = 'RoleModel'
}

export type SuccessStoryMutation = {
    __typename?: 'SuccessStoryMutation'
    saveMySuccessStory?: Maybe<MySuccessStory>
}

export type SuccessStoryMutationSaveMySuccessStoryArgs = {
    input: MySuccessStoryInput
}

export type SuccessStoryQuery = {
    __typename?: 'SuccessStoryQuery'
    /** Get My Success Stories, based on userId from context */
    getMySuccessStories: MySuccessStoryPage
    /** Get My Success Story, based on userId from context */
    getMySuccessStory?: Maybe<MySuccessStory>
    /** Get Success Story Feedback by type */
    getSuccessStoryFeedback?: Maybe<SuccessStoryFeedback>
}

export type SuccessStoryQueryGetMySuccessStoriesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type SuccessStoryQueryGetMySuccessStoryArgs = {
    id: Scalars['UUID']
}

export type SuccessStoryQueryGetSuccessStoryFeedbackArgs = {
    type: SuccessStoryFeedbackType
}

export enum SupportedRecommendedContentType {
    Article = 'ARTICLE',
    Course = 'COURSE',
    None = 'NONE',
    Qotd = 'QOTD',
    Reset = 'RESET'
}

export enum SurveyEligibility {
    AlreadyResponded = 'AlreadyResponded',
    AlreadySent = 'AlreadySent',
    Eligible = 'Eligible',
    NotSubscribed = 'NotSubscribed'
}

export enum Surveys {
    Allyship = 'ALLYSHIP',
    Efficacy = 'EFFICACY',
    EnergyLevels = 'ENERGY_LEVELS',
    Enthusiasm = 'ENTHUSIASM',
    Productivity = 'PRODUCTIVITY',
    Retention = 'RETENTION'
}

export type SurveysMutation = {
    __typename?: 'SurveysMutation'
    /** Get a user's Daily Check-In question */
    requestCheckIn?: Maybe<DailyCheckInDto>
    /** Bulk request Daily Check-Ins for users */
    requestCheckIns?: Maybe<Array<DailyCheckInDto>>
    /** Save a Daily Check-In response */
    saveCheckInResponse?: Maybe<SavedCheckInDto>
}

export type SurveysMutationRequestCheckInArgs = {
    channel: Channel
    requestDate: Scalars['ISODate']
}

export type SurveysMutationRequestCheckInsArgs = {
    request: BulkDailyCheckInRequestInput
}

export type SurveysMutationSaveCheckInResponseArgs = {
    responseInputData: DailyCheckInResponseInput
}

export type SurveysQuery = {
    __typename?: 'SurveysQuery'
    /** Get all survey questions */
    getAll: Array<PulseSurveyQuestionDto>
    /** Get a paginated list of Daily Check-In responses */
    responses?: Maybe<CheckInResponseDetailsPage>
}

export type SurveysQueryGetAllArgs = {
    activeOnly?: InputMaybe<Scalars['Boolean']>
}

export type SurveysQueryResponsesArgs = {
    from?: InputMaybe<Scalars['ISODate']>
    pagination?: InputMaybe<PaginationInput>
    surveyDimension?: InputMaybe<Surveys>
    to?: InputMaybe<Scalars['ISODate']>
}

export type TeamsMutation = {
    __typename?: 'TeamsMutation'
    notify: TeamsNotifyMutation
}

export type TeamsNotifyChallengeUserResult = {
    __typename?: 'TeamsNotifyChallengeUserResult'
    error?: Maybe<Scalars['String']>
    numberOfUsersNotified: Scalars['Int']
}

export type TeamsNotifyMutation = {
    __typename?: 'TeamsNotifyMutation'
    /** Send a reminder to complete the daily goals for a challenge */
    notifyDailyGoalsChallengeUsers: TeamsNotifyChallengeUserResult
}

export type TeamsNotifyMutationNotifyDailyGoalsChallengeUsersArgs = {
    input: TeamsThriveChallengeDailyGoalNotificationInput
}

export type TeamsQuery = {
    __typename?: 'TeamsQuery'
    firstQuery: Scalars['String']
}

export type TeamsThriveChallengeDailyGoalNotificationInput = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    users: Array<Scalars['UUID']>
}

export type TemplateJourney = {
    __typename?: 'TemplateJourney'
    id: Scalars['UUID']
    name: Scalars['String']
    shortName: Scalars['String']
}

export type TemplateOperationResult =
    | ChallengeTemplateInstance
    | ErrorMessageTemplate
    | UpdateResult

export type TermsPage = {
    __typename?: 'TermsPage'
    createdAt?: Maybe<Scalars['DateTime']>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<TermsPageRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    termsPageComponents: Array<Maybe<TermsPageTermsPageComponentsDynamicZone>>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type TermsPageLocalizationsArgs = {
    publicationState?: InputMaybe<PublicationState>
}

export type TermsPageEntity = {
    __typename?: 'TermsPageEntity'
    attributes?: Maybe<TermsPage>
    id?: Maybe<Scalars['ID']>
}

export type TermsPageEntityResponse = {
    __typename?: 'TermsPageEntityResponse'
    data?: Maybe<TermsPageEntity>
}

export type TermsPageInput = {
    publishedAt?: InputMaybe<Scalars['DateTime']>
    termsPageComponents?: InputMaybe<
        Array<Scalars['TermsPageTermsPageComponentsDynamicZoneInput']>
    >
}

export type TermsPageRelationResponseCollection = {
    __typename?: 'TermsPageRelationResponseCollection'
    data: Array<TermsPageEntity>
}

export type TermsPageTermsPageComponentsDynamicZone =
    | ComponentMsComponents1ColOverview
    | ComponentMsComponents2ColFullBleedVisual
    | ComponentMsComponents2ColOverview
    | ComponentMsComponents2ColToggleSection
    | ComponentMsComponentsApproachCta
    | ComponentMsComponentsBlockquoteCard
    | ComponentMsComponentsBlockquoteSection
    | ComponentMsComponentsCtaInlineCallout
    | ComponentMsComponentsFooter
    | ComponentMsComponentsHeader
    | ComponentMsComponentsHeroBgVisual
    | ComponentMsComponentsHeroInlineVisual
    | ComponentMsComponentsHeroNoVisual
    | ComponentMsComponentsHeroWithMedia
    | ComponentMsComponentsJobPostingList
    | ComponentMsComponentsPartnerLogo
    | ComponentMsComponentsPartnerLogos
    | ComponentMsComponentsPhotoCarousel
    | ComponentMsComponentsPrefooterCta
    | ComponentMsComponentsProductFeature
    | ComponentMsComponentsProductFeatureGrid
    | ComponentMsComponentsProductOverviewCta
    | ComponentMsComponentsSectionIntro
    | ComponentMsComponentsStat
    | ComponentMsComponentsStatsSection
    | ComponentMsComponentsStayInTouchCta
    | ComponentMsComponentsStories
    | ComponentMsComponentsStory
    | ComponentMsComponentsTeamMemberRow
    | ComponentMsComponentsTeamMemberSection
    | ComponentMsComponentsTextSection
    | ComponentMsComponentsToggleSectionCard
    | Error

export type TextArticle = Article & {
    __typename?: 'TextArticle'
    articleUrl?: Maybe<Scalars['String']>
    authors: Array<ArticleAuthor>
    body: Scalars['String']
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    createdAt?: Maybe<Scalars['Instant']>
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageSizes?: Maybe<ImageSizes>
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    summary: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
    userJourneys: UserJourneys
}

export type ThriveAudioConnection = {
    __typename?: 'ThriveAudioConnection'
    edges: Array<ThriveAudioEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<Audio>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<Audio>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveAudioEdge = {
    __typename?: 'ThriveAudioEdge'
    cursor: Scalars['String']
    node: Audio
}

export type ThriveAudioQuery = {
    __typename?: 'ThriveAudioQuery'
    get: ThriveAudioConnection
}

export type ThriveAudioQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThriveBanner = {
    __typename?: 'ThriveBanner'
    callToAction?: Maybe<CallToAction>
    description: Scalars['String']
    eyebrow: Scalars['String']
    imageDescription: Scalars['String']
    imageUrl: Scalars['Url']
    title: Scalars['String']
}

export type ThriveBannerConnection = {
    __typename?: 'ThriveBannerConnection'
    edges: Array<ThriveBannerEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<ThriveBanner>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<ThriveBanner>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveBannerEdge = {
    __typename?: 'ThriveBannerEdge'
    cursor: Scalars['String']
    node: ThriveBanner
}

export type ThriveBannerQuery = {
    __typename?: 'ThriveBannerQuery'
    get: ThriveBannerConnection
}

export type ThriveBannerQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThriveChallengeDailyGoalNotificationInput = {
    challengeDay?: InputMaybe<Scalars['Int']>
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    users: Array<Scalars['UUID']>
}

export type ThriveChallengeNotificationInput = {
    challengeId: Scalars['UUID']
    notificationType: ThriveChallengeNotificationType
    subscriptionId: Scalars['UUID']
}

export enum ThriveChallengeNotificationType {
    Announcement = 'ANNOUNCEMENT',
    PreviousDay = 'PREVIOUS_DAY',
    SameDay = 'SAME_DAY'
}

export type ThriveContentQuery = {
    __typename?: 'ThriveContentQuery'
    book: BookQuery
    contentPacks: ContentPackPage
    /** Get bookmarked content for a user */
    getBookmarkedContent: LearnContentItemPage
    /** Get content by Topic. A topic can have Journey UUID or a Pulse Dimension Type. */
    getContentByTopic: LearnContentByTopicOutput
    /** Get content in progress for a user, sorted by latest updatedAt descending. */
    getContentInProgress: LearnContentItemPage
    /** Get finished content for a user, sorted by latest updatedAt descending. */
    getFinishedContent: LearnContentItemPage
    /** Get content by Topic. A topic can have Journey UUID or a Pulse Dimension Type. */
    getMostViewedContent: Array<LearnContentItem>
    reset: ResetQueryV2
}

export type ThriveContentQueryContentPacksArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetBookmarkedContentArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetContentByTopicArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    topic: TopicInput
}

export type ThriveContentQueryGetContentInProgressArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetFinishedContentArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetMostViewedContentArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type ThriveHapiResponse =
    | HapiAccessToken
    | HapiIdToken
    | HapiSessionToken

export type ThriveImagePage = {
    __typename?: 'ThriveImagePage'
    items: Array<Image>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveImageQuery = {
    __typename?: 'ThriveImageQuery'
    get: ThriveImagePage
}

export type ThriveImageQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThrivePulseMutation = {
    __typename?: 'ThrivePulseMutation'
    /** Admin Controls */
    admin: AdminMutation
    /** Modify Insights settings in Pulse */
    insights: InsightsMutation
    /** Modify survey data in Pulse */
    surveys: SurveysMutation
}

export type ThrivePulseQuery = {
    __typename?: 'ThrivePulseQuery'
    /** Get company details from Pulse */
    companies: CompaniesQuery
    /** Get insights/reporting data from Pulse */
    insights: InsightsQuery
    oracle: OracleQuery
    /** Get recommendations from Pulse */
    recommendations: RecommendationsQuery
    /** Get surveys from Pulse */
    surveys: SurveysQuery
    /** Get user data from Pulse */
    users: UsersQuery
}

export type ThrivePulseQueryInsightsArgs = {
    from: Scalars['String']
    to: Scalars['String']
}

export type ThriveQuote = Frame & {
    __typename?: 'ThriveQuote'
    _: Scalars['Boolean']
    author: Scalars['String']
    backgroundColor?: Maybe<Scalars['Color']>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    text: Scalars['String']
    textColor?: Maybe<Scalars['Color']>
    updatedAt: Scalars['Instant']
    /** @deprecated ThriveQuote will never be assigned to a user */
    user?: Maybe<ResetApiUser>
}

export type ThriveQuoteConnection = {
    __typename?: 'ThriveQuoteConnection'
    edges: Array<ThriveQuoteEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<ThriveQuote>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<ThriveQuote>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveQuoteEdge = {
    __typename?: 'ThriveQuoteEdge'
    cursor: Scalars['String']
    node: ThriveQuote
}

export type ThriveQuoteQuery = {
    __typename?: 'ThriveQuoteQuery'
    get: ThriveQuoteConnection
}

export type ThriveQuoteQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThriveReset = Reset & {
    __typename?: 'ThriveReset'
    _: Scalars['Boolean']
    appUrl: Scalars['Url']
    bookmarked: Scalars['Boolean']
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    iframeUrls: AspectRatioUrls
    keywords: Array<ThriveResetKeyword>
    landscape: ThriveResetUrls
    latestFeedback?: Maybe<Feedback>
    likes: Likes
    locale: Scalars['Locale']
    /** @deprecated this field is deprecated, replace with themes.localized */
    localizedTags: Array<LocalizedThriveResetTag>
    name?: Maybe<Scalars['String']>
    partner?: Maybe<Partner>
    portrait: ThriveResetUrls
    resetType: ThriveResetType
    /** @deprecated this field is deprecated, replace with themes */
    tags: Array<ThriveResetTag>
    themes: Array<ThriveResetTheme>
    /** @deprecated this field is deprecated, replace with portrait.thumbnail OR landscape.thumbnail */
    thumbnail?: Maybe<Scalars['Url']>
    updatedAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with portrait.url OR landscape.url */
    url?: Maybe<Scalars['Url']>
    watched: Scalars['Boolean']
}

export type ThriveResetAppUrlArgs = {
    utmSource: Scalars['String']
}

export type ThriveResetIframeUrlsArgs = {
    utmSource: Scalars['String']
}

export type ThriveResetLocalizedTagsArgs = {
    locale: Scalars['Locale']
}

export type ThriveResetActionsMutation = {
    __typename?: 'ThriveResetActionsMutation'
    watched: Scalars['Boolean']
}

export type ThriveResetActionsMutationWatchedArgs = {
    resetId: Scalars['UUID']
}

export type ThriveResetAudio = Reset & {
    __typename?: 'ThriveResetAudio'
    _: Scalars['Boolean']
    bookmarked: Scalars['Boolean']
    createdAt: Scalars['Instant']
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    images: ImageUrls
    likes: Likes
    mp3Url: Scalars['Url']
    name: Scalars['String']
    themes: Array<ThriveResetAudioTheme>
    updatedAt: Scalars['Instant']
    watched: Scalars['Boolean']
}

export type ThriveResetAudioEventMutation = {
    __typename?: 'ThriveResetAudioEventMutation'
    watched: Scalars['Boolean']
}

export type ThriveResetAudioEventMutationWatchedArgs = {
    thriveResetAudioId: Scalars['UUID']
}

export type ThriveResetAudioFilterInput = {
    theme?: InputMaybe<ThriveResetAudioFilterThemeInput>
}

export type ThriveResetAudioFilterThemeInput = {
    in?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetAudioMutation = {
    __typename?: 'ThriveResetAudioMutation'
    event: ThriveResetAudioEventMutation
}

export type ThriveResetAudioPage = {
    __typename?: 'ThriveResetAudioPage'
    items: Array<ThriveResetAudio>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveResetAudioPageV2 = {
    __typename?: 'ThriveResetAudioPageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ThriveResetAudioV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ThriveResetAudioQuery = {
    __typename?: 'ThriveResetAudioQuery'
    get: ThriveResetAudioPage
    getByIds: Array<ThriveResetAudio>
    getRandomBasedOnUserHistory: ThriveResetAudio
    themes: Array<ThriveResetAudioTheme>
}

export type ThriveResetAudioQueryGetArgs = {
    filter?: InputMaybe<ThriveResetAudioFilterInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    random?: InputMaybe<Scalars['Boolean']>
}

export type ThriveResetAudioQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
}

export type ThriveResetAudioTheme = {
    __typename?: 'ThriveResetAudioTheme'
    localized: Scalars['String']
    theme: Scalars['String']
}

export type ThriveResetAudioV2 = {
    __typename?: 'ThriveResetAudioV2'
    durationInSeconds: Scalars['Int']
    images?: Maybe<ImageUrls>
    isArchived: Scalars['Boolean']
    name: Scalars['String']
    uuid: Scalars['UUID']
}

export type ThriveResetFeedbackMutation = {
    __typename?: 'ThriveResetFeedbackMutation'
    create: Scalars['Boolean']
}

export type ThriveResetFeedbackMutationCreateArgs = {
    feedback: Feedback
    id: Scalars['UUID']
}

export type ThriveResetFeedbackQuery = {
    __typename?: 'ThriveResetFeedbackQuery'
    getLatest?: Maybe<Feedback>
}

export type ThriveResetFeedbackQueryGetLatestArgs = {
    id: Scalars['UUID']
}

export type ThriveResetFilterInput = {
    closedCaption?: InputMaybe<ThriveResetFilterStringInput>
    description?: InputMaybe<ThriveResetFilterStringInput>
    keyword?: InputMaybe<ThriveResetFilterKeywordInput>
    name?: InputMaybe<ThriveResetFilterStringInput>
    platform?: InputMaybe<ThriveResetFilterPlatformInput>
    theme?: InputMaybe<ThriveResetFilterThemeInput>
}

export type ThriveResetFilterKeywordInput = {
    in?: InputMaybe<Array<Scalars['String']>>
    notIn?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export type ThriveResetFilterStringInput = {
    contains?: InputMaybe<Scalars['String']>
}

export type ThriveResetFilterThemeInput = {
    in?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetKeyword = {
    __typename?: 'ThriveResetKeyword'
    isAvailableInHelpMeChoose: Scalars['Boolean']
    keyword: Scalars['String']
    localized: Scalars['String']
}

export type ThriveResetKeywordFilterInput = {
    isAvailableInHelpMeChoose?: InputMaybe<Scalars['Boolean']>
    platform?: InputMaybe<ThriveResetKeywordFilterPlatformInput>
}

export type ThriveResetKeywordFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export type ThriveResetPage = {
    __typename?: 'ThriveResetPage'
    items: Array<ThriveReset>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveResetPageV2 = {
    __typename?: 'ThriveResetPageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ThriveResetV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export enum ThriveResetPlatform {
    App = 'App',
    Calls = 'Calls'
}

export type ThriveResetQuery = {
    __typename?: 'ThriveResetQuery'
    daily?: Maybe<ThriveReset>
    feedback: ThriveResetFeedbackQuery
    get: ThriveResetPage
    getByIds: Array<ThriveReset>
    getMostRecentResetUserHistory: Array<ThriveResetUserHistory>
    getRandomResetBasedOnUserFeedback: ThriveReset
    getRandomResetBasedOnUserHistory: ThriveReset
    keywords: Array<ThriveResetKeyword>
    random?: Maybe<ThriveReset>
    /** @deprecated this field is deprecated, replace with themes */
    tags: Array<LocalizedThriveResetTag>
    themes: Array<ThriveResetTheme>
    watched: ThriveResetWatchQuery
}

export type ThriveResetQueryGetArgs = {
    filter?: InputMaybe<ThriveResetFilterInput>
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
    random?: InputMaybe<Scalars['Boolean']>
    resetType?: InputMaybe<ThriveResetType>
    thriveResetTag?: InputMaybe<ThriveResetTag>
}

export type ThriveResetQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
    locale?: InputMaybe<Scalars['Locale']>
}

export type ThriveResetQueryGetMostRecentResetUserHistoryArgs = {
    userId: Scalars['UUID']
}

export type ThriveResetQueryGetRandomResetBasedOnUserFeedbackArgs = {
    feedbackWeights?: InputMaybe<FeedbackWeightsInput>
    filter?: InputMaybe<ThriveResetFilterInput>
}

export type ThriveResetQueryGetRandomResetBasedOnUserHistoryArgs = {
    filter?: InputMaybe<ThriveResetFilterInput>
}

export type ThriveResetQueryKeywordsArgs = {
    filter?: InputMaybe<ThriveResetKeywordFilterInput>
}

export type ThriveResetQueryTagsArgs = {
    locale?: InputMaybe<Scalars['Locale']>
}

export type ThriveResetQueryThemesArgs = {
    filter?: InputMaybe<ThriveResetThemeFilterInput>
}

export enum ThriveResetTag {
    Animals = 'Animals',
    Breathing = 'Breathing',
    EscapeAndExplore = 'EscapeAndExplore',
    Gratitude = 'Gratitude',
    Meditation = 'Meditation',
    Purpose = 'Purpose',
    RestAndReflect = 'RestAndReflect',
    Seasons = 'Seasons',
    Sports = 'Sports',
    StressRelief = 'StressRelief',
    Stretching = 'Stretching',
    Thrive = 'Thrive',
    Wonder = 'Wonder'
}

export type ThriveResetTheme = {
    __typename?: 'ThriveResetTheme'
    localized: Scalars['String']
    theme: Scalars['String']
    toLocalizedThriveResetTag?: Maybe<LocalizedThriveResetTag>
    toThriveResetTag?: Maybe<ThriveResetTag>
}

export type ThriveResetThemeFilterInput = {
    platform?: InputMaybe<ThriveResetThemeFilterPlatformInput>
}

export type ThriveResetThemeFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export enum ThriveResetType {
    PepTalk = 'PEP_TALK',
    Standard = 'STANDARD'
}

export type ThriveResetUrls = {
    __typename?: 'ThriveResetUrls'
    iframe?: Maybe<Scalars['Url']>
    thumbnail?: Maybe<Scalars['Url']>
    thumbnails?: Maybe<ImageUrls>
}

export type ThriveResetUrlsV2 = {
    __typename?: 'ThriveResetUrlsV2'
    iframe?: Maybe<Scalars['Url']>
    thumbnails?: Maybe<ImageUrls>
}

export type ThriveResetUserHistory = {
    __typename?: 'ThriveResetUserHistory'
    country?: Maybe<Scalars['String']>
    language?: Maybe<Scalars['String']>
    resetId: Scalars['UUID']
    userId: Scalars['UUID']
    watchedAt: Scalars['Instant']
}

export type ThriveResetV2 = {
    __typename?: 'ThriveResetV2'
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    landscape: ThriveResetUrlsV2
    name: Scalars['String']
    portrait: ThriveResetUrlsV2
    uuid: Scalars['UUID']
}

export type ThriveResetWatchQuery = {
    __typename?: 'ThriveResetWatchQuery'
    company: Scalars['Int']
    user: Scalars['Int']
}

export type ThriveResetWatchQueryCompanyArgs = {
    end: Scalars['Instant']
    id: Scalars['UUID']
    start: Scalars['Instant']
}

export type ThriveResetWatchQueryUserArgs = {
    end: Scalars['Instant']
    id: Scalars['UUID']
    start: Scalars['Instant']
}

export type ThriveResetsMutation = {
    __typename?: 'ThriveResetsMutation'
    event: ThriveResetActionsMutation
    feedback: ThriveResetFeedbackMutation
}

export type TodayFeaturedItem = {
    __typename?: 'TodayFeaturedItem'
    createdAt?: Maybe<Scalars['DateTime']>
    ctaLink: Scalars['String']
    ctaText: Scalars['String']
    description: Scalars['String']
    header: Scalars['String']
    image?: Maybe<UploadFileEntityResponse>
    locale?: Maybe<Scalars['String']>
    localizations?: Maybe<TodayFeaturedItemRelationResponseCollection>
    publishedAt?: Maybe<Scalars['DateTime']>
    subscriptions?: Maybe<CmsCompanySubscriptionRelationResponseCollection>
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['DateTime']>
    validFrom: Scalars['Date']
    validTo: Scalars['Date']
}

export type TodayFeaturedItemLocalizationsArgs = {
    filters?: InputMaybe<TodayFeaturedItemFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TodayFeaturedItemSubscriptionsArgs = {
    filters?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    publicationState?: InputMaybe<PublicationState>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TodayFeaturedItemEntity = {
    __typename?: 'TodayFeaturedItemEntity'
    attributes?: Maybe<TodayFeaturedItem>
    id?: Maybe<Scalars['ID']>
}

export type TodayFeaturedItemEntityResponse = {
    __typename?: 'TodayFeaturedItemEntityResponse'
    data?: Maybe<TodayFeaturedItemEntity>
}

export type TodayFeaturedItemEntityResponseCollection = {
    __typename?: 'TodayFeaturedItemEntityResponseCollection'
    data: Array<TodayFeaturedItemEntity>
    meta: ResponseCollectionMeta
}

export type TodayFeaturedItemFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<TodayFeaturedItemFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    ctaLink?: InputMaybe<StringFilterInput>
    ctaText?: InputMaybe<StringFilterInput>
    description?: InputMaybe<StringFilterInput>
    header?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    locale?: InputMaybe<StringFilterInput>
    localizations?: InputMaybe<TodayFeaturedItemFiltersInput>
    not?: InputMaybe<TodayFeaturedItemFiltersInput>
    or?: InputMaybe<Array<InputMaybe<TodayFeaturedItemFiltersInput>>>
    publishedAt?: InputMaybe<DateTimeFilterInput>
    subscriptions?: InputMaybe<CmsCompanySubscriptionFiltersInput>
    title?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    validFrom?: InputMaybe<DateFilterInput>
    validTo?: InputMaybe<DateFilterInput>
}

export type TodayFeaturedItemInput = {
    ctaLink?: InputMaybe<Scalars['String']>
    ctaText?: InputMaybe<Scalars['String']>
    description?: InputMaybe<Scalars['String']>
    header?: InputMaybe<Scalars['String']>
    image?: InputMaybe<Scalars['ID']>
    publishedAt?: InputMaybe<Scalars['DateTime']>
    subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    title?: InputMaybe<Scalars['String']>
    validFrom?: InputMaybe<Scalars['Date']>
    validTo?: InputMaybe<Scalars['Date']>
}

export type TodayFeaturedItemRelationResponseCollection = {
    __typename?: 'TodayFeaturedItemRelationResponseCollection'
    data: Array<TodayFeaturedItemEntity>
}

export type TodayMutation = {
    __typename?: 'TodayMutation'
    /** Adds check-ins for the given date and microstep ids. Returns a list of affected results, or an empty list if the checkins already exist */
    checkinToMicrostepsV2: Array<MicrostepCheckin>
    checkinToMicrostepsV2Admin: Array<MicrostepCheckin>
    commitToMicrosteps: Array<MicrostepSubscription>
    /** @deprecated Only used in Today web */
    setFeaturedItemStatus: Scalars['ID']
    /** @deprecated Only used in Today web */
    setGuideUserProgress: GraphQlGuideUserProgress
    /** Removes check-ins for the given date and microstep ids. Returns a list of the removed microstep ids */
    unCheckinToMicrostepsV2: Array<Scalars['ID']>
    unCommitToMicrostep: Array<MicrostepSubscription>
}

export type TodayMutationCheckinToMicrostepsV2Args = {
    date?: InputMaybe<Scalars['ISODate']>
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationCheckinToMicrostepsV2AdminArgs = {
    checkinDate: Scalars['ISODate']
    companyId: Scalars['UUID']
    microstepIds: Array<Scalars['ID']>
    userId: Scalars['UUID']
}

export type TodayMutationCommitToMicrostepsArgs = {
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationSetFeaturedItemStatusArgs = {
    featureItemId: Scalars['ID']
    status: FeaturedItemStatusType
}

export type TodayMutationSetGuideUserProgressArgs = {
    guideId: Scalars['UUID']
    status: Scalars['String']
}

export type TodayMutationUnCheckinToMicrostepsV2Args = {
    date?: InputMaybe<Scalars['ISODate']>
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationUnCommitToMicrostepArgs = {
    microstepAndRemovalReason: Array<MicrostepAndRemovalReasonInput>
}

export type TodayQuery = {
    __typename?: 'TodayQuery'
    allMicrosteps: Array<GraphQlMicrostep>
    getActiveMicrostepsV2: Array<MicrostepUserStatus>
    /** @deprecated Only used in Today web */
    getAllGuides: Array<GraphQlGuide>
    getAllMicrostepCheckinProgressV2: Array<MicrostepCheckinProgressDate>
    /** @deprecated Only used in Today web */
    getFeaturedItemV2: Array<GraphQlFeaturedItem>
    getMicrostepCheckinDates: Array<MicrostepCheckinDateList>
    getMicrostepCheckinDatesAdmin: Array<MicrostepCheckinDateList>
    /** @deprecated Only used in Today web */
    getMicrostepCheckinProgressV2: MicrostepUserProgress
    getMicrostepUserCheckinTotalsAdmin: Array<MicrostepUserTotal>
    /**
     * Returns a list of microsteps with their commit & checkin status
     * @deprecated Only used in Today web
     */
    getMicrostepsUserStatusForJourneys: Array<MicrostepUserStatus>
    getMicrostepsV2: Array<GraphQlMicrostep>
    /**
     * Returns an array of date and microstep check-in count pairings, with a cumulative total. if `timeDimension` parameter is not passed: If the date range us up to 3 months, return each day in the response
     *
     * If the date range is between 3 months and 2 years, bundle the check-ins by week
     *
     * If the date range is longer than 2 years, bundle the check-ins by month.
     * @deprecated Only used in Today web
     */
    microstepMomentum: MicrostepMomentumResult
}

export type TodayQueryAllMicrostepsArgs = {
    removeDeprecated?: InputMaybe<Scalars['Boolean']>
}

export type TodayQueryGetActiveMicrostepsV2Args = {
    activeDate?: InputMaybe<Scalars['ISODate']>
}

export type TodayQueryGetAllMicrostepCheckinProgressV2Args = {
    from: Scalars['ISODate']
    to: Scalars['ISODate']
}

export type TodayQueryGetMicrostepCheckinDatesArgs = {
    microstepIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepCheckinDatesAdminArgs = {
    microstepIds: Array<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type TodayQueryGetMicrostepCheckinProgressV2Args = {
    from: Scalars['ISODate']
    microstepId: Scalars['ID']
    to: Scalars['ISODate']
}

export type TodayQueryGetMicrostepUserCheckinTotalsAdminArgs = {
    microstepIds: Array<Scalars['UUID']>
    userIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepsUserStatusForJourneysArgs = {
    checkinDate?: InputMaybe<Scalars['ISODate']>
    journeyIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepsV2Args = {
    ids: Array<Scalars['ID']>
}

export type TodayQueryMicrostepMomentumArgs = {
    from?: InputMaybe<Scalars['ISODate']>
    timeDimension?: InputMaybe<Scalars['String']>
    to?: InputMaybe<Scalars['ISODate']>
}

export type TokenResponse = {
    __typename?: 'TokenResponse'
    token: Scalars['String']
}

export type Topic = {
    __typename?: 'Topic'
    journey?: Maybe<Journey>
    journeyUUID?: Maybe<Scalars['UUID']>
    pulseDimension?: Maybe<PulseDimension>
    pulseDimensionType?: Maybe<Scalars['String']>
}

export type TopicInput = {
    journeyUUID?: InputMaybe<Scalars['UUID']>
    pulseDimensionType?: InputMaybe<Scalars['String']>
}

export type TransactionHistoryInfo = {
    __typename?: 'TransactionHistoryInfo'
    /** The transaction's amount */
    amount: Scalars['Int']
    createdAt: Scalars['Instant']
    /** The currency code */
    currencyCode: Scalars['String']
    /** In case of DEBIT, the product upc associated to it */
    productUpc?: Maybe<Scalars['String']>
    /** The transaction type. Can be CREDIT or DEBIT */
    transactionType: Scalars['String']
    updatedAt: Scalars['Instant']
    /** The user id */
    userId: Scalars['UUID']
}

export type TrendHighlights = {
    __typename?: 'TrendHighlights'
    greatestOpportunity?: Maybe<DimensionTrendsDto>
    greatestStrength?: Maybe<DimensionTrendsDto>
    mostConsistent?: Maybe<DimensionTrendsDto>
    mostImproved?: Maybe<DimensionTrendsDto>
}

export enum Trigger {
    Break = 'BREAK',
    ConsecutiveCalls = 'CONSECUTIVE_CALLS',
    Duration = 'DURATION',
    None = 'NONE'
}

export type TriggerConfiguration = {
    __typename?: 'TriggerConfiguration'
    agentGroupId: Scalars['String']
    callCountLimit?: Maybe<Scalars['Int']>
    callCountTriggerEnabled: Scalars['Boolean']
    callDurationLimit?: Maybe<Scalars['Int']>
    callDurationTriggerEnabled: Scalars['Boolean']
    createdAt: Scalars['Instant']
    digitalOnly?: Maybe<Scalars['Boolean']>
    direction: Array<Direction>
    locale?: Maybe<Scalars['String']>
    mediaTypes: Array<MediaType>
    updatedAt: Scalars['Instant']
}

export type TriggerMutation = {
    __typename?: 'TriggerMutation'
    /** Inserts or updates a trigger configuration for a company */
    delete?: Maybe<CompanyConfiguration>
    /** Inserts or updates a trigger configuration for a company */
    upsert: CompanyConfiguration
}

export type TriggerMutationDeleteArgs = {
    agentGroupId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type TriggerMutationUpsertArgs = {
    agentGroupId: Scalars['String']
    callCountLimit?: InputMaybe<Scalars['Int']>
    callCountTriggerEnabled?: InputMaybe<Scalars['Boolean']>
    callDurationLimit?: InputMaybe<Scalars['Int']>
    callDurationTriggerEnabled?: InputMaybe<Scalars['Boolean']>
    companyId: Scalars['UUID']
    digitalOnly?: InputMaybe<Scalars['Boolean']>
    direction?: InputMaybe<Array<Direction>>
    locale?: InputMaybe<Scalars['String']>
    mediaTypes?: InputMaybe<Array<MediaType>>
    platform: PlatformType
}

export type TriggerQuery = {
    __typename?: 'TriggerQuery'
    /** Returns the trigger configuration for the given group id */
    get?: Maybe<TriggerConfiguration>
    /** Returns all trigger configuration for the given company */
    getAll: Array<Maybe<TriggerConfiguration>>
}

export type TriggerQueryGetArgs = {
    agentGroupId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type TriggerQueryGetAllArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type TutorialConfig = {
    __typename?: 'TutorialConfig'
    hasPlayedTutorial: Scalars['Boolean']
    lastPlayedAt?: Maybe<Scalars['Instant']>
    pulseUserId: Scalars['UUID']
}

export enum Type {
    Article = 'ARTICLE',
    Book = 'BOOK',
    Challenge = 'CHALLENGE',
    Course = 'COURSE',
    CourseLesson = 'COURSE_LESSON',
    CourseModule = 'COURSE_MODULE',
    Journey = 'JOURNEY',
    Microstep = 'MICROSTEP',
    Podcast = 'PODCAST',
    PodcastEpisode = 'PODCAST_EPISODE',
    PodcastSeason = 'PODCAST_SEASON',
    Recipe = 'RECIPE',
    Reset = 'RESET',
    RoleModel = 'ROLE_MODEL',
    WatchMeThrive = 'WATCH_ME_THRIVE'
}

export type UngroupedAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'UngroupedAssessmentStatsResponse'
    average: Scalars['Float']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
}

export type UnifiedBiotype = {
    __typename?: 'UnifiedBiotype'
    id?: Maybe<Scalars['UUID']>
    name?: Maybe<Scalars['String']>
}

export type UnifiedChallenge = {
    __typename?: 'UnifiedChallenge'
    /** List of better choices of the challenge */
    betterChoices: Array<Scalars['UUID']>
    /** Biotype */
    biotype?: Maybe<UnifiedBiotype>
    /** Deprecated - use journeys list field */
    category?: Maybe<UnifiedJourney>
    /** The template id in case this challenge was created from a template */
    challengeTemplateId?: Maybe<Scalars['UUID']>
    /** Type of challenge (JOURNEY, BIOTYPE, PRIZE, COMPANY) */
    challenge_type: Scalars['String']
    /** The signup day (with no time) if the challenge is a company challenge */
    companyChallengeSignupDay?: Maybe<Scalars['ISODate']>
    /** The start date if the challenge is a company challenge */
    companyChallengeStartDate?: Maybe<Scalars['Instant']>
    /** The start day (with no time) if the challenge is a company challenge */
    companyChallengeStartDay?: Maybe<Scalars['ISODate']>
    /** The instance creator's userId in case this challenges was created from a template */
    createdBy?: Maybe<Scalars['UUID']>
    /** The day of company challenge it's negative if the has not started yet */
    dayOfChallenge?: Maybe<Scalars['Int']>
    /** Description of the challenge */
    description?: Maybe<Scalars['String']>
    /** Identifies if challenge supports wearables functionality  */
    device_enabled: Scalars['Boolean']
    /** Duration of the challenge */
    duration: Scalars['Int']
    /** If the challenge is visible to all users */
    enabled?: Maybe<Scalars['Boolean']>
    /** Goal of the challenge */
    goal?: Maybe<Scalars['String']>
    /** Header image */
    header?: Maybe<Scalars['String']>
    /** The id of this challenge */
    id: Scalars['UUID']
    /** Determines if the challenge is featured (Appears at the top) */
    isFeatured?: Maybe<Scalars['Boolean']>
    /** List of Journeys */
    journeys?: Maybe<Array<UnifiedJourney>>
    /** List of microstep ids of the challenge */
    microsteps: Array<UnifiedMicrostep>
    /** Name of the challenge */
    name: Scalars['String']
    /** Custom  order position set for the challenge */
    position: Scalars['Int']
    /** The description for promo 1 field */
    promoDescription1?: Maybe<Scalars['String']>
    /** The description for promo 2 field */
    promoDescription2?: Maybe<Scalars['String']>
    /** The description for promo 3 field */
    promoDescription3?: Maybe<Scalars['String']>
    /** The header for promo 1 field */
    promoHeader1?: Maybe<Scalars['String']>
    /** The header for promo 2 field */
    promoHeader2?: Maybe<Scalars['String']>
    /** The header for promo 3 field */
    promoHeader3?: Maybe<Scalars['String']>
    /** The image for promo 1 field */
    promoImage1?: Maybe<Scalars['String']>
    /** The image for promo 2 field */
    promoImage2?: Maybe<Scalars['String']>
    /** The image for promo 3 field */
    promoImage3?: Maybe<Scalars['String']>
    /** List of resets ids of the challenge */
    resetIds: Array<Scalars['UUID']>
    /** The company challenge theme */
    theme?: Maybe<Scalars['String']>
    /** Thumbnail */
    thumbnail?: Maybe<Scalars['String']>
    /** Total number of participants in this challenge for the same company */
    totalParticipants: Scalars['Int']
    /** The image for the community challenge trophy plant */
    trophyPlantImage?: Maybe<Scalars['String']>
    /** Type of challenge (JOURNEY, BIOTYPE) */
    type?: Maybe<Scalars['String']>
}

export type UnifiedChallengeActivity = {
    __typename?: 'UnifiedChallengeActivity'
    completedOn?: Maybe<Scalars['Instant']>
    expiredOn?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    join: Scalars['Instant']
    leave?: Maybe<Scalars['Instant']>
    microstepActivities?: Maybe<Array<UnifiedMicrostepActivities>>
    socialGroup?: Maybe<Scalars['UUID']>
    streak?: Maybe<UnifiedStreak>
}

export type UnifiedChallengeAdmin = {
    __typename?: 'UnifiedChallengeAdmin'
    /** Type of challenge (JOURNEY, BIOTYPE, PRIZE, COMPANY) */
    challengeType: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    /** Description of the challenge */
    description?: Maybe<Scalars['String']>
    /** Is enabled */
    enabled: Scalars['Boolean']
    /** The id of this challenge */
    id: Scalars['UUID']
    /** Is default challenge */
    isDefault: Scalars['Boolean']
    /** Name of the challenge */
    name: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
}

export type UnifiedChallengeCompany = {
    __typename?: 'UnifiedChallengeCompany'
    /** The id of the challenge */
    challenge_id: Scalars['UUID']
    /** The id of the company */
    company_id: Scalars['String']
    /** enabled flag */
    enabled: Scalars['Boolean']
    /** The id of this relation */
    id: Scalars['UUID']
    /** The id of the subscription */
    subscription_id?: Maybe<Scalars['UUID']>
}

export type UnifiedChallengeCompanyStoryType = {
    __typename?: 'UnifiedChallengeCompanyStoryType'
    /** The id of the challenge company */
    challengeCompanyId: Scalars['UUID']
    /** The id of this relation */
    id: Scalars['UUID']
    /** The id of the story type */
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengeParticipation = {
    __typename?: 'UnifiedChallengeParticipation'
    challenge: UnifiedChallenge
    participation: Array<UnifiedChallengeActivity>
}

export type UnifiedChallengesMutation = {
    __typename?: 'UnifiedChallengesMutation'
    /** Acknowledge the user decision to not show challenge recommendations again */
    ackRecommendation?: Maybe<ChallengeRecommendationAck>
    addChallengeGoals: Array<ChallengeGoal>
    addChallengesToSubscription: UnifiedResult
    addChallengesToSubscriptions: UnifiedResult
    addStorySubmissionToCompany: Array<UnifiedChallengeCompanyStoryType>
    addWaterIntake: WaterOperationResult
    /** Changes a user participating in a community challenge to another socialGroup (or null for solo) */
    changeCommunityChallengeTeam: UnifiedResult
    /** Changes a user participating in a community challenge to another socialGroup (or null for solo) */
    changeCommunityChallengeTeamAdmin: UnifiedResult
    completeChallenge?: Maybe<UnifiedUserChallengeActivity>
    completeChallengeAdmin?: Maybe<UnifiedUserChallengeActivity>
    completeCommunityChallengeDailyGoal?: Maybe<ChallengeGoal>
    completeCommunityChallengeDay: Array<ChallengeUserDailyGoal>
    createChallenge?: Maybe<Scalars['UUID']>
    /** Creates a new challenge from a template */
    createChallengeFromTemplate: TemplateOperationResult
    /** Creates a new group challenge from a template */
    createGroupChallengeFromTemplate: TemplateOperationResult
    createStoryQuestions?: Maybe<Scalars['String']>
    createStoryQuestionsI18N?: Maybe<Scalars['String']>
    deleteStory: StoryOperationSucceeded
    editStory?: Maybe<Story>
    joinChallenge: UnifiedResult
    joinChallengeAdmin: UnifiedResult
    joinCommunityChallenge: UnifiedResult
    leaveChallenge?: Maybe<UnifiedUserChallengeActivity>
    leaveChallengeAdmin?: Maybe<UnifiedUserChallengeActivity>
    removeChallenge: Scalars['String']
    removeChallengeGoals: Scalars['String']
    removeChallengesFromCompany: Scalars['String']
    removeGroupChallenge: RemoveChallengeResult
    removeUserFromChallenge: UnifiedResult
    setChallengeCustomization: ChallengeCustomizationOperationResult
    setChallengeDefaultStatus: Scalars['Boolean']
    setChallengesStatusForSubscription: UnifiedResult
    /** Marks if a user has watched the tutorial */
    setTutorialWatchedStatus: Scalars['Boolean']
    submitStory?: Maybe<Story>
    updateChallengeGoal: ChallengeGoal
    /** Updates an instance of a challenge */
    updateChallengeInstance: TemplateOperationResult
    /** Modify the join date for a user */
    updateJoinDate: UnifiedResult
    updateMeasureUnitSettings?: Maybe<UserInfo>
    updateStorySubmissionToCompany: Array<UnifiedChallengeCompanyStoryType>
    updateWeekendNotificationSettings?: Maybe<UserInfo>
}

export type UnifiedChallengesMutationAckRecommendationArgs = {
    challengeId: Scalars['UUID']
    microstepId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddChallengeGoalsArgs = {
    newChallengeGoalsInput: Array<ChallengeGoalCreateInput>
}

export type UnifiedChallengesMutationAddChallengesToSubscriptionArgs = {
    challengesToAdd?: InputMaybe<Array<Scalars['UUID']>>
    companyId: Scalars['UUID']
    storyTypeId?: InputMaybe<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddChallengesToSubscriptionsArgs = {
    challengesToAdd: Array<Scalars['UUID']>
    companiesSubscriptionsToAdd?: InputMaybe<Array<SubscriptionCompanyInput>>
    companiesSubscriptionsToExclude?: InputMaybe<
        Array<SubscriptionCompanyInput>
    >
    storyTypeId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationAddStorySubmissionToCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddWaterIntakeArgs = {
    amount: Scalars['Int']
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesMutationChangeCommunityChallengeTeamArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationChangeCommunityChallengeTeamAdminArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteChallengeArgs = {
    completeDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteChallengeAdminArgs = {
    companyId: Scalars['UUID']
    completeDate?: InputMaybe<Scalars['ISODate']>
    subscriptionId: Scalars['UUID']
    userChallengeActivityId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteCommunityChallengeDailyGoalArgs = {
    challengeGoalId: Scalars['UUID']
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteCommunityChallengeDayArgs = {
    challengeId: Scalars['UUID']
    day: Scalars['Int']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCreateChallengeArgs = {
    newChallengeId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationCreateChallengeFromTemplateArgs = {
    challengeTemplateCreateInput: ChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationCreateGroupChallengeFromTemplateArgs = {
    input: GroupChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationCreateStoryQuestionsArgs = {
    id?: InputMaybe<Scalars['UUID']>
    storyCreateInput: StoryQuestionCreateInput
}

export type UnifiedChallengesMutationCreateStoryQuestionsI18NArgs = {
    storyCreateInput: StoryQuestionCreateInput
}

export type UnifiedChallengesMutationDeleteStoryArgs = {
    storyID: Scalars['UUID']
}

export type UnifiedChallengesMutationEditStoryArgs = {
    updatedStory: StoryCreateInput
}

export type UnifiedChallengesMutationJoinChallengeArgs = {
    challengeId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationJoinChallengeAdminArgs = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    locale?: InputMaybe<Scalars['Locale']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
    thriveTimeZone?: InputMaybe<Scalars['String']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationJoinCommunityChallengeArgs = {
    challengeId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
    socialGroupName?: InputMaybe<Scalars['String']>
}

export type UnifiedChallengesMutationLeaveChallengeArgs = {
    leaveDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedChallengesMutationLeaveChallengeAdminArgs = {
    leaveDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveChallengeArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveChallengeGoalsArgs = {
    challengeGoalIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesMutationRemoveChallengesFromCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesMutationRemoveGroupChallengeArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveUserFromChallengeArgs = {
    challengeId: Scalars['UUID']
    userIdToRemove: Scalars['UUID']
}

export type UnifiedChallengesMutationSetChallengeCustomizationArgs = {
    challengeId: Scalars['UUID']
    waterAmount?: InputMaybe<Scalars['Int']>
}

export type UnifiedChallengesMutationSetChallengeDefaultStatusArgs = {
    challengeId: Scalars['UUID']
    isDefault: Scalars['Boolean']
}

export type UnifiedChallengesMutationSetChallengesStatusForSubscriptionArgs = {
    challenges: Array<Scalars['UUID']>
    status: Scalars['Boolean']
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesMutationSetTutorialWatchedStatusArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesMutationSubmitStoryArgs = {
    newStory: StoryCreateInput
}

export type UnifiedChallengesMutationUpdateChallengeGoalArgs = {
    challengeGoalId: Scalars['UUID']
    challengeGoalInput: ChallengeGoalCreateInput
}

export type UnifiedChallengesMutationUpdateChallengeInstanceArgs = {
    challengeId: Scalars['UUID']
    challengeTemplateCreateInput: ChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationUpdateJoinDateArgs = {
    joinDate: Scalars['String']
    modifyExpirationDate: Scalars['Boolean']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationUpdateMeasureUnitSettingsArgs = {
    measureUnit: MeasureUnit
}

export type UnifiedChallengesMutationUpdateStorySubmissionToCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengesMutationUpdateWeekendNotificationSettingsArgs = {
    weekendNotificationsActivated: Scalars['Boolean']
}

export type UnifiedChallengesQuery = {
    __typename?: 'UnifiedChallengesQuery'
    /** Get all challenges provisioned */
    allChallenges: Array<UnifiedChallengeAdmin>
    /** Get available interactions for a challenge */
    availableInteractions: Array<StoryType>
    /** Get available interactions for a challenge template */
    availableTemplateInteractions: Array<StoryType>
    /** Get a list of subscriptions information for a given challenge */
    challengeSubscriptionsInfo: Array<ChallengeSubscriptionsInfo>
    /** Get challenge template goals */
    challengeTemplateGoals: Array<ChallengeTemplateGoal>
    challengeTemplatesAdmin: Array<ChallengeTemplate>
    /** Get challenges by subscriptionId */
    challengesBySubscription: Array<AdminChallengeSubscription>
    /** Get community challenge goals */
    communityChallengeGoals: Array<ChallengeGoal>
    /** Get community challenge goals */
    communityChallengeGoalsAdmin: Array<ChallengeGoal>
    /** Get team members daily goals and progress for a challenge */
    communityChallengeTeamDailyGoals: Array<ChallengeTeamDailyGoal>
    /** Get community challenge template goals */
    communityChallengeTemplateGoals: Array<ChallengeTemplateGoal>
    /** Get total number of completed daily goals for a user */
    communityChallengeTotalDailyGoalsCompleted: Scalars['Int']
    /** Get user daily goals and progress for a challenge */
    communityChallengeUserDailyGoals: Array<ChallengeUserDailyGoal>
    /** Returns the user daily and total steps for a community challenge */
    communityChallengeUserSteps: StepsInfo
    communityUserChallenges: Array<UnifiedChallengeParticipation>
    getChallengeCustomization: ChallengeCustomizationOperationResult
    /** Get community challenge total steps */
    getChallengeTotalSteps: Scalars['Int']
    /** Get community challenge total water */
    getChallengeTotalWater: Scalars['Int']
    /** Get a list of completed days of a community challenge by the user */
    getCommunityChallengeCompletedDays: Array<CommunityChallengeCompletedDay>
    /** Get community challenge threshold */
    getCommunityChallengeThreshold?: Maybe<CommunityChallengeThreshold>
    /** Get the water measure units defined for this user */
    getMeasureUnitSettings?: Maybe<MeasureUnit>
    /** Returns the user's rank if participating solo on a given challenge */
    getSoloPlayerRank: ScoreRanked
    /** Get stories related to a challenge submitted by a user */
    getStories: Array<Story>
    /** Returns the user's team rank on a given challenge */
    getTeamRank: ScoreRanked
    /** Get team's total water for a challenge */
    getTeamTotalWater: Scalars['Int']
    /** Returns the team players leaderboard for a challenge */
    getTeamsLeaderboard: Leaderboard
    /** Returns a boolean indicating if a user has watched the tutorial */
    getTutorialWatchedStatus: Scalars['Boolean']
    /** Get user's total water for a challenge */
    getUserTotalWater: Scalars['Int']
    /** Returns the solo players leaderboard for a challenge */
    getUsersLeaderboard: Leaderboard
    getWaterIntake: WaterOperationResult
    /** Get the notification status for a user for the weekend */
    getWeekendNotificationStatus?: Maybe<Scalars['Boolean']>
    groupChallengeTemplate?: Maybe<ChallengeTemplate>
    groupChallengeTemplates: Array<ChallengeTemplate>
    /** Get story types based on enabled status */
    storyTypes: Array<StoryTypeAdmin>
    /** Get challenge */
    unifiedChallenge?: Maybe<UnifiedChallenge>
    /** Get challenges */
    unifiedChallenges: Array<UnifiedChallenge>
    unifiedChallengesAdmin: Array<UnifiedChallenge>
    /** Get challenges by subscriptionId */
    unifiedChallengesBySubscriptionId: Array<UnifiedChallenge>
    unifiedUserChallenges: Array<UnifiedChallengeParticipation>
    unifiedUserChallengesAdmin: Array<UnifiedChallengeParticipation>
    usersParticipatingInChallenge: Array<Scalars['UUID']>
    usersTotalCheckins: Array<UserTotalCheckins>
}

export type UnifiedChallengesQueryAllChallengesArgs = {
    subscriptionIds?: InputMaybe<Array<Scalars['UUID']>>
}

export type UnifiedChallengesQueryAvailableInteractionsArgs = {
    challengeId: Scalars['UUID']
    locale?: InputMaybe<Scalars['Locale']>
}

export type UnifiedChallengesQueryAvailableTemplateInteractionsArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    templateId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeSubscriptionsInfoArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeTemplateGoalsArgs = {
    challengeTemplateId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeTemplatesAdminArgs = {
    locale: Scalars['Locale']
}

export type UnifiedChallengesQueryChallengesBySubscriptionArgs = {
    subscriptionIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesQueryCommunityChallengeGoalsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeGoalsAdminArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTeamDailyGoalsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTemplateGoalsArgs = {
    challengeTemplateId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTotalDailyGoalsCompletedArgs =
    {
        challengeId: Scalars['UUID']
    }

export type UnifiedChallengesQueryCommunityChallengeUserDailyGoalsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeUserStepsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeCustomizationArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeTotalStepsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeTotalWaterArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetCommunityChallengeCompletedDaysArgs = {
    challengeId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetCommunityChallengeThresholdArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetSoloPlayerRankArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetStoriesArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetTeamRankArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetTeamTotalWaterArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetTeamsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetUserTotalWaterArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetUsersLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
}

export type UnifiedChallengesQueryGetWaterIntakeArgs = {
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesQueryGroupChallengeTemplateArgs = {
    templateId: Scalars['UUID']
}

export type UnifiedChallengesQueryStoryTypesArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesQueryUnifiedChallengeArgs = {
    challengeId: Scalars['UUID']
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesQueryUnifiedChallengesArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesQueryUnifiedChallengesAdminArgs = {
    locale: Scalars['Locale']
    subscriptionId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type UnifiedChallengesQueryUnifiedChallengesBySubscriptionIdArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesQueryUnifiedUserChallengesArgs = {
    inProgress?: InputMaybe<Scalars['Boolean']>
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
    userChallengeActivityId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryUnifiedUserChallengesAdminArgs = {
    companyId?: InputMaybe<Scalars['UUID']>
    inProgress?: InputMaybe<Scalars['Boolean']>
    locale?: InputMaybe<Scalars['Locale']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
    userChallengeActivityId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesQueryUsersParticipatingInChallengeArgs = {
    challengeId: Scalars['UUID']
    companyId?: InputMaybe<Scalars['UUID']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
    withCheckins: Scalars['Boolean']
}

export type UnifiedChallengesQueryUsersTotalCheckinsArgs = {
    challengeId: Scalars['UUID']
    companyId?: InputMaybe<Scalars['UUID']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedJourney = {
    __typename?: 'UnifiedJourney'
    id: Scalars['UUID']
    name?: Maybe<Scalars['String']>
    shortName?: Maybe<Scalars['String']>
}

export type UnifiedMicrostep = {
    __typename?: 'UnifiedMicrostep'
    action?: Maybe<Scalars['String']>
    body?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['Instant']>
    deprecatedAt?: Maybe<Scalars['Instant']>
    duration?: Maybe<Scalars['Int']>
    id: Scalars['UUID']
    journeys?: Maybe<Array<UnifiedJourney>>
    reminderEnabled?: Maybe<Scalars['Boolean']>
    timeUnit?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type UnifiedMicrostepActivities = {
    __typename?: 'UnifiedMicrostepActivities'
    checkIns: Array<Scalars['Instant']>
    microstep: UnifiedMicrostep
}

export type UnifiedResult =
    | EnablingChallengeStatus
    | ErrorMessage
    | JoinChallengeSucceeded
    | UpdateUserChallengeSucceeded

export type UnifiedStreak = {
    __typename?: 'UnifiedStreak'
    currentStreak: Scalars['Int']
    id: Scalars['UUID']
    maxStreak: Scalars['Int']
    updatedAt: Scalars['Instant']
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedUserChallengeActivity = {
    __typename?: 'UnifiedUserChallengeActivity'
    /** The id of this user Challenge Activity */
    challengeActivityId: Scalars['UUID']
    /** The id of the challenge  */
    challengeId: Scalars['UUID']
    /** The completion date for the challenge */
    completedOn?: Maybe<Scalars['Instant']>
    /** The completion date for the challenge */
    completedOnLocalDate?: Maybe<Scalars['ISODate']>
    createdAt: Scalars['Instant']
    /** The expiration date for the challenge */
    expiredOn?: Maybe<Scalars['Instant']>
    /** The expiration date for the challenge */
    expiredOnLocalDate?: Maybe<Scalars['ISODate']>
    /** The creation date for the relation */
    joinDate?: Maybe<Scalars['Instant']>
    /** The creation date for the relation */
    joinLocalDate?: Maybe<Scalars['ISODate']>
    /** The date when the user left a challenge */
    leaveDate?: Maybe<Scalars['Instant']>
    /** The date when the user left a challenge */
    leaveLocalDate?: Maybe<Scalars['ISODate']>
    /** The social group id of the challenge  */
    socialGroupId?: Maybe<Scalars['UUID']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The id of the user  */
    userId: Scalars['String']
}

export type UpdateDisplayNameOperationSucceeded = {
    __typename?: 'UpdateDisplayNameOperationSucceeded'
    /** The display name of the user */
    displayName: Scalars['String']
    /** The ID of the post for which the operation succeeded */
    userId: Scalars['String']
}

export type UpdateIsPublicOperationSucceeded = {
    __typename?: 'UpdateIsPublicOperationSucceeded'
    /** The isPublic status of the user */
    isPublic: Scalars['Boolean']
    /** The ID of the user for which the operation succeeded */
    userId: Scalars['String']
}

export type UpdateResult = {
    __typename?: 'UpdateResult'
    updatedChallenge: UnifiedChallenge
}

export type UpdateUserChallengeSucceeded = {
    __typename?: 'UpdateUserChallengeSucceeded'
    challengeActivity: UnifiedUserChallengeActivity
}

export type UploadFile = {
    __typename?: 'UploadFile'
    alternativeText?: Maybe<Scalars['String']>
    caption?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['DateTime']>
    ext?: Maybe<Scalars['String']>
    formats?: Maybe<Scalars['JSON']>
    hash: Scalars['String']
    height?: Maybe<Scalars['Int']>
    mime: Scalars['String']
    name: Scalars['String']
    previewUrl?: Maybe<Scalars['String']>
    provider: Scalars['String']
    provider_metadata?: Maybe<Scalars['JSON']>
    related?: Maybe<Array<Maybe<GenericMorph>>>
    size: Scalars['Float']
    updatedAt?: Maybe<Scalars['DateTime']>
    url: Scalars['String']
    width?: Maybe<Scalars['Int']>
}

export type UploadFileEntity = {
    __typename?: 'UploadFileEntity'
    attributes?: Maybe<UploadFile>
    id?: Maybe<Scalars['ID']>
}

export type UploadFileEntityResponse = {
    __typename?: 'UploadFileEntityResponse'
    data?: Maybe<UploadFileEntity>
}

export type UploadFileEntityResponseCollection = {
    __typename?: 'UploadFileEntityResponseCollection'
    data: Array<UploadFileEntity>
    meta: ResponseCollectionMeta
}

export type UploadFileFiltersInput = {
    alternativeText?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>
    caption?: InputMaybe<StringFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    ext?: InputMaybe<StringFilterInput>
    folder?: InputMaybe<UploadFolderFiltersInput>
    folderPath?: InputMaybe<StringFilterInput>
    formats?: InputMaybe<JsonFilterInput>
    hash?: InputMaybe<StringFilterInput>
    height?: InputMaybe<IntFilterInput>
    id?: InputMaybe<IdFilterInput>
    mime?: InputMaybe<StringFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<UploadFileFiltersInput>
    or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>
    previewUrl?: InputMaybe<StringFilterInput>
    provider?: InputMaybe<StringFilterInput>
    provider_metadata?: InputMaybe<JsonFilterInput>
    size?: InputMaybe<FloatFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    url?: InputMaybe<StringFilterInput>
    width?: InputMaybe<IntFilterInput>
}

export type UploadFileInput = {
    alternativeText?: InputMaybe<Scalars['String']>
    caption?: InputMaybe<Scalars['String']>
    ext?: InputMaybe<Scalars['String']>
    folder?: InputMaybe<Scalars['ID']>
    folderPath?: InputMaybe<Scalars['String']>
    formats?: InputMaybe<Scalars['JSON']>
    hash?: InputMaybe<Scalars['String']>
    height?: InputMaybe<Scalars['Int']>
    mime?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    previewUrl?: InputMaybe<Scalars['String']>
    provider?: InputMaybe<Scalars['String']>
    provider_metadata?: InputMaybe<Scalars['JSON']>
    size?: InputMaybe<Scalars['Float']>
    url?: InputMaybe<Scalars['String']>
    width?: InputMaybe<Scalars['Int']>
}

export type UploadFileRelationResponseCollection = {
    __typename?: 'UploadFileRelationResponseCollection'
    data: Array<UploadFileEntity>
}

export type UploadFolder = {
    __typename?: 'UploadFolder'
    children?: Maybe<UploadFolderRelationResponseCollection>
    createdAt?: Maybe<Scalars['DateTime']>
    files?: Maybe<UploadFileRelationResponseCollection>
    name: Scalars['String']
    parent?: Maybe<UploadFolderEntityResponse>
    path: Scalars['String']
    pathId: Scalars['Int']
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type UploadFolderChildrenArgs = {
    filters?: InputMaybe<UploadFolderFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UploadFolderFilesArgs = {
    filters?: InputMaybe<UploadFileFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UploadFolderEntity = {
    __typename?: 'UploadFolderEntity'
    attributes?: Maybe<UploadFolder>
    id?: Maybe<Scalars['ID']>
}

export type UploadFolderEntityResponse = {
    __typename?: 'UploadFolderEntityResponse'
    data?: Maybe<UploadFolderEntity>
}

export type UploadFolderEntityResponseCollection = {
    __typename?: 'UploadFolderEntityResponseCollection'
    data: Array<UploadFolderEntity>
    meta: ResponseCollectionMeta
}

export type UploadFolderFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>
    children?: InputMaybe<UploadFolderFiltersInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    files?: InputMaybe<UploadFileFiltersInput>
    id?: InputMaybe<IdFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<UploadFolderFiltersInput>
    or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>
    parent?: InputMaybe<UploadFolderFiltersInput>
    path?: InputMaybe<StringFilterInput>
    pathId?: InputMaybe<IntFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type UploadFolderInput = {
    children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    name?: InputMaybe<Scalars['String']>
    parent?: InputMaybe<Scalars['ID']>
    path?: InputMaybe<Scalars['String']>
    pathId?: InputMaybe<Scalars['Int']>
}

export type UploadFolderRelationResponseCollection = {
    __typename?: 'UploadFolderRelationResponseCollection'
    data: Array<UploadFolderEntity>
}

export type UpsertUserInput = {
    email: Scalars['String']
    realm?: InputMaybe<Realm>
    subscriptionId: Scalars['UUID']
}

export type UsageQuery = {
    __typename?: 'UsageQuery'
    /** Historical App Usage for a company */
    historicAppUsage: HistoricAppUsageResponse
}

export type UsageQueryHistoricAppUsageArgs = {
    companyId: Scalars['UUID']
    end: MonthYearInput
    start: MonthYearInput
}

export type User = {
    __typename?: 'User'
    attributes: Array<UserAttribute>
    company?: Maybe<Company>
    email?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    id: Scalars['String']
    idpIds: Array<IdpIdEntry>
    lastName?: Maybe<Scalars['String']>
    realm?: Maybe<Realm>
    /** Social Groups for this user */
    socialGroups?: Maybe<Array<SocialGroup>>
    subscription?: Maybe<UserSubscription>
    verified?: Maybe<Scalars['Boolean']>
}

export type UserAchievementEventInfo = {
    __typename?: 'UserAchievementEventInfo'
    actionType?: Maybe<ActionType>
    createdAt: Scalars['Instant']
    entityId?: Maybe<Scalars['UUID']>
    eventDate?: Maybe<Scalars['ISODate']>
    eventTimestamp?: Maybe<Scalars['Instant']>
    updatedAt: Scalars['Instant']
}

export type UserAttribute = {
    __typename?: 'UserAttribute'
    key: Scalars['String']
    value?: Maybe<Scalars['String']>
}

export type UserAttributeInput = {
    key: Scalars['String']
    value?: InputMaybe<Scalars['String']>
}

export type UserAttributeMutation = {
    __typename?: 'UserAttributeMutation'
    /** Update attributes for authenticated user */
    save: Array<IdentityUserAttribute>
    /**
     * Update locale and timezone for authenticated user
     * @deprecated Use more generic save instead
     */
    update: Array<IdentityUserAttribute>
}

export type UserAttributeMutationSaveArgs = {
    attributes: Array<UserAttributeInput>
}

export type UserAttributeMutationUpdateArgs = {
    locale: Scalars['AllowedLocale']
    timeZone: Scalars['TimeZone']
}

export type UserAttributeQuery = {
    __typename?: 'UserAttributeQuery'
    /** Get user attributes for the authenticated user (does not include attributes from the IDP) */
    get: Array<IdentityUserAttribute>
    /** Get user attributes for the given user (does not include attributes from the IDP) */
    getForUserId: Array<IdentityUserAttribute>
}

export type UserAttributeQueryGetForUserIdArgs = {
    userId: Scalars['UUID']
}

export type UserChallengeFilter = {
    inProgress?: InputMaybe<Scalars['Boolean']>
    streaksOnQuery?: InputMaybe<Scalars['Boolean']>
}

export type UserCheckinMaturity = {
    __typename?: 'UserCheckinMaturity'
    checkInsUntilMature: Scalars['Int']
    isAccountMature: Scalars['Boolean']
}

export type UserConfigInput = {
    /** The user's company id */
    companyId: Scalars['UUID']
    /** The locale in which the Daily Check-In should be translated. Defaults to en-US */
    locale?: InputMaybe<Scalars['Locale']>
    /** The user's thrive id */
    thriveId: Scalars['UUID']
}

export type UserDto = {
    __typename?: 'UserDTO'
    /** Any linked accounts the user has connected with Pulse (i.e. Slack/Teams) */
    accounts: Array<LinkedAccountDto>
    /** The company this user is assigned to */
    companyId: Scalars['UUID']
    /** The company name, as per the user's Thrive profile */
    companyName?: Maybe<Scalars['String']>
    /** When the user was created in Pulse */
    createdAt: Scalars['Instant']
    /** The user's first name, as per their Thrive profile */
    firstName?: Maybe<Scalars['String']>
    /** The internal pulse id for this user */
    id: Scalars['UUID']
    /** The user's last name, as per their Thrive profile */
    lastName?: Maybe<Scalars['String']>
    /** The user's locale, as per their Thrive profile */
    locale?: Maybe<Scalars['String']>
    /** The global thrive id for this user, if known */
    thriveId?: Maybe<Scalars['UUID']>
    /** The user's timezone, as per their Thrive profile */
    timeZone?: Maybe<Scalars['String']>
    /** When the user was last update in Pulse */
    updatedAt: Scalars['Instant']
}

export type UserInfo = {
    __typename?: 'UserInfo'
    companyId?: Maybe<Scalars['UUID']>
    id: Scalars['UUID']
    locale?: Maybe<Scalars['Locale']>
    measureUnit?: Maybe<MeasureUnit>
    notificationsActivated?: Maybe<Scalars['Boolean']>
    subscriptionId?: Maybe<Scalars['UUID']>
    timeZone?: Maybe<Scalars['String']>
    weekendNotificationsActivated?: Maybe<Scalars['Boolean']>
}

export type UserInfoInput = {
    companyId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UserInventoryInfo = {
    __typename?: 'UserInventoryInfo'
    createdAt?: Maybe<Scalars['Instant']>
    /** the product upc */
    productUpc?: Maybe<Scalars['String']>
    /** the transaction id */
    transactionId?: Maybe<Scalars['UUID']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The user id */
    userId: Scalars['UUID']
    /** the user plant Id */
    userPlantId?: Maybe<Scalars['UUID']>
}

export type UserJourneys = {
    __typename?: 'UserJourneys'
    /** @deprecated No longer supported */
    preferredJourneys: Array<Journey>
    /** Journey selected for Journey progress */
    selectedJourney?: Maybe<Journey>
    /** @deprecated No longer supported */
    subscriptionJourneys: Array<Journey>
}

export type UserLevelHistoryInfo = {
    __typename?: 'UserLevelHistoryInfo'
    claimedAt?: Maybe<Scalars['Instant']>
    completedAt?: Maybe<Scalars['Instant']>
    createdAt: Scalars['Instant']
    level?: Maybe<Scalars['Int']>
    updatedAt: Scalars['Instant']
}

export type UserMutation = {
    __typename?: 'UserMutation'
    /** Create a new user */
    createUser?: Maybe<WearablesUserDto>
    /** Create a new well-being source */
    createWellBeingSource?: Maybe<UserSourceDto>
    /** Update the connection status of a well-being source */
    updateWellBeingConnectionStatus: Scalars['Boolean']
}

export type UserMutationCreateWellBeingSourceArgs = {
    source: WellBeingSourceInput
}

export type UserMutationUpdateWellBeingConnectionStatusArgs = {
    connected: Scalars['Boolean']
    sourceId: Scalars['UUID']
}

export type UserPlant = {
    __typename?: 'UserPlant'
    colorLayer1?: Maybe<Scalars['String']>
    colorLayer2?: Maybe<Scalars['String']>
    colorLayer3?: Maybe<Scalars['String']>
    colorLayer4?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    gardenChallengeId?: Maybe<Scalars['UUID']>
    gardenSocialGroupId?: Maybe<Scalars['UUID']>
    id: Scalars['UUID']
    plantedGardenId?: Maybe<Scalars['UUID']>
    productUpc: Scalars['String']
    progressSteps: Scalars['Int']
    trophyChallengeId?: Maybe<Scalars['UUID']>
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
    wateredAt?: Maybe<Scalars['Instant']>
}

export type UserPlantInfo = {
    __typename?: 'UserPlantInfo'
    /** Indicates if the plant can be watered */
    canBeWatered: Scalars['Boolean']
    /** Challenge id the plant belongs to */
    challengeId?: Maybe<Scalars['UUID']>
    /** Challenge name the plant belongs to */
    challengeName?: Maybe<Scalars['String']>
    /** Plant color for layer 1 */
    colorLayer1?: Maybe<Scalars['String']>
    /** Plant color for layer 2 */
    colorLayer2?: Maybe<Scalars['String']>
    /** Plant color for layer 3 */
    colorLayer3?: Maybe<Scalars['String']>
    /** Plant color for layer 4 */
    colorLayer4?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    /** The plant id */
    id: Scalars['UUID']
    /** The plant's owner display name */
    ownerDisplayName?: Maybe<Scalars['String']>
    /** Plant image for TROPHY_PLANT type */
    plantImage?: Maybe<Scalars['String']>
    /** The garden id where the plant is planted */
    plantedGardenId?: Maybe<Scalars['UUID']>
    /** The product upc associated to the plant */
    productUpc: Scalars['String']
    /** The number of times a plant has been watered */
    progressSteps: Scalars['Int']
    updatedAt: Scalars['Instant']
    /** The user id the plant belongs to */
    userId: Scalars['UUID']
    /** Last time this plant was watered */
    wateredAt?: Maybe<Scalars['Instant']>
}

export type UserQuery = {
    __typename?: 'UserQuery'
    /** Get a user */
    getUser?: Maybe<WearablesUserDto>
    /** Get a user's well-being sources */
    sources: UserSourcesResponse
}

export type UserSourceDto = {
    __typename?: 'UserSourceDTO'
    connected: Scalars['Boolean']
    created: Scalars['Instant']
    externalSourceId?: Maybe<Scalars['String']>
    lastSynced?: Maybe<Scalars['Instant']>
    sourceId: Scalars['UUID']
    sourceName: Scalars['String']
}

export type UserSourcesResponse = {
    __typename?: 'UserSourcesResponse'
    directSources: Array<UserSourceDto>
    humanApiSources: Array<UserSourceDto>
}

/** User's subscription associated with Company. Type renamed as UserSubscription to avoid clash with GraphQL Subscription */
export type UserSubscription = {
    __typename?: 'UserSubscription'
    allowReferrals: Scalars['Boolean']
    companyId: Scalars['UUID']
    enabled: Scalars['Boolean']
    expiryDate?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    internalDescription?: Maybe<Scalars['String']>
    isDefault: Scalars['Boolean']
    license: LicenseType
    scopes: Array<Scalars['String']>
    /** @deprecated Subscription level seat count has been deprecated in favour of company level seat count, replace with company.seatsAllowed */
    seatsAllowed: Scalars['Int']
    startDate?: Maybe<Scalars['Instant']>
    subscriptionType: SubscriptionType
}

export type UserTotalCheckins = {
    __typename?: 'UserTotalCheckins'
    checkedInCount: Scalars['Int']
    userId: Scalars['UUID']
}

export type UsersPermissionsCreateRolePayload = {
    __typename?: 'UsersPermissionsCreateRolePayload'
    ok: Scalars['Boolean']
}

export type UsersPermissionsDeleteRolePayload = {
    __typename?: 'UsersPermissionsDeleteRolePayload'
    ok: Scalars['Boolean']
}

export type UsersPermissionsLoginInput = {
    identifier: Scalars['String']
    password: Scalars['String']
    provider?: Scalars['String']
}

export type UsersPermissionsLoginPayload = {
    __typename?: 'UsersPermissionsLoginPayload'
    jwt?: Maybe<Scalars['String']>
    user: UsersPermissionsMe
}

export type UsersPermissionsMe = {
    __typename?: 'UsersPermissionsMe'
    blocked?: Maybe<Scalars['Boolean']>
    confirmed?: Maybe<Scalars['Boolean']>
    email?: Maybe<Scalars['String']>
    id: Scalars['ID']
    role?: Maybe<UsersPermissionsMeRole>
    username: Scalars['String']
}

export type UsersPermissionsMeRole = {
    __typename?: 'UsersPermissionsMeRole'
    description?: Maybe<Scalars['String']>
    id: Scalars['ID']
    name: Scalars['String']
    type?: Maybe<Scalars['String']>
}

export type UsersPermissionsPasswordPayload = {
    __typename?: 'UsersPermissionsPasswordPayload'
    ok: Scalars['Boolean']
}

export type UsersPermissionsPermission = {
    __typename?: 'UsersPermissionsPermission'
    action: Scalars['String']
    createdAt?: Maybe<Scalars['DateTime']>
    role?: Maybe<UsersPermissionsRoleEntityResponse>
    updatedAt?: Maybe<Scalars['DateTime']>
}

export type UsersPermissionsPermissionEntity = {
    __typename?: 'UsersPermissionsPermissionEntity'
    attributes?: Maybe<UsersPermissionsPermission>
    id?: Maybe<Scalars['ID']>
}

export type UsersPermissionsPermissionFiltersInput = {
    action?: InputMaybe<StringFilterInput>
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    id?: InputMaybe<IdFilterInput>
    not?: InputMaybe<UsersPermissionsPermissionFiltersInput>
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>
    role?: InputMaybe<UsersPermissionsRoleFiltersInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
}

export type UsersPermissionsPermissionRelationResponseCollection = {
    __typename?: 'UsersPermissionsPermissionRelationResponseCollection'
    data: Array<UsersPermissionsPermissionEntity>
}

export type UsersPermissionsRegisterInput = {
    email: Scalars['String']
    password: Scalars['String']
    username: Scalars['String']
}

export type UsersPermissionsRole = {
    __typename?: 'UsersPermissionsRole'
    createdAt?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    name: Scalars['String']
    permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>
    type?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['DateTime']>
    users?: Maybe<UsersPermissionsUserRelationResponseCollection>
}

export type UsersPermissionsRolePermissionsArgs = {
    filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UsersPermissionsRoleUsersArgs = {
    filters?: InputMaybe<UsersPermissionsUserFiltersInput>
    pagination?: InputMaybe<PaginationArg>
    sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UsersPermissionsRoleEntity = {
    __typename?: 'UsersPermissionsRoleEntity'
    attributes?: Maybe<UsersPermissionsRole>
    id?: Maybe<Scalars['ID']>
}

export type UsersPermissionsRoleEntityResponse = {
    __typename?: 'UsersPermissionsRoleEntityResponse'
    data?: Maybe<UsersPermissionsRoleEntity>
}

export type UsersPermissionsRoleEntityResponseCollection = {
    __typename?: 'UsersPermissionsRoleEntityResponseCollection'
    data: Array<UsersPermissionsRoleEntity>
    meta: ResponseCollectionMeta
}

export type UsersPermissionsRoleFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>
    createdAt?: InputMaybe<DateTimeFilterInput>
    description?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    name?: InputMaybe<StringFilterInput>
    not?: InputMaybe<UsersPermissionsRoleFiltersInput>
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>
    permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>
    type?: InputMaybe<StringFilterInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    users?: InputMaybe<UsersPermissionsUserFiltersInput>
}

export type UsersPermissionsRoleInput = {
    description?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
    type?: InputMaybe<Scalars['String']>
    users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type UsersPermissionsUpdateRolePayload = {
    __typename?: 'UsersPermissionsUpdateRolePayload'
    ok: Scalars['Boolean']
}

export type UsersPermissionsUser = {
    __typename?: 'UsersPermissionsUser'
    blocked?: Maybe<Scalars['Boolean']>
    confirmed?: Maybe<Scalars['Boolean']>
    createdAt?: Maybe<Scalars['DateTime']>
    email: Scalars['String']
    provider?: Maybe<Scalars['String']>
    role?: Maybe<UsersPermissionsRoleEntityResponse>
    updatedAt?: Maybe<Scalars['DateTime']>
    username: Scalars['String']
}

export type UsersPermissionsUserEntity = {
    __typename?: 'UsersPermissionsUserEntity'
    attributes?: Maybe<UsersPermissionsUser>
    id?: Maybe<Scalars['ID']>
}

export type UsersPermissionsUserEntityResponse = {
    __typename?: 'UsersPermissionsUserEntityResponse'
    data?: Maybe<UsersPermissionsUserEntity>
}

export type UsersPermissionsUserEntityResponseCollection = {
    __typename?: 'UsersPermissionsUserEntityResponseCollection'
    data: Array<UsersPermissionsUserEntity>
    meta: ResponseCollectionMeta
}

export type UsersPermissionsUserFiltersInput = {
    and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>
    blocked?: InputMaybe<BooleanFilterInput>
    confirmationToken?: InputMaybe<StringFilterInput>
    confirmed?: InputMaybe<BooleanFilterInput>
    createdAt?: InputMaybe<DateTimeFilterInput>
    email?: InputMaybe<StringFilterInput>
    id?: InputMaybe<IdFilterInput>
    not?: InputMaybe<UsersPermissionsUserFiltersInput>
    or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>
    password?: InputMaybe<StringFilterInput>
    provider?: InputMaybe<StringFilterInput>
    resetPasswordToken?: InputMaybe<StringFilterInput>
    role?: InputMaybe<UsersPermissionsRoleFiltersInput>
    updatedAt?: InputMaybe<DateTimeFilterInput>
    username?: InputMaybe<StringFilterInput>
}

export type UsersPermissionsUserInput = {
    blocked?: InputMaybe<Scalars['Boolean']>
    confirmationToken?: InputMaybe<Scalars['String']>
    confirmed?: InputMaybe<Scalars['Boolean']>
    email?: InputMaybe<Scalars['String']>
    password?: InputMaybe<Scalars['String']>
    provider?: InputMaybe<Scalars['String']>
    resetPasswordToken?: InputMaybe<Scalars['String']>
    role?: InputMaybe<Scalars['ID']>
    username?: InputMaybe<Scalars['String']>
}

export type UsersPermissionsUserRelationResponseCollection = {
    __typename?: 'UsersPermissionsUserRelationResponseCollection'
    data: Array<UsersPermissionsUserEntity>
}

export type UsersQuery = {
    __typename?: 'UsersQuery'
    /** Returns the calling user's pulse profile, if one exists */
    me?: Maybe<UserDto>
}

export type ValidateDisplayNameOperationSucceeded = {
    __typename?: 'ValidateDisplayNameOperationSucceeded'
    /** The validity of the display name */
    valid: Scalars['Boolean']
}

export enum VerificationStatus {
    Unverified = 'unverified',
    Verifiedviacompanyclaimeddomain = 'verifiedviacompanyclaimeddomain',
    Verifiedviaconfirmationemail = 'verifiedviaconfirmationemail',
    Verifiedviasociallogin = 'verifiedviasociallogin',
    Verifiedviasso = 'verifiedviasso'
}

export type VideoArticle = Article & {
    __typename?: 'VideoArticle'
    authors: Array<ArticleAuthor>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    createdAt?: Maybe<Scalars['Instant']>
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    mimeType?: Maybe<Scalars['String']>
    rawUri?: Maybe<Scalars['String']>
    source: Scalars['String']
    summary: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
    userJourneys: UserJourneys
}

export enum Visibility {
    Private = 'PRIVATE',
    Public = 'PUBLIC'
}

export enum VisibleProfileAttribute {
    EmployeeId = 'employeeId',
    FacilityNumber = 'facilityNumber',
    FirstName = 'firstName',
    GroupCode = 'groupCode',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    ProfileAvatar = 'profileAvatar',
    ZipCode = 'zipCode'
}

export type WaterGoalDetail = {
    __typename?: 'WaterGoalDetail'
    water: Scalars['Int']
}

export type WaterIntake = {
    __typename?: 'WaterIntake'
    /** Amount of water for the day */
    amount: Scalars['Int']
    /** Challenge id */
    challengeId: Scalars['UUID']
    /** Intake water day */
    intakeDate: Scalars['ISODate']
    /** Social group the user belongs to */
    socialGroupId?: Maybe<Scalars['UUID']>
}

export type WaterOperationResult = ErrorMessageWater | IntakeResult

export enum WaterPlantError {
    ErrorAutomaticPlant = 'ERROR_AUTOMATIC_PLANT',
    ErrorWateringPlant = 'ERROR_WATERING_PLANT',
    PlantAlreadyFullyGrown = 'PLANT_ALREADY_FULLY_GROWN',
    PlantAlreadyWatered = 'PLANT_ALREADY_WATERED',
    PlantNotFoundForUser = 'PLANT_NOT_FOUND_FOR_USER'
}

export type WaterPlantErrorResult = {
    __typename?: 'WaterPlantErrorResult'
    error: WaterPlantError
    plantError?: Maybe<PlantActionError>
}

export type WaterPlantResponse = WaterPlantErrorResult | WaterPlantResult

export type WaterPlantResult = {
    __typename?: 'WaterPlantResult'
    plantActionResult?: Maybe<PlantActionResult>
    userPlant: UserPlantInfo
}

export type WearablesAccess = {
    __typename?: 'WearablesAccess'
    isActivityDataEnabled: Scalars['Boolean']
    isSleepDataEnabled: Scalars['Boolean']
}

export type WearablesInsightsQuery = {
    __typename?: 'WearablesInsightsQuery'
    /** Get daily activity summaries for a user grouped by day, week or month over a time period */
    activitySummaryStats?: Maybe<Array<ActivitySummaryStats>>
    /** Get daily sleep summaries for a user grouped by day, week or month over a time period */
    sleepSummaryStats?: Maybe<Array<SleepSummaryStats>>
}

export type WearablesInsightsQueryActivitySummaryStatsArgs = {
    intervalType: WearablesReportInterval
}

export type WearablesInsightsQuerySleepSummaryStatsArgs = {
    intervalType: WearablesReportInterval
}

export type WearablesPlatformMutation = {
    __typename?: 'WearablesPlatformMutation'
    /** Modify activity data for a user */
    activity: ActivityMutation
    /** Modify sleep data for a user */
    sleep: SleepMutation
    /** Update step count and run auto check-in for a user */
    updateActivitySummary?: Maybe<HapiActivitySummaryModel>
    /** Modify user data */
    users: UserMutation
}

export type WearablesPlatformMutationUpdateActivitySummaryArgs = {
    localTimestamp?: InputMaybe<Scalars['String']>
    localUTCTimezoneOffsetInMinutes?: InputMaybe<Scalars['Int']>
}

export type WearablesPlatformQuery = {
    __typename?: 'WearablesPlatformQuery'
    /** Get manual activity data for a user */
    activity: ActivityQuery
    /** Get HumanAPI session or id token required to open the HumanAPI connect dialog */
    getHapiToken: ThriveHapiResponse
    /** Get last minutes asleep for a user */
    getLastSleep?: Maybe<Scalars['Int']>
    /** Get step count for a user */
    getStepCount?: Maybe<Scalars['Int']>
    /** Get human api specific data */
    humanApi: HumanApiQuery
    /** Get insights/reporting data from Wearables */
    insights: WearablesInsightsQuery
    /** Get user data */
    users: UserQuery
    /** Get user's permissions to access/store steps and sleep via wearables */
    wearablesAccess: WearablesAccess
}

export type WearablesPlatformQueryGetHapiTokenArgs = {
    email: Scalars['String']
    thriveUserId: Scalars['String']
}

export type WearablesPlatformQueryInsightsArgs = {
    from: Scalars['String']
    to: Scalars['String']
}

export enum WearablesReportInterval {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Ungrouped = 'UNGROUPED',
    Weekly = 'WEEKLY'
}

export type WearablesUserDto = {
    __typename?: 'WearablesUserDTO'
    companyId: Scalars['UUID']
    created: Scalars['Instant']
    hasActiveAppleHealthDirectConnection: Scalars['Boolean']
    hasActiveHumanApiConnection: Scalars['Boolean']
    hasAppleHealthDirectConnection: Scalars['Boolean']
    hasHumanApiConnection: Scalars['Boolean']
    isHumanApiUser: Scalars['Boolean']
    userId: Scalars['UUID']
}

export type Webinar = {
    __typename?: 'Webinar'
    description?: Maybe<Scalars['String']>
    events: Array<WebinarEvent>
    excludedSubscriptions: Array<Scalars['UUID']>
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    includedSubscriptions: Array<Scalars['UUID']>
    isUnrestricted: Scalars['Boolean']
    title: Scalars['String']
    webinarProgress: WebinarProgress
}

export type WebinarEvent = {
    __typename?: 'WebinarEvent'
    id: Scalars['ID']
    imageUrl: Scalars['String']
    sessions: Array<WebinarSession>
    subtitle?: Maybe<Scalars['String']>
    tasks: Array<WebinarResource>
    title: Scalars['String']
    webinarEventProgress?: Maybe<WebinarEventProgress>
}

export type WebinarEventProgress = {
    __typename?: 'WebinarEventProgress'
    durationInSeconds: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfPristineSessions: Scalars['Int']
    numberOfSessionsInProgress: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type WebinarPage = {
    __typename?: 'WebinarPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Webinar>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type WebinarProgress = {
    __typename?: 'WebinarProgress'
    attendedEvents: Scalars['Int']
    completedEvents: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    percentageProgress: Scalars['Float']
}

export type WebinarRegistrationResponse = {
    __typename?: 'WebinarRegistrationResponse'
    isForbidden: Scalars['Boolean']
    isNewRegistration: Scalars['Boolean']
    isNotFound: Scalars['Boolean']
    webinar?: Maybe<Webinar>
}

/** Webinar Resource to contain PDF or Survey */
export type WebinarResource = {
    __typename?: 'WebinarResource'
    createdAt: Scalars['Instant']
    id: Scalars['ID']
    /** Course resource type */
    resourceType: CourseResourceType
    /** link to the resource */
    source: Scalars['String']
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type WebinarSession = {
    __typename?: 'WebinarSession'
    endTime?: Maybe<Scalars['Instant']>
    id: Scalars['ID']
    liveUrl: Scalars['String']
    startTime: Scalars['Instant']
    videoUrl?: Maybe<Scalars['String']>
    webinarSessionProgress?: Maybe<WebinarSessionProgress>
}

export type WebinarSessionProgress = {
    __typename?: 'WebinarSessionProgress'
    durationInSeconds: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type WebinarVideoContentStatusInput = {
    /** Webinar Video id */
    contentId: Scalars['String']
    /** Webinar Video progress in seconds */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    totalInSeconds?: InputMaybe<Scalars['Int']>
}

export type WeeklyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'WeeklyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WeeklyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'WeeklyAssessmentStatsResponse'
    average: Scalars['Float']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WeeklySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'WeeklySleepSummaryStatsResponse'
    averageMinutesAsleep: Scalars['Float']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WellBeingSourceInput = {
    connected: Scalars['Boolean']
    sourceName: Scalars['String']
}

export type WhenToSAccepted = {
    __typename?: 'WhenToSAccepted'
    serverTime: Scalars['Instant']
    userId: Scalars['UUID']
    userTime?: Maybe<Scalars['Instant']>
    userZoneId?: Maybe<Scalars['TimeZone']>
}

export type WithBookmarkMutation = {
    bookmark: BookmarkMutation
}

export type WithBookmarkQuery = {
    bookmark: BookmarkQuery
}

export type WithLikeMutation = {
    like: LikeMutation
}

export type WithLikeQuery = {
    like: LikeQuery
}

export type CommitUserToMicrostepsMutationVariables = Exact<{
    microsteps: Array<Scalars['ID']> | Scalars['ID']
}>

export type CommitUserToMicrostepsMutation = {
    __typename?: 'Mutation'
    today: {
        __typename?: 'TodayMutation'
        commitToMicrosteps: Array<{
            __typename?: 'MicrostepSubscription'
            status: string
            microstepId: string
        }>
    }
}

export type UnCommitToMicrostepMutationVariables = Exact<{
    microstepAndRemovalReason:
        | Array<MicrostepAndRemovalReasonInput>
        | MicrostepAndRemovalReasonInput
}>

export type UnCommitToMicrostepMutation = {
    __typename?: 'Mutation'
    today: {
        __typename?: 'TodayMutation'
        unCommitToMicrostep: Array<{
            __typename?: 'MicrostepSubscription'
            microstepId: string
            status: string
            userId: string
        }>
    }
}

export const CommitUserToMicrostepsDocument = gql`
    mutation commitUserToMicrosteps($microsteps: [ID!]!) {
        today {
            commitToMicrosteps(microstepIds: $microsteps) {
                status
                microstepId
            }
        }
    }
`
export type CommitUserToMicrostepsMutationFn = Apollo.MutationFunction<
    CommitUserToMicrostepsMutation,
    CommitUserToMicrostepsMutationVariables
>

/**
 * __useCommitUserToMicrostepsMutation__
 *
 * To run a mutation, you first call `useCommitUserToMicrostepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitUserToMicrostepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitUserToMicrostepsMutation, { data, loading, error }] = useCommitUserToMicrostepsMutation({
 *   variables: {
 *      microsteps: // value for 'microsteps'
 *   },
 * });
 */
export function useCommitUserToMicrostepsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CommitUserToMicrostepsMutation,
        CommitUserToMicrostepsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CommitUserToMicrostepsMutation,
        CommitUserToMicrostepsMutationVariables
    >(CommitUserToMicrostepsDocument, options)
}
export type CommitUserToMicrostepsMutationHookResult = ReturnType<
    typeof useCommitUserToMicrostepsMutation
>
export type CommitUserToMicrostepsMutationResult =
    Apollo.MutationResult<CommitUserToMicrostepsMutation>
export type CommitUserToMicrostepsMutationOptions = Apollo.BaseMutationOptions<
    CommitUserToMicrostepsMutation,
    CommitUserToMicrostepsMutationVariables
>
export const UnCommitToMicrostepDocument = gql`
    mutation UnCommitToMicrostep(
        $microstepAndRemovalReason: [MicrostepAndRemovalReasonInput!]!
    ) {
        today {
            unCommitToMicrostep(
                microstepAndRemovalReason: $microstepAndRemovalReason
            ) {
                microstepId
                status
                userId
            }
        }
    }
`
export type UnCommitToMicrostepMutationFn = Apollo.MutationFunction<
    UnCommitToMicrostepMutation,
    UnCommitToMicrostepMutationVariables
>

/**
 * __useUnCommitToMicrostepMutation__
 *
 * To run a mutation, you first call `useUnCommitToMicrostepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnCommitToMicrostepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unCommitToMicrostepMutation, { data, loading, error }] = useUnCommitToMicrostepMutation({
 *   variables: {
 *      microstepAndRemovalReason: // value for 'microstepAndRemovalReason'
 *   },
 * });
 */
export function useUnCommitToMicrostepMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnCommitToMicrostepMutation,
        UnCommitToMicrostepMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UnCommitToMicrostepMutation,
        UnCommitToMicrostepMutationVariables
    >(UnCommitToMicrostepDocument, options)
}
export type UnCommitToMicrostepMutationHookResult = ReturnType<
    typeof useUnCommitToMicrostepMutation
>
export type UnCommitToMicrostepMutationResult =
    Apollo.MutationResult<UnCommitToMicrostepMutation>
export type UnCommitToMicrostepMutationOptions = Apollo.BaseMutationOptions<
    UnCommitToMicrostepMutation,
    UnCommitToMicrostepMutationVariables
>

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[]
    }
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AboutPageAboutPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        ActivitySummaryStats: [
            'ActivitySummaryStatsResponse',
            'DailyActivitySummaryStatsResponse',
            'MonthlyActivitySummaryStatsResponse',
            'WeeklyActivitySummaryStatsResponse'
        ],
        Article: ['AudioArticle', 'TextArticle', 'VideoArticle'],
        AssessmentStats: [
            'DailyAssessmentStatsResponse',
            'MonthlyAssessmentStatsResponse',
            'UngroupedAssessmentStatsResponse',
            'WeeklyAssessmentStatsResponse'
        ],
        BookmarkedContent: ['ThriveReset', 'ThriveResetAudio'],
        CalendarServiceFields: ['Google', 'Office365', 'Outlook'],
        CareersPageCareersPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFeatureGridWithPhoto',
            'ComponentMsComponentsFeatureWithPhoto',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        ChallengeCustomizationOperationResult: [
            'ChallengeCustomizationResult',
            'ErrorMessageCustomization'
        ],
        CourseMutation: ['LearnMutationV2'],
        CourseQuery: ['LearnQueryV2'],
        EthicsPageEthicsPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        FeedbackQuestionResponse: ['FeedbackQuestionYesNoResponse'],
        Frame: ['Image', 'PersonalizedQuote', 'ThriveQuote'],
        GenericMorph: [
            'AboutPage',
            'CareersPage',
            'CmsAchievement',
            'CmsArticle',
            'CmsArticleArchive',
            'CmsBiotype',
            'CmsChallenge',
            'CmsCompanyResourceSection',
            'CmsCompanySubscription',
            'CmsContributor',
            'CmsCourse',
            'CmsCourseId',
            'CmsCourseResource',
            'CmsJourney',
            'CmsMicrostep',
            'CmsPodcast',
            'CmsPodcastEpisode',
            'CmsPodcastSeason',
            'CmsPulseDimension',
            'CmsReset',
            'CmsResetAudio',
            'CmsResetStockAudio',
            'CmsResetStockImage',
            'CmsResetStockQuote',
            'CmsWebinar',
            'ComponentArticleAudioArticle',
            'ComponentArticleAuthors',
            'ComponentArticleTextArticle',
            'ComponentArticleVideoArticle',
            'ComponentCompanyCompanyResource',
            'ComponentContributorType',
            'ComponentCourseBlurb',
            'ComponentCourseLesson',
            'ComponentCourseModule',
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFeatureGridWithPhoto',
            'ComponentMsComponentsFeatureWithPhoto',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'ComponentSurveyChoiceChoices',
            'ComponentWebinarWebinarEvent',
            'ComponentWebinarWebinarSession',
            'EthicsPage',
            'Homepage',
            'I18NLocale',
            'MsTeamsSubscribe',
            'MsTeamsWelcome',
            'Persona',
            'PrivacyPage',
            'ProductPage',
            'PulseSurvey',
            'Quote',
            'SecurityPage',
            'StoriesPage',
            'TermsPage',
            'TodayFeaturedItem',
            'UploadFile',
            'UploadFolder',
            'UsersPermissionsPermission',
            'UsersPermissionsRole',
            'UsersPermissionsUser'
        ],
        GoalEntityDetail: [
            'ArticleDetail',
            'DailyCheckinDetail',
            'ExternalLinkDetail',
            'MicrostepGoalDetail',
            'ResetGoalDetail',
            'StepsGoalDetail',
            'WaterGoalDetail'
        ],
        GuidePagesDynamicZone: [
            'ComponentGuidePageGuidePageInfo',
            'ComponentGuidePageGuidePageQuiz',
            'ComponentGuidePageGuidePageQuote',
            'ComponentGuidePageGuidePageVideo',
            'Error'
        ],
        HomepageHomepageComponentsDynamicZone: [
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        LearnContentParent: ['CourseModuleParent', 'PodcastEpisodeParent'],
        MicrostepMomentumResult: [
            'GraphQlMicrostepMomentum',
            'MicrostepMomentumErrorResponse'
        ],
        ModuleMutation: ['LearnMutationV2'],
        PlantActionResponse: ['PlantActionErrorResult', 'PlantActionResult'],
        PrivacyPagePrivacyPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        ProductPageProductPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'Error'
        ],
        PulseOnDemand: [
            'PulseDispatchEventResponse',
            'PulseOnDemandErrorResponse'
        ],
        PurchaseResponse: ['PurchaseErrorResult', 'PurchaseResult'],
        RemoveChallengeResult: ['RemoveErrorMessage', 'RemoveSuccess'],
        Reset: ['PersonalizedReset', 'ThriveReset', 'ThriveResetAudio'],
        SecurityPageSecurityPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        SleepSummaryStats: [
            'DailySleepSummaryStatsResponse',
            'MonthlySleepSummaryStatsResponse',
            'SleepSummaryStatsResponse',
            'WeeklySleepSummaryStatsResponse'
        ],
        SocialGroupsResult: [
            'InvalidAction',
            'InvalidInput',
            'PostOperationSucceeded',
            'PostReactionCreationSucceeded',
            'SocialGroup',
            'SocialGroupOperationSucceeded',
            'SocialGroupUndoPostReactionSucceed',
            'UpdateDisplayNameOperationSucceeded',
            'UpdateIsPublicOperationSucceeded',
            'ValidateDisplayNameOperationSucceeded'
        ],
        StoriesPageStoriesPageDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFeatureGridWithPhoto',
            'ComponentMsComponentsFeatureWithPhoto',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        TemplateOperationResult: [
            'ChallengeTemplateInstance',
            'ErrorMessageTemplate',
            'UpdateResult'
        ],
        TermsPageTermsPageComponentsDynamicZone: [
            'ComponentMsComponents1ColOverview',
            'ComponentMsComponents2ColFullBleedVisual',
            'ComponentMsComponents2ColOverview',
            'ComponentMsComponents2ColToggleSection',
            'ComponentMsComponentsApproachCta',
            'ComponentMsComponentsBlockquoteCard',
            'ComponentMsComponentsBlockquoteSection',
            'ComponentMsComponentsCtaInlineCallout',
            'ComponentMsComponentsFooter',
            'ComponentMsComponentsHeader',
            'ComponentMsComponentsHeroBgVisual',
            'ComponentMsComponentsHeroInlineVisual',
            'ComponentMsComponentsHeroNoVisual',
            'ComponentMsComponentsHeroWithMedia',
            'ComponentMsComponentsJobPostingList',
            'ComponentMsComponentsPartnerLogo',
            'ComponentMsComponentsPartnerLogos',
            'ComponentMsComponentsPhotoCarousel',
            'ComponentMsComponentsPrefooterCta',
            'ComponentMsComponentsProductFeature',
            'ComponentMsComponentsProductFeatureGrid',
            'ComponentMsComponentsProductOverviewCta',
            'ComponentMsComponentsSectionIntro',
            'ComponentMsComponentsStat',
            'ComponentMsComponentsStatsSection',
            'ComponentMsComponentsStayInTouchCta',
            'ComponentMsComponentsStories',
            'ComponentMsComponentsStory',
            'ComponentMsComponentsTeamMemberRow',
            'ComponentMsComponentsTeamMemberSection',
            'ComponentMsComponentsTextSection',
            'ComponentMsComponentsToggleSectionCard',
            'Error'
        ],
        ThriveHapiResponse: [
            'HapiAccessToken',
            'HapiIdToken',
            'HapiSessionToken'
        ],
        UnifiedResult: [
            'EnablingChallengeStatus',
            'ErrorMessage',
            'JoinChallengeSucceeded',
            'UpdateUserChallengeSucceeded'
        ],
        WaterOperationResult: ['ErrorMessageWater', 'IntakeResult'],
        WaterPlantResponse: ['WaterPlantErrorResult', 'WaterPlantResult'],
        WithBookmarkMutation: ['ResetAudioMutationV2', 'ResetVideoMutationV2'],
        WithBookmarkQuery: ['ResetAudioQueryV2', 'ResetVideoQueryV2'],
        WithLikeMutation: ['ResetAudioMutationV2', 'ResetVideoMutationV2'],
        WithLikeQuery: ['ResetAudioQueryV2', 'ResetVideoQueryV2']
    }
}
export default result
