import { captureException } from '@thriveglobal/thrive-web-core'
import {
    MicrostepRemovalReason,
    useCommitUserToMicrostepsMutation,
    useUnCommitToMicrostepMutation
} from '../graphql/generated/autogenerated'

export const useMicrosteps = () => {
    const [commitToMicrostep, { loading: isCommitting }] =
        useCommitUserToMicrostepsMutation()

    const [uncommitToMicrostep, { loading: isUncommitting }] =
        useUnCommitToMicrostepMutation()

    const handleCommit = async (microsteps: string) => {
        try {
            await commitToMicrostep({
                variables: {
                    microsteps
                }
            })
        } catch (error) {
            captureException(error, {
                message: 'Failed to get commit microstep'
            })
        }
    }

    const handleUncommit = async (microstepId: string) => {
        try {
            await uncommitToMicrostep({
                variables: {
                    microstepAndRemovalReason: {
                        microstepId,
                        removalReason: MicrostepRemovalReason.NotInterested
                    }
                }
            })
        } catch (error) {
            captureException(error, {
                message: 'Failed to get uncommit microstep'
            })
        }
    }

    return {
        handleCommit,
        handleUncommit,
        isCommitting,
        isUncommitting
    }
}
