import {
    Avatar,
    Card,
    CardContent,
    ListItem,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import GuideCard from './GuideCard'
import RecommendedItem from './RecommendItem'
import { ResponseType, ChatHistory } from '../../../data/models'
import { useIntl } from 'react-intl'
import { messages } from '../../../data/messages'
import { DailyCheckInCard } from '@thriveglobal/thrive-web-pulse-insights'

export type GuideResponseProps = {
    item: ChatHistory
    fullName: string
    userProfileImageUrl: string
    loading?: boolean
}

function GuideResponse(props: GuideResponseProps): JSX.Element {
    const { item, fullName, userProfileImageUrl, loading } = props
    const theme = useTheme()
    const intl = useIntl()

    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    if (!item) return null

    return (
        <ListItem
            data-testid="ChatResponse"
            sx={{
                justifyContent:
                    item.type === 'human' ? 'flex-end' : 'flex-start',
                maxWidth: '100%',
                alignItems: 'flex-end',
                width: '100%'
            }}
        >
            {item.type == ResponseType.AI && (
                <>
                    <Avatar
                        src="https://assets.thriveglobal.com/company_logos/Thrive-Mark-Multi.png"
                        alt={intl.formatMessage(messages.avatarAriaLabel)}
                        sx={{
                            width: 40,
                            height: 40,
                            padding: '5px',
                            mr: theme.spacing(1),
                            backgroundColor: 'secondary.main',
                            boxShadow: 2,
                            '& img': {
                                objectFit: 'contain' // This will apply to the image inside the Avatar
                            }
                        }}
                    />

                    <GuideCard
                        loading={loading}
                        item={item}
                        content={
                            item.data?.recommendations ? (
                                <RecommendedItem
                                    item={item}
                                    recommendations={item.data.recommendations}
                                />
                            ) : null
                        }
                        assessment={
                            item.data?.assessment ? (
                                <DailyCheckInCard
                                    dci={item.data?.assessment}
                                    onSubmitSurvey={() => {}}
                                    isMakeup={false}
                                    loading={loading}
                                />
                            ) : null
                        }
                    ></GuideCard>
                </>
            )}

            {item.type == ResponseType.HUMAN && (
                <>
                    <Card
                        sx={{
                            color: 'accent.contrastText',
                            backgroundColor: 'primary.main'
                        }}
                    >
                        <CardContent
                            sx={{
                                padding: '16px !important'
                            }}
                        >
                            <CoreTypography
                                variant="body2"
                                style={{
                                    whiteSpace: 'pre-wrap'
                                }}
                            >
                                {item.message}
                            </CoreTypography>
                        </CardContent>
                    </Card>
                    <Avatar
                        src={userProfileImageUrl}
                        alt={fullName}
                        sx={{
                            backgroundColor: 'primary.light',
                            ml: theme.spacing(1),
                            boxShadow: 2,
                            width: theme.spacing(isMobileView ? 5 : 6),
                            height: theme.spacing(isMobileView ? 5 : 6)
                        }}
                    ></Avatar>
                </>
            )}
        </ListItem>
    )
}

export default React.memo(GuideResponse)
