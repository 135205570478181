import { Box, Card, CardContent, Stack } from '@mui/material'
import {
    useCrossAppNavigation,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import React, { ReactNode, useContext } from 'react'
import FeedbackActions from './FeedbackActions'
import {
    CoreTypography,
    useTheme,
    ResetIframePlayer
} from '@thriveglobal/thrive-web-leafkit'
import { ChatContext } from '../../../context/chat'
import { ChatHistory, MessageType } from '../../../data/models'
import Markdown from 'react-markdown'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export type GuideCardProps = {
    item: ChatHistory
    content?: ReactNode
    loading?: boolean
    assessment?: ReactNode
}

function GuideCard(props: GuideCardProps): JSX.Element {
    const { item, content, assessment } = props

    const navigate = useCrossAppNavigation<{
        from: string
        referrer: string
        referrerTitle: string
    }>()

    const theme = useTheme()
    const { sessionId } = useContext(ChatContext)

    const header = item?.data?.header ?? false
    const body = item?.data?.body ?? ''

    const hasChatFeedbackEnabled = useIsFeatureEnabled(
        'hasChatFeedbackEnabled',
        false,
        true
    )

    const showFeedbackActions =
        hasChatFeedbackEnabled && item?.data?.transaction_id

    const handleSubmitFeedback = (response: boolean) => {
        const message_type = item?.data?.recommendations
            ? MessageType.RECOMMENDATION
            : MessageType.QUESTION

        Avo.reactionSubmitted({
            activityType: 'feedback_submitted',
            contentFormatType: 'text',
            contentId: item?.data?.content_id?.toString(),
            contentSubtype: null,
            contentTitle: item?.data?.header,
            contentType: null,
            contentUrl: null,
            conversationId: sessionId,
            featureType: 'thrive_guide',
            messageType: message_type,
            feedbackAnswer: response ? 'positive' : 'negative',
            feedbackQuestion: null,
            openAnswer: null,
            openQuestion: null,
            resetType: null,
            transactionId: item?.data?.transaction_id,
            contentSource: 'thrive',
            tabName: null
        })
    }

    if (
        item.data.navigation &&
        item.data.navigation.url &&
        item.data.navigation.url.length > 0
    ) {
        if (!item.data.navigation.didNavigate) {
            item.data.navigation.didNavigate = true
            navigate(item.data.navigation.url)
            console.log('navigation', item.data.navigation)
        }
    }

    return (
        <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            <Card data-testid="ChatCard">
                <CardContent sx={{ padding: theme.spacing(2) + '!important' }}>
                    {header && (
                        <CoreTypography variant="h5">{header}</CoreTypography>
                    )}

                    <Box mb={'-10'}>
                        <CoreTypography variant="body1">
                            <Markdown
                                children={body}
                                components={{
                                    p: 'div',
                                    a(props) {
                                        const {
                                            children,
                                            className,
                                            node,
                                            ...rest
                                        } = props

                                        if (
                                            props.href.includes(
                                                'video.thriveglobal.com'
                                            )
                                        ) {
                                            return (
                                                <Box mt={5} height={'400px'}>
                                                    <ResetIframePlayer
                                                        src={props.href}
                                                        autoplay={false}
                                                        controls={true}
                                                    />
                                                </Box>
                                            )
                                        } else {
                                            return <a {...rest}>{children}</a>
                                        }
                                    }
                                }}
                            />
                        </CoreTypography>
                    </Box>

                    {!!content && content}
                    {!!assessment && <Box mt={3}>{assessment}</Box>}
                </CardContent>
            </Card>
            {showFeedbackActions && (
                <FeedbackActions onFeedback={handleSubmitFeedback} />
            )}
        </Stack>
    )
}

export default React.memo(GuideCard)
