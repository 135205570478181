export enum ResponseType {
    HUMAN = 'human',
    AI = 'ai'
}

export enum MessageType {
    ACTION = 'multiple_choice_response',
    TEXT = 'free_form_text',
    RECOMMENDATION = 'recommendation',
    QUESTION = 'question'
}

export enum ContentType {
    MICROSTEP = 'MICROSTEP',
    RESET = 'RESET',
    CHALLENGE = 'CHALLENGE',
    ROLEMODEL = 'ROLE_MODEL',
    RECIPE = 'RECIPE'
}

export interface ChatResponse {
    input: string
    chat_history: ChatHistory[]
    output: ChatOutput
    session_id: string
    intermediate_steps?: IntermediateSteps[]
}

export interface IntermediateSteps {
    tool?: string
    tool_input?: string
}

export interface ChatHistory {
    type: string
    data?: ChatOutput
    message?: string
}

export interface ChatOutput {
    header?: string
    body: string
    actions?: string[]
    recommendations?: ChatContent[]
    transaction_id?: string
    content_id?: number
    assessment?: any
    navigation?: any
}

export interface ChatContent {
    title: string
    mediaType: string
    id: string
    type: string
    committed?: boolean
}

export enum GuideTab {
    Chat,
    Goals
}
