import { ComponentType, useCallback, useEffect, useMemo } from 'react'

import { useChat } from '../../hooks/useChat'
import ChatContext, { ChatContextType } from './ChatContext'
import { useMicrosteps } from '../../hooks/useMicrosteps'
import { ChatContent } from '../../data/models'

export default function withChatProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        const {
            chatHistory,
            loading,
            submitChatMessage,
            handleNavigateToContent,
            handleUpdateMicrostep,
            sessionId,
            submitChatError,
            handleClearChatError,
            updateUIElements
        } = useChat()

        const { handleCommit, handleUncommit } = useMicrosteps()

        const handleMicrostep = useCallback(
            (microstep: ChatContent) => {
                handleUpdateMicrostep(microstep)
                if (microstep?.committed) {
                    handleUncommit(microstep.id)
                } else {
                    handleCommit(microstep.id)
                }
            },
            [handleUncommit, handleCommit, handleUpdateMicrostep]
        )

        const state = useMemo<ChatContextType>(
            () => ({
                loading: loading,
                handleNavigateToContent: handleNavigateToContent,
                submitChatMessage: submitChatMessage,
                chatHistory: chatHistory,
                handleMicrostep: handleMicrostep,
                sessionId: sessionId,
                error: submitChatError,
                handleClearChatError: handleClearChatError,
                updateUIElements: updateUIElements
            }),
            [
                submitChatMessage,
                handleMicrostep,
                chatHistory,
                loading,
                handleNavigateToContent,
                sessionId,
                submitChatError,
                handleClearChatError,
                updateUIElements
            ]
        )

        return (
            <ChatContext.Provider value={state}>
                <Component {...props} />
            </ChatContext.Provider>
        )
    }
}
