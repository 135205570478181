export enum ROUTES {
    JOURNEY = '/journeys/:id',
    JOURNEYS = '/journeys',
    CHALLENGE_PAGE = '/challenges/:challengeId',
    ARTICLE_PAGE = '/learn/articles/:articleId',
    COURSE_PAGE = '/learn/courses/:courseId',
    PODCAST_PAGE = '/learn/podcasts/:podcastId',
    COURSE_MODULE = '/learn/courses',
    PODCAST_EPISODE = '/learn/podcasts',
    WEBINAR_PAGE = '/learn/webinars/:webinarId',
    ACHIEVEMENTS_PAGE = '/achievements',
    ACHIEVEMENTS_GARDEN = '/achievements/garden',
    RESETS = '/reset',
    CHALLENGES = '/challenges',
    LIBRARY = '/learn',
    MICROSTEPS = '/today/microsteps/',
    MICROSTEPS_DIALOG = '/today/microsteps/:microstepId',
    DOES_NOT_EXIST = '/does-not-exist',
    INSIGHTS_WELLBEING = '/insights/your-wellbeing'
}
