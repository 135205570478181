import React, { useMemo } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

function AvatarIcon(props: SvgIconProps) {
    const { sx, ...restProps } = props

    const iconStyles: SvgIconProps['sx'] = useMemo(() => {
        return sx ?? {}
    }, [sx])

    return (
        <SvgIcon
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            data-testid="AvatarIcon"
            sx={{
                ...iconStyles
            }}
            {...restProps}
        >
            <svg
                viewBox="-2.15 -1.842 43.51 42.398"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <filter
                        id="filter0_dd_7207_5116"
                        x="0.875"
                        y="0.3125"
                        width="52.25"
                        height="52.25"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="0.34375" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.117647 0 0 0 0 0 0 0 0 0 0.337255 0 0 0 0.31 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_7207_5116"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feMorphology
                            radius="1.375"
                            operator="erode"
                            in="SourceAlpha"
                            result="effect2_dropShadow_7207_5116"
                        />
                        <feOffset dy="3.4375" />
                        <feGaussianBlur stdDeviation="2.75" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.117647 0 0 0 0 0 0 0 0 0 0.337255 0 0 0 0.15 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="effect1_dropShadow_7207_5116"
                            result="effect2_dropShadow_7207_5116"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect2_dropShadow_7207_5116"
                            result="shape"
                        />
                    </filter>
                </defs>
                <g
                    filter="url(#filter0_dd_7207_5116)"
                    transform="matrix(1, 0, 0, 1, -7.557000160217285, -4.833000183105469)"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.1207 33.4121C18.0501 35.8288 31.1151 33.9446 36.4627 27.4626C40.8973 22.0873 40.1142 14.1179 34.7137 9.66258C29.3131 5.20723 21.5053 6.08914 17.0707 11.4644C11.8797 17.7566 12.1087 30.9273 15.1207 33.4121ZM31.7735 37.6925H17.1007V39.9269H31.7735V37.6925Z"
                        fill="#9870E5"
                    />
                    <rect
                        x="25.9268"
                        y="38.9668"
                        width="6.8829"
                        height="2.23438"
                        transform="rotate(-90 25.9268 38.9668)"
                        fill="#9870E5"
                    />
                    <rect
                        x="20.8457"
                        y="39.0527"
                        width="6.8829"
                        height="2.23438"
                        transform="rotate(-90 20.8457 39.0527)"
                        fill="#9870E5"
                    />
                    <path
                        d="M34.3913 26.6896C30.3474 30.7335 23.791 30.7335 19.7471 26.6896C15.7032 22.6457 15.0053 15.2631 19.7471 14.2988C21.4441 13.9537 23.8011 16.372 23.8011 16.372C23.8011 16.372 25.8212 18.6218 27.0456 18.6218C29.7036 18.6158 30.2085 14.2988 34.0589 14.2988C38.3878 14.2988 38.4352 22.6457 34.3913 26.6896Z"
                        fill="#F3F3F3"
                    />
                    <mask
                        id="mask0_7207_5116"
                        style={{ maskType: 'alpha' }}
                        maskUnits="userSpaceOnUse"
                        x="16"
                        y="14"
                        width="22"
                        height="16"
                    >
                        <path
                            d="M34.3913 26.6876C30.3474 30.7315 23.791 30.7315 19.7471 26.6876C15.7032 22.6437 15.0053 15.2612 19.7471 14.2968C21.4441 13.9517 23.8011 16.3701 23.8011 16.3701C23.8011 16.3701 25.8212 18.6199 27.0456 18.6199C29.7036 18.6138 30.2085 14.2968 34.0589 14.2968C38.3878 14.2968 38.4352 22.6437 34.3913 26.6876Z"
                            fill="#F3F3F3"
                        />
                    </mask>
                    <g mask="url(#mask0_7207_5116)">
                        <path
                            d="M26.7954 20.118C29.4222 25.1444 35.6264 27.0897 40.6528 24.4629"
                            stroke="#E0D3FB"
                            stroke-width="2.0625"
                        />
                        <path
                            d="M26.8839 21.28C23.5047 25.8348 17.4758 27.0867 13.418 24.0762"
                            stroke="#E0D3FB"
                            stroke-width="2.0625"
                        />
                    </g>
                    <path
                        d="M27.0438 23.0292C27.0438 18.0501 23.0075 14.0137 18.0283 14.0137"
                        stroke="#4D1AAD"
                        stroke-width="2.0625"
                    />
                    <path
                        d="M27.0438 23.0292C27.0438 18.0501 31.0802 14.0137 36.0593 14.0137"
                        stroke="#4D1AAD"
                        stroke-width="2.0625"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.9431 20.877C22.3064 20.5619 22.5362 20.0968 22.5362 19.5781C22.5362 18.6289 21.7667 17.8594 20.8174 17.8594C19.8682 17.8594 19.0986 18.6289 19.0986 19.5781C19.0986 20.0968 19.3284 20.5618 19.6916 20.877C19.8619 20.4207 20.3017 20.0957 20.8174 20.0957C21.3331 20.0957 21.7729 20.4207 21.9431 20.877Z"
                        fill="#4D1AAD"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.3963 20.877C34.7595 20.5619 34.9893 20.0968 34.9893 19.5781C34.9893 18.6289 34.2198 17.8594 33.2705 17.8594C32.3213 17.8594 31.5518 18.6289 31.5518 19.5781C31.5518 20.0968 31.7815 20.5618 32.1448 20.877C32.3151 20.4207 32.7548 20.0957 33.2705 20.0957C33.7862 20.0957 34.226 20.4207 34.3963 20.877Z"
                        fill="#4D1AAD"
                    />
                </g>
            </svg>
        </SvgIcon>
    )
}

export default React.memo(AvatarIcon)
