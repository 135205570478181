import { useCallback, useContext, useEffect, useRef } from 'react'
import { Box, Grid, List, Stack, Typography } from '@mui/material'
import {
    LeafIcon,
    PageLayout,
    useTheme,
    FullLogoMulti,
    MarkLogoMulti
} from '@thriveglobal/thrive-web-leafkit'
import { ChatContext, withChatProvider } from '../context/chat'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import GuideInput from '../components/features/ThriveGuide/GuideInput'
import GuideResponse from '../components/features/ThriveGuide/GuideResponse'
import GuideActions from '../components/features/ThriveGuide/GuideActions'
import GuideResponseLoading from '../components/features/ThriveGuide/GuideResponseLoading'
import AvatarIcon from '../icons/avatar'

function GuidePage(): JSX.Element {
    const listRef = useRef<HTMLDivElement | null>(null)
    const bottomOfListRef = useRef<HTMLDivElement | null>(null)
    const { chatHistory, loading } = useContext(ChatContext)

    const theme = useTheme()

    const { fullName, userProfileImageUrl } = useAppSelector(
        (state) => state.user
    )

    const handleScrollToBottomOfList = useCallback(() => {
        if (bottomOfListRef.current && listRef.current) {
            const buttonContainerTop =
                bottomOfListRef.current.offsetTop - listRef.current.offsetTop
            listRef.current.scrollTo({
                top: buttonContainerTop,
                behavior: 'smooth'
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatHistory, bottomOfListRef, listRef])

    useEffect(() => {
        handleScrollToBottomOfList()
    }, [handleScrollToBottomOfList])

    const latestMessage = chatHistory.slice(-1)[0]

    const chatActions = latestMessage?.data?.actions ?? []

    return (
        <Grid container spacing={0} alignItems="center" justifyContent="center">
            {/* Fixed Thrive Coach Header */}
            <Box
                sx={{
                    backgroundColor: theme.palette.primary.dark,
                    width: '100%',
                    padding: theme.spacing(2),
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'sticky', // Keeps it fixed at the top
                    top: 0, // Ensures it stays at the top
                    zIndex: 1 // Ensure it's above other content
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <MarkLogoMulti
                        sx={{
                            height: '2.9rem',
                            width: 'auto',
                            img: {
                                height: '100%',
                                objectFit: 'cover'
                            }
                        }}
                    />
                    <Typography variant="h5" color="white">
                        Thrive AI Coach
                    </Typography>
                </Stack>
            </Box>

            {/* Main Content Section */}
            <Grid
                item
                lg={12}
                sx={{
                    boxShadow: theme.shadows[8],
                    backgroundColor: theme.palette.grey[100],
                    height: '100vh', // Full height of the viewport
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* Scrollable chat content between the header and input */}
                <Stack
                    ref={listRef}
                    justifyContent="space-between"
                    sx={{
                        overflowY: 'auto', // Makes the content scrollable
                        flexGrow: 1, // Take up remaining space between header and input
                        paddingBottom: theme.spacing(2) // Add some spacing at the bottom
                    }}
                >
                    <List>
                        {chatHistory.map((item, index) => {
                            return (
                                <GuideResponse
                                    key={`chat-item-${index}`}
                                    item={item}
                                    loading={loading}
                                    fullName={fullName ?? ''}
                                    userProfileImageUrl={
                                        userProfileImageUrl ?? ''
                                    }
                                />
                            )
                        })}
                        <Box ref={bottomOfListRef}></Box>
                        {loading && <GuideResponseLoading />}
                    </List>
                </Stack>

                {/* Input fixed at the bottom */}
                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: theme.palette.background.paper,
                        px: theme.spacing(1),
                        py: theme.spacing(1),
                        boxShadow: theme.shadows[2]
                    }}
                >
                    <GuideInput />
                </Box>
            </Grid>
        </Grid>
    )
}

export default withChatProvider(GuidePage)
