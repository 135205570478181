import {
    captureException,
    useCrossAppNavigation
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { messages } from '../data/messages'
import { getPathToFeaturedContent } from '../utils/getPathToFeaturedContent'
import { toTitleCase } from '../utils/toTitleCase'
import { REFERRER_NAME } from '../constants'
import { ChatContent, MessageType } from '../data/models'
import { ROUTES } from '../routes/routes'
import useChatRequests from './useChatRequests'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export const useChat = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const storedHistory = sessionStorage.getItem('thrive_ai_chat_history')
    const [submitChatError, setSubmitChatError] = useState(false)
    const [updateUIElements, setUpdateUIElements] = useState([])

    const { getChatHistory, getWelcomeMessage, getProactiveMessages } =
        useChatRequests()

    const navigate = useCrossAppNavigation<{
        from: string
        referrer: string
        referrerTitle: string
    }>()

    const [chatHistory, setChatHistory] = useState(
        (storedHistory &&
            storedHistory !== 'undefined' &&
            JSON.parse(storedHistory).history) ||
            []
    )

    const [sessionId, setSessionId] = useState(
        (storedHistory &&
            storedHistory !== 'undefined' &&
            JSON.parse(storedHistory).sessionId) ||
            null
    )

    const handleUpdateSessionStorage = useCallback((history, sessionId) => {
        setChatHistory(history)
        setSessionId(sessionId)

        sessionStorage.setItem(
            'thrive_ai_chat_history',
            JSON.stringify({
                history,
                sessionId
            })
        )
    }, [])

    const handleClearChatError = useCallback(() => {
        setSubmitChatError(false)
    }, [])

    const handleGetWelcomeMessage = useCallback(async () => {
        try {
            const response = await getWelcomeMessage()
            const data = await response.json()
            const message = {
                type: 'ai',
                data
            }

            setChatHistory([message])
        } catch (error) {
            captureException(error, {
                message: 'Failed to get welcome message'
            })
        }
    }, [getWelcomeMessage])

    const handleGetProactiveMessages = useCallback(async () => {
        try {
            const response = await getProactiveMessages()
            const data = await response.json()
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    const message = {
                        type: 'ai',
                        data: data[i]
                    }
                    setChatHistory((prev) => [...prev, message])
                }
            }
        } catch (error) {
            captureException(error, {
                message: 'Failed to get proactive messages'
            })
        }
    }, [getProactiveMessages])

    const handleSubmitEvents = useCallback(
        (data, messageType) => {
            try {
                const responseRecommendations =
                    data?.output?.recommendations &&
                    data?.output?.recommendations.length > 0

                if (data?.output?.transaction_id) {
                    Avo.responseSubmitted({
                        activityType: 'thrive_guide_user_responded',
                        featureName: 'thrive_guide',
                        conversationId: sessionId,
                        featureType: 'thrive_guide',
                        messageType: messageType,
                        transactionId: data?.output?.transaction_id
                    })

                    Avo.responseServed({
                        activityType: 'thrive_guide_recommendation',
                        conversationId: sessionId,
                        featureType: 'thrive_guide',
                        messageType: messageType,
                        transactionId: data?.output?.transaction_id
                    })
                }
            } catch (error) {
                captureException(error, {
                    message: 'Failed to submit recommendation event'
                })
            }
        },
        [sessionId]
    )

    const handleAddUserMessageToChatHistory = useCallback(
        (message: string) => {
            setChatHistory([
                ...chatHistory,
                {
                    type: 'human',
                    message
                }
            ])
        },
        [chatHistory]
    )

    const handleCreateMessageBody = useCallback(
        (message: string) => {
            const body = {
                message
            }
            if (sessionId !== null) {
                body['session_id'] = sessionId
            }
            return body
        },
        [sessionId]
    )

    const submitChatMessage = useCallback(
        async (messageType: MessageType, message: string) => {
            setLoading(true)
            handleAddUserMessageToChatHistory(message)
            try {
                const response = await getChatHistory(
                    handleCreateMessageBody(message)
                )

                if (response.status === 200) {
                    const data = await response.json()
                    handleSubmitEvents(data, messageType)
                    handleUIUpdates(data)
                    const history = [
                        ...chatHistory,
                        { type: 'human', message: data.input },
                        { type: 'ai', data: data.output }
                    ]
                    handleUpdateSessionStorage(history, data.session_id)
                } else {
                    setSubmitChatError(true)
                }
            } catch (error) {
                captureException(error, {
                    message: 'Failed to submit message'
                })
            } finally {
                setLoading(false)
            }
        },
        [
            chatHistory,
            getChatHistory,
            handleUpdateSessionStorage,
            handleSubmitEvents,
            handleAddUserMessageToChatHistory,
            handleCreateMessageBody
        ]
    )

    const handleUpdateMicrostep = useCallback(
        (microstep: ChatContent) => {
            try {
                const updatedHistory = chatHistory.map((item) => {
                    if (item.type === 'ai' && item.data?.recommendations) {
                        const updatedRecommendations =
                            item.data.recommendations.map((recommendation) => {
                                if (recommendation.id === microstep.id) {
                                    return {
                                        ...recommendation,
                                        committed: !recommendation.committed
                                    }
                                }
                                return recommendation
                            })

                        return {
                            ...item,
                            data: {
                                ...item.data,
                                recommendations: updatedRecommendations
                            }
                        }
                    }
                    return item
                })

                handleUpdateSessionStorage(updatedHistory, sessionId)
            } catch (error) {
                captureException(error, {
                    message: 'ThriveAiChat failed to update microstep'
                })
            }
        },
        [chatHistory, sessionId, handleUpdateSessionStorage]
    )

    const handleNavigateToContent = useCallback(
        (item) => {
            let parentId = ''
            let subParentId = ''
            if (item?.parent?.__typename === 'PodcastEpisodeParent') {
                parentId = item?.parent?.podcastID
                subParentId = item?.parent?.seasonID
            }
            if (item?.parent?.__typename === 'CourseModuleParent') {
                parentId = item?.parent?.courseID
            }

            navigate(
                getPathToFeaturedContent(
                    toTitleCase(item.type),
                    item.id,
                    parentId,
                    subParentId
                ),
                {
                    from: location?.pathname ?? ROUTES.JOURNEYS,
                    referrer: REFERRER_NAME,
                    referrerTitle: intl.formatMessage(messages.backToJourneys)
                }
            )
        },
        [navigate, intl]
    )

    const handleUIUpdates = (data) => {
        const toolNames = [
            'user_goal_update_tool',
            'user_goal_delete_tool',
            'user_goal_complete_tool',
            'user_goal_saving_tool',
            'user_goal_complete_achieve_tool'
        ]

        if (
            data.intermediate_steps &&
            data.intermediate_steps.length > 0 &&
            toolNames.includes(data.intermediate_steps[0][0].tool)
        ) {
            setUpdateUIElements(['goals'])
        }
    }

    useEffect(() => {
        if (!storedHistory) {
            handleGetWelcomeMessage()
        }
    }, [handleGetWelcomeMessage, storedHistory])

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleGetProactiveMessages()
        }, 30000) // 30000 milliseconds = 30 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId)
    }, [handleGetProactiveMessages])

    return {
        chatHistory,
        submitChatMessage,
        loading,
        handleNavigateToContent,
        handleGetWelcomeMessage,
        handleUpdateMicrostep,
        sessionId,
        submitChatError,
        handleClearChatError,
        updateUIElements,
        handleGetProactiveMessages
    }
}
