import { InputBase, Stack } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { messages } from '../../../data/messages'
import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'
import { ChatContext } from '../../../context/chat'
import { MessageType } from '../../../data/models'
import SpeechRecognition, {
    useSpeechRecognition
} from 'react-speech-recognition'

function GuideInput(): JSX.Element {
    const theme = useTheme()
    const intl = useIntl()

    const { submitChatMessage, loading } = useContext(ChatContext)

    const [textfieldValue, setTextfieldValue] = useState('')

    const disableSendButton = loading || textfieldValue === ''

    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition()

    const handleSubmit = () => {
        if (!disableSendButton) {
            submitChatMessage(MessageType.TEXT, textfieldValue)
            setTextfieldValue('')
        }
    }

    const handleTextFieldChange = (e) => {
        setTextfieldValue(e.target.value)
    }

    const handleSpeechToText = () => {
        if (listening) {
            SpeechRecognition.stopListening()
        } else {
            SpeechRecognition.startListening()
        }
    }

    useEffect(() => {
        if (transcript) {
            setTextfieldValue(transcript)
        }
    }, [transcript])

    return (
        <Stack
            data-testid="ChatInput"
            direction={'row'}
            sx={{
                backgroundColor: 'background.paper',
                padding: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                marginY: theme.spacing(0.5),
                mt: theme.spacing(1)
            }}
        >
            {browserSupportsSpeechRecognition && (
                <IconButton
                    aria-label={intl.formatMessage(messages.inputAriaLabel)}
                    onClick={handleSpeechToText}
                    variant={'contained'}
                    color={listening ? 'error' : 'primary'}
                    disabled={!isMicrophoneAvailable}
                >
                    <LeafIcon
                        fontSize={'medium'}
                        icon={'microphone'}
                        color={'info'}
                    />
                </IconButton>
            )}

            <InputBase
                sx={{ ml: theme.spacing(1) }}
                onChange={handleTextFieldChange}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !disableSendButton) {
                        handleSubmit()
                    }
                }}
                placeholder={intl.formatMessage(messages.inputPlaceholder)}
                fullWidth
                value={textfieldValue}
            />
            <IconButton
                aria-label={intl.formatMessage(messages.inputAriaLabel)}
                onClick={handleSubmit}
                disabled={disableSendButton}
                color={'primary'}
                variant={'contained'}
            >
                <LeafIcon icon={'send'} />
            </IconButton>
        </Stack>
    )
}

export default React.memo(GuideInput)
