import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    resetChatPrompt: {
        defaultMessage: 'How else can I help?',
        description: 'Once user clears chat window the reset message'
    },
    inputPlaceholder: {
        defaultMessage: 'Type a response...',
        description: 'Placeholder text for the chat input field.'
    },
    inputAriaLabel: {
        defaultMessage: 'Speak or type a message...',
        description: 'Accessible label for the chat input submission button.'
    },
    backToJourneys: {
        defaultMessage: `Back to Journeys`,
        description: `Clicking this message will take the user back to the 'Journeys' web page`
    },
    positiveFeedbackAriaLabel: {
        defaultMessage: 'Positive feedback button',
        description: 'Accessible label for the positive feedback button.'
    },
    negativeFeedbackAriaLabel: {
        defaultMessage: 'Negative feedback button',
        description: 'Accessible label for the positive feedback button.'
    },
    chatActionAriaLabel: {
        defaultMessage: '{message} - chat action button',
        description: 'Accessible label for the chat action button.'
    },
    avatarAriaLabel: {
        defaultMessage: 'Thrive avatar',
        description: 'Accessible label for the avatar icon.'
    }
})
