import { List, Stack } from '@mui/material'
import React from 'react'
import { ChatContent, ChatHistory } from '../../../data/models'
import RecommendedContent from './RecommendedContent'

export type RecommendedItemProps = {
    recommendations: ChatContent[]
    item: ChatHistory
}

function RecommendedItem(props: RecommendedItemProps): JSX.Element {
    const { recommendations, item } = props

    const transaction_id = item.data?.transaction_id

    return (
        <List data-testid="ChatItems">
            <Stack gap={1} width={'100%'}>
                {recommendations.map((recommendation, index) => {
                    return (
                        <RecommendedContent
                            key={`chat-item-${index}`}
                            item={recommendation}
                            transactionId={transaction_id}
                        />
                    )
                })}
            </Stack>
        </List>
    )
}

export default React.memo(RecommendedItem)
